import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { TodayEntrancePayAtTheDoorInitialState } from "slices/reservationMonitoring/todayEntrancePayAtTheDoor/reducer";
import { listTodayEntrancePayAtTheDoor } from "slices/reservationMonitoring/todayEntrancePayAtTheDoor/thunk";


const useTodayEntrancePayAtTheDoor = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { TodayEntrancePayAtTheDoor: TodayEntrancePayAtTheDoorInitialState }) => state.TodayEntrancePayAtTheDoor
  );

  useEffect(() => {
    dispatch(listTodayEntrancePayAtTheDoor());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useTodayEntrancePayAtTheDoor;
