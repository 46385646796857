import { COLORS } from "utils/cardColors";

interface TimerProps {
  countdown: number;
}
const Timer = ({ countdown }: TimerProps) => {

  return (
    <>
      <div
        className="d-flex rounded gap-2 justify-content-center align-items-center"
        style={{
          background: COLORS.red,
          color: "white",
          fontSize: "24px",
          width: "120px",
          marginBottom: "1rem"
        }}
      >
        <i className="ri-timer-line" />
        {countdown}
      </div>
    </>
  );
};

export default Timer;
