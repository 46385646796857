import { CustomRowOperationButton } from "Components/Custom/UI";
import CustomAuthorizedFile from "Components/Custom/UI/CustomAuthorizedFile/CustomAuthorizedFile";
import { useDispatch, useSelector } from "react-redux";
import { ListCostReviseInitialState } from "slices/reservationMonitoring/listCostRevise/reducer";
import {
  ChangeStatusPayload,
  ListCostReviseData,
  MarkAsResponsiblePayload,
  UpdateCostRevisePayload,
} from "types/reservationMonitoring";
import { TABLE_COLORS } from "utils/tableColors";
import { Dispatch, SetStateAction } from "react";
import { setToastError } from "slices/toast/reducer";
import { useNavigate } from "react-router-dom";
import {
  changeStatusNegative,
  changeStatusPositive,
  listCostRevise,
  markAsResponsible,
  updateCostRevise,
} from "slices/reservationMonitoring/listCostRevise/thunk";

function formatCurrency(value: string | number): string {
  const number = typeof value === 'string' ? parseFloat(value) : value;
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
}

const useHandleTable = (
  setSelectedReservationId: Dispatch<SetStateAction<number>>,
  setShowUpdateStatusModal: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const { costRevise } = useSelector(
    (state: { ListCostRevise: ListCostReviseInitialState }) =>
      state.ListCostRevise
  );
  const handleShowReservation = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-incele?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const handleUpdateReservation = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-duzenle?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const handleChangeStatusPositive = (payload: ChangeStatusPayload) => {
    try {
      dispatch(
        changeStatusPositive(payload, () => {
          setShowUpdateStatusModal((prevState) => !prevState);
          setSelectedReservationId(0);
          dispatch(listCostRevise());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleChangeStatusNegative = (payload: ChangeStatusPayload) => {
    try {
      dispatch(
        changeStatusNegative(payload, () => {
          setShowUpdateStatusModal((prevState) => !prevState);
          setSelectedReservationId(0);
          dispatch(listCostRevise());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleUpdateCostRevise = (payload: UpdateCostRevisePayload) => {
    try {
      dispatch(
        updateCostRevise(payload, () => {
          setShowUpdateStatusModal((prevState) => !prevState);
          setSelectedReservationId(0);
          dispatch(listCostRevise());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleMarkAsResponsible = (payload: MarkAsResponsiblePayload) => {
    try {
      dispatch(
        markAsResponsible(payload, () => {
          dispatch(listCostRevise());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const tableData = costRevise?.map((reservation) => {
    let rowColor = "";

    if (reservation.kbr_durum === 2) {
      rowColor = TABLE_COLORS.red;
    } else if (reservation.kbr_durum === 1) {
      rowColor = TABLE_COLORS.green;
    } else {
      rowColor = TABLE_COLORS.yellow;
    }

    return {
      rezervasyon_id: reservation.rezervasyon_id,
      id: reservation.id,
      rez_kodu: `${reservation.rezervasyon_kodu} ${
        reservation.ana_rez_kod ? reservation.ana_rez_kod : ""
      } (${reservation.otel_danismani})`,
      grup_adi: reservation.grup_adi,
      kayit_tarihi: reservation.kayit_tarihi,
      musteri_adi: `${reservation.musteri_ad_soyad} ${
        reservation.ucretli_cocuk_sayisi ? reservation.ucretli_cocuk_sayisi : ""
      } ${
        reservation.ucretli_cocuk_aciklama
          ? reservation.ucretli_cocuk_aciklama
          : ""
      }`,
      musteri_ad_soyad: reservation.musteri_ad_soyad,
      otel_adi_aciklama: reservation.otel_adi_aciklama,
      rez_tutar:
        reservation.iptal_edildi_mi === "e"
          ? reservation.iptal_tutar
          : formatCurrency(reservation.toplam_tutar),
      cekilen_tutar: reservation.cekilen_tutar,
      satici_adi: reservation.satici_adi,
      musaitlik: reservation.musaitlik,
      onay: `${reservation.konfirme_durum}  ${
        reservation.iptal_durum ? reservation.iptal_durum : ""
      }`,
      operator_aciklama: reservation.operator_aciklama,
      beklemede_ilgilenen_personel: reservation.beklemede_ilgilenen_personel,
      operasyon_fiyat: reservation.operasyon_fiyat,
      operasyon_not: reservation.operasyon_not,
      kbr_durum: reservation.kbr_durum,
      kbr_id: reservation.kbr_id,
      satici_id: reservation.satici_id,
      rez_tipi: reservation.rez_tipi,
      satis_operator: reservation.satis_operator,
      supheli_aciklama: reservation.supheli_aciklama,
      supheli_cevaplayan_ad_soyad: reservation.supheli_cevaplayan_ad_soyad,

      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "rowNumber",
      enableColumnFilter: false,
      cell: ({ row }: { row: { index: number } }) => <>{row.index + 1}</>,
    },
    {
      header: "Rez. Kodu",
      accessorKey: "rez_kodu",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Grup Adı",
      accessorKey: "grup_adi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Sat. Tarih",
      accessorKey: "kayit_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Müşteri Adı",
      accessorKey: "musteri_adi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();

        const formattedLines = value
          ?.split(/<\/?br>/)
          .filter((line: string) => line.trim() !== "") || [""];

        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {formattedLines.map((line: any, index: any) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      header: "Otel Adı",
      accessorKey: "otel_adi_aciklama",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();

        const formattedLines = value
          ?.split(/<\/?br>/)
          .filter((line: string) => line.trim() !== "") || [""];

        return (
          <>
            <div className="text-wrap" style={{ width: "100px" }}>
              {formattedLines.map((line: any, index: any) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      header: "Rez. T.",
      accessorKey: "rez_tutar",
      enableColumnFilter: false,
    },
    {
      header: "Çek. T.",
      accessorKey: "cekilen_tutar",
      enableColumnFilter: false,
    },
    {
      header: "Personel",
      accessorKey: "satici_adi",
      enableColumnFilter: false,
    },
    {
      header: "Müsaitlik",
      accessorKey: "musaitlik",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();

        const formattedLines = value
          ?.split(/<\/?br>/)
          .filter((line: string) => line.trim() !== "") || [""];

        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {formattedLines.map((line: any, index: any) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      header: "Onay",
      accessorKey: "onay",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();

        const formattedLines = value
          ?.split(/<\/?br>/)
          .filter((line: string) => line.trim() !== "") || [""];

        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {formattedLines.map((line: any, index: any) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      header: "Operatör",
      accessorKey: "operator_aciklama",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();

        const formattedLines = value
          ?.split(/<\/?br>/)
          .filter((line: string) => line.trim() !== "") || [""];

        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {formattedLines.map((line: any, index: any) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      header: "İlgilenen",
      accessorKey: "beklemede_ilgilenen_personel",
      enableColumnFilter: false,
    },
    {
      header: "Kar",
      accessorKey: "operasyon_fiyat",
      enableColumnFilter: false,
    },
    {
      header: "Not",
      accessorKey: "operasyon_not",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "200px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "yorum_id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData: ListCostReviseData = cell.row.original;
        const id = rowData.rezervasyon_id;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              {rowData.kbr_durum === 3 && (
                <CustomRowOperationButton
                  variant="check"
                  onClick={() => {
                    setSelectedReservationId(id);
                    setShowUpdateStatusModal(true);
                  }}
                  title="Tamamla"
                />
              )}
              <CustomAuthorizedFile
                needAllAuthorities={false}
                userId={[]}
                groupId={[]}
                yetki={["root", "admin", "user"]}
              >
                {(rowData.rez_tipi === "kaporalisatis" ||
                  rowData.rez_tipi === "kapidaodeme") && (
                  <CustomRowOperationButton
                    variant="update"
                    onClick={() => handleUpdateReservation(id)}
                    title="Düzenle"
                  />
                )}
              </CustomAuthorizedFile>
              <CustomRowOperationButton
                variant="info"
                onClick={() => handleShowReservation(id)}
                title="İncele"
              />
              {rowData.beklemede_ilgilenen_personel === "" && (
                <CustomRowOperationButton
                  variant="check"
                  onClick={() =>
                    handleMarkAsResponsible({
                      rezervasyon_id: id,
                    })
                  }
                  title="İlgileniyorum"
                />
              )}
              {rowData.supheli_cevaplayan_ad_soyad !== "" && (
                <CustomRowOperationButton
                  variant="warning"
                  onClick={() => {}}
                  title="Uyarı"
                />
              )}
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.yellow,
      label: "İlgileniyor",
    },
    {
      color: TABLE_COLORS.green,
      label: "Olumlu",
    },
    {
      color: TABLE_COLORS.red,
      label: "Olumsuz",
    },
  ];

  return {
    tableData,
    tableColumns,
    legend,
    reservations: costRevise,
    handleChangeStatusPositive,
    handleChangeStatusNegative,
    handleUpdateCostRevise,
  };
};

export default useHandleTable;
