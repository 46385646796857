import { createSlice } from "@reduxjs/toolkit";
import { TotalReservationData } from "types/reservationLists";

export interface TotalReservationDataInitialState {
  totalReservations: TotalReservationData[];
  isLoading: boolean;
  error: string;
}

const initialState: TotalReservationDataInitialState = {
  totalReservations: [],
  isLoading: false,
  error: "",
};

const totalReservationDataSlice = createSlice({
  name: "totalReservationData",
  initialState,
  reducers: {
    handleSaveTotalReservationDataResponse(state, action) {
      state.totalReservations = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveTotalReservationDataResponse, setIsLoading, setError } =
  totalReservationDataSlice.actions;

export default totalReservationDataSlice.reducer;
