import ListINetNonentryReservationsPage from "Components/Custom/ReservationListsPages/ListINetNonentryReservationsPage";
import useListINetNonentryReservations from "Components/Hooks/reservationLists/useListINetNonentryReservations";
import PageContainer from "pages/PageContainer";

const ListINetNonentryReservations = () => {
  const { isLoading } = useListINetNonentryReservations();

  return (
    <PageContainer title="Giriş Yapmayan iNet Rezervasyonları">
      <ListINetNonentryReservationsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default ListINetNonentryReservations;
