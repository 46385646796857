import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  CustomMultipleFileUploader,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import {
  AddCertificateFormErrors,
  AddCertificatePayload,
} from "types/myWebSite";
import { handleChangeInput } from "slices/myWebSite/addCertificate/reducer";
import { ADD_CERTIFICATE_IMAGE } from "utils/fieldDescriptions";

interface CertificateImageProps {
  values: AddCertificatePayload;
  formErrors: AddCertificateFormErrors;
  validateFormChange: (values: AddCertificatePayload) => void;
}

const CertificateImage = ({
  values,
  validateFormChange,
  formErrors,
}: CertificateImageProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const files = values.sertifika_link ? [values.sertifika_link] : [];


  return (
    <>
      <CustomFormFieldContainer
        label="Sertifika Pdf : "
        description={ADD_CERTIFICATE_IMAGE}
        error={formErrors.sertifika_link}
        required
      >
        <CustomMultipleFileUploader
          files={files as any}
          handleAddFiles={(fileItems) => {
            dispatch(handleChangeInput({sertifika_link: fileItems[0]}));
            validateFormChange({ ...values, sertifika_link: fileItems[0] });
          }}
          maxFiles={1}
          maxFileSize={500000000}
          allowedFileTypes={["application/pdf"]}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default CertificateImage;
