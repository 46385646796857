import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { ListPriceReviseData, UpdatePriveReviseSalesPayload } from "types/reservationMonitoring";
import ModalInfo from "./ModalInfo";

const lineStyle: React.CSSProperties = {
  borderBottom: "1px solid #eaeaea",
  maxWidth: "1000px",
  margin: "16px auto",
};

interface UpdateStatusSalesModalProps {
  reservations: ListPriceReviseData[];
  reservation_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  updateStatus: (payload: UpdatePriveReviseSalesPayload) => void;
}

const UpdateStatusSalesModal = ({
  reservations,
  reservation_id,
  isOpen,
  handleToggle,
  updateStatus,
}: UpdateStatusSalesModalProps) => {
  const reservation = reservations.find(
    (reservation) => reservation.id === reservation_id
  )!;

  const [answer, setAnswer] = useState("");
  const [operationPrice, setOperationPrice] = useState(
    (reservation.operasyonun_aldigi_fiyat ? reservation.operasyonun_aldigi_fiyat :  "")
  );
  const [childFree, setChildFree] = useState(reservation.child_free ?? "0");
  const [roomUpgrade, setRoomUpgrade] = useState(
    reservation.room_upgrade ?? "0"
  );

  useEffect(() => {
    setAnswer("");
    setOperationPrice(reservation.operasyonun_aldigi_fiyat ? reservation.operasyonun_aldigi_fiyat :  "");
    setChildFree(reservation.child_free ?? "0");
    setRoomUpgrade(reservation.room_upgrade ?? "0");
  }, [reservation]);


  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="lg">
        <ModalHeader>Durum Güncelle (Satış)</ModalHeader>
        <ModalInfo reservation={reservation} />
        <ModalBody>
          <FormGroup row className="d-flex align-items-center">
            <Label for="operationPrice" sm={3}>
              Operasyonun Aldığı Fiyat
            </Label>
            <Col sm={9}>
              <Input
                disabled
                id="operationPrice"
                type="text"
                placeholder="Fiyat"
                value={operationPrice}
                onChange={(e) => setOperationPrice(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={{ size: 9, offset: 3 }}>
              <Label check className="d-flex gap-2">
                <Input
                  disabled
                  type="checkbox"
                  checked={childFree === 1}
                  onChange={(e) => setChildFree(e.target.checked ? 1 : 0)}
                />
                Child Free
              </Label>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={{ size: 9, offset: 3 }}>
              <Label check className="d-flex gap-2">
                <Input
                  disabled
                  type="checkbox"
                  checked={roomUpgrade === 1}
                  onChange={(e) => setRoomUpgrade(e.target.checked ? 1 : 0)}
                />
                Room Upgrade
              </Label>
            </Col>
          </FormGroup>
          <div style={lineStyle}></div>
          <FormGroup row>
            <Label for="aciklama" sm={3} className="control-label">
              Açıklama
            </Label>
            <Col sm={9}>
              <Input
                id="aciklama"
                type="textarea"
                placeholder="Açıklama"
                rows={3}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="primary"
            onClick={() => updateStatus({
              teklif_id: reservation_id,
              durum: "0", 
              aciklama: answer,
            })}
          >
            Güncelle
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="success"
            onClick={() => updateStatus({
              teklif_id: reservation_id,
              durum: "1", 
              aciklama: answer,
            })}
          >
            Rezervasyon Tamamlandı
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="danger"
            onClick={() => updateStatus({
              teklif_id: reservation_id,
              durum: "0", 
              aciklama: answer,
            })}
          >
            Olumsuz
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default UpdateStatusSalesModal;
