import { useState } from "react";
import { useDispatch } from "react-redux";
import { handleCollectionFormErrors } from "slices/reservation/reviewReservation/reducer";
import { setToastError } from "slices/toast/reducer";
import {
  CreateGroupReservationPayload,
  CreateGroupReservationFormErrors,
} from "types/reservation";

const useValidateForm = () => {
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: CreateGroupReservationPayload,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: CreateGroupReservationFormErrors = {};

    if (!values.yetiskin_sayisi) {
      errors.yetiskin_sayisi = "Yetişkin sayısı boş bırakılamaz.";
    }
    if (Number(values.yetiskin_sayisi) < 10) {
      errors.yetiskin_sayisi = "Yetişkin sayısı en az 20 olmalı!";
    }
    if (!values.musteri_ad_soyad) {
      errors.musteri_ad_soyad = "Müşteri ad soyadı boş bırakılamaz.";
    }
    if (!values.musteri_cep) {
      errors.musteri_cep = "Müşteri telefonu boş bırakılamaz.";
    } else if (!/^(0?5\d{9})$/.test(values.musteri_cep)) {
      errors.musteri_cep =
        "Geçerli bir telefon numarası giriniz. (05XXXXXXXXX)";
    }

    if (!values.otel_adi) {
      errors.otel_adi = "Otel seçiniz!";
    }
    if (!values.otel_id) {
      errors.otel_id = "Otel seçiniz!";
    }

    dispatch(handleCollectionFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    if (isScroll && !isValid) {
      scrollToError();
    }

    if (showErrorToast && !isValid) {
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));
    }

    return { isValid };
  };

  const validateFormChange = (values: CreateGroupReservationPayload) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return {
    validateForm,
    validateFormChange,
    handleSubmitted: setIsSubmitted,
    additionalPagesFormErrors: {},
  };
};

export default useValidateForm;
