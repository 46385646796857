import {
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { useEffect } from "react";
import Select from "react-select";
import { Input, Label } from "reactstrap";
import { InvoiceInfoAndOtherFieldsFormValues } from "types/reservation";

interface InvoiceInfoAndOtherFieldsFormProps {
  employeesOptions: Option[];
  formValues: InvoiceInfoAndOtherFieldsFormValues;
  handleChangeInput: (key: string, value: any) => void;
  paymentType: string;
}

interface Option {
  value: number;
  label: string;
  isSelf?: boolean;
}

const validateForm = (formValues: InvoiceInfoAndOtherFieldsFormValues) => {
  const errors: {
    invoiceName?: string;
    invoiceAddress?: string;
    invoiceTaxOffice?: string;
    invoiceTaxNumber?: string;
  } = {};

  if (!formValues.invoiceName) {
    errors.invoiceName = "Fatura Unvanı zorunludur!";
  }

  if (!formValues.invoiceAddress) {
    errors.invoiceAddress = "Fatura Adresi zorunludur!";
  }

  const isValid = Object.keys(errors).length === 0;

  return { isValid, errors };
};

const InvoiceInfoAndOtherFieldsForm = ({
  employeesOptions,
  formValues,
  handleChangeInput,
  paymentType,
}: InvoiceInfoAndOtherFieldsFormProps) => {
  const { errors } = validateForm(formValues);

  useEffect(() => {
    const employee =
      formValues.employeeId ||
      employeesOptions.find((option) => option.isSelf)?.value;

    handleChangeInput("employeeId", employee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesOptions]);

  return (
    <>
      {paymentType !== "kapida" && (
        <>
          <CustomFormContainer title="Rezervasyon Detayları">
            <div className="d-flex gap-2">
              <Input
                id="different-agent"
                type="checkbox"
                checked={formValues.diffrentAgent}
                onChange={(e) =>
                  handleChangeInput("diffrentAgent", e.target.checked)
                }
              />
              <Label for="different-agent" className="form-check-label">
                Farklı Acente Fiyatından Satış
              </Label>
            </div>
          </CustomFormContainer>
          <CustomFormContainer title="Fatura Bilgileri">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              <CustomFormFieldContainer
                label="Fatura Unvanı"
                divider
                required
                error={errors.invoiceName}
              >
                <Input
                  type="text"
                  placeholder="Fatura Unvanı"
                  value={formValues.invoiceName}
                  onChange={(e) =>
                    handleChangeInput("invoiceName", e.target.value)
                  }
                />
              </CustomFormFieldContainer>
              <CustomFormFieldContainer
                label="Fatura Adresi"
                required
                divider
                description="<span class='theme-text-danger fw-medium fs-14'> Fatura Adres Bilgileri Girişi Zorunludur! </span>"
                error={errors.invoiceAddress}
              >
                <Input
                  type="textarea"
                  placeholder="Fatura Adresi"
                  value={formValues.invoiceAddress}
                  onChange={(e) =>
                    handleChangeInput("invoiceAddress", e.target.value)
                  }
                />
              </CustomFormFieldContainer>
              <CustomFormFieldContainer
                label="Vergi Dairesi"
                divider
                error={errors.invoiceTaxOffice}
              >
                <Input
                  type="text"
                  placeholder="Vergi Dairesi"
                  value={formValues.invoiceTaxOffice}
                  onChange={(e) =>
                    handleChangeInput("invoiceTaxOffice", e.target.value)
                  }
                />
              </CustomFormFieldContainer>
              <CustomFormFieldContainer
                label="Vergi Numarası"
                divider
                error={errors.invoiceTaxNumber}
              >
                <Input
                  type="text"
                  placeholder="Vergi Numarası"
                  value={formValues.invoiceTaxNumber}
                  onChange={(e) =>
                    handleChangeInput("invoiceTaxNumber", e.target.value)
                  }
                />
              </CustomFormFieldContainer>
            </div>
            <span className="theme-text-danger fw-medium fs-14">
              Şahıs faturalarımız yukardaki girdiğiniz isme kesilerek otomatik
              mail adresine gönderilmektedir. Sadece Şirketler için Fatura
              Bilgileri kısmını giriniz. Fatura Ünvanı kısmına Şirket Resmi ve
              Tam adı yazılmalı (şahıs şirketi ise isimde olabilir) Mutlaka
              Şirketin Vergi dairesi, Vergi numarası ve resmi adresi yazılmalı.
              Tüm Faturalarımız Efatura şeklinde olup müşteri çıkış gününde
              yazdığınız mail adresine gönderilir.
            </span>
          </CustomFormContainer>
        </>
      )}
      <CustomFormContainer title="Açıklama">
        <CustomFormFieldContainer
          label=""
          orientation="vertical"
          divider
          description="<span class='theme-text-danger fw-medium fs-14'>(Rezervasyonda İndirim, Müsaitlik Onayı, Kombinasyon Onayı, Sigorta v.s ekstra bilgileri ekleyin.)</span>"
        >
          <Input
            type="textarea"
            placeholder="Açıklama"
            value={formValues.description}
            onChange={(e) => handleChangeInput("description", e.target.value)}
          />
        </CustomFormFieldContainer>
      </CustomFormContainer>
      <CustomFormContainer title="İşlemler">
        <CustomFormFieldContainer
          label="Satış Yapılan Kanal (Zorunlu Değil)"
          divider
        >
          <div className="d-flex gap-5">
            <div className="d-flex gap-2">
              <Input
                id="whatsapp"
                type="radio"
                name="satis_kanali"
                checked={formValues.sellingChannel === "whatsapp"}
                onChange={() => handleChangeInput("sellingChannel", "whatsapp")}
              />
              <Label for="whatsapp" className="form-check-label">
                Whatsapp
              </Label>
            </div>
            <div className="d-flex gap-2">
              <Input
                id="instagram"
                type="radio"
                name="satis_kanali"
                checked={formValues.sellingChannel === "instagram"}
                onChange={() =>
                  handleChangeInput("sellingChannel", "instagram")
                }
              />
              <Label for="instagram" className="form-check-label">
                Instagram
              </Label>
            </div>
            <div className="d-flex gap-2">
              <Input
                id="facebook"
                type="radio"
                name="satis_kanali"
                checked={formValues.sellingChannel === "facebook"}
                onChange={() => handleChangeInput("sellingChannel", "facebook")}
              />
              <Label for="facebook" className="form-check-label">
                Facebook
              </Label>
            </div>
          </div>
        </CustomFormFieldContainer>
        <CustomFormFieldContainer label="Rezervasyonu Yapan Kişi" divider>
          <Select
            value={
              employeesOptions.find(
                (option) => option.value === formValues.employeeId
              ) || employeesOptions.find((option) => option.isSelf)
            }
            onChange={(option: Option) => {
              handleChangeInput("employeeId", option.value);
            }}
            options={employeesOptions}
          />
        </CustomFormFieldContainer>
      </CustomFormContainer>
    </>
  );
};

export default InvoiceInfoAndOtherFieldsForm;
