import { CustomFormContainer } from "Components/Custom/UI";
import useHandleReservationData from "./useHandleReservationData";
import ReservationCardRow from "./ReservationCardRow";

const ReservationData = () => {
  const { cards } = useHandleReservationData();
  
  return (
    <CustomFormContainer title="Günün Özeti">
      {cards.map((item: any, index: number) => (
        <ReservationCardRow key={index} item={item} />
      ))}
    </CustomFormContainer>
  );
};

export default ReservationData;
