import { createSlice } from "@reduxjs/toolkit";
import {
  ListPriceReviseData,
  ListPriceRevisePayload,
  ListPriceReviseResponse,
} from "types/reservationMonitoring";

export interface ListPriceReviseInitialState {
  priceRevise: ListPriceReviseData[];
  filterPriceRevise: ListPriceRevisePayload;
  cardData: {
    toplam_maliyet: string;
    toplam_alinan_indirim: string;
  };
  isLoading: boolean;
  error: string;
  markAsResponsibleLoading: boolean;
  markAsResponsibleError: string;
}

const initialState: ListPriceReviseInitialState = {
  priceRevise: [],
  filterPriceRevise: {
    start_date: "",
    end_date: "",
    teklif_id: "",
  },
  cardData: {
    toplam_maliyet: "",
    toplam_alinan_indirim: "",
  },
  isLoading: false,
  error: "",
  markAsResponsibleLoading: false,
  markAsResponsibleError: "",
};

const priceReviseSlice = createSlice({
  name: "priceRevise",
  initialState,
  reducers: {
    handleSavePriceReviseResponse(state, action) {
      const { veriler, toplam_maliyet, toplam_alinan_indirim } =
        action.payload as ListPriceReviseResponse;
      state.priceRevise = veriler;
      state.cardData = {
        toplam_maliyet: toplam_maliyet,
        toplam_alinan_indirim: toplam_alinan_indirim,
      };
    },
    handleChangeFilterChoices(state, action) {
      state.filterPriceRevise = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setMarkAsResponsibleLoading(state, action) {
      state.markAsResponsibleLoading = action.payload;
    },
    setMarkAsResponsibleError(state, action) {
      state.markAsResponsibleError = action.payload;
    },
  },
});

export const {
  handleSavePriceReviseResponse,
  handleChangeFilterChoices,
  setIsLoading,
  setError,
  setMarkAsResponsibleLoading,
  setMarkAsResponsibleError,
} = priceReviseSlice.actions;

export default priceReviseSlice.reducer;
