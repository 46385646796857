import { useState } from "react";
import useHandleRequests from "./useHandleRequests";

const useHandleCards = () => {
  const {
    sorsatListeleCount,
    fiyatRevizeListesiCount,
    iptalTalebiListesiCount,
    maliyetRevizeListesiCount,
    countdown,
  } = useHandleRequests();

  const [selectedTable, setSelectedTable] = useState("sorsatListele");

  const reservationMonitoringCardData = [
    {
      info: sorsatListeleCount,
      title: "Sorsat Listesi",
      link: "/sorsat-listele",
      tableCode: "sorsatListele",
    },
    {
      info: fiyatRevizeListesiCount,
      title: "Fiyat Revize Listesi",
      link: "/fiyat-revize-listesi",
      tableCode: "fiyatRevizeListesi",
    },
    {
      info: maliyetRevizeListesiCount,
      title: "Maliyet Revize",
      link: "/maliyet-revize-listesi",
      tableCode: "maliyetRevizeListesi",
    },
    {
      info: iptalTalebiListesiCount,
      title: "İptal Talebi Listesi",
      link: "/iptal-talebi-listesi",
      tableCode: "iptalTalebiListesi",
    },
    {
      info: 0,
      title: "Konfirme Onay",
      link: "/konfirme onay",
      tableCode: "konfirmeOnay",
    },
  ];

  const handleOnClick = (tableCode: string) => {
    setSelectedTable(tableCode);
  };

  return {
    data: reservationMonitoringCardData,
    selectedTable,
    handleOnClick,
    countdown,
  };
};

export default useHandleCards;
