// import { useDispatch } from "react-redux";
// import { Dispatch } from "redux";
// import { useDispatch } from "react-redux";
// import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import {  Widget, WidgetLocation } from "types/myWebSite";

interface WidgetLocationSelectorProps {
  values: Widget;
  formChoices: WidgetLocation[];
}

// interface Option {
//   value: string;
//   label: string;
// }
// interface Option {
//   value: string;
//   label: string;
// }

const WidgetLocationSelector = ({
  values,
  formChoices,
}: WidgetLocationSelectorProps) => {
  // const dispatch: Dispatch<any> = useDispatch();


//   const selectedOption = formChoices.find((option) => option.value === values.organizasyon_sayfasi_acik.toString());

  return (
    <>
      <CustomFormFieldContainer
        label="Rezervasyon Modülü :"
      >
        <></>
        {/* <Select
          options={formChoices}
          value={selectedOption || null}
          onChange={(selected: Option) => {
            if (selected) {
              dispatch(handleChangeAddAdditionalPageInput({ ...values, organizasyon_sayfasi_acik: Number(selected.value) }));
              validateFormChange({ ...values, organizasyon_sayfasi_acik: Number(selected.value) });
            };
          }}
        /> */}
      </CustomFormFieldContainer>
    </>
  );
};

export default WidgetLocationSelector;
