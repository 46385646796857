import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveTodayEntrancePayAtTheDoorResponse,
  setIsLoading,
  setError,
  setChangeStatusLoading,
  setChangeStatusError,
  setCallCustomerLoading,
  setCallCustomerError
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  CANCEL_PAY_AT_THE_DOOR_RESERVATION,
  CHANGE_PAY_AT_THE_DOOR_STATUS_POSITIVE,
  CHANGE_PAY_AT_THE_DOOR_STATUS_NOT_REACHED,
  FILTER_TODAY_ENTRANCE_PAY_AT_THE_DOOR,
  LIST_TODAY_ENTRANCE_PAY_AT_THE_DOOR,
  // HANDLE_CALL_PAY_AT_THE_DOOR,
  CALL_WITH_ZOIPER,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import {
  ChangeTodayEntranceStatusPayload,
  TodayEntrancePayAtTheDoorPayload,
  CancelPayAtTheDoorPayload,
  CallCustomerPayload,
} from "types/reservationMonitoring";

type SuccessCallback = () => void;

export const listTodayEntrancePayAtTheDoor =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(LIST_TODAY_ENTRANCE_PAY_AT_THE_DOOR)

      dispatch(
        handleSaveTodayEntrancePayAtTheDoorResponse(response.data || response)
      );
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const filterTodayEntrancePayAtTheDoorData =
  (
    payload: TodayEntrancePayAtTheDoorPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(
        FILTER_TODAY_ENTRANCE_PAY_AT_THE_DOOR,
        payload
      );

      dispatch(
        handleSaveTodayEntrancePayAtTheDoorResponse(response.data || response)
      );
      dispatch(
        setToastSuccess(
          "Bugün girişli kapıda ödemeli rezervasyonlar başarıyla listelendi."
        )
      );
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const changeStatusPositive =
  (
    payload: ChangeTodayEntranceStatusPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(CHANGE_PAY_AT_THE_DOOR_STATUS_POSITIVE, payload);

      dispatch(setToastSuccess("Durum başarıyla güncellendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };

export const changeStatusNotReached =
  (
    payload: ChangeTodayEntranceStatusPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(`${CHANGE_PAY_AT_THE_DOOR_STATUS_NOT_REACHED}?rezervasyon_id=${payload.rezervasyon_id}`, payload);

      dispatch(setToastSuccess("Durum başarıyla güncellendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };

export const cancelReservation =
  (payload: CancelPayAtTheDoorPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(CANCEL_PAY_AT_THE_DOOR_RESERVATION, payload);

      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };

  export const callCustomer =
    (
      payload: CallCustomerPayload,
      successCallback: SuccessCallback
    ) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch(setCallCustomerLoading(true));
        await axios.post(CALL_WITH_ZOIPER, payload);
  
        successCallback();
      } catch (error) {
        handleErrors(error, (message: string) => {
          dispatch(setCallCustomerError(message));
          dispatch(setToastError(message));
        });
      } finally {
        dispatch(setCallCustomerLoading(false));
      }
    };
