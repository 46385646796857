import { createSlice } from "@reduxjs/toolkit";
import {
  ListCancellationRequestsData,
  ListCancellationRequestsPayload,
  ListCancellationRequestsResponseData,
} from "types/reservationMonitoring";

export interface ListCancellationRequestsInitialState {
  listCancellationRequests: ListCancellationRequestsData[];
  filterListCancellationRequests: ListCancellationRequestsPayload;
  isLoading: boolean;
  error: string;
  cancelLoading: boolean;
  cancelError: string;
  changeStatusLoading: boolean;
  changeStatusError: string;
  callCustomerLoading: boolean;
  callCustomerError: string;
  markAsResponsibleLoading: boolean;
  markAsResponsibleError: string;
}

const initialState: ListCancellationRequestsInitialState = {
  listCancellationRequests: [],
  filterListCancellationRequests: {
    start_date: "",
    end_date: "",
    rezervasyon_kodu: "",
  },
  isLoading: false,
  error: "",
  cancelLoading: false,
  cancelError: "",
  changeStatusLoading: false,
  changeStatusError: "",
  callCustomerLoading: false,
  callCustomerError: "",
  markAsResponsibleLoading: false,
  markAsResponsibleError: "",
};

const listCancellationRequestsSlice = createSlice({
  name: "listCancellationRequests",
  initialState,
  reducers: {
    handleSaveListCancellationRequestsResponse(state, action) {
      const payload = action.payload as ListCancellationRequestsResponseData[];
      const listCancellationRequests: ListCancellationRequestsData[] =
        payload.map((item) => ({
          id: item.id,
          kayit_tarihi: item.kayit_tarihi,
          rezervasyon_id: item.rezervasyon_id,
          rezervasyon_kodu: item.rezervasyon_kodu,
          otel_adi: item.otel_adi,
          giris_tarihi: item.giris_tarihi,
          cikis_tarihi: item.cikis_tarihi,
          istek_yapan_personel: item.istek_yapan_personel,
          istek_mesaj: item.istek_mesaj,
          ilgilenen_personel: item.ilgilenen_personel,
          cevap_mesaj: item.cevap_mesaj,
          musteri_cep: item.musteri_cep,
          musteri_tel: item.musteri_tel,
          iptal_edildi_mi: item.iptal_edildi_mi,
          inet_no_show_durum: item.inet_no_show_durum,
          kaporali_rezervasyon_giris: item.kaporali_rezervasyon_giris,
          musteri_ad_soyad: item.musteri_ad_soyad,
          toplam_tutar: item.toplam_tutar,
          iptal_nedeni: item.iptal_nedeni,
          durum: item.durum,
          otel_telefon: item.otel_telefon,
          list_bg_color_class: item.list_bg_color_class,
          iptal_id: item.iptal_id,
        }));
      state.listCancellationRequests = listCancellationRequests;
    },
    handleChangeFilterChoices(state, action) {
      state.filterListCancellationRequests = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setCancelLoading(state, action) {
      state.cancelLoading = action.payload;
    },
    setCancelError(state, action) {
      state.cancelError = action.payload;
    },
    setChangeStatusLoading(state, action) {
      state.changeStatusLoading = action.payload;
    },
    setChangeStatusError(state, action) {
      state.changeStatusError = action.payload;
    },
    setCallCustomerLoading(state, action) {
      state.callCustomerLoading = action.payload;
    },
    setCallCustomerError(state, action) {
      state.callCustomerError = action.payload;
    },
    setMarkAsResponsibleLoading(state, action) {
      state.markAsResponsibleLoading = action.payload;
    },
    setMarkAsResponsibleError(state, action) {
      state.markAsResponsibleError = action.payload;
    },
  },
});

export const {
  handleSaveListCancellationRequestsResponse,
  handleChangeFilterChoices,
  setIsLoading,
  setError,
  setCancelLoading,
  setCancelError,
  setChangeStatusLoading,
  setChangeStatusError,
  setCallCustomerLoading,
  setCallCustomerError,
  setMarkAsResponsibleLoading,
  setMarkAsResponsibleError,
} = listCancellationRequestsSlice.actions;

export default listCancellationRequestsSlice.reducer;
