/* 
 * "columnId" is the same as 'accessorKey' or 'id' from table
*/
export interface ColumnColorRule {
  columnId: string;
  getColor: (rowData: any) => string; 
}

export function getCellBackgroundColor(
  rowData: any,            
  columnId: string,        
  colorConfig: ColumnColorRule[] = []
): string {
  let bgColor = rowData.rowColor || "";

  const overrideRule = colorConfig.find((rule) => rule.columnId === columnId);
  if (overrideRule) {
    bgColor = overrideRule.getColor(rowData);
  }

  return bgColor;
}
