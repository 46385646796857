import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { Input, Label } from "reactstrap";
import Select from "react-select";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import {
  CalculatePriceForHotelSpecialDiscount,
  EditReservationData,
} from "types/reservation";

interface ReservationInfoFormProps {
  formValues: EditReservationData;
  handleChangeInput: (key: string, value: any) => void;
  roomConceptTypeOptions: RoomConceptOption[];
  onCalculatePrice: () => void;
  calculatedPrice: number | undefined;
  moneyPoint: number;
  specialDiscounts: CalculatePriceForHotelSpecialDiscount[];
}

interface RoomConceptOption {
  value: string;
  label: string;
  oda_id: number;
  pansiyon_id: number;
}

const ReservationInfoForm = ({
  formValues,
  handleChangeInput,
  roomConceptTypeOptions,
}: ReservationInfoFormProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();
  console.log(formValues.giris_tarihi);

  return (
    <CustomFormContainer title="Rezervasyon Bilgileri">
      <div className="d-flex gap-4">
        <CustomFormFieldContainer
          label="Otel Adı"
          divider
          description="Otel değişikliğinde HER ODA için YENİ ODA seçilmelidir."
        >
          <Input
            type="text"
            value={formValues.otel_adi}
            onChange={(e) => handleChangeInput("otel_adi", e.target.value)}
            disabled={true}
            style={{
              textTransform: "uppercase",
            }}
          />
        </CustomFormFieldContainer>
        <div style={{ height: "38" }}>
          <CustomButton onClick={() => {}} variant="primary" isLoading={false}>
            Yeni Odaları Getir
          </CustomButton>
        </div>
      </div>
      {/* {formValues.rez_tipi === "kapidaodeme" ? ( */}
      <div className="d-flex gap-4">
        <CustomFormFieldContainer label="Oda/Pansiyon Tipi" divider>
          <Select
            options={roomConceptTypeOptions}
            value={roomConceptTypeOptions.find(
              (option) => option.value === formValues.pansiyon_adi
            )}
            onChange={(selectedOption: RoomConceptOption) =>
              handleChangeInput("oda_pansiyon_tipi", selectedOption.value)
            }
            placeholder="Seçiniz..."
          />
        </CustomFormFieldContainer>
        <div className="fs-14 fw-medium bg-light" style={{ width: "152px" }}>
          <div className="border border-2 px-3 py-1">{formValues.oda_adi}</div>
          <div className="border border-2 px-3 py-1">
            {formValues.pansiyon_adi}
          </div>
        </div>
      </div>
      {/* ) : (
        <></>
      )} */}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "3rem",
        }}
      >
        <CustomFormFieldContainer
          label="Giriş Tarihi"
          divider
          orientation="vertical"
          description="Tarih değişikliğinde tekrar FİYAT HESAPLANMALIDIR."
        >
          <Flatpickr
            className="form-control"
            value={formValues.giris_tarihi}
            onChange={(date) => {
              if (date) {
                const stringStartDate = moment(date).format("YYYY-MM-DD");

                handleChangeInput("giris_tarihi", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              minDate: moment().subtract(1, "days").toDate(),
              onDayCreate,
            }}
            placeholder="Giriş Tarihi"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Çıkış Tarihi"
          divider
          orientation="vertical"
          description="Tarih değişikliğinde tekrar FİYAT HESAPLANMALIDIR."
        >
          <Flatpickr
            className="form-control"
            value={formValues.cikis_tarihi}
            onChange={(date) => {
              if (date) {
                const stringEndDate = moment(date).format("YYYY-MM-DD");

                handleChangeInput("cikis_tarihi", stringEndDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              minDate: moment().toDate(),
              onDayCreate,
            }}
            placeholder="Çıkış Tarihi"
          />
        </CustomFormFieldContainer>

        <CustomFormFieldContainer
          label="Müşteri Adı Soyadı"
          divider
          orientation="vertical"
        >
          <Input
            type="text"
            value={formValues.musteri_ad_soyad}
            onChange={(e) =>
              handleChangeInput("musteri_ad_soyad", e.target.value)
            }
            style={{
              textTransform: "uppercase",
            }}
          />
        </CustomFormFieldContainer>

        <CustomFormFieldContainer
          label="Müşteri Telefonu"
          divider
          orientation="vertical"
        >
          <Input
            type="text"
            value={formValues.musteri_cep}
            onChange={(e) => handleChangeInput("musteri_cep", e.target.value)}
            style={{
              textTransform: "uppercase",
            }}
          />
        </CustomFormFieldContainer>

        <CustomFormFieldContainer
          label="Müşteri E-Posta"
          divider
          orientation="vertical"
        >
          <Input
            type="text"
            value={formValues.musteri_eposta}
            onChange={(e) =>
              handleChangeInput("musteri_eposta", e.target.value)
            }
            valid={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
              formValues.musteri_eposta
            )}
            invalid={
              !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                formValues.musteri_eposta
              )
            }
            placeholder="example@example.com"
            disabled={formValues.musteri_eposta === "example@example.com"}
          />
          <div className="d-flex align-items-center gap-2 mt-2">
            <Input
              id="musteri_eposta"
              type="checkbox"
              className=" cursor-pointer m-0"
              checked={formValues.musteri_eposta === "example@example.com"}
              onChange={(e) => {
                if (e.target.checked) {
                  handleChangeInput("musteri_eposta", "example@example.com");
                } else {
                  handleChangeInput("musteri_eposta", "");
                }
              }}
            />
            <Label for="musteri_eposta" className="m-0 cursor-pointer">
              E-Posta Yok
            </Label>
          </div>
        </CustomFormFieldContainer>

        <CustomFormFieldContainer
          label="Müşteri T.C. Kimlik No"
          required
          divider
          orientation="vertical"
        >
          <Input
            type="number"
            value={formValues.musteri_tc_kimlik}
            onChange={(e) => {
              e.target.value.length <= 11 &&
                handleChangeInput("musteri_tc_kimlik", e.target.value);
            }}
            min={11}
            max={11}
            valid={formValues.musteri_tc_kimlik.length === 11}
            invalid={formValues.musteri_tc_kimlik.length !== 11}
          />
          <div className="d-flex align-items-center gap-2 mt-2">
            <Input
              id="iptal_sigortasi"
              type="checkbox"
              className=" cursor-pointer m-0"
              checked={formValues.iptal_sigortasi !== "yok"}
              onChange={(e) => {
                if (e.target.checked) {
                  handleChangeInput("iptal_sigortasi", "var");
                } else {
                  handleChangeInput("iptal_sigortasi", "yok");
                }
              }}
            />
            <Label for="iptal_sigortasi" className="m-0 cursor-pointer">
              İptal Güvence Paketi İstiyorum
            </Label>
          </div>
        </CustomFormFieldContainer>
      </div>
      {formValues.rez_tipi === "kapida" && (
        <>
          <CustomFormFieldContainer
            label="Giriş Saati"
            required="Kapıda Ödeme de Saat Belirtmek Zorunludur!"
            orientation="vertical"
          >
            <div style={{ width: "300px" }}>
              <Flatpickr
                className="form-control"
                value={formValues.giris_tarihi}
                onChange={(date) => {
                  const [startDate] = date;
                  if (startDate) {
                    const stringStartDate = moment(startDate).format("HH:mm");

                    handleChangeInput("giris_tarihi", stringStartDate);
                  }
                }}
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "H:i",
                }}
                placeholder="Giriş Tarihi"
              />
            </div>
          </CustomFormFieldContainer>
          <div></div>
          <div
            style={{
              gridColumn: "span 2",
            }}
          >
            <strong className="text-decoration-underline mb-1">
              Misafire Mutlaka Belirtiniz:
            </strong>
            <p className="theme-text-danger fs-14">
              Misafire Mutlaka Otele Giriş yapacagı saati sorunuz. 14.00 a kadar
              giriş yapacaksa bir saat belirtmenize gerek yok. 14.00-18.00
              arasında ise gireceği saati seçim yapınız. 18.00 sonrasında odaya
              girecekse misafire bu saate kadar odayı tutamayacagınızı
              belirterek ödeme tahsilatını almanız gerektiğini belirtiniz. Yada
              otel ile iletişime geçerek geç giriş saati için mutlaka otelden
              onay alıp notlara yazınız.
            </p>
            <strong className="text-decoration-underline mb-1">
              ***Mutlaka Söylüyoruz***:
            </strong>
            <p className="theme-text-danger fs-14">
              {" "}
              Odanızı giriş saatinize kadar sizin için bekletiyoruz,
              Rezervasyonunuzla ilgili iptal yada değişiklik durumunda lütfen
              bize bilgi veriniz.
            </p>
          </div>
        </>
      )}
    </CustomFormContainer>
  );
};

export default ReservationInfoForm;
