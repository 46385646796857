import { CustomButton } from "Components/Custom/UI";

interface AskToOperationButtonsProps {
  toggleOnRequestModal: () => void;
  handleGroupReservationRequest: () => void;
}

const AskToOperationButtons = ({
  toggleOnRequestModal,
  handleGroupReservationRequest,
}: AskToOperationButtonsProps) => {
  return (
    <div
      className="border border-2 rounded p-2 d-flex justify-content-between align-items-center"
      style={{
        gap: "1rem",
        backgroundColor: "#f8fafc",
      }}
    >
      <div className="fw-semibold">İşlemler : </div>
      <div
        className="d-flex align-items-center justify-content-end"
        style={{
          gap: "1rem",
        }}
      >
        <CustomButton variant="warning" onClick={toggleOnRequestModal}>
          SorSat Yap
        </CustomButton>
        <CustomButton variant="warning" onClick={handleGroupReservationRequest}>
          Grup Rezervasyon Talebi Oluştur
        </CustomButton>
      </div>
    </div>
  );
};

export default AskToOperationButtons;
