import { useState } from "react";
import { useSelector } from "react-redux";
import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import { CallLaterPayload, RoomOption } from "types/reservation";

const useHandlePage = () => {
  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const [selectedRoom, setSelectedRoom] = useState<RoomOption>({
    oda_adi: "",
    pansiyon_adi: "",
    fiyat: "",
  });

  const [selectedCallOption, setSelectedCallOption] = useState("");

  const [formValues, setFormValues] = useState<CallLaterPayload>({
    arama_id: calculatePriceForHotel?.arama_sorgu.sorgu_id ?? 0,
    sorgu_id: calculatePriceForHotel?.arama_sorgu.sorgu_id ?? 0,
    otel_id:
      Number(
        Object.values(calculatePriceForHotel?.fiyatlar ?? {})[0]?.otel_id
      ) ?? 0,
    otel_adi: calculatePriceForHotel?.otel_adi ?? "",
    giris_tarihi:
      Object.values(calculatePriceForHotel?.fiyatlar ?? {})[0]?.giris_tarihi ??
      "",
    cikis_tarihi:
      Object.values(calculatePriceForHotel?.fiyatlar ?? {})[0]?.cikis_tarihi ??
      "",
    yetiskin_sayisi:
      Number(
        Object.values(calculatePriceForHotel?.fiyatlar ?? {})[0]?.request
          ?.yetiskin_sayisi
      ) ?? 0,
    cocuk_sayisi:
      Object.values(calculatePriceForHotel?.fiyatlar ?? {})[0]?.request
        ?.cocuk_sayisi ?? 0,
    cocuk_dogum_1: "",
    cocuk_dogum_2: "",
    cocuk_dogum_3: "",
    cocuk_dogum_4: "",
    en_dusuk_fiyat:
      Object.values(calculatePriceForHotel?.fiyatlar ?? {})[0]?.en_dusuk_fiyat
        ?.normal_toplam ?? 0,
    musteri_id: calculatePriceForHotel?.musteri?.musteri_id ?? "",
    musteri_isim: calculatePriceForHotel?.musteri?.musteri_isim ?? "",
    musteri_tel: calculatePriceForHotel?.telefon ?? "",
    fiyat_bilgileri: `${calculatePriceForHotel.otel_adi}~${calculatePriceForHotel}~${calculatePriceForHotel}`,
    aciklama: "",
    arama_tarihi: "",
    arama_saati: "",
    islem: "",
  });

  const checkboxItems = [
    { value: "fiyat-arastiriyor", label: "Fiyat Araştırıyor" },
    { value: "fiyat_yok_dolu", label: "Fiyat Çıkmıyor/Otel Dolu" },
    { value: "sonra-ara", label: "Sonra Aranacak" },
    { value: "rezervasyon-disi", label: "Rezervasyon Dışı" },
    { value: "havale-sms", label: "Havale SMS" },
    { value: "sorsat", label: "Sorsat" },
    { value: "dusen-cagri", label: "Düşen Çağrı" },
    { value: "not-ekle", label: "Diğer" },
  ];

  const handleInputChange = (field: keyof CallLaterPayload, value: any) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const handleSaveFormValues = () => {
    console.log("Payload:", formValues);
  };

  const handleSelectRoomToCallLater = (
    oda_adi: string,
    pansiyon_adi: string,
    fiyat: string
  ) => {
    setSelectedRoom({
      oda_adi: oda_adi,
      pansiyon_adi: pansiyon_adi,
      fiyat: fiyat,
    });
    const selectedRoomStr = `${selectedRoom.oda_adi}~${selectedRoom.pansiyon_adi}~${selectedRoom.fiyat}`;
    console.log("room selected : ", selectedRoomStr)
    handleInputChange("fiyat_bilgileri", selectedRoomStr);
  };

  return {
    calculatePriceForHotel,
    checkboxItems,
    selectedCallOption,
    selectedRoom,
    setSelectedCallOption,
    setSelectedRoom,
    handleInputChange,
    handleSaveFormValues,
    handleSelectRoomToCallLater,
  };
};

export default useHandlePage;
