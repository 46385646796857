import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "react";
import {
  handleSelectedUser,
  handleSelectedCity,
  PersonnelHotelAssignmentsInitialState,
} from "slices/userOperations/personnelHotelAssignments/reducer";
import { listUnassignedHotelsAccordingToCity } from "slices/userOperations/personnelHotelAssignments/thunk";
import useValidateForm from "./useValidateForm";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    assignHotelToUser,
    assignHotelToUserFormChoices,
    selectedUser,
    selectedCity,
    assignHotelToUserLoading,
    assignHotelToUserFormErrors,
  } = useSelector(
    (state: {
      PersonnelHotelAssignments: PersonnelHotelAssignmentsInitialState;
    }) => state.PersonnelHotelAssignments
  );

  const assignHotelsToUserPayload = new FormData();

  const handleSelectCity = (id: number) => {
    dispatch(handleSelectedCity(id));
    dispatch(listUnassignedHotelsAccordingToCity({ sehir_id: id }));
  };

  const handleSelectUser = (id: number) => {
    dispatch(handleSelectedUser(id));
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    assignHotelToUser,
    assignHotelToUserFormChoices,
    assignHotelsToUserPayload,
    selectedCity,
    selectedUser,
    handleSelectUser,
    handleSelectCity,
    assignHotelToUserLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
    formErrors: assignHotelToUserFormErrors,
  };
};

export default useHandleForm;
