import TomorrowEntranceWithDepositPage from "Components/Custom/ReservationMonitoringPages/TomorrowEntranceWithDepositPage";
import useTomorrowEntranceWithDeposit from "Components/Hooks/reservationMonitoring/useTomorrowEntranceWithDeposit";
import PageContainer from "pages/PageContainer";

const TomorrowEntranceWithDeposit = () => {
  const { isLoading } = useTomorrowEntranceWithDeposit();

  return (
    <PageContainer title="Yarın Girişli Kaporalı Rezervasyonlar">
      <TomorrowEntranceWithDepositPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default TomorrowEntranceWithDeposit;
