import TodayEntrancePayAtTheDoorPage from "Components/Custom/ReservationMonitoringPages/TodayEntrancePayAtTheDoorPage";
import useTodayEntrancePayAtTheDoorRes from "Components/Hooks/reservationMonitoring/useTodayEntrancePayAtTheDoorRes";
import PageContainer from "pages/PageContainer";

const TodayEntrancePayAtTheDoorRes = () => {
  const { isLoading } = useTodayEntrancePayAtTheDoorRes();

  return (
    <PageContainer title="Bugün Girişli Kapıda Ödemeli Rezervasyonlar">
      <TodayEntrancePayAtTheDoorPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default TodayEntrancePayAtTheDoorRes;
