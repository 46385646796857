import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { LoginInitialState } from "slices/auth/login/reducer";
import { callCustomer } from "slices/dashboard/crm/thunk";
import { setToastError } from "slices/toast/reducer";
import { CallsDueLaterCardData } from "types/dashboard";
import { MakeZoiperCallPayload } from "types/zoiper";

interface TableRow {
  order: number;
  call_due_date: string;
  caller_name: string;
  hotel: string;
  personnel: string;
  description: string;
  situation: string;
}

interface CalculatePricePayload {
  otel_adi: string;
  otel_no: string;
  aranan_no: string;
  arayan_no: string;
  dahili: string;
}

const useHandleTable = (value: CallsDueLaterCardData) => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );

  const handleCall = (payload: MakeZoiperCallPayload) => {
    try {
      dispatch(callCustomer(payload));
    } catch (error) {
      setToastError(error);
    }
  };

  const handleCalculatePrice = (payload: CalculatePricePayload) => {
    navigate(
      `rezervasyon-cagri?aranan=${payload.otel_adi}_${payload.otel_no}-${payload.aranan_no}&arayan=${payload.arayan_no}&dahili=${payload.dahili}-IDEALTUR`
    );
  };

  const tableData: TableRow[] = [];
  let orderCounter = 1;

  Object.entries(value).forEach(([dateKey, call]) => {
    tableData.push({
      order: orderCounter++,
      call_due_date: call.sonra_aranacak,
      caller_name: call.musteri_isim,
      hotel: call.otel_adi,
      personnel: call.crm_kul_adi,
      description: call.aciklama,
      situation: call.arandi_mi,
    });
  });

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "order",
      enableColumnFilter: false,
    },
    {
      header: "Aranacak Tarih",
      accessorKey: "call_due_date",
      enableColumnFilter: false,
    },
    {
      header: "Arayan Ad Soyad",
      accessorKey: "caller_name",
      enableColumnFilter: false,
    },
    {
      header: "Aranan Otel",
      accessorKey: "hotel",
      enableColumnFilter: false,
    },
    {
      header: "Kayıt Yapan",
      accessorKey: "personnel",
      enableColumnFilter: false,
    },
    {
      header: "Açıklama",
      accessorKey: "description",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div className="text-wrap" style={{ maxWidth: "200px" }}>
            {value}
          </div>
        );
      },
    },
    {
      header: "Durum",
      accessorKey: "situation",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "yorum_id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData = cell.row.original;
        const otel_adi = rowData.otel_adi;
        const otel_no = rowData.otel_telefon;
        const dahili = user.dahili;
        const aranan_no = rowData.aranan_no;
        const arayan_no = rowData.arayan_no;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="check"
                onClick={() => {
                  handleCalculatePrice({
                    otel_adi: otel_adi,
                    otel_no: otel_no,
                    aranan_no: aranan_no,
                    arayan_no: arayan_no,
                    dahili: dahili
                  });
                }}
                title="Fiyat Hesapla"
              />
              <CustomRowOperationButton
                variant="phone"
                onClick={() =>
                  handleCall({
                    dahili: Number(dahili),
                    aranan_no: aranan_no,
                    arayan_no: arayan_no,
                  })
                }
                title="Müşteriyi Ara"
              />
            </div>
          </>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
