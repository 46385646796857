import { CustomButton } from "Components/Custom/UI";
import {
  CardBody,
  CardSubtitle,
  CardTitle,
  Row,
} from "reactstrap";
import {
  Notification,
  ObjectNotification,
  StringNotification,
  HTMLTableNotification,
  HTMLLinkNotification,
  UnknownNotification,
} from "types/userOperations";

/* 

  * This component renders a single notification row

*/

export const cleanHtmlContent = (htmlContent: string): string => {
  return htmlContent.replace(/(\{\s*['"]\s*['"]\s*\})/g, "");
};

interface NotificationRowProps {
  data: Notification;
  onMarkAsRead: (id: number) => void;
}

const NotificationRow = ({ data, onMarkAsRead }: NotificationRowProps) => {
  const isRead = data.okundu_durum === "1";

  const handleCheckboxChange = () => {
    if (!isRead) {
      onMarkAsRead(data.id);
    }
  };

  const renderContent = () => {
    switch (data.contentType) {
      case "object": {
        const objectData = data as ObjectNotification;
        return (
          <div>
            <p>ID: {objectData.data.id}</p>
            <p>Sayfa: {objectData.data.sayfa}</p>
            <p>Zaman: {objectData.data.zaman}</p>
            <p>Otel Adı: {objectData.data.otel_adi}</p>
          </div>
        );
      }
      case "string": {
        const stringData = data as StringNotification;
        return <p>{stringData.message}</p>;
      }
      case "htmlTable": {
        const htmlTableData = data as HTMLTableNotification;
        const cleanedHtml = cleanHtmlContent(htmlTableData.htmlContent);
        return <div dangerouslySetInnerHTML={{ __html: cleanedHtml }} />;
      }
      case "htmlLink": {
        const htmlLinkData = data as HTMLLinkNotification;
        return (
          <a href={htmlLinkData.link} target="_blank" rel="noopener noreferrer">
            {htmlLinkData.linkText}
          </a>
        );
      }
      default:
        const unknownNotificationData = data as UnknownNotification;
        return <p>{unknownNotificationData.bildirim}</p>;
    }
  };

  return (
    <Row
      style={{
        backgroundColor: data.okundu_durum === "1" ? "#e5e5e5" : "#ffffff",
        marginBottom: "1rem",
        gap: 0,
        border: "1px solid #e5e5e5",
        borderRadius: "5px",
      }}
    >
      <CardBody className="p-4 d-flex flex-column" style={{ gap: 0 }}>
        <CardTitle
          style={{
            fontWeight: "bold",
            marginBottom: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{data.baslik}</span>
          {/* 

          If we bring the "mark read notifications as unread" feature in the future, we can use this checkbox.
        
          {!isRead && (
            <FormGroup check>
              <Input
                type="checkbox"
                onChange={handleCheckboxChange}
                id={`notification-checkbox-${data.id}`}
                style={{ background: "#e5e5e5", border: "1px solid black" }}
              />
              <Label
                htmlFor={`notification-checkbox-${data.id}`}
                check
                style={{ cursor: "pointer", fontWeight: "400" }}
              >
                Okundu Olarak İşaretle
              </Label>
            </FormGroup>
          )} 
           
          */}
          {!isRead && (
            <CustomButton variant="success" onClick={handleCheckboxChange}>
              Okundu Olarak İşaretle
            </CustomButton>
          )}
        </CardTitle>
        {renderContent()}
        <div style={{ textAlign: "right" }}>
          <CardSubtitle>{data.tarih}</CardSubtitle>
          <CardSubtitle
            className={`badge bg-secondary text-secondary bg-opacity-25`}
          >
            {data.tipi}
          </CardSubtitle>
        </div>
      </CardBody>
    </Row>
  );
};

export default NotificationRow;
