import { useCallback, useEffect, useState } from "react";

import { CreditCardInstallment } from "./types";
import { CreditCard, creditCardSchema } from "./schemas";

type MakePayment = (
  card: CreditCard,
  installment: CreditCardInstallment
) => void;

let load = false;

const useHandleCard = (
  makePayment: MakePayment,
  formId: string,
  installments: CreditCardInstallment[],
  getInstallments: (bin: string) => void,
  resetInstallments: () => void,
  needApproval: boolean
) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [state, setState] = useState({
    cardOwner: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
    focus: "",
    approved: needApproval ? false : true,
  });

  const [errors, setErrors] = useState<CreditCard>({
    cardOwner: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
    approved: false,
  });

  const limitCharacters = (value: string, limit: number) => {
    return value.slice(0, limit);
  };

  const validateForm = (submitted = false) => {
    if (submitted || isSubmitted) {
      const formData = new FormData(
        document.getElementById(formId) as HTMLFormElement
      );

      const cardData: CreditCard = {
        cardOwner: formData.get("cardOwner") as string,
        cardNumber: formData.get("cardNumber") as string,
        expiryDate: formData.get("expiryDate") as string,
        cvc: formData.get("cvc") as string,
        approved: needApproval ? formData.get("approved") === "on" : true,
      };

      const result = creditCardSchema.safeParse(cardData);

      if (!result.success) {
        setErrors(
          result.error.errors.reduce(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (acc: any, error: any) => {
              acc[error.path[0]] = error.message;
              return acc;
            },
            {
              cardOwner: "",
              cardNumber: "",
              expiryDate: "",
              cvc: "",
            }
          )
        );
        return false;
      } else {
        setErrors({
          cardOwner: "",
          cardNumber: "",
          expiryDate: "",
          cvc: "",
          approved: false,
        });
        return cardData;
      }
    } else return false;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    validateForm();

    if (name === "cardNumber") {
      const cardNumber = limitCharacters(value, 16);
      setState((prev) => ({ ...prev, [name]: cardNumber }));
    } else if (name === "expiryDate") {
      const expiryDate = limitCharacters(value, 5);
      let newValue = expiryDate;

      if (value.length === 2 && !value.includes("/")) {
        newValue = value + "/";
        setState((prev) => ({ ...prev, [name]: newValue }));
        return;
      }
      if (value.length === 3 && value.includes("/")) {
        newValue = value.slice(0, 2);
        setState((prev) => ({ ...prev, [name]: newValue }));
        return;
      }
      setState((prev) => ({ ...prev, [name]: newValue }));
    } else if (name === "cvc") {
      const cvc = limitCharacters(value, 3);
      setState((prev) => ({ ...prev, [name]: cvc }));

      if (cvc.length < 3) setState((prev) => ({ ...prev, focus: "cvc" }));

      if (cvc.length === 3) {
        setTimeout(() => {
          setState((prev) => ({ ...prev, focus: "" }));
        }, 400);
      }
    } else if (name === "approved") {
      setState((prev) => ({ ...prev, [name]: e.target.checked }));
    } else setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (
    e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    setState((prev) => ({ ...prev, focus: e.target.name }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const cardData = validateForm(true);
      setIsSubmitted(true);

      if (cardData) {
        makePayment(cardData, selectedInstallment);
      }
    } catch {
      alert("Submitting form failed!");
    }
  };

  const [selectedInstallment, setSelectedInstallment] =
    useState<CreditCardInstallment>({
      paymentId: 0,
      month: 0,
      amount: 0,
      total: 0,
    });

  useEffect(() => {
    const notSelected = !selectedInstallment.paymentId;
    const installmentsExist = installments.length > 0;

    if (notSelected && installmentsExist) {
      setSelectedInstallment(installments[0]);
    }
  }, [installments, selectedInstallment]);

  const handleSelectInstallment = (installment: CreditCardInstallment) => {
    setSelectedInstallment(installment);
  };

  const resetSelectedInstallment = () => {
    setSelectedInstallment({
      paymentId: 0,
      month: 0,
      amount: 0,
      total: 0,
    });
  };

  const getCardInstallments = useCallback(
    (
      cardNumber: string,
      getInstallments: (bin: string) => void,
      resetInstallments: () => void,
      isPaste?: boolean
    ) => {
      const bin = cardNumber.slice(0, 6);

      if (isPaste) {
        if (bin.length > 5) getInstallments(bin);
        return;
      } else if (cardNumber.length < 6) {
        if (load) {
          resetInstallments();
          resetSelectedInstallment();
          load = false;
        }
      } else {
        if (!load) getInstallments(bin);
        load = true;
      }
    },
    []
  );

  const handlePasteCardNumber = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = e.clipboardData.getData("text");
    getCardInstallments(paste, getInstallments, resetInstallments, true);
  };

  const isSubmitButtonDisabled =
    !selectedInstallment.paymentId ||
    Object.values(errors).some((error) => error);

  return {
    isSubmitted,
    handleSubmit,
    errors,
    state,
    handleInputChange,
    handleInputFocus,
    selectedInstallment,
    onSelectedInstallment: handleSelectInstallment,
    getCardInstallments,
    onPasteCardNumber: handlePasteCardNumber,
    isSubmitButtonDisabled,
  };
};

export default useHandleCard;
