import { CustomPageDescriptions } from "Components/Custom/UI";
import { LIST_ON_REQUEST_PAGE_DESCRIPTION } from "utils/pageDescriptions";
import FilterForm from "./FilterForm";
import ListOnRequestReservationsTable from "./ListOnRequestReservationsTable";

interface ListOnRequestReservationsPageProps {
  isLoading: boolean;
}

const ListOnRequestReservationsPage = ({
  isLoading,
}: ListOnRequestReservationsPageProps) => {

  const descriptions = LIST_ON_REQUEST_PAGE_DESCRIPTION.map(
    (description, index) => (
      <div
        key={index}
        dangerouslySetInnerHTML={{ __html: description }}
        className="user-select-none"
      />
    )
  );

  return (
    <div className="d-flex flex-column gap-4">
      <CustomPageDescriptions descriptions={descriptions} />
      <FilterForm />
      <ListOnRequestReservationsTable isLoading={isLoading} />
    </div>
  );
};

export default ListOnRequestReservationsPage;
