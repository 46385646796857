import { useNumberFormatter } from "Components/Hooks/useNumberFormatter";
import { useSelector } from "react-redux";
import { ListIncomingTranfersInitialState } from "slices/reservationLists/listIncomingTranfers/reducer";

const useHandleTable = () => {
  const { incomingTransfers } = useSelector(
    (state: { ListIncomingTranfers: ListIncomingTranfersInitialState }) =>
      state.ListIncomingTranfers
  );

  const { formatNumber } = useNumberFormatter();

  const tableData = incomingTransfers?.map((transfer) => ({
    id: transfer.id,
    banka: transfer.banka,
    tutar: transfer.tutar,
    cur: transfer.cur,
    banka_logo: transfer.banka_logo,
    aciklama: transfer.aciklama,
    rez_kodu: transfer.rez_kodu,
    durum: transfer.durum,
    tahsilat_id: transfer.tahsilat_id,
    havale_tarihi: transfer.havale_tarihi,
    kayit_tarihi: transfer.kayit_tarihi,
  }));

  const tableColumns = [
    {
      header: "ID",
      accessorKey: "id",
      enableColumnFilter: false,
    },
    {
      header: "Havale Tarihi",
      accessorKey: "havale_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Açıklama",
      accessorKey: "aciklama",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "400px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Rez. Kodu",
      accessorKey: "rez_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Banka Adı",
      accessorKey: "banka_logo",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <div
            style={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={value}
              alt="Banka Logo"
              style={{ height: "40px", width: "auto", objectFit: "contain" }}
            />
          </div>
        );
      },
    },
    {
      header: "Havale Tutarı",
      accessorKey: "tutar",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        const number = formatNumber(value);
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {number}
            </div>
          </>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
