import IncomingCollectionSelector from "./FormFields/IncomingCollectionSelector";
import useHandleForm from "./useHandleForm";
import CollectionTypesSelector from "./FormFields/CollectionTypesSelector";
import CollectionBankSelector from "./FormFields/CollectionBankSelector";
import InstallmentSelector from "./FormFields/InstallmentSelector";
import InstallmentDate from "./FormFields/InstallmentDate";
import CollectionDescriptionInput from "./FormFields/CollectionDescriptionInput";
import CollectionAmountInput from "./FormFields/CollectionAmountInput";
import SaveCollectionButton from "./FormFields/SaveCollectionButton";

const CollectionForm = () => {
  const {
    values,
    collectionFormErrors,
    collectionFormOptions,
    addCollectionLoading,
    handleAddNewCollection,
    validateForm,
    validateFormChange,
    handleSubmitted,
  } = useHandleForm();

  return (
    <>
      <div className="d-flex flex-column gap-3 border rounded bg-white">
        <div className="d-flex align-items-center gap-2 p-3 rounded bg-dark bg-opacity-10">
          <span className="fw-bold fs-18">Tahsilat Bilgisi Gir</span>
        </div>
        <div className="d-flex flex-column gap-3 p-3">
          <IncomingCollectionSelector
            values={values}
            formChoices={collectionFormOptions}
            formErrors={collectionFormErrors}
            validateFormChange={validateFormChange}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "1rem",
            }}
          >
            <CollectionTypesSelector
              values={values}
              formChoices={collectionFormOptions}
              formErrors={collectionFormErrors}
              validateFormChange={validateFormChange}
            />
            <CollectionBankSelector
              values={values}
              formChoices={collectionFormOptions}
              formErrors={collectionFormErrors}
              validateFormChange={validateFormChange}
            />
            <InstallmentSelector
              values={values}
              formChoices={collectionFormOptions}
              formErrors={collectionFormErrors}
              validateFormChange={validateFormChange}
            />
            <InstallmentDate
              values={values}
              formErrors={collectionFormErrors}
              validateFormChange={validateFormChange}
            />
          </div>
          <CollectionDescriptionInput
            values={values}
            formErrors={collectionFormErrors}
            validateFormChange={validateFormChange}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "1rem",
            }}
          >
            <CollectionAmountInput
              values={values}
              formErrors={collectionFormErrors}
              validateFormChange={validateFormChange}
            />
            <SaveCollectionButton
              values={values}
              validateForm={validateForm}
              handleSubmitted={handleSubmitted}
              onAddNewCollection={handleAddNewCollection}
              isLoading={addCollectionLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionForm;
