import {
  CreditCardBrand,
  CreditCardInstallment,
  TranslatedContent,
} from "../types";
import { CreditCard } from "../schemas";

import styles from "./CreditCardForm.module.scss";
import { translate } from "../helpers";

interface CreditCardFormProps {
  id: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isSubmitted: boolean;
  errors: CreditCard;
  state: CreditCard & { focus: string | undefined };
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  isSubmitButtonDisabled: boolean;
  installmentsLoading: boolean;
  cardBrand: CreditCardBrand;
  invalidCardNumber: string;
  selectedInstallment: CreditCardInstallment;
  makePaymentLoading: boolean;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  common: TranslatedContent[];
}

interface CreditCradLogoProps {
  loading: boolean;
  cardBrand: CreditCardBrand;
}

interface FieldErrorProps {
  field: keyof CreditCard;
  errors: CreditCard;
  invalidCardNumber?: string;
  common: TranslatedContent[];
}
const CreditCardLogo = ({ loading, cardBrand }: CreditCradLogoProps) => {
  return (
    <>
      {loading ? (
        <div className={styles.form__logos}>
          <span className={styles.spinner}></span>
        </div>
      ) : (
        <>
          {cardBrand.showBrand && (
            <div className={styles.form__logos}>
              {cardBrand.cardBrandLogo ? (
                <img
                  src={cardBrand.cardBrandLogo}
                  alt="card-brand"
                  width={50}
                  height={20}
                />
              ) : (
                <></>
              )}
              {cardBrand.cardProgramLogo ? (
                <img
                  src={cardBrand.cardProgramLogo}
                  alt="card-program"
                  width={50}
                  height={25}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

const FieldError = ({
  field,
  errors,
  invalidCardNumber,
  common,
}: FieldErrorProps) => {
  return (
    <p
      className=""
      style={{
        color: "#de1135",
        fontSize: "12px",
      }}
    >
      {translate(invalidCardNumber || (errors[field] as string) || "", common)}
    </p>
  );
};

const CreditCardForm = ({
  id,
  onSubmit,
  isSubmitted,
  errors,
  state,
  handleInputChange,
  handleInputFocus,
  isSubmitButtonDisabled,
  installmentsLoading,
  cardBrand,
  invalidCardNumber,
  selectedInstallment,
  makePaymentLoading,
  onPaste,
  children,
  common,
}: CreditCardFormProps) => {
  const isFieldValid = (field: keyof CreditCard) => {
    return isSubmitted && !errors[field];
  };

  const isFieldInvalid = (field: keyof CreditCard) => {
    return !!errors[field];
  };

  const getInputClass = (
    field: keyof CreditCard,
    invalidCardNumber?: string
  ) => {
    return `${isFieldValid(field) ? styles.success : ""} ${
      isFieldInvalid(field) || invalidCardNumber ? styles.error : ""
    }`;
  };

  const translatedCardOwnerPlaceholder =
    common.find((item) => item.original === "Kart Sahibi Adı Soyadı")
      ?.translated ?? "Kart Sahibi Adı Soyadı";

  const translatedCardNumberPlaceholder =
    common.find((item) => item.original === "Kart Numarası")?.translated ??
    "Kart Numarası";

  const translatedExpiryDatePlaceholder =
    common.find((item) => item.original === "AA/YY")?.translated ?? "AA/YY";

  const translatedCvcPlaceholder =
    common.find((item) => item.original === "Güvenlik Kodu")?.translated ??
    "Güvenlik Kodu";

  return (
    <form id={id} className={styles.form} onSubmit={onSubmit}>
      <div className={styles.form__owner}>
        <input
          id="cardOwner"
          className={styles.success}
          type="text"
          placeholder={translatedCardOwnerPlaceholder}
          name="cardOwner"
          value={state.cardOwner}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <FieldError field="cardOwner" errors={errors} common={common} />
      </div>
      <div className={styles.form__number}>
        <input
          id="cardNumber"
          type="number"
          className={getInputClass("cardNumber", invalidCardNumber)}
          placeholder={translatedCardNumberPlaceholder}
          name="cardNumber"
          value={state.cardNumber}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onPaste={onPaste}
        />
        <CreditCardLogo loading={installmentsLoading} cardBrand={cardBrand} />
        <FieldError
          field="cardNumber"
          errors={errors}
          invalidCardNumber={invalidCardNumber}
          common={common}
        />
      </div>
      <div className={styles.form__expiration}>
        <input
          id="expiryDate"
          className={getInputClass("expiryDate")}
          type="text"
          placeholder={translatedExpiryDatePlaceholder}
          name="expiryDate"
          value={state.expiryDate}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <FieldError field="expiryDate" errors={errors} common={common} />
      </div>
      <div className={styles.form__cvc}>
        <input
          id="cvc"
          className={getInputClass("cvc")}
          type="number"
          placeholder={translatedCvcPlaceholder}
          name="cvc"
          value={state.cvc}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <FieldError field="cvc" errors={errors} common={common} />
      </div>
      <div className={styles.form__children}>{children}</div>
      <button
        type="submit"
        className={styles.form__submit}
        disabled={installmentsLoading || isSubmitButtonDisabled}
      >
        {makePaymentLoading && <span className={styles.spinner}></span>}

        <p className="">{translate("Ödeme Yap", common)}</p>
        {selectedInstallment.paymentId ? (
          <span>({selectedInstallment.total} TL)</span>
        ) : (
          <></>
        )}
      </button>
    </form>
  );
};

export default CreditCardForm;
