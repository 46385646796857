import NoteConfirmationReservationsPage from "Components/Custom/ReservationListsPages/NoteConfirmationReservationsPage";
import useNoteConfirmationReservations from "Components/Hooks/reservationLists/useNoteConfirmationReservations";
import PageContainer from "pages/PageContainer";

const NoteConfirmationReservations = () => {
  const { isLoading } = useNoteConfirmationReservations();

  return (
    <PageContainer title="Not Confirme Rezervasyonlar">
      <NoteConfirmationReservationsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default NoteConfirmationReservations;
