import { DocumentCenterData } from "types/documents";

const useDocumentCenter = () => {
  const accountingData: DocumentCenterData = {
    links: [
      "Mail Order Formu",
      "Banka Hesap Bilgileri",
      "2015 Taksit Avantajları",
      "Kredi Kartı Taksit Tablosu",
      //   "MSO",
      //   "MSOC",
    ],
    pdf_file_routes: [
      "/pdfs/mail_order_formu.pdf",
      "/pdfs/banka_hesap_bilgileri.pdf",
      "/pdfs/2015_taksit_avantajlari.pdf",
      "/pdfs/kredi_karti_taksit_tablosu.pdf",
      //   "/pdfs/mso.pdf",
      //   "/pdfs/msoc.pdf",
    ],
  };

  const operationData: DocumentCenterData = {
    links: [
      "Odeon Oda Tipleri",
      "Puzzle Travel Oda Tipleri",
    ],
    pdf_file_routes: [
      "/pdfs/odeon_oda_tipleri.pdf",
      "/pdfs/puzzle_oda_tipleri.pdf",
    ],
  };

  return {
    accountingData,
    operationData,
  };
};

export default useDocumentCenter;
