import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import { Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  ListOnRequestReservationsData,
  UpdateStatusOnRequestReservationPayload,
} from "types/reservationMonitoring";
import ModalInfo from "./ModalInfo";

interface UpdateStatusModalProps {
  reservations: ListOnRequestReservationsData[];
  reservation_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  updateStatus: (payload: UpdateStatusOnRequestReservationPayload) => void;
}

const UpdateStatusModal = ({
  reservations,
  reservation_id,
  isOpen,
  handleToggle,
  updateStatus,
}: UpdateStatusModalProps) => {
  const reservation = reservations.find(
    (reservation) => reservation.id === reservation_id
  )!;

  const [answer, setAnswer] = useState("");

  useEffect(() => {
    setAnswer("");
  }, [reservation]);

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="md">
        <ModalHeader>Durum Güncelle</ModalHeader>
        <ModalInfo reservation={reservation} />
        <ModalBody>
          <Label>Açıklama</Label>
          <Input
            type="textarea"
            placeholder="Açıklama"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="primary"
            onClick={() =>
              updateStatus({
                sorsat_id: reservation_id,
                cevap: answer,
                durum: "1",
              })
            }
            isLoading={false}
          >
            Güncelle
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="success"
            onClick={() =>
              updateStatus({
                sorsat_id: reservation_id,
                cevap: answer,
                durum: "2",
              })
            }
          >
            Olumlu
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="danger"
            onClick={() =>
              updateStatus({
                sorsat_id: reservation_id,
                cevap: answer,
                durum: "3",
              })
            }
          >
            Olumsuz
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default UpdateStatusModal;
