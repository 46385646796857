import { createSlice } from "@reduxjs/toolkit";
import {
  CreateGroupReservationFormErrors,
  CreateGroupReservationPayload,
} from "types/reservation";

export interface EnterGroupReservationInitialState {
  groupReservation: CreateGroupReservationPayload;
  groupReservationFormErrors: CreateGroupReservationFormErrors;
  isLoading: boolean;
  error: string;
}

export const enterGroupReservationInitialState: EnterGroupReservationInitialState =
  {
    groupReservation: {
      otel_adi: "",
      otel_id: 0,
      musteri_ad_soyad: "",
      musteri_cep: "",
      musteri_eposta: "",
      giris_tarihi: "",
      cikis_tarihi: "",
      yetiskin_sayisi: "",
      musteri_ozel_istek: "",
    },
    groupReservationFormErrors: {},
    isLoading: false,
    error: "",
  };

const enterGroupReservationSlice = createSlice({
  name: "enterGroupReservation",
  initialState: enterGroupReservationInitialState,
  reducers: {
    handleChangeInput(state, action) {
      state.groupReservation = {
        ...state.groupReservation,
        ...action.payload,
      };
    },
    handleResetGroupReservationForm(state) {
      state.groupReservation = {
        otel_adi: "",
        otel_id: 0,
        musteri_ad_soyad: "",
        musteri_cep: "",
        musteri_eposta: "",
        giris_tarihi: "",
        cikis_tarihi: "",
        yetiskin_sayisi: "",
        musteri_ozel_istek: "",
      };
    },
    handleGroupReservationFormErrors(state, action) {
      state.groupReservationFormErrors = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  handleChangeInput,
  handleResetGroupReservationForm,
  handleGroupReservationFormErrors,
  setIsLoading,
  setError,
} = enterGroupReservationSlice.actions;

export default enterGroupReservationSlice.reducer;
