import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  FindReservationsInitialState,
  handleChangeReservationFilterChoices,
} from "slices/reservationLists/findReservation/reducer";
import { findReservations } from "slices/reservationLists/findReservation/thunk";
import { FindReservationPayload } from "types/reservationLists";

const useHandleForm = () => {
  const { isLoading, filterReservations } = useSelector(
    (state: { FindReservations: FindReservationsInitialState }) =>
      state.FindReservations
  );

  const [filterForm, setFilterForm] = useState<FindReservationPayload>({
    start_date: filterReservations.start_date,
    rezervasyon_kodu: filterReservations.rezervasyon_kodu,
    musteri_adi: filterReservations.musteri_adi,
    per_page: filterReservations.per_page,
    page: filterReservations.page,
    search: filterReservations.musteri_adi,
  });

  const handleFormChange = (key: string, value: any) => {
    setFilterForm((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      start_date: filterForm.start_date,
      rezervasyon_kodu: filterForm.rezervasyon_kodu,
      musteri_adi: filterForm.musteri_adi,
      per_page: filterForm.per_page,
      page: filterForm.page,
      search: filterForm.musteri_adi,
    };
    dispatch(handleChangeReservationFilterChoices(params));
    dispatch(findReservations(params, () => {}));
  };

  const handleResetFilter = () => {
    setFilterForm({
      start_date: "",
      rezervasyon_kodu: "",
      musteri_adi: "",
      per_page: 0,
      page: 0,
      search: "",
    });

    const params = {
      start_date: "",
      rezervasyon_kodu: "",
      musteri_adi: "",
      per_page: 0,
      page: 0,
      search: "",
    };
    dispatch(findReservations(params, () => {}));
  };

  return {
    isLoading,
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
