import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveTomorrowEntranceWithDepositResponse,
  setIsLoading,
  setError,
  setChangeStatusLoading,
  setChangeStatusError,
  setCallCustomerLoading,
  setCallCustomerError
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  CANCEL_WITH_DEPOSIT_RESERVATION,
  CHANGE_PAY_WITH_DEPOSIT_STATUS_NOT_REACHED,
  CHANGE_PAY_WITH_DEPOSIT_STATUS_POSITIVE,
  FILTER_TOMORROW_ENTRANCE_WITH_DEPOSIT,
  CALL_WITH_ZOIPER,
  LIST_TOMORROW_ENTRANCE_WITH_DEPOSIT,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import {
  CallCustomerPayload,
  CancelWithDepositPayload,
  ChangeTomorrowEntranceStatusPayload,
  TomorrowEntranceWithDepositPayload,
} from "types/reservationMonitoring";

type SuccessCallback = () => void;

export const listTomorrowEntranceWithDeposit =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(LIST_TOMORROW_ENTRANCE_WITH_DEPOSIT);

      dispatch(
        handleSaveTomorrowEntranceWithDepositResponse(response.data || response)
      );
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const filterTomorrowEntranceWithDepositData =
  (
    payload: TomorrowEntranceWithDepositPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(
        FILTER_TOMORROW_ENTRANCE_WITH_DEPOSIT,
        payload
      );

      dispatch(
        handleSaveTomorrowEntranceWithDepositResponse(response.data || response)
      );
      dispatch(
        setToastSuccess(
          "Yarın girişli kaporalı rezervasyonlar başarıyla listelendi."
        )
      );
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  export const changeStatusPositive =
  (
    payload: ChangeTomorrowEntranceStatusPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(CHANGE_PAY_WITH_DEPOSIT_STATUS_POSITIVE, payload);

      dispatch(setToastSuccess("Durum başarıyla güncellendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };

export const changeStatusNotReached =
  (
    payload: ChangeTomorrowEntranceStatusPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(CHANGE_PAY_WITH_DEPOSIT_STATUS_NOT_REACHED, payload);

      dispatch(setToastSuccess("Durum başarıyla güncellendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };


export const cancelReservation =
  (payload: CancelWithDepositPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(CANCEL_WITH_DEPOSIT_RESERVATION, payload);

      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };

  export const callCustomer =
    (
      payload: CallCustomerPayload,
      successCallback: SuccessCallback
    ) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch(setCallCustomerLoading(true));
        await axios.post(CALL_WITH_ZOIPER, payload);
  
        successCallback();
      } catch (error) {
        handleErrors(error, (message: string) => {
          dispatch(setCallCustomerError(message));
          dispatch(setToastError(message));
        });
      } finally {
        dispatch(setCallCustomerLoading(false));
      }
    };
