import { z } from 'zod'

export const creditCardSchema = z
  .object({
    cardOwner: z.string().min(2, { message: '*Zorunlu alan.' }),
    cardNumber: z.string().min(16, { message: 'Kart numarası 16 haneli olmalıdır.' }),
    expiryDate: z.string().min(1, { message: '*Zorunlu alan.' }),
    cvc: z.string().min(3, { message: '3 haneli olmalıdır.' }),
    approved: z.boolean(),
  })
  .refine((data) => !isNaN(Number(data.cardNumber)), {
    message: 'Kart numarası sadece rakamlardan oluşmalıdır.',
    path: ['cardNumber'],
  })
  .refine((data) => !isNaN(Number(data.cvc)), {
    message: 'Geçersiz güvenlik kodu.',
    path: ['cvc'],
  })
  .refine(
    (data) => {
      const arr = data.cardOwner.split(' ')
      return arr.length >= 2 && arr.every((word) => word.length > 1)
    },
    {
      message: 'Geçersiz ad soyad.',
      path: ['cardOwner'],
    }
  )
  .refine(
    (data) => {
      const [month, year] = data.expiryDate.split('/')
      const currentYear = new Date().getFullYear().toString().slice(2, 4)
      const currentMonth = new Date().getMonth() + 1

      if (Number(month) && Number(year) && year?.length === 2 && month?.length === 2) {
        if (year < currentYear) {
          return false
        } else if (year === currentYear && Number(month) < currentMonth) {
          return false
        } else if (Number(month) > 12) return false
        else return true
      } else return false
    },
    { message: 'Geçersiz tarih.', path: ['expiryDate'] }
  )
  .refine((data) => data.approved, {
    message: 'Lütfen okuyup onaylayın.',
    path: ['approved'],
  })

export type CreditCard = z.infer<typeof creditCardSchema>
