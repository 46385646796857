import { CustomButton } from "Components/Custom/UI";
import { useNumberFormatter } from "Components/Hooks/useNumberFormatter";
import { Dispatch, SetStateAction } from "react";
import { TABLE_COLORS } from "utils/tableColors";

interface TahsilatBilgisi {
  tahsilat_id: number;
  odeme_id: number;
  banka_adi: string;
  banka_kodu: string;
  cekim_turu: string;
  cekim_tipi: string;
  cekilen_tutar: string;
  cekim_taksit: string;
  cekim_tarihi: string;
  tahsilat_durum: string;
  virman_durum: string;
  aciklama: string | null;
}

interface CollectionRowProps {
  tahsilat: TahsilatBilgisi;
  setSelectedCollectionId: Dispatch<SetStateAction<number>>;
  setShowCollectionCancellationModal: Dispatch<SetStateAction<boolean>>;
}

const CollectionRow = ({
  tahsilat,
  setSelectedCollectionId,
  setShowCollectionCancellationModal,
}: CollectionRowProps) => {
  const { formatNumber } = useNumberFormatter();

  // console.log("tahsilat : ", tahsilat);

  let bgColor = TABLE_COLORS.white;
  if (tahsilat.tahsilat_durum === "1") {
    bgColor = TABLE_COLORS.yellow;
  } else if (tahsilat.tahsilat_durum === "2") {
    bgColor = TABLE_COLORS.red;
  }

  return (
    <div
      className="d-flex align-items-stretch  gap-3 border rounded p-0 justify-content-between fw-semibold"
      style={{
        backgroundColor: `${bgColor}`,
      }}
    >
      <div
        className="border-end border-1 border-gray w-100"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "0",
        }}
      >
        <div className="border-end border-1 border-gray d-flex align-items-center px-3">
          TAHSİLAT : {formatNumber(tahsilat.cekilen_tutar)} TL
        </div>
        <div className="border-end border-1 border-gray d-flex align-items-center px-3">
          {tahsilat.cekim_turu === "kredi_karti"
            ? "Kredi Kartı"
            : tahsilat.cekim_turu === "nakit"
            ? "Nakit"
            : "Havale"}
        </div>
        <div className="border-end border-1 border-gray d-flex align-items-center px-3">
          Taksit : {tahsilat.cekim_taksit}
        </div>
        <div className="d-flex align-items-center px-3">
          Tarih : {tahsilat.cekim_tarihi}
        </div>
      </div>
      <div className="p-3">
        <CustomButton
          variant="danger"
          onClick={() => {
            setSelectedCollectionId(tahsilat.tahsilat_id);
            setShowCollectionCancellationModal(true);
          }}
        >
          İptal Et
        </CustomButton>
      </div>
    </div>
  );
};

export default CollectionRow;
