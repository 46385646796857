import { createSlice } from "@reduxjs/toolkit";
import {
  InetRoomsWithoutImagesData,
} from "types/reservationMonitoring";

export interface InetRoomsWithoutImagesInitialState {
  inetRoomsWithoutImages: InetRoomsWithoutImagesData[];
  isLoading: boolean;
  error: string;
}

const initialState: InetRoomsWithoutImagesInitialState = {
  inetRoomsWithoutImages: [],
  isLoading: false,
  error: "",
};

const inetRoomsWithoutImagesSlice = createSlice({
  name: "inetRoomsWithoutImages",
  initialState,
  reducers: {
    handleSaveInetRoomsWithoutImagesResponse(state, action) {
      state.inetRoomsWithoutImages = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveInetRoomsWithoutImagesResponse,
  setIsLoading,
  setError,
} = inetRoomsWithoutImagesSlice.actions;

export default inetRoomsWithoutImagesSlice.reducer;
