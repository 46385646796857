import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Dispatch } from "redux";
import { EditReservationInitialState } from "slices/reservation/editReservation/reducer";
import { getEditReservationData } from "slices/reservation/editReservation/thunk";

const useEditReservation = () => {
  const [searchParams] = useSearchParams();

  const dispatch: Dispatch<any> = useDispatch();

  const reservationId = searchParams.get("rezervasyon_id");

  const { isLoading } = useSelector(
    (state: { EditReservation: EditReservationInitialState }) =>
      state.EditReservation
  );

  useEffect(() => {
    if (reservationId) {
      const id = Number(reservationId);
      dispatch(getEditReservationData(id));
    }
  }, [dispatch, reservationId]);

  const isValid = reservationId ? true : false;

  return {
    isValid,
    isLoading,
  };
};

export default useEditReservation;
