import { useState } from "react";
import { useDispatch } from "react-redux";
import { handleCollectionFormErrors } from "slices/reservation/reviewReservation/reducer";
import { setToastError } from "slices/toast/reducer";
import {
  ReservationReviewCollection,
  ReservationReviewCollectionFormErrors,
} from "types/reservation";

const useValidateForm = () => {
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToError = () => {
    const errorElement = document.querySelector(
      ".border-danger"
    ) as HTMLElement;
    errorElement && errorElement.scrollIntoView({ behavior: "smooth" });
  };

  const validateForm = (
    values: ReservationReviewCollection,
    isScroll = true,
    showErrorToast = true
  ) => {
    const errors: ReservationReviewCollectionFormErrors = {};

    // if (!values.banka_adi) {
    //   errors.banka_adi = "Banka adı boş bırakılamaz.";
    // }

    dispatch(handleCollectionFormErrors(errors));

    const isValid = Object.keys(errors).length === 0;

    if (isScroll && !isValid) {
      scrollToError();
    }

    if (showErrorToast && !isValid) {
      dispatch(setToastError("Lütfen formu eksiksiz doldurunuz."));
    }

    return { isValid };
  };

  const validateFormChange = (values: ReservationReviewCollection) => {
    if (isSubmitted) {
      validateForm(values, false, false);
    }
  };

  return {
    validateForm,
    validateFormChange,
    handleSubmitted: setIsSubmitted,
    additionalPagesFormErrors: {},
  };
};

export default useValidateForm;
