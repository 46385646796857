import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  fetchReservationFailure,
  fetchReservationRequest,
  fetchReservationSuccess,
  handleSaveCollectionFormOptions,
  setActionLoading,
  setActionError,
  sendConfirmationRequest,
  sendConfirmationSuccess,
  sendConfirmationFailure,
  sendVoucherRequest,
  sendVoucherSuccess,
  sendVoucherFailure,
  getConfirmationFileRequest,
  getConfirmationFileSuccess,
  getConfirmationFileFailure,
  getVoucherFileRequest,
  getVoucherFileSuccess,
  getVoucherFileFailure,
  setAddCollectionLoading,
  setAddCollectionError,
  setMakeCommissionPaymentLoading,
  setMakeCommissionPaymentError,
} from "./reducer";

import { setToastSuccess, setToastError } from "slices/toast/reducer";

import {
  GET_REVIEW_RESERVATION,
  REVIEW_CANCEL_RESERVATION,
  SEND_CONFIRMATION,
  SEND_VOUCHER,
  GET_CONFIRMATION_FILE,
  GET_VOUCHER_FILE,
  GET_COLLECTION_FORM_OPTIONS,
  ADD_COLLECTION,
} from "helpers/url_helper";

import { Dispatch } from "redux";
import {
  ReservationReview,
  ReservationReviewCollectionFormChoices,
} from "types/reservation";
import { StartPaymentPayload } from "Components/Custom/UI/CustomCreditCard/CustomCreditCard/types";

interface CancelReservationRequestPayload {
  rez_id: number;
  cepNo: string;
}

type SuccessCallback = () => void;

export const getReservationReview =
  (reservationId: string, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchReservationRequest());
    try {
      const response = await axios.get(GET_REVIEW_RESERVATION, {
        params: {
          rezervasyon_id: reservationId,
        },
      });

      const res: ReservationReview[] = response.data || response;

      dispatch(fetchReservationSuccess(res));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(fetchReservationFailure(message));
        dispatch(setToastError(message));
      });
    }
  };

export const getCollectionFormOptions = () => async (dispatch: Dispatch) => {
  try {
    const response = await axios.get(GET_COLLECTION_FORM_OPTIONS);

    const mockResponse: ReservationReviewCollectionFormChoices = {
      gelen_havaleler: [
        {
          id: 48598,
          finekraID: "18ccc934-449e-433f-4db4-08dd324eb8f0",
          havale_tarihi: "202501112231",
          kayit_tarihi: "202501111934",
          banka: "Garanti Bankası",
          tutar: "2233.00",
          cur: "TRY",
          banka_logo:
            "https://polynom-api.finekra.com/content/banklogo/Garanti.png",
          aciklama: "FAST401141042426052608-ONUR GÖKÇE-Betül Gökçe",
          rez_kodu: "",
          durum: "0",
          tahsilat_id: 0,
          iade_durum: "0",
          iade_tutar: "",
          iade_log: "",
          islem_zamani: "2025-01-11 22:34:03",
        },
        {
          id: 48579,
          finekraID: "64bce589-de4e-4615-4b7e-08dd324eb8f0",
          havale_tarihi: "202501111836",
          kayit_tarihi: "202501111539",
          banka: "Garanti Bankası",
          tutar: "2050.00",
          cur: "TRY",
          banka_logo:
            "https://polynom-api.finekra.com/content/banklogo/Garanti.png",
          aciklama:
            "FAST3896667921-NİRVANA KOMPRESÖR SANAYİ TİCARET Lİ-NİRVANA K",
          rez_kodu: "",
          durum: "0",
          tahsilat_id: 0,
          iade_durum: "0",
          iade_tutar: "",
          iade_log: "",
          islem_zamani: "2025-01-11 18:39:02",
        },
      ],
      tahsilat_turu: [
        {
          value: "",
          label: "Seçiniz",
        },
        {
          value: "sanal_pos",
          label: "ONLINE",
        },
        {
          value: "havale",
          label: "HAVALE",
        },
        {
          value: "nakit",
          label: "NAKİT",
        },
      ],
      poslar: [
        {
          id: 2,
          banka_adi: "BONUS (garanti)",
          banka_kodu: "garanti",
          banka_tam_adi: "TÜRKİYE GARANTİ BANKASI",
          icon: "bonus.png",
          ek_taksit: "0",
          aciklama: "Lütfen misafirden tanımlı bir email adresi alınız.",
          online_durum: "1",
          ozel_durum: "1",
          durum: "1",
          odeon_id: 28,
          sira: "5",
        },
        {
          id: 3,
          banka_adi: "QNB FİNANSBANK",
          banka_kodu: "finans",
          banka_tam_adi: "QNB FİNANSBANK",
          icon: "finans.png",
          ek_taksit: "3",
          aciklama: "",
          online_durum: "1",
          ozel_durum: "0",
          durum: "1",
          odeon_id: 22,
          sira: "3",
        },
        {
          id: 4,
          banka_adi: "AXESS (akbank)",
          banka_kodu: "akbank",
          banka_tam_adi: "AKBANK",
          icon: "axess.png",
          ek_taksit: "0",
          aciklama: "",
          online_durum: "1",
          ozel_durum: "0",
          durum: "1",
          odeon_id: 20,
          sira: "6",
        },
      ],
      taksit: [
        {
          value: "",
          label: "Seçiniz",
        },
        {
          value: "1",
          label: "Tek Çekim",
        },
        {
          value: "2",
          label: "2 Taksit",
        },
        {
          value: "3",
          label: "3 Taksit",
        },
        {
          value: "4",
          label: "4 Taksit",
        },
        {
          value: "5",
          label: "5 Taksit",
        },
        {
          value: "6",
          label: "6 Taksit",
        },
        {
          value: "7",
          label: "7 Taksit",
        },
        {
          value: "8",
          label: "8 Taksit",
        },
        {
          value: "9",
          label: "9 Taksit",
        },
        {
          value: "10",
          label: "10 Taksit",
        },
        {
          value: "11",
          label: "11 Taksit",
        },
        {
          value: "12",
          label: "12 Taksit",
        },
      ],
    };

    dispatch(
      // handleSaveCollectionFormOptions(response.data || response)
      handleSaveCollectionFormOptions(mockResponse)
    );
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setToastError(message));
    });
  }
};

export const addCollection =
  (payload: FormData, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setAddCollectionLoading(true));

      await axios.post(ADD_COLLECTION, payload);

      successCallback();
      dispatch(setToastSuccess("Tahsilat başarıyla eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setAddCollectionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setAddCollectionLoading(false));
    }
  };

export const cancelReservation =
  (payload: CancelReservationRequestPayload) => async (dispatch: Dispatch) => {
    dispatch(setActionLoading(true));
    try {
      await axios.post(REVIEW_CANCEL_RESERVATION, payload);

      dispatch(setToastError("Rezervasyon iptal edildi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setActionError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setActionLoading(false));
    }
  };

export const sendConfirmation =
  (rezervasyon_id: number) => async (dispatch: Dispatch) => {
    dispatch(sendConfirmationRequest());
    try {
      await axios.post(SEND_CONFIRMATION, {
        rezervasyon_id,
      });

      dispatch(sendConfirmationSuccess());
      dispatch(setToastSuccess("Konfirme gönderildi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(sendConfirmationFailure(message));
        dispatch(setToastError(message));
      });
    }
  };

export const sendVoucher =
  (rezervasyon_id: number) => async (dispatch: Dispatch) => {
    dispatch(sendVoucherRequest());
    try {
      await axios.post(SEND_VOUCHER, {
        rezervasyon_id,
      });

      dispatch(sendVoucherSuccess());
      dispatch(setToastSuccess("Voucher gönderildi"));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(sendVoucherFailure(message));
        dispatch(setToastError(message));
      });
    }
  };

export const getConfirmationFile =
  (rezervasyon_id: number) => async (dispatch: Dispatch) => {
    dispatch(getConfirmationFileRequest());
    try {
      const response = await axios.get(GET_CONFIRMATION_FILE, {
        params: {
          rezervasyon_id,
        },
      });

      const res: { konfirme: string } = response.data || response;

      window.open(res.konfirme, "_blank", "noopener noreferrer");

      dispatch(getConfirmationFileSuccess());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(getConfirmationFileFailure(message));
        dispatch(setToastError(message));
      });
    }
  };

export const getVoucherFile =
  (rezervasyon_id: number) => async (dispatch: Dispatch) => {
    dispatch(getVoucherFileRequest());
    try {
      const response = await axios.get(GET_VOUCHER_FILE, {
        params: {
          rezervasyon_id,
        },
      });

      const res: { voucher: string } = response.data || response;

      window.open(res.voucher, "_blank", "noopener noreferrer");

      dispatch(getVoucherFileSuccess());
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(getVoucherFileFailure(message));
        dispatch(setToastError(message));
      });
    }
  };

export const makePayment =
  (payload: StartPaymentPayload) => async (dispatch: Dispatch) => {
    dispatch(setMakeCommissionPaymentLoading(true));
    try {
      const response = await fetch("/payment/start-payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMakeCommissionPaymentError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMakeCommissionPaymentLoading(false));
    }
  };
