import { createSlice } from "@reduxjs/toolkit";
import {
  ListIncomingTranfersData,
  ListIncomingTranfersPayload,
} from "types/reservationLists";

export interface ListIncomingTranfersInitialState {
  incomingTransfers: ListIncomingTranfersData[];
  filterIncomingTransfers: ListIncomingTranfersPayload;
  isLoading: boolean;
  error: string;
}

const initialState: ListIncomingTranfersInitialState = {
  incomingTransfers: [],
  filterIncomingTransfers: {
    start_date: "",
    end_date: "",
  },
  isLoading: false,
  error: "",
};

const listIncomingTransfersSlice = createSlice({
  name: "listIncomingTransfers",
  initialState,
  reducers: {
    handleSaveListIncomingTransfersResponse(state, action) {
      state.incomingTransfers = action.payload;
    },
    handleChangeFilterChoices(state, action) {
      state.filterIncomingTransfers = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveListIncomingTransfersResponse,
  handleChangeFilterChoices,
  setIsLoading,
  setError,
} = listIncomingTransfersSlice.actions;

export default listIncomingTransfersSlice.reducer;
