interface ReservationCardProps {
  title: string;
  value: string | string[];
  color: string;
}
const ReservationCard = ({ title, value, color }: ReservationCardProps) => {
  return (
    <>
      <div
        className="d-flex align-items-center  rounded px-6 py-3 flex-grow"
        style={{ backgroundColor: color, padding: "3px 32px" }}
      >
        <div className="d-flex flex-column gap-2 rounded text-white">
          {Array.isArray(value) ? (
            <div>
              {value.map((val, index) => (
                <div key={index} className="fw-semibold fs-18">
                  {val}
                </div>
              ))}
            </div>
          ) : (
            <span className="fw-semibold fs-18">{value} TL</span>
          )}
          <span className="fs-14">{title}</span>
        </div>
      </div>
    </>
  );
};

export default ReservationCard;
