import { createSlice } from "@reduxjs/toolkit";

export interface ZoiperCallInitialState {
  callCustomerLoading: boolean;
  callCustomerError: string;
}

const initialState: ZoiperCallInitialState = {
  callCustomerLoading: false,
  callCustomerError: "",
};

const zoiperCallSlice = createSlice({
  name: "zoiperCall",
  initialState,
  reducers: {
    setCallCustomerLoading(state, action) {
      state.callCustomerLoading = action.payload;
    },
    setCallCustomerError(state, action) {
      state.callCustomerError = action.payload;
    },
  },
});

export const {
  setCallCustomerLoading,
  setCallCustomerError
} = zoiperCallSlice.actions;

export default zoiperCallSlice.reducer;
