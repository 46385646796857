import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ListOnRequestReservationsInitialState } from "slices/reservationMonitoring/listOnRequestReservations/reducer";
import { filterOnRequestReservationsData } from "slices/reservationMonitoring/listOnRequestReservations/thunk";
import { ListOnRequestReservationsPayload } from "types/reservationMonitoring";

const useHandleForm = () => {
  const { isLoading, filterOnRequestReservations } = useSelector(
    (state: {
      ListOnRequestReservations: ListOnRequestReservationsInitialState;
    }) => state.ListOnRequestReservations
  );

  const [filterForm, setFilterForm] =
    useState<ListOnRequestReservationsPayload>({
      start_date: filterOnRequestReservations.start_date,
      end_date: filterOnRequestReservations.end_date,
      sorsat_id: filterOnRequestReservations.sorsat_id,
    });

  const handleFormChange = (key: string, value: any) => {
    setFilterForm((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      start_date: filterForm.start_date,
      end_date: filterForm.end_date,
      sorsat_id: filterForm.sorsat_id,
    };
    dispatch(filterOnRequestReservationsData(params, () => {}));
  };

  const handleResetFilter = () => {
    setFilterForm({
      start_date: "",
      end_date: "",
      sorsat_id: "",
    });

    const params = {
      start_date: "",
      end_date: "",
      sorsat_id: "",
    };
    dispatch(filterOnRequestReservationsData(params, () => {}));
  };

  return {
    isLoading,
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
