import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";
import { ChildInformationInitialState } from "slices/pricesAndQuota/childInformation/reducer";
import { saveChildInformation } from "slices/pricesAndQuota/childInformation/thunk";
import {
  ChildGeneralInformation,
  ChildInformationRequestPayload,
} from "types/PricesQuotas";

interface Option {
  value: number;
  label: string;
}

const useHandleForm = ({
  otel_id,
  oda_id,
}: {
  otel_id: number;
  oda_id: number;
}) => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectChildInformationState = (state: {
    ChildInformations: ChildInformationInitialState;
  }) => state.ChildInformations;

  const selectChildInformationProperties = createSelector(
    selectChildInformationState,
    (state) => ({
      childInformation: state.childInformation,
      childInformationState: state.childInformationState,
      isLoading: state.isLoading,
      error: state.error,
    })
  );

  const selectHotelState = (state: any) => state.Hotel;
  const selectHotelProperties = createSelector(selectHotelState, (state) => ({
    hotel: state.hotel,
  }));

  const { childInformationState, childInformation, isLoading, error } =
    useSelector(selectChildInformationProperties);

  const { hotel } = useSelector(selectHotelProperties);

  const [childSpecialAgeOptions, setChildSpecialAgeOptions] = useState<
    Option[]
  >([]);

  useEffect(() => {
    const options = childInformation.cocuk_yas_bilgileri.map((item) => {
      return {
        value: item.id,
        label: item.ozel_yas_ismi,
      };
    });
    setChildSpecialAgeOptions(options);
  }, [childInformation]);

  const singleChildInformationState = (state: any) => state.ChildInformations;
  const selectSingleChildInformationProperties = createSelector(
    singleChildInformationState,
    (state) => ({
      childSingleGeneralInformation: state.childSingleGeneralInformation,
      childMultipleGeneralInformation: state.childMultipleGeneralInformation,
    })
  );

  const { childSingleGeneralInformation, childMultipleGeneralInformation } =
    useSelector(selectSingleChildInformationProperties);

  const [isClicked, setIsClicked] = useState("0");

  const getChildAgeInformation = (
    value: number,
    key: string,
    state: ChildGeneralInformation
  ) => {
    // if (key === "ucretsiz_yas_bitis") {
    //   return {
    //     ...state,
    //     ucretsiz_yas_bitis: value.toString(),
    //     ucretli_yas_baslangic: (value + 1).toString(),
    //     ucretli_yas_bitis:
    //       state.ucretli_yas_bitis <= (value + 1).toString()
    //         ? (value + 2).toString()
    //         : state.ucretli_yas_bitis,
    //   };
    // }
    if (key === "ucretsiz_yas_bitis") {
      if (value === 0) {
        return {
          ...state,
          ucretsiz_yas_bitis: value.toString(),
          ucretli_yas_baslangic: value.toString(),
        };
      } else {
        const currentUcretsizYasBitis = parseInt(state.ucretsiz_yas_bitis, 10);
        const currentUcretliYasBitis = parseInt(state.ucretli_yas_bitis, 10);
        const currentUcretliYasBaslangic = parseInt(
          state.ucretli_yas_baslangic,
          10
        );

        if (value > currentUcretsizYasBitis) {
          // Artış durumu
          if (currentUcretliYasBaslangic === currentUcretliYasBitis - 1) {
            return {
              ...state,
              ucretsiz_yas_bitis: value.toString(),
              ucretli_yas_baslangic: (value + 1).toString(),
              ucretli_yas_bitis:
                currentUcretliYasBitis <= value + 1
                  ? (value + 2).toString()
                  : currentUcretliYasBitis.toString(),
            };
          } else {
            return {
              ...state,
              ucretsiz_yas_bitis: value.toString(),
              ucretli_yas_baslangic: (value + 1).toString(),
              ucretli_yas_bitis:
                currentUcretliYasBitis <= value + 1
                  ? (value + 2).toString()
                  : currentUcretliYasBitis.toString(),
            };
          }
        } else {
          // Azalış durumu
          return {
            ...state,
            ucretsiz_yas_bitis: value.toString(),
            ucretli_yas_baslangic: (value + 1).toString(),
            // ucretli_yas_bitis değişmiyor
          };
        }
      }
    }

    if (key === "ucretli_yas_baslangic") {
      if (value === 0) {
        return {
          ...state,
          ucretli_yas_baslangic: value.toString(),
          ucretsiz_yas_bitis: value.toString(),
        };
      } else {
        const currentUcretliYasBaslangic = parseInt(value.toString(), 10);
        const currentUcretsizYasBitis = parseInt(state.ucretsiz_yas_bitis, 10);
        const currentUcretliYasBitis = parseInt(state.ucretli_yas_bitis, 10);

        if (value < currentUcretsizYasBitis) {
          // Azalış durumu
          if (currentUcretliYasBitis === currentUcretliYasBaslangic + 1) {
            return {
              ...state,
              ucretli_yas_baslangic: value.toString(),
              ucretli_yas_bitis: (value + 1).toString(),
              ucretsiz_yas_bitis: (value - 1).toString(),
            };
          } else {
            return {
              ...state,
              ucretli_yas_baslangic: value.toString(),
              ucretli_yas_bitis: (value + 1).toString(),
              ucretsiz_yas_bitis: (value - 1).toString(),
            };
          }
        } else {
          // Artış durumu
          return {
            ...state,
            ucretli_yas_baslangic: value.toString(),
            ucretli_yas_bitis: (value + 1).toString(),
            ucretsiz_yas_bitis: (value - 1).toString(),
          };
        }
      }
    }

    if (key === "ucretli_yas_bitis") {
      if (value === 0) {
        return {
          ...state,
          ucretli_yas_bitis: value.toString(),
          ucretli_yas_baslangic: value.toString(),
          ucretsiz_yas_bitis: value.toString(), // ucretsiz_yas_bitis de 0 olacak
        };
      } else {
        // const currentUcretliYasBitis = parseInt(state.ucretli_yas_bitis, 10);
        const currentUcretliYasBaslangic = parseInt(
          state.ucretli_yas_baslangic,
          10
        );
        if (value > currentUcretliYasBaslangic) {
          return {
            ...state,
            ucretli_yas_bitis: value.toString(),
          };
        } else {
          return {
            ...state,
            ucretli_yas_bitis: value.toString(),
            ucretli_yas_baslangic: (value - 1).toString(),
            ucretsiz_yas_bitis: (value - 2).toString(),
          };
        }
      }
    }

    return state;
  };

  const handleSave = () => {
    const saveNewChildInformationRequestPayload: ChildInformationRequestPayload =
      {
        otel_id: otel_id,
        oda_id: oda_id,
        dogum_hesaplama:
          childInformationState.oda_genel_bilgileri.dogum_hesaplama,
        tek_cocuk: childInformationState.oda_genel_bilgileri.tek_yani_cocuk,
        yas_id: childInformationState.cocuk_bilgileri[0].ozel_yas_id,
        tip: isClicked === "0" ? "tek" : "cift",
        // cocuk_yas_araliklari: childInformationState.cocuk_bilgileri.map(
        //   (item) => {
        //     return {
        //       sira: item.id,
        //       ucretsiz: parseInt(item.ucretsiz_yas_bitis, 10),
        //       ucretli: parseInt(item.ucretli_yas_bitis, 10),
        //       ucretsiz_yas_baslangic: parseInt(item.ucretsiz_yas_baslangic, 10),
        //       ucretsiz_yas_bitis: parseInt(item.ucretsiz_yas_bitis, 10),
        //       ucretli_yas_baslangic: parseInt(item.ucretli_yas_baslangic, 10),
        //       ucretli_yas_bitis: parseInt(item.ucretli_yas_bitis, 10),
        //     };
        //   }
        // ),
        cocuk_yas_araliklari:
          isClicked === "0"
            ? childSingleGeneralInformation.map((item: any) => {
                return {
                  sira: item.cocuk,
                  ucretsiz: (item.cocuk_kabul ? "1" : "0").toString(),
                  ucretli: (item.cocuk_kabul2 ? "1" : "0").toString(),
                  ucretsiz_yas_baslangic: parseInt(
                    item.ucretsiz_yas_baslangic,
                    10
                  ),
                  ucretsiz_yas_bitis: parseInt(item.ucretsiz_yas_bitis, 10),
                  ucretli_yas_baslangic: parseInt(
                    item.ucretli_yas_baslangic,
                    10
                  ),
                  ucretli_yas_bitis: parseInt(item.ucretli_yas_bitis, 10),
                };
              })
            : childMultipleGeneralInformation.map((item: any) => {
                return {
                  sira: item.cocuk,
                  ucretsiz: (item.cocuk_kabul ? "1" : "0").toString(),
                  ucretli: (item.cocuk_kabul2 ? "1" : "0").toString(),
                  ucretsiz_yas_baslangic: parseInt(
                    item.ucretsiz_yas_baslangic,
                    10
                  ),
                  ucretsiz_yas_bitis: parseInt(item.ucretsiz_yas_bitis, 10),
                  ucretli_yas_baslangic: parseInt(
                    item.ucretli_yas_baslangic,
                    10
                  ),
                  ucretli_yas_bitis: parseInt(item.ucretli_yas_bitis, 10),
                };
              }),
      };
    dispatch(saveChildInformation(saveNewChildInformationRequestPayload));
  };

  return {
    childInformationState,
    childInformation,
    childSpecialAgeOptions,
    childSingleGeneralInformation,
    childMultipleGeneralInformation,
    isClicked,
    setIsClicked,
    getChildAgeInformation,
    handleSave,
    hotel,
    isLoading,
    error,
  };
};

export default useHandleForm;
