import { CustomRowOperationButton } from "Components/Custom/UI";
import { useNumberFormatter } from "Components/Hooks/useNumberFormatter";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NoteConfirmationReservationsInitialState } from "slices/reservationLists/noteconfirmationReservations/reducer";
import { TABLE_COLORS } from "utils/tableColors";

const useHandleTable = () => {

  const navigate = useNavigate();

  const { noteConfirmationReservations } = useSelector(
    (state: {
      NoteConfirmationReservations: NoteConfirmationReservationsInitialState;
    }) => state.NoteConfirmationReservations
  );

  const { formatNumber } = useNumberFormatter();

  const handleShowReservation = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-incele?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const rowColors = {
    success: TABLE_COLORS.green,
    inverse: TABLE_COLORS.gray,
    info: TABLE_COLORS.blue,
  };

  const tableData = noteConfirmationReservations?.map((reservation, index) => ({
    rezervasyon_kodu: reservation.rezervasyon_kodu, // Rez. Kod
    yeni_field: reservation.yeni_field, // ! EKLENECEK Rez. Kod
    otel_yetkilisi: reservation.otel_yetkilisi, // Yetkili
    grup_adi: reservation.grup_adi, // Sat. Grup
    kayit_tarihi: reservation.kayit_tarihi, // Sat. Tarihi
    musteri_ad_soyad: reservation.musteri_ad_soyad, // Müşteri Ad
    yeni_field_2: reservation.yeni_field_2, // ! EKLENECEK Müşteri Ad
    otel_adi: reservation.otel_adi, // Otel Adı
    inet_otel_kodu: reservation.inet_otel_kodu, // Otel Adı
    giris_tarihi: reservation.giris_tarihi, // Giriş Tarihi
    cikis_tarihi: reservation.cikis_tarihi, // Çıkış Tarihi
    cekilecek_tutar: reservation.cekilecek_tutar, // Çekilen Tutar
    toplam_otel_maliyet_tutar_kur: reservation.toplam_otel_maliyet_tutar_kur, // Maliyet Tutar
    yeni_field_3: reservation.yeni_field_3, // ! EKLENECEK N. Konf
    iptal_nedeni: reservation.iptal_nedeni, // N. Konf
    otel_mail: reservation.otel_mail, // ! EKLENECEK
    renk: reservation.renk, // ! EKLENECEK
    rowColor: rowColors[reservation.renk as keyof typeof rowColors],
    rowNumber: index + 1,
  }));

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "rowNumber",
      enableColumnFilter: false,
    },
    {
      header: "Rez. Kod",
      accessorKey: "rezervasyon_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Yetkili",
      accessorKey: "otel_yetkilisi",
      enableColumnFilter: false,
    },
    {
      header: "Sat. Grup",
      accessorKey: "grup_adi",
      enableColumnFilter: false,
    },
    {
      header: "Sat. Tarihi",
      accessorKey: "kayit_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Müşteri Ad",
      accessorKey: "musteri_ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Giriş Tarihi",
      accessorKey: "giris_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Çıkış Tarihi",
      accessorKey: "cikis_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Çekilen Tutar",
      accessorKey: "cekilecek_tutar",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        const number = formatNumber(value);
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {number}
            </div>
          </>
        );
      },
    },
    {
      header: "Maliyet Tutar",
      accessorKey: "toplam_otel_maliyet_tutar_kur",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        const number = formatNumber(value);
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {number}
            </div>
          </>
        );
      },
    },
    {
      header: "N. Konf",
      accessorKey: "iptal_nedeni",
      enableColumnFilter: false,
    },
    {
      header: "Otel Mail",
      accessorKey: "otel_mail",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="update"
                onClick={() => handleShowReservation(id)}
                title="İncele"
              />
              <CustomRowOperationButton
                variant="check"
                onClick={() => {}}
                title="Konfirme"
              />
              <CustomRowOperationButton
                variant="delete"
                onClick={() => {}}
                title="İptal Konfirme"
              />
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.gray,
      label: "Non - Konfirme",
    },
    {
      color: TABLE_COLORS.green,
      label: "Konfirme",
    },
    {
      color: TABLE_COLORS.blue,
      label: "Otel Not - Konfirme",
    },
  ];

  return { tableData, tableColumns, legend };
};

export default useHandleTable;
