import { CustomFormContainer } from "Components/Custom/UI";
import { useState } from "react";
import { Collapse } from "reactstrap";
import useHandleForm from "./useHandleForm";
import DateInput from "./FormFields/DateInput";
import FindIncomingTransfersButtons from "./FormFields/FindIncomingTransfersButtons";

const FilterIncomingTransfersForm = () => {
  const {
    isLoading,
    filterForm,
    handleFormChange,
    onGetFilteredList,
    onResetFilter,
  } = useHandleForm();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <CustomFormContainer>
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer bg-secondary py-1 px-3 text-white rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="fs-18 fw-bold m-0 ">Gelen Havaleleri Filtrele</p>
        <i className={`ri-arrow-${isOpen ? "up" : "down"}-s-line fs-24`}></i>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="d-flex flex-column gap-2">
          <DateInput
            values={filterForm}
            handleFormChange={handleFormChange}
          />
          <FindIncomingTransfersButtons
            onResetFilter={onResetFilter}
            onGetFilteredList={onGetFilteredList}
            isLoading={isLoading}
          />
        </div>
      </Collapse>
    </CustomFormContainer>
  );
};

export default FilterIncomingTransfersForm;
