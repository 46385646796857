import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { loginSuccess } from "slices/auth/login/reducer";

interface UserInfo {
  user: string;
  token: string;
  authorities: string;
}

type GetUserInfoFromCookie = () => {
  user: string;
  token: string;
  authorities: any;
};

const getUserInfoFromCookie: GetUserInfoFromCookie = () => {
  const user = localStorage.getItem("user");
  const token = Cookies.get("token");
  const authorities = localStorage.getItem("auth");


  if (!user || !token || !authorities) {
    return {
      user: "",
      token: "",
      authorities: "",
    };
  } else {
    return { user: JSON.parse(user), token, authorities };
  }
};

const useGetUser = () => {
  const { user, token, authorities } = getUserInfoFromCookie();

  const dispatch: Dispatch<any> = useDispatch();

  const info = user && token;

  const [loading, setLoading] = useState(info ? false : true);
  const [userInfo, setUserInfo] = useState<UserInfo>(
    info
      ? { user, token, authorities }
      : {
          user: "",
          token: "",
          authorities: "",
        }
  );

  useEffect(() => {
    const info = getUserInfoFromCookie();

    user && dispatch(loginSuccess(user));

    setUserInfo(
      info
        ? { user, token, authorities }
        : {
            user: "",
            token: "",
            authorities: "",
          }
    );
    setLoading(info ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { userInfo, loading };
};

export default useGetUser;
