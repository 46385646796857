import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveOnRequestReservationsResponse,
  setIsLoading,
  setError,
  setMarkAsResponsibleLoading,
  setMarkAsResponsibleError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  ANSWER_REQUEST,
  FILTER_ON_REQUEST_RESERVATIONS,
  LIST_ON_REQUEST_RESERVATIONS,
  ON_REQUEST_MARK_AS_RESPONSIBLE,
  UPDATE_ON_REQUEST_STATUS,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import {
  AnswerOnRequestReservationPayload,
  ListOnRequestReservationsPayload,
  UpdateStatusOnRequestReservationPayload,
} from "types/reservationMonitoring";

type SuccessCallback = () => void;

export const listOnRequestReservations = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(LIST_ON_REQUEST_RESERVATIONS);

    dispatch(
      handleSaveOnRequestReservationsResponse(response.data || response)
    );
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const filterOnRequestReservationsData =
  (
    payload: ListOnRequestReservationsPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(
        FILTER_ON_REQUEST_RESERVATIONS,
        payload
      );

      dispatch(
        handleSaveOnRequestReservationsResponse(response.data || response)
      );
      dispatch(setToastSuccess("Sorsatlar başarıyla listelendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const answerRequest =
  (
    payload: AnswerOnRequestReservationPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(
        ANSWER_REQUEST,
        payload
      );

      dispatch(setToastSuccess("Sorsat cevabı kaydedildi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  export const updateStatus =
  (
    payload: UpdateStatusOnRequestReservationPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(
        UPDATE_ON_REQUEST_STATUS,
        payload
      );

      dispatch(setToastSuccess("Sorsat durumu başarıyla güncellendi"));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  export const markAsResponsible =
  (payload: {sorsat_id: number}, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setMarkAsResponsibleLoading(true));
     await axios.post(ON_REQUEST_MARK_AS_RESPONSIBLE, payload);

      dispatch(setToastSuccess("Sorumlu başarıyla atandı."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMarkAsResponsibleError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMarkAsResponsibleLoading(false));
    }
  };