import { CustomFormFieldContainer } from "Components/Custom/UI";
import {
  CreateGroupReservationPayload,
  CreateGroupReservationFormErrors,
} from "types/reservation";
import AsyncSelect from "react-select/async";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { handleChangeInput } from "slices/reservation/enterGroupReservation/reducer";

interface HotelNameInputProps {
  values: CreateGroupReservationPayload;
  formErrors: CreateGroupReservationFormErrors;
  validateFormChange: (values: CreateGroupReservationPayload) => void;
  loadOptions: () => Promise<any>;
  handleChangeQuery: (value: string) => void;
}

const HotelNameInput = ({
  values,
  formErrors,
  loadOptions,
  validateFormChange,
  handleChangeQuery,
}: HotelNameInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Otel Adı "
        error={formErrors.otel_adi}
        divider
        required
      >
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          onInputChange={handleChangeQuery}
          placeholder="Otel Adı Giriniz..."
          onChange={(selectedOption) => {
            if (!selectedOption) return;

            dispatch(
              handleChangeInput({
                otel_id: selectedOption.value,
              })
            );
            dispatch(
              handleChangeInput({
                otel_adi: selectedOption.label,
              })
            );
            validateFormChange({
              ...values,
              otel_adi: selectedOption.label,
            });
          }}
          defaultValue={
            values.otel_adi
              ? {
                  value: values.otel_adi,
                  label: values.otel_adi,
                }
              : null
          }
          defaultOptions={[]}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default HotelNameInput;
