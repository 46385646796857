import { handleErrors } from "helpers/axios_helpers";
import { Dispatch } from "redux";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import axios from "axios";
import { CreateGroupReservationPayload } from "types/reservation";
import { ENTER_GROUP_RESERVATION } from "helpers/url_helper";
import { setIsLoading, setError } from "./reducer";

type SuccessCallback = () => void;
export const enterGroupReservation =
  (payload: CreateGroupReservationPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));

      await axios.post(ENTER_GROUP_RESERVATION, payload);

      successCallback();
      dispatch(setToastSuccess("Grup rezervasyon talebi eklendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
