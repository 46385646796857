import INetNonentryReservationsTable from "./INetNonentryReservationsTable";

interface ListINetNonentryReservationsPageProps {
  isLoading: boolean;
}

const ListINetNonentryReservationsPage = ({ isLoading }: ListINetNonentryReservationsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <INetNonentryReservationsTable isLoading={isLoading} />
    </div>
  );
};

export default ListINetNonentryReservationsPage;
