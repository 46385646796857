import ConfirmationApprovalPage from "Components/Custom/ReservationListsPages/ConfirmationApprovalPage";
import PageContainer from "pages/PageContainer";

const ConfirmationApproval = () => {
//   const { isLoading } = useConfirmationApproval();

  return (
    <PageContainer title="Konfirme Onay">
      <ConfirmationApprovalPage isLoading={false} />
    </PageContainer>
  );
};

export default ConfirmationApproval;
