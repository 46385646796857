import { createSlice } from "@reduxjs/toolkit";
import { HotelsWithoutPriceData } from "types/documents";

export interface HotelsWithoutPriceInitialState {
  hotelsWithoutPrice: HotelsWithoutPriceData[];
  isLoading: boolean;
  error: string;
}

const initialState: HotelsWithoutPriceInitialState = {
  hotelsWithoutPrice: [],
  isLoading: false,
  error: "",
};

const hotelsWithoutPriceSlice = createSlice({
  name: "hotelsWithoutPrice",
  initialState,
  reducers: {
    handleSaveHotelsWithoutPriceResponse(state, action) {
      state.hotelsWithoutPrice = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveHotelsWithoutPriceResponse,
  setIsLoading,
  setError,
} = hotelsWithoutPriceSlice.actions;

export default hotelsWithoutPriceSlice.reducer;