import { CustomPageDescriptions } from "Components/Custom/UI";
import { CONFIRMATION_APPROVAL_DESCRIPTION } from "utils/pageDescriptions";
import FilterReservationsForm from "./FilterReservationsForm";
import ReservationsTable from "./ReservationsTable";

interface ConfirmationApprovalPageProps {
  isLoading: boolean;
}

const ConfirmationApprovalPage = ({
  isLoading,
}: ConfirmationApprovalPageProps) => {

  const descriptions = CONFIRMATION_APPROVAL_DESCRIPTION.map(
    (description, index) => (
      <div
        key={index}
        dangerouslySetInnerHTML={{ __html: description }}
        className="user-select-none"
      />
    )
  );

  return (
    <div className="d-flex flex-column gap-4">
      <CustomPageDescriptions variant="info" descriptions={descriptions} />
      <FilterReservationsForm />
      <ReservationsTable isLoading={isLoading} />
    </div>
  );
};

export default ConfirmationApprovalPage;
