import EnterGroupReservationPage from "Components/Custom/ReservationPages/EnterGroupReservationPage";
import PageContainer from "pages/PageContainer";

const EnterGroupReservation = () => {
//   const { values } = useEnterGroupReservation();

  return (
    <PageContainer title="Grup Rezervasyon Girişi">
      <EnterGroupReservationPage isLoading={false} />
    </PageContainer>
  );
};

export default EnterGroupReservation;
