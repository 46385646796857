import { RoomOption } from "types/reservation";
import FacilityAvailabilities from "../FacilityAvailabilities";
import FacilityChildrenInfo from "../FacilityChildrenInfo";
import FacilityGeneralInfo from "../FacilityGeneralInfo";
import FacilityLocation from "../FacilityLocation";
import HotelGroup from "../HotelGroup";
import PhotoGallery from "../PhotoGallery";
import RoomCards from "../RoomCards";
import SendSmsButton from "../SendSmsButton";

const useHandleTabs = (
  toggleOnRequestModal: () => void,
  handleGroupReservationRequest: () => void,
  handleSelectRoomToCallLater: (
    oda_adi: string,
    pansiyon_adi: string,
    fiyat: string
  ) => void,
  selectedRoom: RoomOption
) => {
  const tabButtons = [
    {
      tabId: "1",
      tabName: "Hesaplanan Fiyat",
    },
    {
      tabId: "2",
      tabName: "Genel",
    },
    {
      tabId: "3",
      tabName: "Konum",
    },
    {
      tabId: "4",
      tabName: "Galeri",
    },
    {
      tabId: "5",
      tabName: "Zincir Oteller",
    },
    {
      tabId: "6",
      tabName: "Müsaitlik",
    },
    {
      tabId: "7",
      tabName: "Çocuk Bilgisi",
    },
    {
      tabId: "8",
      tabName: "Havale SMS At",
    },
  ];

  const tabContents = [
    {
      tabId: "1",
      tabContent: (
        <RoomCards
          toggleOnRequestModal={toggleOnRequestModal}
          handleGroupReservationRequest={handleGroupReservationRequest}
          handleSelectRoomToCallLater={handleSelectRoomToCallLater}
          selectedRoom={selectedRoom}
        />
      ),
    },
    {
      tabId: "2",
      tabContent: <FacilityGeneralInfo />,
    },
    {
      tabId: "3",
      tabContent: <FacilityLocation />,
    },
    {
      tabId: "4",
      tabContent: <PhotoGallery />,
    },
    {
      tabId: "5",
      tabContent: <HotelGroup />,
    },
    {
      tabId: "6",
      tabContent: <FacilityAvailabilities />,
    },
    {
      tabId: "7",
      tabContent: <FacilityChildrenInfo />,
    },
    {
      tabId: "8",
      tabContent: <SendSmsButton />,
    },
  ];

  return { tabButtons, tabContents };
};

export default useHandleTabs;
