import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import { GET_RESERVATION_CALL_HOTELS_AND_LOCATIONS } from "helpers/url_helper";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  ReservationCallInitialState,
  handleCalculationFormChange,
} from "slices/reservation/reservationCall/reducer";
import {
  calculatePriceForHotel,
  calculatePriceForLocation,
} from "slices/reservation/reservationCall/thunk";
import { setToastError } from "slices/toast/reducer";
import {
  CalculatePriceForHotelRequestParams,
  CalculatePriceForLocationRequestPayload,
  CalculatePriceFormErrors,
  CalculatePriceFormValues,
  ReservationCallLocation,
} from "types/reservation";
import { useNavigate, useSearchParams } from "react-router-dom";

const validateForm = (
  formValues: CalculatePriceFormValues,
  dispatch: Dispatch<any>,
  singleCalculation: boolean
) => {
  const errors: CalculatePriceFormErrors = {};

  if (singleCalculation && !formValues.secilen.value) {
    errors.secilen = "Lütfen bir otel seçiniz.";
  }
  if (!formValues.giris_tarihi) {
    errors.giris_tarihi = "Lütfen giriş tarihini seçiniz.";
  }
  if (!formValues.cikis_tarihi) {
    errors.cikis_tarihi = "Lütfen çıkış tarihini seçiniz.";
  }
  if (!formValues.yetiskin_sayisi) {
    errors.yetiskin_sayisi = "En az bir yetişkin seçmelisiniz.";
  }
  if (formValues.cocuk_sayisi > 3 && !formValues.cocuk_dogum_4) {
    errors.cocuk_dogum_4 = "4. çocuğun doğum tarihini seçiniz.";
  }
  if (
    formValues.cocuk_sayisi > 3 &&
    moment().diff(moment(formValues.cocuk_dogum_4), "years") > 12
  ) {
    errors.cocuk_dogum_4 = "4. çocuk 12 yaşından küçük olmalıdır.";
  }

  if (formValues.cocuk_sayisi > 2 && !formValues.cocuk_dogum_3) {
    errors.cocuk_dogum_3 = "3. çocuğun doğum tarihini seçiniz.";
  }

  if (
    formValues.cocuk_sayisi > 2 &&
    moment().diff(moment(formValues.cocuk_dogum_3), "years") > 12
  ) {
    errors.cocuk_dogum_3 = "3. çocuk 12 yaşından küçük olmalıdır.";
  }
  if (formValues.cocuk_sayisi > 1 && !formValues.cocuk_dogum_2) {
    errors.cocuk_dogum_2 = "2. çocuğun doğum tarihini seçiniz.";
  }

  if (
    formValues.cocuk_sayisi > 1 &&
    moment().diff(moment(formValues.cocuk_dogum_2), "years") > 12
  ) {
    errors.cocuk_dogum_2 = "2. çocuk 12 yaşından küçük olmalıdır.";
  }
  if (formValues.cocuk_sayisi > 0 && !formValues.cocuk_dogum_1) {
    errors.cocuk_dogum_1 = "1. çocuğun doğum tarihini seçiniz.";
  }

  if (
    formValues.cocuk_sayisi > 0 &&
    moment().diff(moment(formValues.cocuk_dogum_1), "years") > 12
  ) {
    errors.cocuk_dogum_1 = "1. çocuk 12 yaşından küçük olmalıdır.";
  }

  const isValid = Object.keys(errors).length === 0;

  !isValid && dispatch(setToastError(Object.values(errors)[0]));

  return isValid;
};

const useHandleForm = (singleCalculation: boolean) => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const { reservationCall, calculatePriceLoading, calculationForm } =
    useSelector(
      (state: { ReservationCall: ReservationCallInitialState }) =>
        state.ReservationCall
    );

  const handleFormChange = (
    key: string,
    value:
      | number
      | string
      | {
          value: number;
          label: string;
          category: string;
        }
      | string[]
  ) => {
    dispatch(handleCalculationFormChange({ [key]: value }));
  };

  const hotelInfo =
    reservationCall.otel_sorgu.length === 1
      ? reservationCall.otel_sorgu[0]
      : null;

  const [query, setQuery] = useState("");

  const loadOptions = async () => {
    if (query.length > 1) {
      try {
        const response = await axios.post(
          GET_RESERVATION_CALL_HOTELS_AND_LOCATIONS,
          {
            term: query,
            platform: "crm",
          }
        );
        const res: ReservationCallLocation[] =
          (response as any).data || response;

        const options = res.reduce(
          (acc: any, item: ReservationCallLocation) => {
            if (item.category === "Otel") {
              acc[0].options.push({
                value: item.id,
                label: item.label,
                category: item.category,
              });
            } else {
              acc[1].options.push({
                value: item.id,
                label: item.label,
                category: item.category,
              });
            }
            return acc;
          },
          [
            {
              label: "Otel",
              options: [],
            },
            {
              label: "Lokasyon",
              options: [],
            },
          ]
        );

        return options;
      } catch (error) {
        handleErrors(error, (message: string) => {
          dispatch(setToastError(message));
        });
      }
    }
  };
  const handleChangeQuery = (value: string) => {
    setQuery(value);
  };

  const [searchParams] = useSearchParams();

  const aranan = searchParams.get("aranan") || "";
  const arayan = searchParams.get("arayan") || "";
  const dahili = searchParams.get("dahili") || "";

  const handleCalculatePriceForHotel = () => {
    const payload: CalculatePriceForHotelRequestParams = singleCalculation
      ? {
          otel_id: calculationForm.secilen.value,
          giris_tarihi: moment(calculationForm.giris_tarihi, "D MMMM Y").format(
            "YYYY-MM-DD"
          ),
          cikis_tarihi: moment(calculationForm.cikis_tarihi, "D MMMM Y").format(
            "YYYY-MM-DD"
          ),
          yetiskin_sayisi: calculationForm.yetiskin_sayisi,
          cocuk_sayisi: calculationForm.cocuk_sayisi,
          cocuk_dogum_1:
            calculationForm.cocuk_sayisi > 0
              ? calculationForm.cocuk_dogum_1
              : "",
          cocuk_dogum_2:
            calculationForm.cocuk_sayisi > 1
              ? calculationForm.cocuk_dogum_2
              : "",
          cocuk_dogum_3:
            calculationForm.cocuk_sayisi > 2
              ? calculationForm.cocuk_dogum_3
              : "",
          cocuk_dogum_4:
            calculationForm.cocuk_sayisi > 3
              ? calculationForm.cocuk_dogum_4
              : "",
        }
      : {
          giris_tarihi: moment(calculationForm.giris_tarihi, "D MMMM Y").format(
            "YYYY-MM-DD"
          ),
          cikis_tarihi: moment(calculationForm.cikis_tarihi, "D MMMM Y").format(
            "YYYY-MM-DD"
          ),
          yetiskin_sayisi: calculationForm.yetiskin_sayisi,
          cocuk_sayisi: calculationForm.cocuk_sayisi,
          cocuk_dogum_1:
            calculationForm.cocuk_sayisi > 0
              ? calculationForm.cocuk_dogum_1
              : "",
          cocuk_dogum_2:
            calculationForm.cocuk_sayisi > 1
              ? calculationForm.cocuk_dogum_2
              : "",
          cocuk_dogum_3:
            calculationForm.cocuk_sayisi > 2
              ? calculationForm.cocuk_dogum_3
              : "",
          cocuk_dogum_4:
            calculationForm.cocuk_sayisi > 3
              ? calculationForm.cocuk_dogum_4
              : "",
          musteri_id: reservationCall.musteri_id,
          arama_id: reservationCall.arama_id,
          musteri_tel: reservationCall.musteri_tel,
          musteri_ad_soyad: reservationCall.musteri_ad_soyad,
          geri_arama_id: reservationCall.geri_arama_id,
          geri_arama_tipi: reservationCall.geri_arama_tipi,
          otel_id: calculationForm.secilen.value
            ? calculationForm.secilen.value
            : hotelInfo?.otel_id || 0,
          option: ["filterx", "filter_zincir"],
          parapuan_secim: calculationForm.parapuan_secim,
          ozel_indirimler: calculationForm.ozel_indirimler,
        };

    if (!payload.cocuk_dogum_1) {
      delete payload.cocuk_dogum_1;
    }
    if (!payload.cocuk_dogum_2) {
      delete payload.cocuk_dogum_2;
    }
    if (!payload.cocuk_dogum_3) {
      delete payload.cocuk_dogum_3;
    }
    if (!payload.cocuk_dogum_4) {
      delete payload.cocuk_dogum_4;
    }

    if (validateForm(calculationForm, dispatch, singleCalculation)) {
      sessionStorage.setItem(
        "customerCalculationParameters",
        JSON.stringify(payload)
      );

      dispatch(
        calculatePriceForHotel(payload, () => {
          handleFormChange("ozel_indirimler", []);

          navigate(
            
            singleCalculation
              ? `/otel-fiyat-hesaplama?id=${payload.otel_id}`
              : `/otel-fiyat-hesaplama?id=${payload.otel_id}&aranan=${aranan}&arayan=${arayan}&dahili=${dahili}`
          );
        })
      );
    }
  };

  const handleCalculatePriceForLocation = () => {
    const payload: CalculatePriceForLocationRequestPayload = singleCalculation
      ? {
          search: calculationForm.secilen.label,
          giris_tarihi: moment(calculationForm.giris_tarihi, "D MMMM Y").format(
            "YYYY-MM-DD"
          ),
          cikis_tarihi: moment(calculationForm.cikis_tarihi, "D MMMM Y").format(
            "YYYY-MM-DD"
          ),
          yetiskin_sayisi: calculationForm.yetiskin_sayisi,
          cocuk_sayisi: calculationForm.cocuk_sayisi,
          cocuk_dogum_1:
            calculationForm.cocuk_sayisi > 0
              ? calculationForm.cocuk_dogum_1
              : "",
          cocuk_dogum_2:
            calculationForm.cocuk_sayisi > 1
              ? calculationForm.cocuk_dogum_2
              : "",
          cocuk_dogum_3:
            calculationForm.cocuk_sayisi > 2
              ? calculationForm.cocuk_dogum_3
              : "",
          cocuk_dogum_4:
            calculationForm.cocuk_sayisi > 3
              ? calculationForm.cocuk_dogum_4
              : "",
        }
      : {
          giris_tarihi: moment(calculationForm.giris_tarihi, "D MMMM Y").format(
            "YYYY-MM-DD"
          ),
          cikis_tarihi: moment(calculationForm.cikis_tarihi, "D MMMM Y").format(
            "YYYY-MM-DD"
          ),
          yetiskin_sayisi: calculationForm.yetiskin_sayisi,
          cocuk_sayisi: calculationForm.cocuk_sayisi,
          cocuk_dogum_1:
            calculationForm.cocuk_sayisi > 0
              ? calculationForm.cocuk_dogum_1
              : "",
          cocuk_dogum_2:
            calculationForm.cocuk_sayisi > 1
              ? calculationForm.cocuk_dogum_2
              : "",
          cocuk_dogum_3:
            calculationForm.cocuk_sayisi > 2
              ? calculationForm.cocuk_dogum_3
              : "",
          cocuk_dogum_4:
            calculationForm.cocuk_sayisi > 3
              ? calculationForm.cocuk_dogum_4
              : "",
          musteri_id: reservationCall.musteri_id,
          arama_id: reservationCall.arama_id,
          musteri_tel: reservationCall.musteri_tel,
          musteri_ad_soyad: reservationCall.musteri_ad_soyad,
          geri_arama_id: reservationCall.geri_arama_id,
          geri_arama_tipi: reservationCall.geri_arama_tipi,
          search: calculationForm.secilen.label,
        };

    if (!payload.cocuk_dogum_1) {
      delete payload.cocuk_dogum_1;
    }
    if (!payload.cocuk_dogum_2) {
      delete payload.cocuk_dogum_2;
    }
    if (!payload.cocuk_dogum_3) {
      delete payload.cocuk_dogum_3;
    }
    if (!payload.cocuk_dogum_4) {
      delete payload.cocuk_dogum_4;
    }

    if (validateForm(calculationForm, dispatch, singleCalculation)) {
      sessionStorage.setItem(
        "customerLocationCalculationParameters",
        JSON.stringify(payload)
      );

      dispatch(
        calculatePriceForLocation(payload, () => {
          navigate(
            singleCalculation
              ? `/lokasyon-fiyat-hesaplama?lokasyon=${payload.search}`
              : `/lokasyon-fiyat-hesaplama?lokasyon=${payload.search}&aranan=${aranan}&arayan=${arayan}&dahili=${dahili}`
          );
        })
      );
    }
  };

  const handleCalculatePrice = () => {
    if (calculationForm.secilen.category === "Lokasyon") {
      handleCalculatePriceForLocation();
    } else {
      handleCalculatePriceForHotel();
    }
  };

  return {
    formValues: calculationForm,
    handleFormChange,
    hotelInfo,
    loadOptions,
    handleChangeQuery,
    isLoading: calculatePriceLoading,
    onCalculatePrice: handleCalculatePrice,
  };
};

export default useHandleForm;
