import { createSlice } from "@reduxjs/toolkit";
import { Pagination } from "types/GlobalTypes";
import { ListConfirmationApprovalData, ListConfirmationApprovalPayload, ListConfirmationApprovalResponse } from "types/reservationLists";

export interface ListConfirmationApprovalInitialState {
  listConfirmationApprovalResponse: ListConfirmationApprovalResponse;
  reservations: ListConfirmationApprovalData[];
  filterReservations: ListConfirmationApprovalPayload;
  pagination: Pagination;
  isLoading: boolean;
  error: string;
}

const initialState: ListConfirmationApprovalInitialState = {
  listConfirmationApprovalResponse: {
    rezler: [],
    iptal_tutar: 0,
    bekleyen_tutar: 0,
    tamamlanan_tutar: 0,
    toplam_rez: 0,
    gosterilmeyecek_alanlar: {},
  },
  reservations: [],
  filterReservations: {
      start_date: "",
      rezervasyon_kodu: "",
      musteri_adi: "",
      per_page: 0,
      page: 0,
      search: ""
  },
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  isLoading: false,
  error: "",
};

const listConfirmationApprovalSlice = createSlice({
  name: "listConfirmationApproval",
  initialState,
  reducers: {
    handleSaveListConfirmationApprovalResponse(state, action) {
      state.listConfirmationApprovalResponse = action.payload;
      const { data, ...rest } = action.payload.rezler;
      state.reservations = data;
      state.pagination = { ...rest };
    },
    handleChangeReservationFilterChoices(state, action) {
      state.filterReservations = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveListConfirmationApprovalResponse,
  handleChangeReservationFilterChoices,
  setIsLoading,
  setError,
} = listConfirmationApprovalSlice.actions;

export default listConfirmationApprovalSlice.reducer;
