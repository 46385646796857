import { useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  CreateGroupReservationPayload,
  ReservationCallLocation,
} from "types/reservation";
import {
  EnterGroupReservationInitialState,
  handleChangeInput,
  handleResetGroupReservationForm,
} from "slices/reservation/enterGroupReservation/reducer";
import { useState } from "react";
import { enterGroupReservation } from "slices/reservation/enterGroupReservation/thunk";
import { GET_RESERVATION_CALL_HOTELS_AND_LOCATIONS } from "helpers/url_helper";
import axios from "axios";
import { setToastError } from "slices/toast/reducer";
import { handleErrors } from "helpers/axios_helpers";

const useHandleForm = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { groupReservation, groupReservationFormErrors, isLoading } =
    useSelector(
      (state: { EnterGroupReservation: EnterGroupReservationInitialState }) =>
        state.EnterGroupReservation
    );

  const handleEnterGroupResrvation = (
    formValues: CreateGroupReservationPayload
  ) => {
    dispatch(
      enterGroupReservation(formValues, () => {
        dispatch(handleResetGroupReservationForm());
      })
    );
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  const [query, setQuery] = useState("");

  const loadOptions = async () => {
    if (query.length <= 1) {
      return [];
    }

    try {
      const response = await axios.post(
        GET_RESERVATION_CALL_HOTELS_AND_LOCATIONS,
        {
          term: query,
          platform: "crm",
        }
      );
      const res: ReservationCallLocation[] = (response as any).data || response;

      const options = res.reduce(
        (acc: any, item: ReservationCallLocation) => {
          if (item.category === "Otel") {
            acc[0].options.push({
              value: item.id,
              label: item.label,
              category: item.category,
            });
          } else {
            acc[1].options.push({
              value: item.id,
              label: item.label,
              category: item.category,
            });
          }
          return acc;
        },
        [
          {
            label: "Otel",
            options: [],
          },
          {
            label: "Lokasyon",
            options: [],
          },
        ]
      );
      return options;
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setToastError(message));
      });
      return [];
    }
  };

  const handleChangeQuery = (value: string) => {
    setQuery(value);
  };

  const handleFormChange = (
    field: keyof CreateGroupReservationPayload,
    value: string
  ) => {
    dispatch(handleChangeInput({ field, value }));

    validateFormChange({ ...groupReservation, [field]: value });
  };

  return {
    isLoading,
    values: groupReservation,
    formErrors: groupReservationFormErrors,
    handleEnterGroupResrvation,
    loadOptions,
    handleChangeQuery,
    validateForm,
    validateFormChange,
    handleSubmitted,
    handleFormChange,
  };
};

export default useHandleForm;
