import { CustomLoadingPage } from "Components/Custom/UI";
import ReservationData from "./ReservationData";


interface TotalReservationDataPageProps {
  isLoading: boolean;
}

const TotalReservationDataPage = ({
  isLoading,
}: TotalReservationDataPageProps) => {
  return (
    <div>
      <CustomLoadingPage isLoading={isLoading}/>
      <ReservationData />
    </div>
  );
};

export default TotalReservationDataPage;
