import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";
import { useState } from "react";
import CancellationModal from "./Modals/CancellationModal";
import UpdateStatusModal from "./Modals/UpdateStatusModal";

interface ListCancellationRequestsTableProps {
  isLoading: boolean;
}

const ListCancellationRequestsTable = ({
  isLoading,
}: ListCancellationRequestsTableProps) => {
  const [selectedReservationId, setSelectedReservationId] = useState(0);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);

  const {
    tableData,
    tableColumns,
    legend,
    reservations,
    handleUpdateCancellationRequest,
    handleUpdateStatus,
    handleFinalizeRequest,
  } = useHandleTable(
    setSelectedReservationId,
    setShowCancellationModal,
    setShowUpdateStatusModal
  );

  return (
    <>
      <CustomReactTable
        tableData={tableData}
        tableColumns={tableColumns}
        loading={isLoading}
        legend={legend}
      />
      {selectedReservationId !== 0 && (
        <CancellationModal
          reservations={reservations}
          reservation_id={selectedReservationId}
          isOpen={showCancellationModal}
          handleToggle={() =>
            setShowCancellationModal((prevState) => !prevState)
          }
          updateStatus={handleUpdateCancellationRequest}
        />
      )}
      {selectedReservationId !== 0 && (
        <UpdateStatusModal
          reservations={reservations}
          reservation_id={selectedReservationId}
          isOpen={showUpdateStatusModal}
          handleToggle={() =>
            setShowUpdateStatusModal((prevState) => !prevState)
          }
          updateStatus={handleUpdateStatus}
          finalizeRequest={handleFinalizeRequest}
        />
      )}
    </>
  );
};

export default ListCancellationRequestsTable;
