import { CustomPageDescriptions } from "Components/Custom/UI";
import { LIST_PRICE_REVISE_OPERATION_DESCRIPTION, LIST_PRICE_REVISE_SALES_DESCRIPTION } from "utils/pageDescriptions";
import FilterForm from "./FilterForm";
import ListPriceReviseTable from "./ListPriceReviseTable";
import InfoCards from "./InfoCards";

interface ListPriceRevisePageProps {
  isLoading: boolean;
}

const ListPriceRevisePage = ({
  isLoading,
}: ListPriceRevisePageProps) => {
  const operationDescription = LIST_PRICE_REVISE_OPERATION_DESCRIPTION.map(
    (description, index) => (
      <div
        key={index}
        dangerouslySetInnerHTML={{ __html: description }}
        className="user-select-none"
      />
    )
  );
  const salesDescription = LIST_PRICE_REVISE_SALES_DESCRIPTION.map(
    (description, index) => (
      <div
        key={index}
        dangerouslySetInnerHTML={{ __html: description }}
        className="user-select-none"
      />
    )
  );
  return (
    <div className="d-flex flex-column gap-4">
      <CustomPageDescriptions descriptions={operationDescription} />
      <CustomPageDescriptions descriptions={salesDescription} />
      <InfoCards/>
      <FilterForm />
      <ListPriceReviseTable isLoading={isLoading} />
    </div>
  );
};

export default ListPriceRevisePage;
