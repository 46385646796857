import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ListCancellationRequestsInitialState } from "slices/reservationMonitoring/listCancellationRequests/reducer";
import { listListCancellationRequests } from "slices/reservationMonitoring/listCancellationRequests/thunk";
import { ListCostReviseInitialState } from "slices/reservationMonitoring/listCostRevise/reducer";
import { listCostRevise } from "slices/reservationMonitoring/listCostRevise/thunk";
import { ListOnRequestReservationsInitialState } from "slices/reservationMonitoring/listOnRequestReservations/reducer";
import { listOnRequestReservations } from "slices/reservationMonitoring/listOnRequestReservations/thunk";
import { ListPriceReviseInitialState } from "slices/reservationMonitoring/listPriceRevise/reducer";
import { listPriceRevise } from "slices/reservationMonitoring/listPriceRevise/thunk";

const useHandleRequests = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [countdown, setCountdown] = useState(0);
  
//   useEffect(() => {
//     dispatch(listOnRequestReservations());
//     dispatch(listPriceRevise());
//     dispatch(listListCancellationRequests());
//     dispatch(listCostRevise());

//     setCountdown(0 + REFRESH_INTERVAL_TIME);
//   }, [dispatch]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       dispatch(listOnRequestReservations());
//       dispatch(listPriceRevise());
//       dispatch(listListCancellationRequests());
//       dispatch(listCostRevise());
//       console.log("requests sent");
//     }, REFRESH_INTERVAL_TIME);

//     setCountdown(0 + REFRESH_INTERVAL_TIME);
//     return () => clearInterval(interval);
//   }, [dispatch]);

const fetchData = () => {
    dispatch(listOnRequestReservations());
    dispatch(listPriceRevise());
    dispatch(listListCancellationRequests());
    dispatch(listCostRevise());
    console.log("Datalar çekildi");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 0) {
          fetchData();
          return 60;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => {
    fetchData();
    setCountdown(60);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onRequestReservations } = useSelector(
    (state: {
      ListOnRequestReservations: ListOnRequestReservationsInitialState;
    }) => state.ListOnRequestReservations
  );

  const { priceRevise } = useSelector(
    (state: { ListPriceRevise: ListPriceReviseInitialState }) =>
      state.ListPriceRevise
  );

  const { listCancellationRequests } = useSelector(
    (state: {
      ListCancellationRequests: ListCancellationRequestsInitialState;
    }) => state.ListCancellationRequests
  );

  const { costRevise } = useSelector(
    (state: { ListCostRevise: ListCostReviseInitialState }) =>
      state.ListCostRevise
  );

  const sorsatListeleCount =
    onRequestReservations?.filter((item) => (item.durum !== "1" && item.durum !== "2" && item.durum !== "3")).length ?? 0;

  const fiyatRevizeListesiCount =
    priceRevise?.filter((item) => item.fiyat_bg === "warning" || item.op_bg === "warning").length ?? 0;

  const iptalTalebiListesiCount =
    listCancellationRequests?.filter((item) => item.durum === "0").length ?? 0;

  const maliyetRevizeListesiCount =
    costRevise?.filter((item) => item.kbr_durum === 0).length ?? 0;

  return {
    sorsatListeleCount,
    fiyatRevizeListesiCount,
    iptalTalebiListesiCount,
    maliyetRevizeListesiCount,
    countdown,
  };
};
export default useHandleRequests;
