import FindReservationsForm from "./FindReservationsForm";
import ReservationsTable from "./ReservationsTable";
import RezervationData from "./RezervationData";


interface FindReservationPageProps {
  isLoading: boolean;
}

const FindReservationPage = ({ isLoading }: FindReservationPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FindReservationsForm />
      <ReservationsTable isLoading={isLoading} />
      <RezervationData/>
    </div>
  );
};

export default FindReservationPage;
