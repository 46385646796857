import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ListCancellationRequestsInitialState } from "slices/reservationMonitoring/listCancellationRequests/reducer";
import { listListCancellationRequests } from "slices/reservationMonitoring/listCancellationRequests/thunk";


const useListCancellationRequests = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ListCancellationRequests: ListCancellationRequestsInitialState }) => state.ListCancellationRequests
  );

  useEffect(() => {
    dispatch(listListCancellationRequests());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useListCancellationRequests;
