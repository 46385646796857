import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ListReservationsChannelInitialState } from "slices/reservationLists/listReservationsChannel/reducer";
import { listReservations } from "slices/reservationLists/listReservationsChannel/thunk";

const useListReservationsChannel = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, filterReservations } = useSelector(
    (state: { ListReservationsChannel: ListReservationsChannelInitialState }) =>
      state.ListReservationsChannel
  );

  const startDate =
    filterReservations.start_date === ""
      ? moment().format("YYYY-MM-DD")
      : filterReservations.start_date;

  useEffect(() => {
    dispatch(listReservations(startDate, () => {}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useListReservationsChannel;
