import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveTemporaryOnlineReservationsResponse,
  setIsLoading,
  setError,
  setUpdateStatusLoading,
  setUpdateStatusError,
  setCallCustomerLoading,
  setCallCustomerError
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  HANDLE_CALL_TEMPORARY_ONLINE,
  LIST_TEMPORARY_ONLINE_RESERVATIONS,
  UPDATE_TEMP_REQUEST_DESCRIPTION,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import { CallCustomerPayload, UpdateTempOnlineReservationPayload } from "types/reservationMonitoring";

type SuccessCallback = () => void;

export const listTemporaryOnlineReservations =
  () => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(LIST_TEMPORARY_ONLINE_RESERVATIONS);

      dispatch(
        handleSaveTemporaryOnlineReservationsResponse(response.data || response)
      );
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateTempDescription =
  (
    payload: UpdateTempOnlineReservationPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateStatusLoading(true));
      await axios.post(UPDATE_TEMP_REQUEST_DESCRIPTION, payload);

      dispatch(setToastSuccess("Başarıyla güncellendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateStatusLoading(false));
    }
  };

  export const callCustomer =
    (
      payload: CallCustomerPayload,
      successCallback: SuccessCallback
    ) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch(setCallCustomerLoading(true));
        await axios.post(HANDLE_CALL_TEMPORARY_ONLINE, payload);
  
        successCallback();
      } catch (error) {
        handleErrors(error, (message: string) => {
          dispatch(setCallCustomerError(message));
          dispatch(setToastError(message));
        });
      } finally {
        dispatch(setCallCustomerLoading(false));
      }
    };
