import { Col, Label, Row } from "reactstrap";
import { ListPriceReviseData } from "types/reservationMonitoring";

interface ModalInfoProps {
  reservation: ListPriceReviseData;
}
const lineStyle: React.CSSProperties = {
  borderBottom: "1px solid #eaeaea",
  maxWidth: "1000px",
  margin: "0 auto",
  paddingBottom: "4px"
};

const ModalInfo = ({ reservation }: ModalInfoProps) => {
  return (
    <>
      <Row className="mx-2 mt-4">
        <div className="d-flex gap-2">
          <Label>Otel Adı:</Label>
          <p style={{ margin: 0 }}>{reservation.otel_adi ?? ""}</p>
        </div>
      <div style={lineStyle}></div>
      </Row>

      <Row className="d-flex mx-2 mt-4 ">
        <Col>
          <div className="d-flex gap-2" style={{ marginRight: "2rem" }}>
            <Label>Giriş Tarihi:</Label>
            <p style={{ margin: 0 }}>{reservation.giris_tarihi ?? ""}</p>
          </div>
        </Col>
        <Col>
          <div className="d-flex gap-2">
            <Label>Çıkış Tarihi:</Label>
            <p style={{ margin: 0 }}>{reservation.cikis_tarihi ?? ""}</p>
          </div>
        </Col>
      <div style={lineStyle}></div>
      </Row>

      <Row className="mx-2 mt-4 d-flex">
        <Col>
          <div className="d-flex gap-2" style={{ marginRight: "2rem" }}>
            <Label>Yetişkin:</Label>
            <p style={{ margin: 0 }}>{reservation.yetiskin_sayisi ?? ""}</p>
          </div>
        </Col>
        <Col>
          <div className="d-flex gap-2">
            <Label>Çocuk:</Label>
            <p style={{ margin: 0 }}>{reservation.cocuk_sayisi ?? ""}</p>
          </div>
        </Col>
      <div style={lineStyle}></div>
      </Row>

      <Row className="mx-2 mt-4">
        <div className="d-flex gap-2">
          <Label>Sistem Fiyatı:</Label>
          <p style={{ margin: 0 }}>{reservation.verilen_fiyat ?? ""}</p>
        </div>
      <div style={lineStyle}></div>
      </Row>

      <Row className="mx-2 mt-4">
        <div className="d-flex gap-2">
          <Label>İlk Açıklama:</Label>
          <p style={{ margin: 0 }}>{reservation.teklif_aciklama ?? ""}</p>
        </div>
      <div style={lineStyle}></div>
      </Row>
    </>
  );
};

export default ModalInfo;
