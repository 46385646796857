import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  setCallCustomerLoading,
  setCallCustomerError
} from "./reducer";
import { setToastError } from "slices/toast/reducer";
import {
  CALL_WITH_ZOIPER,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import {
  CallCustomerPayload,
} from "types/reservationMonitoring";

type SuccessCallback = () => void;

  export const callCustomer =
    (
      payload: CallCustomerPayload,
      successCallback: SuccessCallback
    ) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch(setCallCustomerLoading(true));
        await axios.post(CALL_WITH_ZOIPER, payload);
  
        successCallback();
      } catch (error) {
        handleErrors(error, (message: string) => {
          dispatch(setCallCustomerError(message));
          dispatch(setToastError(message));
        });
      } finally {
        dispatch(setCallCustomerLoading(false));
      }
    };
