import { DocumentCenterData } from "types/documents";
import AccountingDocuments from "./AccountingDocuments";
import GeneralDocuments from "./GeneralDocuments";
import OperationDocuments from "./OperationDocuments";

interface DocumentCenterPageProps {
  accountingData: DocumentCenterData;
  operationData: DocumentCenterData;
}

const DocumentCenterPage = ({ accountingData, operationData}: DocumentCenterPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <AccountingDocuments data={accountingData} />
      <GeneralDocuments />
      <OperationDocuments data={operationData}/>
    </div>
  );
};

export default DocumentCenterPage;
