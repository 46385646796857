import DataCards from "./DataCards";
import FilterForm from "./FilterForm";
import InfoCards from "./InfoCards";
import ListCostReviseTable from "./ListCostReviseTable";

interface ListCostRevisePageProps {
  isLoading: boolean;
}

const ListCostRevisePage = ({ isLoading }: ListCostRevisePageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <InfoCards />
      <FilterForm />
      <ListCostReviseTable isLoading={isLoading} />
      <DataCards />
    </div>
  );
};

export default ListCostRevisePage;
