import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { FindReservationsInitialState } from "slices/reservationLists/findReservation/reducer";
import { listReservations } from "slices/reservationLists/findReservation/thunk";

const useFindReservation = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, filterReservations } = useSelector(
    (state: { FindReservations: FindReservationsInitialState }) =>
      state.FindReservations
  );

  const startDate =
    filterReservations.start_date === ""
      ? moment().format("YYYY-MM-DD")
      : filterReservations.start_date;

  useEffect(() => {
    dispatch(listReservations(startDate, () => {}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useFindReservation;
