import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { Dispatch } from "react";
import { handleChangeInput } from "slices/reservation/reviewReservation/reducer";
import {
  ReservationReviewCollection,
  ReservationReviewCollectionFormChoices,
  ReservationReviewCollectionFormErrors,
} from "types/reservation";

interface IncomingCollectionSelectorProps {
  values: ReservationReviewCollection;
  formChoices: ReservationReviewCollectionFormChoices;
  formErrors: ReservationReviewCollectionFormErrors;
  validateFormChange: (values: ReservationReviewCollection) => void;
}

interface Option {
  value: { id: string; banka: string };
  label: string;
}

const IncomingCollectionSelector = ({
  values,
  formChoices,
  formErrors,
  validateFormChange,
}: IncomingCollectionSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const havaleOptions: Option[] = formChoices.gelen_havaleler.map((item) => ({
    value: { id: item.id.toString(), banka: item.banka },
    label: `${item.islem_zamani} | ${item.banka} | ${item.tutar} | ${item.aciklama}`,
  }));

  const selectedOption = havaleOptions.find(
    (option) => option.value.id === values.tahsilat_id.toString()
  );

  return (
    <>
      <CustomFormFieldContainer
        label="Gelen Havale Seçimi "
        error={formErrors.tahsilat_id}
        divider
        required
        orientation="vertical"
      >
        <Select
          options={havaleOptions}
          value={selectedOption || null}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                ...values,
                tahsilat_id: selected.value.id,
                tahsilat_durum: "1",
                banka_adi: selected.value.banka,
              })
            );

            validateFormChange({
              ...values,
              tahsilat_id: Number(selected.value),
            });
          }}
          placeholder="Seçiniz"
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default IncomingCollectionSelector;
