import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ListCostReviseInitialState } from "slices/reservationMonitoring/listCostRevise/reducer";
import { filterCostReviseData } from "slices/reservationMonitoring/listCostRevise/thunk";

const useHandleForm = () => {
  const { isLoading, filterCostRevise } = useSelector(
    (state: { ListCostRevise: ListCostReviseInitialState }) =>
      state.ListCostRevise
  );

  const [filterForm, setFilterForm] = useState({
    date: filterCostRevise.date,
    rezervasyon_kodu: filterCostRevise.rezervasyon_kodu,
  });

  const handleFormChange = (key: string, value: any) => {
    setFilterForm((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      date: filterForm.date,
      rezervasyon_kodu: filterForm.rezervasyon_kodu,
    };
    dispatch(filterCostReviseData(params, () => {}));
  };

  const handleResetFilter = () => {
    setFilterForm({
      date: "",
      rezervasyon_kodu: "",
    });

    const params = {
      date: "",
      rezervasyon_kodu: "",
    };
    dispatch(filterCostReviseData(params, () => {}));
  };

  return {
    isLoading,
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
