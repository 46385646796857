import FilterIncomingTransfersForm from "./FilterIncomingTransfersForm";
import IncomingTransfersTable from "./IncomingTransfersTable";

interface ListIncomingTransfersPageProps {
  isLoading: boolean;
}

const ListIncomingTransfersPage = ({ isLoading }: ListIncomingTransfersPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterIncomingTransfersForm />
      <IncomingTransfersTable isLoading={isLoading} />
    </div>
  );
};

export default ListIncomingTransfersPage;
