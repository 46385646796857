import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
    handleSaveListConfirmationApprovalResponse,
  setIsLoading,
  setError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { FILTER_CONFIRMATION_APPROVAL_DATA, LIST_CONFIRMATION_APPROVAL_DATA } from "helpers/url_helper";
import { Dispatch } from "redux";
import { ListConfirmationApprovalPayload } from "types/reservationLists";

type SuccessCallback = () => void;

export const listReservations =
  (start_date: string, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(LIST_CONFIRMATION_APPROVAL_DATA, {
        params: {start_date},
      });

      dispatch(handleSaveListConfirmationApprovalResponse(response.data || response));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const filterReservationData =
  (payload: ListConfirmationApprovalPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(FILTER_CONFIRMATION_APPROVAL_DATA, payload);

      dispatch(handleSaveListConfirmationApprovalResponse(response.data || response));
      dispatch(setToastSuccess("Rezervasyonlar başarıyla listelendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
