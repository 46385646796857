import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { FindReservationPayload } from "types/reservationLists";

interface DateInputProps {
  values: FindReservationPayload;
  handleFormChange: (key: string, value: string) => void;
}

const DateInput = ({ values, handleFormChange }: DateInputProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer label="Rezervasyon Tarihi :">
        <Flatpickr
          className="form-control"
          value={[values.start_date]}
          onChange={(dates) => {
            const [startDate] = dates;

            if (startDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");

              handleFormChange("start_date", stringStartDate);
            }
          }}
          options={{
            mode: "single",
            dateFormat: "Y-m-d",
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default DateInput;
