import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface ReservationsTableProps {
  isLoading: boolean;
}

const ReservationsTable = ({ isLoading }: ReservationsTableProps) => {
  const { tableData, tableColumns, legend } = useHandleTable();

  return (
    <CustomReactTable
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
      legend={legend}
    />
  );
};

export default ReservationsTable;
