import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { TodayEntrancePayAtTheDoorInitialState } from "slices/reservationMonitoring/todayEntrancePayAtTheDoor/reducer";
import { filterTodayEntrancePayAtTheDoorData } from "slices/reservationMonitoring/todayEntrancePayAtTheDoor/thunk";
import { TodayEntrancePayAtTheDoorPayload } from "types/reservationMonitoring";

const useHandleForm = () => {
  const { isLoading, filterTodayEntrancePayAtTheDoor } = useSelector(
    (state: { TodayEntrancePayAtTheDoor: TodayEntrancePayAtTheDoorInitialState }) =>
      state.TodayEntrancePayAtTheDoor
  );

  const [filterForm, setFilterForm] = useState<TodayEntrancePayAtTheDoorPayload>({
    date: filterTodayEntrancePayAtTheDoor.date,
    rezervasyon_kodu: filterTodayEntrancePayAtTheDoor.rezervasyon_kodu,
    musteri_adi: filterTodayEntrancePayAtTheDoor.musteri_adi,
  });

  const handleFormChange = (key: string, value: any) => {
    setFilterForm((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      date: filterForm.date,
      rezervasyon_kodu: filterForm.rezervasyon_kodu,
      musteri_adi: filterForm.musteri_adi,
    };
    dispatch(filterTodayEntrancePayAtTheDoorData(params, () => {}));
  };

  const handleResetFilter = () => {
    setFilterForm({
      date: "",
      rezervasyon_kodu: "",
      musteri_adi: "",
    });

    const params = {
      date: "",
      rezervasyon_kodu: "",
      musteri_adi: "",
    };
    dispatch(filterTodayEntrancePayAtTheDoorData(params, () => {}));
  };

  return {
    isLoading,
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
