import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { GroupReservationsInitialState } from "slices/reservationMonitoring/groupReservations/reducer";
import { listGroupReservations } from "slices/reservationMonitoring/groupReservations/thunk";


const useGroupReservations = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { GroupReservations: GroupReservationsInitialState }) => state.GroupReservations
  );

  useEffect(() => {
    dispatch(listGroupReservations());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useGroupReservations;
