import { createSlice } from "@reduxjs/toolkit";
import { PersonnelRequest } from "types/personelRequest";

export interface PersonnelRequestInitialState {
    personnelRequest: PersonnelRequest[];
    isLoading: boolean;
    error: string;
    answerRequestIsLoading: boolean;
    answerRequestError: string;
}

const initialState: PersonnelRequestInitialState = {
    personnelRequest: [],

    isLoading: false,
    error: "",
    answerRequestIsLoading: false,
    answerRequestError: "",
};


const personnelRequestSlice = createSlice({
    name: "personnelRequest",
    initialState,
    reducers: {
        handlePersonnelRequest(state, action) {
            state.personnelRequest = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setAnswerRequestIsLoading(state, action) {
            state.answerRequestIsLoading = action.payload;
        },
        setAnswerRequestError(state, action) {
            state.answerRequestError = action.payload;
        },
    },
});

export const { handlePersonnelRequest, setIsLoading, setError, setAnswerRequestIsLoading, setAnswerRequestError } =
    personnelRequestSlice.actions;

export default personnelRequestSlice.reducer;