import { CustomButton } from "Components/Custom/UI";
// import { Dispatch } from "redux";
// import { useDispatch, useSelector } from "react-redux";
// import { HotelInitialState } from "slices/hotel/reducer";
// import {
//   addAdditionalPage,
//   getAdditionalPages,
// } from "slices/myWebSite/additionalPages/thunk";
// import { resetAddAdditionalPageFormFields } from "slices/myWebSite/additionalPages/reducer";
import { Widget } from "types/myWebSite";
// import { createWidget } from "slices/myWebSite/createWidget/thunk";

interface CreateWidgetButtonProps {
  values: Widget;
  requestPayload: FormData;
}

const CreateWidgetButton = ({
  values,
  requestPayload,
}: CreateWidgetButtonProps) => {
  // const dispatch: Dispatch<any> = useDispatch();

  // const { hotel } = useSelector(
  //   (state: { Hotel: HotelInitialState }) => state.Hotel
  // );

  // const { otel_id } = hotel;

  const onClickSave = () => {
      const {
        otel_id,
        otel_adi,
        inet_kodu,
        oteldenal_sef_link,
        oteldenal_title,
        oteldenal_keywords,
        oteldenal_description,
        // wScode,
        // wUrl,
        // widget_yonlendirme,
        // wScode_durum,
      } = values;

      requestPayload.append("otel_id", otel_id.toString());
      requestPayload.append("otel_adi", otel_adi);
      requestPayload.append("inet_kodu",inet_kodu);
      requestPayload.append("oteldenal_sef_link", oteldenal_sef_link);
      requestPayload.append("oteldenal_title", oteldenal_title);
      requestPayload.append("oteldenal_keywords", oteldenal_keywords);
      requestPayload.append("oteldenal_description",oteldenal_description);
    //   requestPayload.append("wScode", wScode);
    //   requestPayload.append("wUrl", wUrl);
    //   requestPayload.append("widget_yonlendirme", widget_yonlendirme);
    //   requestPayload.append("wScode_durum", wScode_durum);

      console.log("REQUESTPAYLOAD FROM BUTTON : ", requestPayload)
    //   dispatch(
    //     createWidget(requestPayload, () => {
    //       dispatch(getAdditionalPages(otel_id));
    //     })
    //   );  
  };

  return (
    <div className="gap-4 mt-4">
      <CustomButton variant="primary" onClick={onClickSave}>
        Widget Oluştur
      </CustomButton>
    </div>
  );
};

export default CreateWidgetButton;
