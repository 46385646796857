import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveNoteConfirmationReservationsResponse,
  setIsLoading,
  setError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  FILTER_NOTE_CONFIRMATION_RESERVATIONS,
  LIST_NOTE_CONFIRMATION_RESERVATIONS,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import { NoteConfirmationPayload } from "types/reservationLists";

type SuccessCallback = () => void;

export const listNoteConfirmationReservations = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(LIST_NOTE_CONFIRMATION_RESERVATIONS);

    dispatch(
      handleSaveNoteConfirmationReservationsResponse(response.data || response)
    );
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const filterReservations =
  (payload: NoteConfirmationPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(FILTER_NOTE_CONFIRMATION_RESERVATIONS, payload);

      dispatch(
        handleSaveNoteConfirmationReservationsResponse(response.data || response)
      );
      dispatch(setToastSuccess("Not konfirme rezervasyonlar başarıyla listelendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
