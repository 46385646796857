import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { Dispatch } from "react";
import { handleChangeAddAdditionalPageInput } from "slices/myWebSite/additionalPages/reducer";
import {
  ReservationReviewCollection,
  ReservationReviewCollectionFormChoices,
  ReservationReviewCollectionFormErrors,
} from "types/reservation";
import { handleChangeInput } from "slices/reservation/reviewReservation/reducer";

interface CollectionBankSelectorProps {
  values: ReservationReviewCollection;
  formChoices: ReservationReviewCollectionFormChoices;
  formErrors: ReservationReviewCollectionFormErrors;
  validateFormChange: (values: ReservationReviewCollection) => void;
}

interface Option {
  value: string;
  label: string;
}

const CollectionBankSelector = ({
  values,
  formChoices,
  formErrors,
  validateFormChange,
}: CollectionBankSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const collectionTypeOptions: Option[] = formChoices.poslar.map((item) => ({
    value: item.banka_kodu,
    label: item.banka_adi,
  }));

  const selectedOption = collectionTypeOptions.find(
    (option) => option.value === values.banka_kodu
  );

  return (
    <>
      <CustomFormFieldContainer
        label="Tahsilat Yapılan Banka "
        error={formErrors.banka_kodu}
        divider
        required
        orientation="vertical"
      >
        <Select
          options={collectionTypeOptions}
          value={selectedOption || null}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                banka_kodu: selected.value,
              })
            );

            validateFormChange({
              ...values,
              banka_kodu: selected.value,
            });
          }}
          placeholder="Seçiniz"
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default CollectionBankSelector;
