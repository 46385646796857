import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import UserAuthoritySelector from "./UserAuthoritySelector";
import UserAuthorityName from "./UserAuthorityName";
// import { Label } from "reactstrap";
import { UserAuthority } from "types/userOperations";
import useHandleForm from "./useHandleForm";

interface AddNewAuthorityProps {
  authorities: UserAuthority[];
  selectedAuthority: number;
  handleSelectAuthority: (id: number) => void;
  isLoading: boolean;
  addAuthoritiesLoading: boolean;
}

const AddNewAuthorityForm = (props: AddNewAuthorityProps) => {
  const { handleAddNewAuthority, newAuthorityName, setNewAuthorityName } =
    useHandleForm();

  return (
    <CustomFormContainer title="Yeni Bir Yetki Ekle">
      <UserAuthoritySelector
        authorities={props.authorities}
        selectedAuthority={props.selectedAuthority}
        handleSelectAuthority={props.handleSelectAuthority}
      />
      <UserAuthorityName
        value={newAuthorityName}
        formErrors={""}
        onChange={(e) => {
          setNewAuthorityName(e.target.value);
        }}
      />
      <div className="d-flex justify-content-end">
        <CustomButton
          variant="success"
          onClick={handleAddNewAuthority}
          isLoading={props.addAuthoritiesLoading}
        >
          Ekle
        </CustomButton>
      </div>
    </CustomFormContainer>
  );
};

export default AddNewAuthorityForm;
