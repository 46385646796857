import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch, useSelector } from "react-redux";
import { TodayEntrancePayAtTheDoorInitialState } from "slices/reservationMonitoring/todayEntrancePayAtTheDoor/reducer";
import {
  CallCustomerPayload,
  CancelPayAtTheDoorPayload,
  ChangeTodayEntranceStatusPayload,
  TodayEntrancePayAtTheDoorData,
} from "types/reservationMonitoring";
import { TABLE_COLORS } from "utils/tableColors";
import { Dispatch, SetStateAction } from "react";
import { setToastError } from "slices/toast/reducer";
import {
  callCustomer,
  listTodayEntrancePayAtTheDoor,
  changeStatusNotReached,
  cancelReservation,
  changeStatusPositive,
} from "slices/reservationMonitoring/todayEntrancePayAtTheDoor/thunk";
import { LoginInitialState } from "slices/auth/login/reducer";

const useHandleTable = (
  setSelectedReservationId: Dispatch<SetStateAction<number>>,
  setShowCancellationModal: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { todayEntrancePayAtTheDoor } = useSelector(
    (state: {
      TodayEntrancePayAtTheDoor: TodayEntrancePayAtTheDoorInitialState;
    }) => state.TodayEntrancePayAtTheDoor
  );

  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );

  const handleChangeStatusPositive = (
    payload: ChangeTodayEntranceStatusPayload
  ) => {
    try {
      dispatch(
        changeStatusPositive(payload, () => {
          dispatch(listTodayEntrancePayAtTheDoor());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleChangeStatusNotReached = (
    payload: ChangeTodayEntranceStatusPayload
  ) => {
    try {
      dispatch(
        changeStatusNotReached(payload, () => {
          dispatch(listTodayEntrancePayAtTheDoor());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleCancel = (payload: CancelPayAtTheDoorPayload) => {
    try {
      dispatch(
        cancelReservation(payload, () => {
          dispatch(listTodayEntrancePayAtTheDoor());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleCall = (payload: CallCustomerPayload) => {
    try {
      dispatch(callCustomer(payload, () => {}));
    } catch (error) {
      setToastError(error);
    }
  };

  const tableData = todayEntrancePayAtTheDoor?.map((item) => {
    let rowColor = "";

    if (item.kapida_odeme_giris === "gelecek") {
      rowColor = TABLE_COLORS.green;
    } else if (item.kapida_odeme_giris === "ulasilamadi") {
      rowColor = TABLE_COLORS.yellow;
    } else if (
      item.kapida_odeme_giris === "iptal" ||
      item.iptal_edildi_mi === "e"
    ) {
      rowColor = TABLE_COLORS.red;
    } else {
      rowColor = TABLE_COLORS.white;
    }

    return {
      id: item.id,
      rezervasyon_kodu: item.rezervasyon_kodu,
      otel_adi: item.otel_adi,
      musteri_ad_soyad: item.musteri_ad_soyad,
      musteri_cep: item.musteri_cep,
      musteri_tel: item.musteri_tel,
      toplam_tutar: item.toplam_tutar,
      kayit_tarihi: item.kayit_tarihi,
      iptal_edildi_mi: item.iptal_edildi_mi,
      iptal_nedeni: item.iptal_nedeni,
      kapida_odeme_giris: item.kapida_odeme_giris,
      satici_adi: item.satici_adi,
      grup_adi: item.grup_adi,
      otel_telefon: item.otel_telefon,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "rowNumber",
      enableColumnFilter: false,
      cell: ({ row }: { row: { index: number } }) => <>{row.index + 1}</>,
    },
    {
      header: "Rez. Kod",
      accessorKey: "rezervasyon_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Grup",
      accessorKey: "grup_adi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Sat. Tarihi",
      accessorKey: "kayit_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Müşteri Ad",
      accessorKey: "musteri_ad_soyad",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Otel Ad",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Rez. Tutar",
      accessorKey: "toplam_tutar",
      enableColumnFilter: false,
    },
    {
      header: "Personel",
      accessorKey: "satici_adi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Telefon",
      accessorKey: "musteri_tel",
      enableColumnFilter: false,
    },
    {
      header: "Telefon 2",
      accessorKey: "musteri_tel",
      enableColumnFilter: false,
    },
    {
      header: "İptal Eden",
      accessorKey: "iptal_nedeni",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "200px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "yorum_id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData: TodayEntrancePayAtTheDoorData = cell.row.original;
        const id = rowData.id;
        const dahili = user.dahili;
        const musteri_no = `0${rowData.musteri_tel}`;
        const otel_no = rowData.otel_telefon;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="phone"
                onClick={() =>
                  handleCall({
                    dahili: dahili,
                    aranan_no: musteri_no,
                    arayan_no: otel_no,
                  })
                }
                title="Müşteriyi Ara"
              />
              <CustomRowOperationButton
                variant="check"
                onClick={() =>
                  handleChangeStatusPositive({
                    rezervasyon_id: id,
                  })
                }
                title="Gelecek"
              />
              <CustomRowOperationButton
                variant="warning"
                onClick={() =>
                  handleChangeStatusNotReached({
                    rezervasyon_id: id,
                  })
                }
                title="Ulaşılamadı"
              />
              <CustomRowOperationButton
                variant="uncheck"
                onClick={() => {
                  setSelectedReservationId(id);
                  setShowCancellationModal((prevState) => !prevState);
                }}
                title="İptal"
              />
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.yellow,
      label: "Ulaşılamadı",
    },
    {
      color: TABLE_COLORS.green,
      label: "Gelecek",
    },
    {
      color: TABLE_COLORS.red,
      label: "İptal Edildi",
    },
  ];

  return {
    tableData,
    tableColumns,
    legend,
    reservations: todayEntrancePayAtTheDoor,
    handleCancel,
  };
};

export default useHandleTable;
