import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { NoteConfirmationReservationsInitialState } from "slices/reservationLists/noteconfirmationReservations/reducer";
import { listNoteConfirmationReservations } from "slices/reservationLists/noteconfirmationReservations/thunk";


const useNoteConfirmationReservations = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { NoteConfirmationReservations: NoteConfirmationReservationsInitialState }) =>
      state.NoteConfirmationReservations
  );



  useEffect(() => {
    dispatch(listNoteConfirmationReservations());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useNoteConfirmationReservations;
