import { ColumnColorRule, getCellBackgroundColor } from "helpers/table_cell_coloring_helper";
import { Spinner } from "reactstrap";

interface CustomTableBodyProps {
  loading: boolean;
  columns: any;
  getRowModel: any;
  flexRender: any;
  colorConfig?: ColumnColorRule[];
}

const CustomTableBody = ({
  loading,
  columns,
  getRowModel,
  flexRender,
  colorConfig,
}: CustomTableBodyProps) => {
  return (
    <>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={columns.length} className="text-center py-2 fs-13">
              <Spinner color="primary" size="sm" />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {getRowModel().rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className="text-center fs-13">
                Kayıt bulunamadı.
              </td>
            </tr>
          ) : (
            getRowModel().rows.map((row: any, index: number) => {
              const rowData = row.original;
              return (
                <tr key={index}>
                  {row.getVisibleCells().map((cell: any, index: number) => {
                    const rowBgColor = getCellBackgroundColor(
                      rowData,
                      cell.column.id,
                      colorConfig
                    );
                    return (
                      <td
                        key={index}
                        className="fs-13 text-nowrap border"
                        style={{ backgroundColor: rowBgColor }}
                      >
                        <span>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      )}
    </>
  );
};

export default CustomTableBody;
