import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import { Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  AnswerOnRequestReservationPayload,
  ListOnRequestReservationsData,
} from "types/reservationMonitoring";
import ModalInfo from "./ModalInfo";

interface AnswerRequestModalProps {
  reservations: ListOnRequestReservationsData[];
  reservation_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  answerRequest: (payload: AnswerOnRequestReservationPayload) => void;
}

const AnswerRequestModal = ({
  reservations,
  reservation_id,
  isOpen,
  handleToggle,
  answerRequest,
}: AnswerRequestModalProps) => {
  const reservation = reservations.find(
    (reservation) => reservation.id === reservation_id
  )!;

  const [answer, setAnswer] = useState("");

  useEffect(() => {
    setAnswer("");
  }, [reservation]);

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="md">
        <ModalHeader>Cevap Güncelle</ModalHeader>
        <ModalInfo reservation={reservation} />
        <ModalBody>
          <Label>Açıklama</Label>
          <Input
            type="textarea"
            placeholder="Açıklama"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="primary"
            onClick={() =>
              answerRequest({ sorsat_id: reservation_id, cevap: answer })
            }
            isLoading={false}
          >
            Güncelle
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default AnswerRequestModal;
