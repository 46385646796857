import {
  BankInstallment,
  CalculatePriceForHotelConcept,
  CalculatePriceForHotelProvider,
  RoomOption,
} from "types/reservation";
import useHandlePayloadForEnterReservation from "./PricesAndPayButtons/useHandlePayloadForEnterReservation";
import NormalPayment from "./PricesAndPayButtons/PaymentButtons/NormalPayment";
import IptalEdilemezPayment from "./PricesAndPayButtons/PaymentButtons/IptalEdilemezPayment";
import KapidaPayment from "./PricesAndPayButtons/PaymentButtons/KapidaPayment";
import KaporaliPayment from "./PricesAndPayButtons/PaymentButtons/KaporaliPayment";
import SamePricePayments from "./PricesAndPayButtons/PaymentButtons/SamePricePayments";
import ConceptCardWithoutPrice from "../ConceptCardWithoutPrice";

interface ConceptPriceCardProps extends CalculatePriceForHotelConcept {
  provider: CalculatePriceForHotelProvider;
  kismi_odeme: {
    kismi_odeme: 0 | 1;
    kismi_odeme_orani: number;
    kismi_odeme_tarihi: string;
    iptal_sigortasi_durum: "1" | "0";
  };
  kapida_odeme: {
    kapida_odeme_durum: "evet" | "hayir";
    kapida_komisyon_orani: string;
    kapida_indirim_orani: string;
    kapida_odeme_para_birimi_goster: "0" | "1";
  };
  kaporali_odeme: {
    kaporali_satis_durum: "evet" | "hayir";
    kaporali_satis_orani: string;
    kaporali_indirim_orani: string;
  };
  priceCalculationStatusMessages: {
    status: boolean;
    message: string | undefined;
    description: string;
  }[];
  oda_id: string;
  oda_adi: string;
  giris_tarihi: string;
  iptal_sigortasi_durum: "1" | "0";
  iptal_sigortasi_gun: string;
  gifts: string;
  odeme_sekli: string[];
  banka_taksit: BankInstallment[];
  handleSelectRoomToCallLater: (
    oda_adi: string,
    pansiyon_adi: string,
    fiyat: string
  ) => void;
  selectedRoom: RoomOption;
}

const ConceptPriceCard = ({
  pansiyon_id,
  pansiyon_adi,
  fiyatlar,
  ayni_fiyatli_odeme_tipleri = [],
  provider,
  kapida_odeme,
  kaporali_odeme,
  kismi_odeme,
  kontenjan,
  priceCalculationStatusMessages,
  oda_id,
  oda_adi,
  giris_tarihi,
  iptal_sigortasi_durum,
  iptal_sigortasi_gun,
  gifts,
  odeme_sekli,
  banka_taksit,
  handleSelectRoomToCallLater,
  selectedRoom,
}: ConceptPriceCardProps) => {
  const { onClick } = useHandlePayloadForEnterReservation();

  // console.log("priceCalculationStatusMessages", priceCalculationStatusMessages);
  // console.log(oda_adi, pansiyon_adi, odeme_sekli, fiyatlar);

  const showNormalPayment =
    odeme_sekli.includes("normal") && !!fiyatlar?.normal;
  const showIptalEdilemezPayment =
    odeme_sekli.includes("iptal_edilemez") && !!fiyatlar?.iptal_edilemez;
  const showKapidaPayment =
    odeme_sekli.includes("kapida") && !!fiyatlar?.kapida;
  const showKaporaliPayment =
    odeme_sekli.includes("kaporali") && !!fiyatlar?.kaporali;

  const getOrder = (price: string) => {
    const prices = fiyatlar ? Object.keys(fiyatlar) : [];

    return prices.indexOf(price);
  };

  const showSingleNormalPayment =
    !ayni_fiyatli_odeme_tipleri.includes("normal");
  const showSingleIptalEdilemezPayment =
    !ayni_fiyatli_odeme_tipleri.includes("iptal_edilemez");
  const showSingleKapidaPayment =
    !ayni_fiyatli_odeme_tipleri.includes("kapida");
  const showSingleKaporaliPayment =
    !ayni_fiyatli_odeme_tipleri.includes("kaporali");

  const hasMessage = priceCalculationStatusMessages.some(
    (message) => message.status === true
  );

  return (
    <div className="d-flex flex-column gap-1">
      {ayni_fiyatli_odeme_tipleri?.length > 0 && (
        <SamePricePayments
          ayni_fiyatli_odeme_tipleri={ayni_fiyatli_odeme_tipleri}
          oda_id={oda_id}
          oda_adi={oda_adi}
          pansiyon_id={pansiyon_id}
          pansiyon_adi={pansiyon_adi}
          fiyatlar={fiyatlar}
          kontenjan={kontenjan}
          gifts={gifts}
          tedarikci={provider}
          giris_tarihi={giris_tarihi}
          iptal_sigortasi_gun={iptal_sigortasi_gun}
          iptal_sigortasi_durum={iptal_sigortasi_durum}
          kismi_odeme={kismi_odeme}
          kapida_odeme={kapida_odeme}
          kaporali_odeme={kaporali_odeme}
          priceCalculationStatusMessages={priceCalculationStatusMessages}
          onClick={onClick}
          banka_taksit={banka_taksit}
          handleSelectRoomToCallLater={handleSelectRoomToCallLater}
          selectedRoom={selectedRoom}
        />
      )}
      {showIptalEdilemezPayment && showSingleIptalEdilemezPayment && (
        <div style={{ order: getOrder("iptal_edilemez") }}>
          <IptalEdilemezPayment
            oda_id={oda_id}
            oda_adi={oda_adi}
            pansiyon_id={pansiyon_id}
            pansiyon_adi={pansiyon_adi}
            fiyatlar={fiyatlar}
            kontenjan={kontenjan}
            ayni_fiyatli_odeme_tipleri={ayni_fiyatli_odeme_tipleri}
            priceCalculationStatusMessages={priceCalculationStatusMessages}
            gifts={gifts}
            onClick={onClick}
            banka_taksit={banka_taksit}
          />
        </div>
      )}
      {odeme_sekli.includes("iptal_edilemez") && !fiyatlar && hasMessage && (
        <ConceptCardWithoutPrice
          oda_id={oda_id}
          pansiyon_id={pansiyon_id}
          pansiyon_adi={pansiyon_adi}
          fiyatlar={fiyatlar}
          kontenjan={kontenjan}
          priceCalculationStatusMessages={priceCalculationStatusMessages}
          gifts={gifts}
          odeme_tipi={["iptal_edilemez"]}
        />
      )}
      {showNormalPayment && showSingleNormalPayment && (
        <div style={{ order: getOrder("normal") }}>
          <NormalPayment
            oda_id={oda_id}
            oda_adi={oda_adi}
            pansiyon_id={pansiyon_id}
            pansiyon_adi={pansiyon_adi}
            fiyatlar={fiyatlar}
            kontenjan={kontenjan}
            tedarikci={provider}
            ayni_fiyatli_odeme_tipleri={ayni_fiyatli_odeme_tipleri}
            priceCalculationStatusMessages={priceCalculationStatusMessages}
            giris_tarihi={giris_tarihi}
            iptal_sigortasi_durum={iptal_sigortasi_durum}
            iptal_sigortasi_gun={iptal_sigortasi_gun}
            kismi_odeme={kismi_odeme}
            gifts={gifts}
            onClick={onClick}
            banka_taksit={banka_taksit}
          />
        </div>
      )}
      {odeme_sekli.includes("normal") && !fiyatlar && hasMessage && (
        <ConceptCardWithoutPrice
          oda_id={oda_id}
          pansiyon_id={pansiyon_id}
          pansiyon_adi={pansiyon_adi}
          fiyatlar={fiyatlar}
          kontenjan={kontenjan}
          priceCalculationStatusMessages={priceCalculationStatusMessages}
          gifts={gifts}
          odeme_tipi={["normal"]}
        />
      )}
      {showKapidaPayment && showSingleKapidaPayment && (
        <div style={{ order: getOrder("kapida") }}>
          <KapidaPayment
            oda_id={oda_id}
            oda_adi={oda_adi}
            pansiyon_id={pansiyon_id}
            pansiyon_adi={pansiyon_adi}
            fiyatlar={fiyatlar}
            kontenjan={kontenjan}
            kapida_odeme={kapida_odeme}
            ayni_fiyatli_odeme_tipleri={ayni_fiyatli_odeme_tipleri}
            priceCalculationStatusMessages={priceCalculationStatusMessages}
            gifts={gifts}
            onClick={onClick}
            banka_taksit={banka_taksit}
          />
        </div>
      )}
      {odeme_sekli.includes("kapida") && !fiyatlar && hasMessage && (
        <ConceptCardWithoutPrice
          oda_id={oda_id}
          pansiyon_id={pansiyon_id}
          pansiyon_adi={pansiyon_adi}
          fiyatlar={fiyatlar}
          kontenjan={kontenjan}
          priceCalculationStatusMessages={priceCalculationStatusMessages}
          gifts={gifts}
          odeme_tipi={["kapida"]}
        />
      )}
      {showKaporaliPayment && showSingleKaporaliPayment && (
        <div style={{ order: getOrder("kaporali") }}>
          <KaporaliPayment
            oda_id={oda_id}
            oda_adi={oda_adi}
            pansiyon_id={pansiyon_id}
            pansiyon_adi={pansiyon_adi}
            fiyatlar={fiyatlar}
            kontenjan={kontenjan}
            kaporali_odeme={kaporali_odeme}
            ayni_fiyatli_odeme_tipleri={ayni_fiyatli_odeme_tipleri}
            priceCalculationStatusMessages={priceCalculationStatusMessages}
            gifts={gifts}
            onClick={onClick}
            banka_taksit={banka_taksit}
          />
        </div>
      )}
      {odeme_sekli.includes("kaporali") && !fiyatlar && hasMessage && (
        <ConceptCardWithoutPrice
          oda_id={oda_id}
          pansiyon_id={pansiyon_id}
          pansiyon_adi={pansiyon_adi}
          fiyatlar={fiyatlar}
          kontenjan={kontenjan}
          priceCalculationStatusMessages={priceCalculationStatusMessages}
          gifts={gifts}
          odeme_tipi={["kaporali"]}
        />
      )}
    </div>
  );
};

export default ConceptPriceCard;
