import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ListCancellationRequestsInitialState } from "slices/reservationMonitoring/listCancellationRequests/reducer";
import { filterListCancellationRequestsData } from "slices/reservationMonitoring/listCancellationRequests/thunk";
import { ListCancellationRequestsPayload } from "types/reservationMonitoring";

const useHandleForm = () => {
  const { isLoading, filterListCancellationRequests } = useSelector(
    (state: { ListCancellationRequests: ListCancellationRequestsInitialState }) =>
      state.ListCancellationRequests
  );

  const [filterForm, setFilterForm] = useState<ListCancellationRequestsPayload>({
    start_date: filterListCancellationRequests.start_date,
    end_date: filterListCancellationRequests.end_date,
    rezervasyon_kodu: filterListCancellationRequests.rezervasyon_kodu,
  });

  const handleFormChange = (key: string, value: any) => {
    setFilterForm((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      start_date: filterForm.start_date,
      end_date: filterForm.end_date,
      rezervasyon_kodu: filterForm.rezervasyon_kodu,
    };
    dispatch(filterListCancellationRequestsData(params, () => {}));
  };

  const handleResetFilter = () => {
    setFilterForm({
      start_date: "",
      end_date: "",
      rezervasyon_kodu: "",
    });

    const params = {
      start_date: "",
      end_date: "",
      rezervasyon_kodu: "",
    };
    dispatch(filterListCancellationRequestsData(params, () => {}));
  };

  return {
    isLoading,
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
