import DocumentControlsPage from "Components/Custom/DocumentsPages/DocumentControlsPage";
import PageContainer from "pages/PageContainer";

const DocumentControls = () => {
  return (
    <PageContainer title={"Kontroller"}>
      <DocumentControlsPage />
    </PageContainer>
  );
};

export default DocumentControls;
