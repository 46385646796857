import FilterForm from "./FilterForm";
import TomorrowEntranceWithDepositTable from "./TomorrowEntranceWithDepositTable";

interface TomorrowEntranceWithDepositPageProps {
  isLoading: boolean;
}

const TomorrowEntranceWithDepositPage = ({
  isLoading,
}: TomorrowEntranceWithDepositPageProps) => {

  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <TomorrowEntranceWithDepositTable isLoading={isLoading} />
    </div>
  );
};

export default TomorrowEntranceWithDepositPage;
