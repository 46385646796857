import moment from "moment";

interface SpecialDates {
  label: string;
  value: string;
}

// 2024 yılına ait özel günler
const specialDates: SpecialDates[] = [
  { label: "Yılbaşı", value: "2024-01-01" },
  { label: "Ramazan Bayramı Arifesi", value: "2024-04-09" },
  { label: "Ramazan Bayramı 1. Gün", value: "2024-04-10" },
  { label: "Ramazan Bayramı 2. Gün", value: "2024-04-11" },
  { label: "Ramazan Bayramı 3. Gün", value: "2024-04-12" },
  { label: "Ulusal Egemenlik ve Çocuk Bayramı", value: "2024-04-23" },
  { label: "Emek ve Dayanışma Günü", value: "2024-05-01" },
  { label: "Atatürk'ü Anma, Gençlik ve Spor Bayramı", value: "2024-05-19" },
  { label: "Kurban Bayramı Arifesi", value: "2024-06-15" },
  { label: "Kurban Bayramı 1. Gün", value: "2024-06-16" },
  { label: "Kurban Bayramı 2. Gün", value: "2024-06-17" },
  { label: "Kurban Bayramı 3. Gün", value: "2024-06-18" },
  { label: "Kurban Bayramı 4. Gün", value: "2024-06-19" },
  { label: "Demokrasi ve Milli Birlik Günü", value: "2024-07-15" },
  { label: "Zafer Bayramı", value: "2024-08-30" },
  { label: "Cumhuriyet Bayramı", value: "2024-10-29" },
  { label: "Yılbaşı", value: "2024-12-31" },
];

const today = moment().format("YYYY-MM-DD");

const useSpecialDaysForFlatpickr = (showNumberOfNights?: boolean) => {
  const specialDatesMap = new Map(
    specialDates.map((date) => [date.value, date.label])
  );

  const onDayCreate = (dObj: any, dStr: any, fp: any, dayElem: any) => {
    const date = moment(dayElem.dateObj).format("YYYY-MM-DD");

    if (specialDatesMap.has(date)) {
      dayElem.classList.add("special-date");
      dayElem.title = specialDatesMap.get(date);
    }

    if (date === today) {
      dayElem.classList.add("special-date-today");
    }

    if (showNumberOfNights && fp.selectedDates.length === 1) {
      const startDate = fp.selectedDates[0];
      const hoverDate = dayElem.dateObj;
      const numberOfNights = moment(hoverDate).diff(startDate, "days");

      if (numberOfNights > 0) {
        dayElem.classList.add("special-date-hover-text");

        const tooltip = document.createElement("span");
        tooltip.id = "special-date-bottom";
        tooltip.classList.add("special-date-tooltip-text");
        tooltip.textContent = `${numberOfNights} Gece`;
        dayElem.appendChild(tooltip);
      }
    }
  };

  const onChange = (selectedDates: Date[], dateStr: string, instance: any) => {
    instance.redraw(); // Redraw the calendar to trigger onDayCreate for updating tooltips
  };

  return { onDayCreate, onChange };
};

export default useSpecialDaysForFlatpickr;