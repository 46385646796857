import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";
import CancellationModal from "./Modals/CancellationModal";
import { useState } from "react";

interface TodayEntrancePayAtTheDoorTableProps {
  isLoading: boolean;
}

const TodayEntrancePayAtTheDoorTable = ({
  isLoading,
}: TodayEntrancePayAtTheDoorTableProps) => {
  const [selectedReservationId, setSelectedReservationId] = useState(0);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  const { tableData, tableColumns, legend, reservations, handleCancel } =
    useHandleTable(setSelectedReservationId, setShowCancellationModal);

  return (
    <>
      <CustomReactTable
        tableData={tableData}
        tableColumns={tableColumns}
        loading={isLoading}
        legend={legend}
      />
      {selectedReservationId !== 0 && (
        <CancellationModal
          reservations={reservations}
          reservation_id={selectedReservationId}
          isOpen={showCancellationModal}
          handleToggle={() =>
            setShowCancellationModal((prevState) => !prevState)
          }
          updateStatus={handleCancel}
        />
      )}
    </>
  );
};

export default TodayEntrancePayAtTheDoorTable;
