import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveFindReservationResponse,
  handleSaveAvailabilityResponse,
  setIsLoading,
  setError,
  setUpdateAvailabilityLoading,
  setUpdateAvailabilityError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  FIND_RESERVATION,
  GET_AVAILABILITY_DATA,
  LIST_RESERVATIONS,
  UPDATE_AVAILABILITY_DATA,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import {
  AvailabilityPayload,
  FindReservationPayload,
} from "types/reservationLists";

type SuccessCallback = () => void;

export const listReservations =
  (start_date: string, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(LIST_RESERVATIONS, {
        params: { start_date },
      });

      dispatch(handleSaveFindReservationResponse(response.data || response));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const findReservations =
  (payload: FindReservationPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(FIND_RESERVATION, payload);

      dispatch(handleSaveFindReservationResponse(response.data || response));
      dispatch(setToastSuccess("Rezervasyonlar başarıyla listelendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getAvailabilityData =
  (rezervasyon_id: number, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_AVAILABILITY_DATA, {
        params: { rezervasyon_id },
      });

      dispatch(handleSaveAvailabilityResponse(response.data || response));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateAvailabilityData =
  (payload: AvailabilityPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateAvailabilityLoading(true));
      await axios.post(UPDATE_AVAILABILITY_DATA, payload);

      dispatch(setToastSuccess("Müsaitlik başarıyla güncellendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateAvailabilityError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateAvailabilityLoading(false));
    }
  };
