import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import {
  CreateGroupReservationPayload,
  CreateGroupReservationFormErrors,
} from "types/reservation";
import { handleChangeInput } from "slices/reservation/enterGroupReservation/reducer";

interface AccommodationDatesProps {
  values: CreateGroupReservationPayload;
  formErrors: CreateGroupReservationFormErrors;
  validateFormChange: (values: CreateGroupReservationPayload) => void;
}

const AccommodationDates = ({
  values,
  formErrors,
  validateFormChange,
}: AccommodationDatesProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer
        label="Giriş / Çıkış Tarihleri "
        error={formErrors.giris_tarihi}
        required
        divider
        orientation="vertical"
      >
        <Flatpickr
          className="form-control"
          value={[values.cikis_tarihi, values.cikis_tarihi]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;
            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");
              dispatch(
                handleChangeInput({
                  giris_tarihi: stringStartDate,
                  cikis_tarihi: stringEndDate,
                })
              );
              validateFormChange({
                ...values,
                giris_tarihi: stringStartDate,
                cikis_tarihi: stringEndDate,
              });
            }
          }}
          options={{
            mode: "range",
            dateFormat: "d-m-Y",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default AccommodationDates;
