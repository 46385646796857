import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import moment from "moment";
import { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import {
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  GroupReservationsData,
  UpdateGroupReservationsPayload,
} from "types/reservationMonitoring";

const lineStyle: React.CSSProperties = {
  borderBottom: "1px solid #eaeaea",
  maxWidth: "1400px",
  margin: "0 auto",
  paddingBottom: "4px",
};

interface UpdateStatusModalProps {
  reservations: GroupReservationsData[];
  reservation_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  updateGroupReservation: (payload: UpdateGroupReservationsPayload) => void;
  isLoading: boolean;
}

const UpdateStatusModal = ({
  reservations,
  reservation_id,
  isOpen,
  handleToggle,
  updateGroupReservation,
  isLoading,
}: UpdateStatusModalProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const reservation = reservations.find(
    (reservation) => reservation.id === reservation_id
  )!;

  const [formFields, setFormFields] = useState<UpdateGroupReservationsPayload>({
    rezervasyon_id: reservation.id ?? 0,
    otel_adi: reservation.otel_adi ?? "",
    otel_id: reservation.otel_id ?? 0,
    musteri_ad_soyad: reservation.musteri_ad_soyad ?? "",
    musteri_cep: reservation.musteri_cep ?? "",
    musteri_eposta: reservation.musteri_eposta ?? "",
    giris_tarihi: reservation.giris_tarihi ?? "",
    cikis_tarihi: reservation.cikis_tarihi ?? "",
    yetiskin_sayisi: reservation.yetiskin_sayisi ?? 0,
    opsiyon_tarihi: reservation.opsiyon_tarihi ?? "",
    ozel_istekler: reservation.ozel_istekler ?? "",
    islemler: "",
  });

  useEffect(() => {
    setFormFields({
      rezervasyon_id: reservation.id ?? 0,
      otel_adi: reservation.otel_adi ?? "",
      otel_id: reservation.otel_id ?? 0,
      musteri_ad_soyad: reservation.musteri_ad_soyad ?? "",
      musteri_cep: reservation.musteri_cep ?? "",
      musteri_eposta: reservation.musteri_eposta ?? "",
      giris_tarihi: reservation.giris_tarihi ?? "",
      cikis_tarihi: reservation.cikis_tarihi ?? "",
      yetiskin_sayisi: reservation.yetiskin_sayisi ?? 0,
      opsiyon_tarihi: reservation.opsiyon_tarihi ?? "",
      ozel_istekler: reservation.ozel_istekler ?? "",
      islemler:  "",
    });
  }, [reservation]);

  const handleUpdate = () => {
    updateGroupReservation({
      rezervasyon_id: formFields.rezervasyon_id ?? 0,
      otel_adi: formFields.otel_adi ?? "",
      otel_id: formFields.otel_id ?? 0,
      musteri_ad_soyad: formFields.musteri_ad_soyad ?? "",
      musteri_cep: formFields.musteri_cep ?? "",
      musteri_eposta: formFields.musteri_eposta ?? "",
      giris_tarihi: formFields.giris_tarihi ?? "",
      cikis_tarihi: formFields.cikis_tarihi ?? "",
      yetiskin_sayisi: formFields.yetiskin_sayisi ?? 0,
      opsiyon_tarihi: formFields.opsiyon_tarihi ?? "",
      ozel_istekler: formFields.ozel_istekler ?? "",
      islemler: formFields.islemler ?? "",
    });
  };

  const handleChange = (
    fieldName: keyof UpdateGroupReservationsPayload,
    value: string
  ) => {
    setFormFields((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="xl">
        <ModalHeader>Grup Rezervasyon Durum Güncelle</ModalHeader>
        <ModalBody>
          <FormGroup row className="mx-2 mt-4">
            <Col>
              <div className="d-flex gap-2">
                <Label style={{ minWidth: "120px" }}>Otel Adı :</Label>
                <Input
                  id="aciklama"
                  type="text"
                  placeholder="Otel Adı..."
                  FormGroup
                  value={formFields.otel_adi}
                  onChange={(e) => handleChange("otel_adi", e.target.value)}
                />
              </div>
            </Col>
            <Col>
              <div className="d-flex gap-2">
                <Label style={{ minWidth: "120px" }}>Yetişkin Sayısı :</Label>
                <Input
                  id="aciklama"
                  type="text"
                  placeholder="Yetişkin Sayısı..."
                  FormGroup
                  value={formFields.yetiskin_sayisi}
                  onChange={(e) =>
                    handleChange("yetiskin_sayisi", e.target.value)
                  }
                />
              </div>
            </Col>
          </FormGroup>
          <div style={lineStyle}></div>
          <FormGroup row className="d-flex mx-2 mt-4 ">
            <Col>
              <div className="d-flex gap-2" style={{ marginRight: "2rem" }}>
                <Label style={{ minWidth: "120px" }}>Müşteri Ad Soyad :</Label>
                <Input
                  id="aciklama"
                  type="text"
                  placeholder="Müşteri ad soyadı..."
                  FormGroup
                  value={formFields.musteri_ad_soyad}
                  onChange={(e) =>
                    handleChange("musteri_ad_soyad", e.target.value)
                  }
                />
              </div>
            </Col>
            <Col>
              <div className="d-flex gap-2">
                <Label style={{ minWidth: "120px" }}>
                  Müşteri Cep Telefonu :
                </Label>
                <Input
                  id="aciklama"
                  type="text"
                  placeholder="Müşterinin cep telefonu..."
                  FormGroup
                  value={formFields.musteri_cep}
                  onChange={(e) => handleChange("musteri_cep", e.target.value)}
                />
              </div>
            </Col>
          </FormGroup>
          <div style={lineStyle}></div>
          <FormGroup row className="d-flex mx-2 mt-4 ">
            <Col>
              <div className="d-flex gap-2" style={{ marginRight: "2rem" }}>
                <Label style={{ minWidth: "120px" }}>Müşteri Eposta :</Label>
                <Input
                  id="aciklama"
                  type="text"
                  placeholder="Müşterinin eposta adresi..."
                  FormGroup
                  value={formFields.musteri_eposta}
                  onChange={(e) =>
                    handleChange("musteri_eposta", e.target.value)
                  }
                />
              </div>
            </Col>
            <Col>
              <div className="d-flex gap-2">
                <Label style={{ minWidth: "120px" }}>Opsiyon Tarihi :</Label>
                <Flatpickr
                  className="form-control"
                  value={formFields.opsiyon_tarihi}
                  onChange={(dates) => {
                    const [optionDate] = dates;
                    if (optionDate) {
                      const stringOptionDate =
                        moment(optionDate).format("DD-MM-YYYY");

                      handleChange("opsiyon_tarihi", stringOptionDate);
                    }
                  }}
                  options={{
                    mode: "single",
                    dateFormat: "d-m-Y",
                    locale: Turkish,
                    onDayCreate,
                  }}
                  placeholder="Tarih seçiniz..."
                />
              </div>
            </Col>
          </FormGroup>
          <div style={lineStyle}></div>
          <FormGroup row className="mx-2 mt-4 d-flex">
            <Col>
              <div className="d-flex gap-2">
                <Label style={{ minWidth: "120px" }}>
                  Giriş - Çıkış Tarihleri:
                </Label>
                <Flatpickr
                  className="form-control"
                  value={[formFields.giris_tarihi, formFields.cikis_tarihi]}
                  onChange={(dates) => {
                    const [startDate, endDate] = dates;
                    if (startDate && endDate) {
                      const stringStartDate =
                        moment(startDate).format("DD-MM-YYYY");
                      const stringEndDate =
                        moment(endDate).format("DD-MM-YYYY");

                      handleChange("giris_tarihi", stringStartDate);
                      handleChange("cikis_tarihi", stringEndDate);
                    }
                  }}
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y",
                    showMonths: 2,
                    locale: Turkish,
                    onDayCreate,
                  }}
                  placeholder="Filtrelemek İçin Tarih Seçiniz..."
                />
              </div>
            </Col>
          </FormGroup>
          <div style={lineStyle}></div>
          <FormGroup row className="mx-2 mt-4">
            <div className="d-flex gap-2">
              <Label style={{ minWidth: "120px" }}>Özel İstekler :</Label>
              <Input
                id="aciklama"
                type="textarea"
                placeholder="Müşterinin özel istekleri..."
                FormGroup
                rows={3}
                value={formFields.ozel_istekler}
                onChange={(e) => handleChange("ozel_istekler", e.target.value)}
              />
            </div>
          </FormGroup>
          <div style={lineStyle}></div>
          <FormGroup row className="mx-2 mt-4">
            <div className="d-flex gap-2">
              <Label style={{ minWidth: "120px" }}>Durum Açıklaması :</Label>
              <Input
                id="aciklama"
                type="textarea"
                placeholder="Durum açıklaması..."
                FormGroup
                rows={3}
                value={formFields.islemler}
                onChange={(e) => handleChange("islemler", e.target.value)}
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="primary"
            onClick={handleUpdate}
            isLoading={isLoading}
          >
            Güncelle
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default UpdateStatusModal;
