import { useSelector } from "react-redux";

import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import { CalculatePriceForHotelRoomCard } from "types/reservation";

const useHandleRoomCards = () => {
  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  const result = Object.values(calculatePriceForHotel.fiyatlar)[0].odalar.map(
    (oda) => ({
      ...oda,
      lowest_price: oda.pansiyonlar.map((pansiyon) =>
        pansiyon?.fiyatlar
          ? Object.values(pansiyon.fiyatlar).map((fiyat) => {
              if ([2, 3].includes(fiyat.stop_durum)) {
                return 10000000000000;
              } else
                return Math.min(
                  ...[
                    fiyat.normal_indirimli,
                    fiyat.iptal_edilemez_indirimli,
                    fiyat.kapida_indirimli,
                    fiyat.kaporali_indirimli,
                  ].filter((price) => price)
                );
            })
          : [10000000000000]
      )[0][0],
    })
  );

  const sortedRooms = result.sort((a, b) => a.lowest_price - b.lowest_price);

  const hotel = Object.values(calculatePriceForHotel.fiyatlar)[0];

  const rooms: CalculatePriceForHotelRoomCard[] = sortedRooms.map((room) => ({
    oda_id: room.oda_id,
    oda_adi: room.oda_adi,
    oda_aciklama: room.oda_aciklama,
    oda_genisligi: room.oda_genisligi,
    resimler: [room.resim1, room.resim2, room.resim3, room.resim4],
    gostermelik_indirim_orani: room.gostermelik_indirim_orani,
    kullanilabilir_ozel_indirimler: room.kullanilabilir_ozel_indirimler || [],
    secilen_ozel_indirim: room.secilen_ozel_indirimler || [],
    yetiskin_sayisi: room.yetiskin_sayisi,
    cocuk_sayisi: room.cocuk_sayisi,
    giris_tarihi: hotel.giris_tarihi,
    cikis_tarihi: hotel.cikis_tarihi,
    toplam_gece: hotel.toplam_gece,
    minimum_gece_durum: room.minimum_gece_durum,
    banka_taksit: calculatePriceForHotel.banka_taksit,
    kismi_odeme: {
      kismi_odeme: hotel.kismi_odeme,
      kismi_odeme_orani: hotel.kismi_odeme_orani,
      kismi_odeme_tarihi: hotel.kismi_odeme_tarihi,
    },
    kapida_odeme: {
      kapida_odeme_durum: hotel.kapida_odeme_durum,
      kapida_komisyon_orani: hotel.kapida_komisyon_orani,
      kapida_indirim_orani: hotel.kapida_indirim_orani,
      kapida_odeme_para_birimi_goster: hotel.kapida_odeme_para_birimi_goster,
    },
    kaporali_odeme: {
      kaporali_satis_durum: hotel.kaporali_satis_durum,
      kaporali_satis_orani: hotel.kaporali_satis_orani,
      kaporali_indirim_orani: hotel.kaporali_indirim_orani,
    },
    iptal_sigortasi_durum: calculatePriceForHotel.iptal_sigortasi_durum,
    iptal_sigortasi_gun: calculatePriceForHotel.iptal_sigortasi_gun,
    odeme_sekli: JSON.parse(room.odeme_sekli)?.odeme,
  }));

  return { rooms };
};

export default useHandleRoomCards;
