import { Dispatch } from "react";
import {
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { AddCommentFormErrors, AddCommentState } from "types/comments";
import { useDispatch } from "react-redux";
import { handleChangeCommentInput } from "slices/comments/addComment/reducer";
import { Input } from "reactstrap";

interface PositiveCommentFieldProps {
  values: AddCommentState;
  formErrors: AddCommentFormErrors;
  validateFormChange: (values: AddCommentState) => void;
}

const PositiveCommentField = ({
  values,
  formErrors,
  validateFormChange,
}: PositiveCommentFieldProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <div>
      <CustomFormFieldContainer
        label="Olumlu Yorum"
        error={formErrors.musteri_adi}
        orientation="vertical"
        divider
      >
        <Input
          style={{ minHeight: "100px"}}
          type="text"
          autoCorrect="on"
          value={values.yorum_olumlu}
          onChange={(e: { target: { value: any } }) => {
            dispatch(
              handleChangeCommentInput({
                yorum_olumlu: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              yorum_olumlu: e.target.value,
            });
          }}
        />
        {/* <CustomFormEditor
          editorData={values.yorum_olumlu}
          handleGetEditorData={(data) => {
            dispatch(
              handleChangeCommentInput({
                yorum_olumlu: data,
              })
            );
            validateFormChange({
              ...values,
              yorum_olumlu: data,
            });
          }}
        /> */}
      </CustomFormFieldContainer>
    </div>
  );
};
export default PositiveCommentField;
