import { CustomLoadingPage, CustomPageDescriptions } from "Components/Custom/UI";
import { ASSIGN_HOTELS_TO_USERS_DESCRIPTIONS } from "utils/pageDescriptions";
import CitySelector from "./CitySelector";
import useHandleForm from "./useHandleForm";
import UnassignedHotelsList from "./UnassignedHotelsList";
import PersonnelSelector from "./PersonnelSelector";
import AssignHotelsToUserButton from "./AssignHotelsToUserButton";

interface AssignHotelsToUsersFormProps {
  isLoading: boolean;
}

const AssignHotelsToUsersForm = ({isLoading} : AssignHotelsToUsersFormProps) => {
  const {
    assignHotelToUser,
    assignHotelToUserFormChoices,
    selectedCity,
    selectedUser,
    handleSelectUser,
    handleSelectCity,
    assignHotelToUserLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
    formErrors,
  } = useHandleForm();

  return (
    <>
    <CustomLoadingPage isLoading={isLoading} />
      <div style={{ padding: "8px 16px" }}>
        <CustomPageDescriptions
          variant="danger"
          descriptions={ASSIGN_HOTELS_TO_USERS_DESCRIPTIONS}
        />
          <CitySelector
            cityFormChoices={assignHotelToUserFormChoices.sehirler}
            selectedCity={selectedCity}
            handleSelectCity={handleSelectCity}
          />
          {selectedCity !== 0 ? (
            <>
              <UnassignedHotelsList
                formErrors={formErrors}
                validateFormChange={validateFormChange}
              />
              <PersonnelSelector
                values={assignHotelToUser}
                personnelFormChoices={assignHotelToUserFormChoices.personeller}
                selectedUser={selectedUser}
                handleSelectUser={handleSelectUser}
                formErrors={formErrors}
                validateFormChange={validateFormChange}
              />
              <AssignHotelsToUserButton
                values={assignHotelToUser}
                validateForm={validateForm}
                handleSubmitted={handleSubmitted}
                isLoading={assignHotelToUserLoading}
              />
            </>
          ) : (
            <></>
          )}
      </div>
    </>
  );
};

export default AssignHotelsToUsersForm;
