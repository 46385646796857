import { createSlice } from "@reduxjs/toolkit";
import {
  TomorrowEntranceWithDepositData,
  TomorrowEntranceWithDepositPayload,
  TomorrowEntranceWithDepositResponseData,
} from "types/reservationMonitoring";

export interface TomorrowEntranceWithDepositInitialState {
  tomorrowEntranceWithDeposit: TomorrowEntranceWithDepositData[];
  filterTomorrowEntranceWithDeposit: TomorrowEntranceWithDepositPayload;
  isLoading: boolean;
  error: string;
  changeStatusLoading: boolean;
  changeStatusError: string;
  callCustomerLoading: boolean;
  callCustomerError: string;
}

const initialState: TomorrowEntranceWithDepositInitialState = {
  tomorrowEntranceWithDeposit: [],
  filterTomorrowEntranceWithDeposit: {
    date: "",
    rezervasyon_kodu: "",
    musteri_adi: "",
  },
  isLoading: false,
  error: "",
  changeStatusLoading: false,
  changeStatusError: "",
  callCustomerLoading: false,
  callCustomerError: "",
};

const tomorrowEntranceWithDepositSlice = createSlice({
  name: "tomorrowEntranceWithDeposit",
  initialState,
  reducers: {
    handleSaveTomorrowEntranceWithDepositResponse(state, action) {
      const payload =
        action.payload as TomorrowEntranceWithDepositResponseData[];
      const tomorrowEntranceWithDeposit: TomorrowEntranceWithDepositData[] =
        payload.map((item) => ({
          id: item.id,
          rezervasyon_kodu: item.rezervasyon_kodu,
          otel_adi: item.otel_adi,
          musteri_ad_soyad: item.musteri_ad_soyad,
          musteri_cep: item.musteri_cep,
          musteri_tel: item.musteri_tel,
          toplam_tutar: item.toplam_tutar,
          kayit_tarihi: item.kayit_tarihi,
          iptal_edildi_mi: item.iptal_edildi_mi,
          iptal_nedeni: item.iptal_nedeni,
          kapida_odeme_giris: item.kapida_odeme_giris,
          satici_adi: item.satici_adi,
          grup_adi: item.grup_adi,
          kaporali_rezervasyon_giris: item.kaporali_rezervasyon_giris,
          otel_telefon: item.otel_telefon,
        }));
      state.tomorrowEntranceWithDeposit = tomorrowEntranceWithDeposit;
    },
    handleChangeFilterChoices(state, action) {
      state.filterTomorrowEntranceWithDeposit = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setChangeStatusLoading(state, action) {
      state.changeStatusLoading = action.payload;
    },
    setChangeStatusError(state, action) {
      state.changeStatusError = action.payload;
    },
    setCallCustomerLoading(state, action) {
      state.callCustomerLoading = action.payload;
    },
    setCallCustomerError(state, action) {
      state.callCustomerError = action.payload;
    },
  },
});

export const {
  handleSaveTomorrowEntranceWithDepositResponse,
  handleChangeFilterChoices,
  setIsLoading,
  setError,
  setChangeStatusLoading,
  setChangeStatusError,
  setCallCustomerLoading,
  setCallCustomerError
} = tomorrowEntranceWithDepositSlice.actions;

export default tomorrowEntranceWithDepositSlice.reducer;
