import { createSlice } from "@reduxjs/toolkit";
import {
  InetRoomsWithoutDescriptionData,
} from "types/reservationMonitoring";

export interface InetRoomsWithoutDescriptionInitialState {
  inetRoomsWithoutDescription: InetRoomsWithoutDescriptionData[];
  isLoading: boolean;
  error: string;
}

const initialState: InetRoomsWithoutDescriptionInitialState = {
  inetRoomsWithoutDescription: [],
  isLoading: false,
  error: "",
};

const inetRoomsWithoutDescriptionSlice = createSlice({
  name: "inetRoomsWithoutDescription",
  initialState,
  reducers: {
    handleSaveInetRoomsWithoutDescriptionResponse(state, action) {
      state.inetRoomsWithoutDescription = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveInetRoomsWithoutDescriptionResponse,
  setIsLoading,
  setError,
} = inetRoomsWithoutDescriptionSlice.actions;

export default inetRoomsWithoutDescriptionSlice.reducer;
