import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {  ListConfirmationApprovalInitialState } from "slices/reservationLists/confirmationApproval/reducer";
import { listReservations } from "slices/reservationLists/confirmationApproval/thunk";

const useListINetNonentryReservations = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ListConfirmationApproval: ListConfirmationApprovalInitialState }) =>
      state.ListConfirmationApproval
  );

  useEffect(() => {
    dispatch(listReservations("", () => {}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useListINetNonentryReservations;
