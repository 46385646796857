import {
  CustomLoadingPage,
  CustomPageDescriptions,
} from "Components/Custom/UI";
import { ENTER_GROUP_RESERVATION_PAGE_DESCRIPTION } from "utils/pageDescriptions";
import GroupReservationForm from "./GroupReservationForm";

interface EnterGroupReservationPageProps {
  isLoading: boolean;
}

const EnterGroupReservationPage = ({
  isLoading,
}: EnterGroupReservationPageProps) => {
  const description = ENTER_GROUP_RESERVATION_PAGE_DESCRIPTION.map(
    (description, index) => (
      <div
        key={index}
        dangerouslySetInnerHTML={{ __html: description }}
        className="user-select-none"
      />
    )
  );
  return (
    <div className="d-flex flex-column gap-4">
      <CustomLoadingPage isLoading={isLoading} />
      <CustomPageDescriptions descriptions={description} variant="warning" />
      <GroupReservationForm />
    </div>
  );
};

export default EnterGroupReservationPage;
