import DocumentCenterPage from "Components/Custom/DocumentsPages/DocumentCenterPage";
import useDocumentCenter from "Components/Hooks/documents/useDocumentCenter";
import PageContainer from "pages/PageContainer";

const DocumentCenter = () => {
  const { accountingData, operationData } = useDocumentCenter();

  return (
    <PageContainer title={"Dosya Merkezi"}>
      <DocumentCenterPage accountingData={accountingData} operationData={operationData}  />
    </PageContainer>
  );
};

export default DocumentCenter;
