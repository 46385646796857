import { CustomRowOperationButton } from "Components/Custom/UI";
import { useNumberFormatter } from "Components/Hooks/useNumberFormatter";
import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginInitialState } from "slices/auth/login/reducer";
import { ListReservationsChannelInitialState } from "slices/reservationLists/listReservationsChannel/reducer";
import {
  filterReservationData,
  getAvailabilityData,
  updateAvailabilityData,
} from "slices/reservationLists/listReservationsChannel/thunk";
import { AvailabilityPayload } from "types/reservationLists";
import { TABLE_COLORS } from "utils/tableColors";

const useHandleTable = (
  setSelectedReservationId: Dispatch<SetStateAction<number>>,
  setShowUpdateAvailabilityModal: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const {
    reservations,
    listReservationsResponse,
    availabilityResponse,
    filterReservations,
  } = useSelector(
    (state: { ListReservationsChannel: ListReservationsChannelInitialState }) =>
      state.ListReservationsChannel
  );

  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );

  const { yetki } = user;

  const { formatNumber } = useNumberFormatter();

  const handleShowReservation = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-incele?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const handleUpdateReservation = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-duzenle?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const handleAvailabilityModal = (id: number) => {
    dispatch(
      getAvailabilityData(id, () => {
        setSelectedReservationId(id);
        setShowUpdateAvailabilityModal((prevState) => !prevState);
      })
    );
  };

  const handleUpdateAvailabilityData = (payload: AvailabilityPayload) => {
    dispatch(
      updateAvailabilityData(payload, () => {
        setSelectedReservationId(0);
        setShowUpdateAvailabilityModal((prevState) => !prevState);
        dispatch(filterReservationData(filterReservations, () => {}));
      })
    );
  };

  const dataKeyMatch: { [key: string]: string } = {
    otele_odeme_durum: "otele_odeme_durum",
    muhasebe_tahsilat_onay: "muhasebe_tahsilat_onay",
    ana_rez_kod: "ana_rez_kod",
    rezervasyon_kodu: "rezervasyon_kodu",
    kayit_tarihi: "kayit_tarihi",
    giris_cikis_tarihi: "giris_cikis_tarihi",
    musteri_ad_soyad: "musteri_ad_soyad",
    otel_adi: "otel_adi",
    otel_id: "otel_id",
    toplam_tutar: "toplam_tutar",
    grup: "grup",
    personel: "personel",
    musaitlik: "musaitlik",
    operator_firma: "operator_firma",
    operator_rez_kodu: "operator_rez_kodu",
    id: "id",
    oda_id: "oda_id",
    cekilen: "cekilen",
    komisyon_tutar: "komisyon_tutar",
    otel_tutar: "otel_tutar",
    durum: "durum",
    renk: "renk",
    tr_display: "tr_display",
    komisyon: "komisyon",
  };

  const hiddenColumns =
    listReservationsResponse.gosterilmeyecek_alanlar[yetki] ?? [];

  const columnVisibility: { [key: string]: boolean } = {};

  hiddenColumns.forEach((column) => {
    columnVisibility[dataKeyMatch[column]] = false;
  });

  const rowColors = {
    success: TABLE_COLORS.green,
    danger: TABLE_COLORS.red,
    warning: TABLE_COLORS.yellow,
  };

  const tableData = reservations?.map((reservation, index) => ({
    otele_odeme_durum: reservation.otele_odeme_durum, //type?
    muhasebe_tahsilat_onay: reservation.muhasebe_tahsilat_onay, //type?
    ana_rez_kod: reservation.ana_rez_kod, //type?
    rezervasyon_kodu: reservation.rezervasyon_kodu,
    kayit_tarihi: reservation.kayit_tarihi,
    giris_cikis_tarihi: `${reservation.giris_tarihi} - ${reservation.cikis_tarihi}`,
    musteri_ad_soyad: reservation.musteri_ad_soyad,
    otel_adi: reservation.otel_adi,
    otel_id: reservation.otel_id,
    toplam_tutar: reservation.toplam_tutar,
    grup: reservation.grup, //type?
    personel: reservation.personel,
    musaitlik: reservation.musaitlik,
    operator_firma: reservation.operator_firma,
    operator_rez_kodu: reservation.operator_rez_kodu,
    id: reservation.id,
    oda_id: reservation.oda_id,
    cekilen: reservation.cekilen,
    komisyon_tutar: reservation.komisyon_tutar,
    otel_tutar: reservation.otel_tutar, //type?
    durum: reservation.durum,
    renk: reservation.renk,
    tr_display: reservation.tr_display,
    komisyon: reservation.komisyon,
    rowColor: rowColors[reservation.renk as keyof typeof rowColors],
    rowNumber: index + 1,
  }));

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "rowNumber",
      enableColumnFilter: false,
    },
    {
      header: "Rez. kodu",
      accessorKey: "rezervasyon_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Grup",
      accessorKey: "grup",
      enableColumnFilter: false,
    },
    {
      header: "Sat. Tarihi",
      accessorKey: "kayit_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Müşteri",
      accessorKey: "musteri_ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Otel",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Rez. Tutarı",
      accessorKey: "toplam_tutar",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        const number = formatNumber(value);
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {number}
            </div>
          </>
        );
      },
    },
    {
      header: "Çekilen Tutar",
      accessorKey: "cekilen",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        const number = formatNumber(value);
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {number}
            </div>
          </>
        );
      },
    },
    {
      header: "Personel",
      accessorKey: "personel",
      enableColumnFilter: false,
    },
    {
      header: "Müsaitlik",
      accessorKey: "musaitlik",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData = cell.row.original;
        const value = cell.getValue();
        return (
          <>
            <div
              className="text-wrap d-flex flex-column"
              style={{ width: "80px" }}
            >
              <div>{value}</div>
              <div>({rowData.operator_firma})</div>
            </div>
          </>
        );
      },
    },
    {
      header: "Onay",
      accessorKey: "durum",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData = cell.row.original;
        const value = cell.getValue();
        return (
          <>
            <div
              className="text-wrap d-flex flex-column"
              style={{ width: "80px" }}
            >
              <div>{value}</div>
              <div>({rowData.personel})</div>
            </div>
          </>
        );
      },
    },
    {
      header: "Operatör",
      accessorKey: "operator_firma",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="check"
                onClick={() => handleAvailabilityModal(id)}
                title="Müsaitlik"
              />
              <CustomRowOperationButton
                variant="update"
                onClick={() => handleUpdateReservation(id)}
                title="Düzenle"
              />
              <CustomRowOperationButton
                variant="delete"
                onClick={() => {}}
                title="İptal"
              />
              <CustomRowOperationButton
                variant="detail"
                onClick={() => handleShowReservation(id)}
                title="İncele"
              />
              <CustomRowOperationButton
                variant="warning"
                onClick={() => {}}
                title="Uyarı"
              />
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.lime,
      label: "Beklemede",
    },
    {
      color: TABLE_COLORS.green,
      label: "Onaylanmış",
    },
    {
      color: TABLE_COLORS.purple,
      label: "İptal Edilmiş",
    },
    {
      color: TABLE_COLORS.orange,
      label: "Bugün -Yarın Girişli veya Havale",
    },
    {
      color: TABLE_COLORS.teal,
      label: "Kapıda Ödemeli",
    },
    {
      color: TABLE_COLORS.fuchsia,
      label: "Kaporalı",
    },
    {
      color: TABLE_COLORS.violet,
      label: "Online",
    },
    {
      color: TABLE_COLORS.red,
      label: "Giriş Yapılmamış",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "Sorsat",
    },
    {
      color: TABLE_COLORS.emerald,
      label: "Mailorder",
    },
  ];

  return {
    tableData,
    tableColumns,
    columnVisibility,
    legend,
    availabilityResponse,
    handleUpdateAvailabilityData,
  };
};

export default useHandleTable;
