import { Col, Label, Row } from "reactstrap";
import { CalculatePriceForHotel, CalculatePriceFormValues } from "types/reservation";

interface ModalInfoProps {
  hotelInfo: CalculatePriceForHotel;
  formInfo: CalculatePriceFormValues;
}
const lineStyle: React.CSSProperties = {
  borderBottom: "1px solid #eaeaea",
  maxWidth: "1000px",
  margin: "0 auto",
  paddingBottom: "4px"
};

const ModalInfo = ({ hotelInfo, formInfo }: ModalInfoProps) => {
  return (
    <>
      <Row className="mx-2 mt-4">
        <div className="d-flex gap-2">
          <Label>Otel Adı:</Label>
          <p style={{ margin: 0 }}>{hotelInfo.otel_adi ?? ""}</p>
        </div>
      <div style={lineStyle}></div>
      </Row>

      <Row className="mx-2 mt-4">
        <div className="d-flex gap-2">
          <Label>Yetkili</Label>
          <p style={{ margin: 0 }}>{hotelInfo.otel_adi ?? ""}</p>
        </div>
      <div style={lineStyle}></div>
      </Row>

      <Row className="d-flex mx-2 mt-4 ">
        <Col>
          <div className="d-flex gap-2" style={{ marginRight: "2rem" }}>
            <Label>Giriş Tarihi:</Label>
            <p style={{ margin: 0 }}>{formInfo.giris_tarihi ?? ""}</p>
          </div>
        </Col>
        <Col>
          <div className="d-flex gap-2">
            <Label>Çıkış Tarihi:</Label>
            <p style={{ margin: 0 }}>{formInfo.cikis_tarihi ?? ""}</p>
          </div>
        </Col>
      <div style={lineStyle}></div>
      </Row>

      <Row className="mx-2 mt-4 d-flex">
        <Col>
          <div className="d-flex gap-2" style={{ marginRight: "2rem" }}>
            <Label>Yetişkin Sayısı :</Label>
            <p style={{ margin: 0 }}>{formInfo.yetiskin_sayisi ?? ""}</p>
          </div>
        </Col>
        <Col>
          <div className="d-flex gap-2">
            <Label>Çocuk Sayısı:</Label>
            <p style={{ margin: 0 }}>{formInfo.cocuk_sayisi ?? ""}</p>
          </div>
        </Col>
      <div style={lineStyle}></div>
      </Row>
    </>
  );
};

export default ModalInfo;
