import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { TodayEntrancePayAtTheDoorPayload } from "types/reservationMonitoring";

interface DateInputProps {
  values: TodayEntrancePayAtTheDoorPayload;
  handleFormChange: (key: string, value: string) => void;
}

const DateInput = ({
  values,
  handleFormChange,
}: DateInputProps) => {
  const {onDayCreate} = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer label="Tarih">
        <Flatpickr
          className="form-control"
          value={values.date}
          onChange={(dates) => {
            const [filterDate] = dates;

            if (filterDate) {
              const stringDate = moment(filterDate).format("YYYY-MM-DD");

              handleFormChange("date", stringDate);
            }
          }}
          options={{
            mode: "single",
            dateFormat: "Y-m-d",
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default DateInput;