import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { makePayment } from "slices/reservation/reviewReservation/thunk";
import {
  ReservationReview,
  ReservationReviewCreditCard,
} from "types/reservation";

const useHandleRequests = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [showCollectionCancellationModal, setShowCollectionCancellationModal] =
    useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState(0);

  const [showUpdateCreditCardModal, setShowUpdateCreditCardModal] =
    useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(0);

  const [showApprovePaymentModal, setShowApprovePaymentModal] = useState(false);
  const [selectedCreditCard, setSelectedCreditCard] =
    useState<ReservationReviewCreditCard | null>(null);

  const toggleCollectionCancellationModal = () =>
    setShowCollectionCancellationModal((prevState) => !prevState);

  const toggleUpdateCreditCardModal = () =>
    setShowUpdateCreditCardModal((prevState) => !prevState);

  const toggleApprovePaymentModal = () =>
    setShowApprovePaymentModal((prevState) => !prevState);

  const handleCreateRequest = (payload: {
    tahsilat_id: number;
    aciklama: string;
  }) => {};

  const handleUpdateCardInfo = (payload: ReservationReviewCreditCard) => {};

  const handleApprovePayment = useCallback(
    (
      selectedCreditCard: ReservationReviewCreditCard | null,
      reservation: ReservationReview
    ) => {
      if (!selectedCreditCard) return;

      const [expiryMonth, expiryYear] = selectedCreditCard.kk_tarih.split("/");

      const payload = {
        isPostman: false,
        type: "reservation",
        reservationId: String(reservation.rezervasyon_id),
        priceId: 1,
        installmentId: Number(selectedCreditCard.kk_taksit) || 1,
        card: {
          cardOwner: selectedCreditCard.kk_ad_soyad,
          cardNumber: selectedCreditCard.kk_no,
          expiryMonth,
          expiryYear,
          cvc: selectedCreditCard.kk_cvc,
        },
      };

      console.log("Payload:", payload);
      dispatch(makePayment(payload));
    },
    [dispatch]
  );

  return {
    showCollectionCancellationModal,
    setShowCollectionCancellationModal,
    selectedCollectionId,
    setSelectedCollectionId,
    showUpdateCreditCardModal,
    setShowUpdateCreditCardModal,
    selectedPaymentId,
    setSelectedPaymentId,
    showApprovePaymentModal,
    setShowApprovePaymentModal,
    toggleCollectionCancellationModal,
    toggleUpdateCreditCardModal,
    toggleApprovePaymentModal,
    handleCreateRequest,
    handleUpdateCardInfo,
    selectedCreditCard,
    setSelectedCreditCard,
    handleApprovePayment,
  };
};

export default useHandleRequests;
