import { useEffect, useState } from "react";
import { Table } from "reactstrap";

import {
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";

import CustomTableTop from "./CustomTableTop";
import CustomTableBody from "./CustomTableBody";
import CustomTableHeader from "./CustomTableHeader";
import CustomTableBottom from "./CustomTableBottom";
import { PaginationBottom } from "types/GlobalTypes";
import { ColumnColorRule } from "helpers/table_cell_coloring_helper";

interface CustomTableContainerProps {
  columns?: any;
  columnVisibility?: any;
  data?: any;
  isGlobalFilter?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  SearchPlaceholder?: any;
  handleLeadClick?: any;
  handleCompanyClick?: any;
  handleContactClick?: any;
  handleTicketClick?: any;
  pagination?: boolean;
  loading?: boolean;
  onSearch?: (searchValue: string) => void;
  onPageSizeChange?: (pageSize: number) => void;
  paginationBottomProps?: PaginationBottom;
  colorConfig?: ColumnColorRule[];
}

const CustomTableContainer = ({
  columns,
  columnVisibility,
  data,
  isGlobalFilter,
  // customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  pagination = false,
  loading = false,
  onSearch = () => {},
  onPageSizeChange = () => {},
  paginationBottomProps,
  colorConfig
}: CustomTableContainerProps) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const [customPageSize, setCustomPageSize] = useState(pagination ? 100 : 25);

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      columnVisibility,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
  } = table;

  useEffect(() => {
    Number(customPageSize) && setPageSize(Number(customPageSize));
  }, [customPageSize, setPageSize]);

  const numberOfTotalRows = table.getFilteredRowModel().rows.length;
  const numberOfPageRows = table.getPaginationRowModel().rows.length;

  return (
    <>
      <CustomTableTop
        isGlobalFilter={isGlobalFilter}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        pagination={pagination}
        onSearch={onSearch}
        onPageSizeChange={onPageSizeChange}
        SearchPlaceholder={SearchPlaceholder}
        customPageSize={customPageSize}
        onCustomPageSizeChange={setCustomPageSize}
      />

      <div className={divClass}>
        <Table hover className={tableClass}>
          <CustomTableHeader
            getHeaderGroups={getHeaderGroups}
            thClass={thClass}
            trClass={trClass}
            theadClass={theadClass}
            flexRender={flexRender}
            table={table}
          />

          <CustomTableBody
            loading={loading}
            columns={columns}
            getRowModel={getRowModel}
            flexRender={flexRender}
            colorConfig={colorConfig}
          />
        </Table>
      </div>

      <CustomTableBottom
        numberOfTotalRows={numberOfTotalRows}
        numberOfPageRows={numberOfPageRows}
        data={data}
        getState={getState}
        getCanPreviousPage={getCanPreviousPage}
        previousPage={previousPage}
        getPageOptions={getPageOptions}
        setPageIndex={setPageIndex}
        getCanNextPage={getCanNextPage}
        nextPage={nextPage}
        pagination={pagination}
        paginationBottomProps={paginationBottomProps}
        loading={loading}
      />
    </>
  );
};

export default CustomTableContainer;
