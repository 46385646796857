import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  FindReservationsInitialState,
  handleChangeReservationFilterChoices,
} from "slices/reservationLists/findReservation/reducer";
import { findReservations } from "slices/reservationLists/findReservation/thunk";
import { PaginationBottom } from "types/GlobalTypes";
import { FindReservationPayload } from "types/reservationLists";

let firstRender = true;

const useHandlePagination = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [paginationTop, setPaginationTop] = useState({
    per_page: 10,
    search: "",
  });

  const { pagination, filterReservations } = useSelector(
    (state: { FindReservations: FindReservationsInitialState }) =>
      state.FindReservations
  );

  const handleClickNextPage = () => {
    const params: FindReservationPayload = {
      ...paginationTop,
      page: pagination.current_page + 1,
      start_date:
        filterReservations.start_date === ""
          ? moment().format("YYYY-MM-DD")
          : filterReservations.start_date,
      rezervasyon_kodu: filterReservations.rezervasyon_kodu,
      musteri_adi: filterReservations.musteri_adi,
    };
    pagination.next_page_url && dispatch(findReservations(params, () => {}));
  };
  const handleClickPrevPage = () => {
    const params: FindReservationPayload = {
      ...paginationTop,
      page: pagination.current_page - 1,
      start_date:
        filterReservations.start_date === ""
          ? moment().format("YYYY-MM-DD")
          : filterReservations.start_date,
      rezervasyon_kodu: filterReservations.rezervasyon_kodu,
      musteri_adi: filterReservations.musteri_adi,
    };

    pagination.prev_page_url &&
      dispatch(findReservations(params, () => {})) &&
      dispatch(handleChangeReservationFilterChoices(params));
  };

  const paginationBottomProps: PaginationBottom = {
    total: pagination.total,
    current_page: pagination.current_page,
    per_page: pagination.per_page,
    prev_page_url: pagination.prev_page_url,
    next_page_url: pagination.next_page_url,
    onClickNextPage: handleClickNextPage,
    onClickPrevPage: handleClickPrevPage,
  };

  const handleChange = (key: string, value: number | string) => {
    if (firstRender) {
      firstRender = false;
      return;
    }
    const newPaginationTop = {
      ...paginationTop,
      [key]: value,
      page: key === "per_page" ? 1 : pagination.current_page,
    };

    setPaginationTop(newPaginationTop);

    const params: FindReservationPayload = {
      ...newPaginationTop,
      page: pagination.current_page - 1,
      start_date:
        filterReservations.start_date === ""
          ? moment().format("YYYY-MM-DD")
          : filterReservations.start_date,
      rezervasyon_kodu: filterReservations.rezervasyon_kodu,
      musteri_adi: filterReservations.musteri_adi,
    };

    if (key === "per_page") {
      params.page = 1;
    }

    dispatch(findReservations(params, () => {}));
  };

  return { handleChange, paginationBottomProps };
};

export default useHandlePagination;
