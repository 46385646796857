import { createSlice } from "@reduxjs/toolkit";

import {
  EditReservationData,
  EditReservationFormChoices,
} from "types/reservation";

export interface EditReservationInitialState {
  reservation: EditReservationData;
  reservationOtherRooms: EditReservationData[];
  editReservation: EditReservationData;
  formChoices: EditReservationFormChoices;
  isLoading: boolean;
  error: string;
}

const initialState: EditReservationInitialState = {
  reservation: {
    rezervasyon_id: 0,
    rezervasyon_kodu: "",
    ana_rez_kod: 0,
    satici_id: 0,
    otel_adi: "",
    otel_id: 0,
    oda_adi: "",
    oda_id: 0,
    pansiyon_adi: "",
    pansiyon_id: 0,
    giris_tarihi: "",
    cikis_tarihi: "",
    musteri_tc_kimlik: "",
    musteri_ad_soyad: "",
    musteri_cep: "",
    musteri_tel: "",
    musteri_eposta: "",
    toplam_tutar: "",
    kartla_odeme: "",
    havaleyle_odeme: "",
    fatura_unvani: "",
    fatura_adresi: "",
    vergi_dairesi: "",
    vergi_no: "",
    aciklama: "",
    request: "",
    request_acenta: 0,
    konfirme_edildi_mi: "",
    konfirme_alis_zamani: "",
    iptal_edildi_mi: "",
    kayit_tarihi: "",
    musaitlik: "",
    musaitlik_aciklama: "",
    musaitlik_alan: 0,
    musaitlik_alis_zamani: "",
    rez_tipi: "",
    rez_durum: "",
    operator_firma: "",
    fatura_no: "",
    satis_operator: "",
    fiyat_hesaplama: "",
    karsilama_kodu: "",
    inet_no_show_durum: "",
    iptal_sigortasi: "",
    satici_adi: "",
    ucretli_cocuk_durum: "",
    kaporali_satis_orani: "",
    farkli_acenteden_satis_durum: "",
    uygulanan_indirimler: [],
    verilen_hediyeler: "",
    komisyon_faturali_rezervasyon: "",
    fiyat_saglayici: "",
    fiyat_saglayici_confirm_durum: "",
    rezervasyon_giris_saat_farki: 0,
    fiyat_saglayici_iptal_talebi: "",
    fiyat_saglayici_iptal_talebi_zamani: null,
    eski_rezervasyon: "",
    inet_otel_kodu: "",
    otel_parapuan_orani: "",
    kismi_odeme_durum: "",
    kismi_odeme_tarihi: "",
    alt_acente_satisi: "",
    alt_acente_komisyon_tutar: "",
    kendisine_goster: 0,
    kart_goster: "",
    misafirler: [],
    yetiskin_sayisi: 0,
    cocuk_sayisi: 0,
    kk: [],
    havale: "",
    nakit: "",
    tahsilat_bilgileri: [],
    muhasebe_bilgileri: {
      misafir_fatura_no: "",
      misafir_fatura_id: "",
      muhasebe_tahsilat_onay: "",
      toplam_tahsilat_tutar: "",
      toplam_otel_onaylanan_tahsilat_tutar: "",
      komisyon_orani: "",
      indirim_orani: "",
      para_birimi: "",
      kur: "",
      toplam_cekilen_tutar: "",
      toplam_otel_brut_tutar: "",
      toplam_otel_indirimli_tutar: "",
      toplam_otel_cekilen_tutar: "",
      toplam_otel_maliyet_tutar: "",
      toplam_otel_maliyet_tutar_kur: "",
      toplam_otel_gercek_maliyet_tutar: "",
      toplam_otel_gercek_maliyet_tutar_kur: "",
      toplam_otel_komisyon_tutar: "",
      toplam_transfer_cekilen_tutar: "",
      toplam_transfer_maliyet_tutar: "",
      toplam_transfer_komisyon_tutar: "",
      otele_odeme_gunu: "",
      otel_fatura_no: "",
      otele_odeme_durum: "",
      otele_odeme_tutar: "",
      cari_durum_result: "",
    },
    finans_bilgileri: {
      finans_id: 0,
      komisyon_orani: "",
      indirim_orani: "",
      para_birimi: "",
      brut_tutar: "",
      maliyet_tutar: "",
      indirimli_tutar: "",
      komisyon_tutar: "",
      indirim_tutar: "",
      iptal_sigortasi_tutar: "",
      odeme_tipi: "",
      kur: "",
      kur_degeri: "",
      maliyet_tutar_kur: "",
      gercek_maliyet_tutar: "",
      gercek_maliyet_tutar_kur: "",
      fiyat_saglayici_gunluk_fiyatlar: "",
      fiyat_saglayici_rates: "",
      stop_durum: "",
      kismi_odeme_orani: "",
      fiyat_dusme: "",
      fiyat_dusme_bilgisi: null,
    },
    otel_odalari: [],
    balayi_var_mi: "",
  },
  reservationOtherRooms: [],
  editReservation: {
    rezervasyon_id: 0,
    rezervasyon_kodu: "",
    ana_rez_kod: 0,
    satici_id: 0,
    otel_adi: "",
    otel_id: 0,
    oda_adi: "",
    oda_id: 0,
    pansiyon_adi: "",
    pansiyon_id: 0,
    giris_tarihi: "",
    cikis_tarihi: "",
    musteri_tc_kimlik: "",
    musteri_ad_soyad: "",
    musteri_cep: "",
    musteri_tel: "",
    musteri_eposta: "",
    toplam_tutar: "",
    kartla_odeme: "",
    havaleyle_odeme: "",
    fatura_unvani: "",
    fatura_adresi: "",
    vergi_dairesi: "",
    vergi_no: "",
    aciklama: "",
    request: "",
    request_acenta: 0,
    konfirme_edildi_mi: "",
    konfirme_alis_zamani: "",
    iptal_edildi_mi: "",
    kayit_tarihi: "",
    musaitlik: "",
    musaitlik_aciklama: "",
    musaitlik_alan: 0,
    musaitlik_alis_zamani: "",
    rez_tipi: "",
    rez_durum: "",
    operator_firma: "",
    fatura_no: "",
    satis_operator: "",
    fiyat_hesaplama: "",
    karsilama_kodu: "",
    inet_no_show_durum: "",
    iptal_sigortasi: "",
    satici_adi: "",
    ucretli_cocuk_durum: "",
    kaporali_satis_orani: "",
    farkli_acenteden_satis_durum: "",
    uygulanan_indirimler: [],
    verilen_hediyeler: "",
    komisyon_faturali_rezervasyon: "",
    fiyat_saglayici: "",
    fiyat_saglayici_confirm_durum: "",
    rezervasyon_giris_saat_farki: 0,
    fiyat_saglayici_iptal_talebi: "",
    fiyat_saglayici_iptal_talebi_zamani: null,
    eski_rezervasyon: "",
    inet_otel_kodu: "",
    otel_parapuan_orani: "",
    kismi_odeme_durum: "",
    kismi_odeme_tarihi: "",
    alt_acente_satisi: "",
    alt_acente_komisyon_tutar: "",
    kendisine_goster: 0,
    kart_goster: "",
    misafirler: [],
    yetiskin_sayisi: 0,
    cocuk_sayisi: 0,
    kk: [],
    havale: "",
    nakit: "",
    tahsilat_bilgileri: [],
    muhasebe_bilgileri: {
      misafir_fatura_no: "",
      misafir_fatura_id: "",
      muhasebe_tahsilat_onay: "",
      toplam_tahsilat_tutar: "",
      toplam_otel_onaylanan_tahsilat_tutar: "",
      komisyon_orani: "",
      indirim_orani: "",
      para_birimi: "",
      kur: "",
      toplam_cekilen_tutar: "",
      toplam_otel_brut_tutar: "",
      toplam_otel_indirimli_tutar: "",
      toplam_otel_cekilen_tutar: "",
      toplam_otel_maliyet_tutar: "",
      toplam_otel_maliyet_tutar_kur: "",
      toplam_otel_gercek_maliyet_tutar: "",
      toplam_otel_gercek_maliyet_tutar_kur: "",
      toplam_otel_komisyon_tutar: "",
      toplam_transfer_cekilen_tutar: "",
      toplam_transfer_maliyet_tutar: "",
      toplam_transfer_komisyon_tutar: "",
      otele_odeme_gunu: "",
      otel_fatura_no: "",
      otele_odeme_durum: "",
      otele_odeme_tutar: "",
      cari_durum_result: "",
    },
    finans_bilgileri: {
      finans_id: 0,
      komisyon_orani: "",
      indirim_orani: "",
      para_birimi: "",
      brut_tutar: "",
      maliyet_tutar: "",
      indirimli_tutar: "",
      komisyon_tutar: "",
      indirim_tutar: "",
      iptal_sigortasi_tutar: "",
      odeme_tipi: "",
      kur: "",
      kur_degeri: "",
      maliyet_tutar_kur: "",
      gercek_maliyet_tutar: "",
      gercek_maliyet_tutar_kur: "",
      fiyat_saglayici_gunluk_fiyatlar: "",
      fiyat_saglayici_rates: "",
      stop_durum: "",
      kismi_odeme_orani: "",
      fiyat_dusme: "",
      fiyat_dusme_bilgisi: null,
    },
    otel_odalari: [],
    balayi_var_mi: "",
  },
  formChoices: {
    otel_adi: [],
    oda_pansiyon_tipi: [],
    yetiskin_sayisi: [
      {
        label: "1 Kişi",
        value: 1,
      },
      {
        label: "2 Kişi",
        value: 2,
      },
      {
        label: "3 Kişi",
        value: 3,
      },
      {
        label: "4 Kişi",
        value: 4,
      },
      {
        label: "5 Kişi",
        value: 5,
      },
      {
        label: "6 Kişi",
        value: 6,
      },
      {
        label: "7 Kişi",
        value: 7,
      },
      {
        label: "8 Kişi",
        value: 8,
      },
    ],
    cocuk_sayisi: [
      {
        label: "1 Çocuk",
        value: 1,
      },
      {
        label: "2 Çocuk",
        value: 2,
      },
      {
        label: "3 Çocuk",
        value: 3,
      },
      {
        label: "4 Çocuk",
        value: 4,
      },
    ],
  },
  isLoading: false,
  error: "",
};

const editReservationSlice = createSlice({
  name: "editReservation",
  initialState,
  reducers: {
    handleReservationData(state, action) {
      const [first, ...rest] = action.payload;
      state.reservation = first;
      state.reservationOtherRooms = rest;

      console.log("reservation : ", state.reservation);
    },
    handleFormChoices(state, action) {
      state.formChoices = action.payload;
    },
    handleChangeInput(state, action) {
      state.editReservation = {
        ...state.editReservation,
        ...action.payload,
      };
      console.log("input is changing: ", action.payload);
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleReservationData,
  handleFormChoices,
  handleChangeInput,
  setIsLoading,
  setError,
} = editReservationSlice.actions;

export default editReservationSlice.reducer;
