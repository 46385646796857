import Cards from "react-credit-cards-2";

import CreditCardForm from "./CreditCardForm";
import InstallmentsList from "./InstallmentsList";

import useHandleCard from "./useHandleCard";
import useHandleInstallments from "./useHandleInstallments";

import { CreditCardInstallment, TranslatedContent } from "./types";
import { CreditCard } from "./schemas";

import styles from "./CreditCard.module.scss";

interface CustomCreditCardProps {
  formId: string;
  makePayment: (card: CreditCard, installment: CreditCardInstallment) => void;
  makePaymentLoading: boolean;
  reservationId: string;
  priceId: number;
  getCardInfoUrl: string;
  common?: TranslatedContent[];
  needApproval?: boolean;
}

export function CustomCreditCard({
  formId,
  makePayment,
  makePaymentLoading,
  reservationId,
  priceId,
  getCardInfoUrl,
  common = [],
  needApproval = false,
}: CustomCreditCardProps) {
  const {
    getInstallments,
    resetInstallments,
    hasInstallments,
    installments,
    isLoading: installmentsLoading,
    cardBrand,
    invalidCardNumber,
  } = useHandleInstallments(reservationId, priceId, getCardInfoUrl);

  const {
    isSubmitted,
    handleSubmit,
    errors,
    state,
    handleInputChange,
    handleInputFocus,
    isSubmitButtonDisabled,
    getCardInstallments,
    selectedInstallment,
    onSelectedInstallment,
    onPasteCardNumber,
  } = useHandleCard(
    makePayment,
    formId,
    installments,
    getInstallments,
    resetInstallments,
    needApproval
  );

  const callback = () => {
    getCardInstallments(state.cardNumber, getInstallments, resetInstallments);
  };

  const translatedCardOwner =
    common.find((content) => content.original === "Kart Sahibi")?.translated ??
    "Kart Sahibi";

  const translatedExpiryDate =
    common.find((content) => content.original === "AA/YY")?.translated ??
    "AA/YY";

  return (
      <section className={styles.container}>
        <section className={styles["card-container"]}>
          <Cards
            number={state.cardNumber}
            name={state.cardOwner}
            expiry={state.expiryDate}
            cvc={state.cvc}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            focused={state.focus as any}
            placeholders={{
              name: translatedCardOwner,
            }}
            locale={{ valid: translatedExpiryDate }}
            callback={callback}
          />
        </section>
        <CreditCardForm
          id={formId}
          onSubmit={handleSubmit}
          isSubmitted={isSubmitted}
          errors={errors}
          state={state}
          handleInputChange={handleInputChange}
          handleInputFocus={handleInputFocus}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
          installmentsLoading={installmentsLoading}
          cardBrand={cardBrand}
          invalidCardNumber={invalidCardNumber}
          selectedInstallment={selectedInstallment}
          makePaymentLoading={makePaymentLoading}
          onPaste={onPasteCardNumber}
          common={common}
        >
          <InstallmentsList
            hasInstallments={hasInstallments}
            installments={installments}
            selectedInstallment={selectedInstallment}
            onSelectedInstallment={onSelectedInstallment}
            common={common}
          />
        </CreditCardForm>
      </section>
  );
}
