import {
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { AddCommentFormErrors, AddCommentState } from "types/comments";
import { handleChangeCommentInput } from "slices/comments/addComment/reducer"

interface Option {
  value: string;
  label: string;
}
interface CommentCategorySelectorProps {
  values: AddCommentState;
  formErrors: AddCommentFormErrors;
  validateFormChange: (values: AddCommentState) => void;
  commentCategoryOptions: Option[];
}


const CommentCategorySelector = ({
  values,
  formErrors,
  validateFormChange,
  commentCategoryOptions,
}: CommentCategorySelectorProps) => {

  const dispatch: Dispatch<any> = useDispatch();

  return (
    <CustomFormFieldContainer
      label="Yorum Kategorisi"
      error={formErrors.kategori}
      divider
      required
    >
      <Select
        value={
          commentCategoryOptions.find(
            (option) => option.value === values.kategori
          ) || null
        }
        onChange={(selected: Option) => {
          dispatch(
            handleChangeCommentInput({
              kategori: selected.value,
            })
          );
          validateFormChange({ ...values, kategori: selected.value });
        }}
        options={commentCategoryOptions}
        placeholder="Yorum Kategorisi"
      />
    </CustomFormFieldContainer>
  );
};
export default CommentCategorySelector;
