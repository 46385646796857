import ReservationCard from "./ReservationCard";
import { ANALYSIS_COLORS } from "utils/analysisColors";

const icons = {
  money: "ri-wallet-3-line",
  room: "ri-hotel-bed-line",
  hotel: "ri-hotel-line",
};

interface ReservationItem {
  grup_adi: string;
  satis_sayi: number;
  gerceklesen_tutar: number;
  beklenen_tutar: number;
  iptal_tutar: number;
}

interface ReservationCardRowProps {
  item: ReservationItem;
}


const ReservationCardRow = ({ item }: ReservationCardRowProps) => {
  const cards = [
    {
      title: `${item.grup_adi} Satış Sayısı`,
      value: `${item.satis_sayi} Adet`,
      color: ANALYSIS_COLORS.blue,
      cardIcon: icons.hotel,
    },
    {
      title: `${item.grup_adi} Tamamlanan`,
      value: `${item.gerceklesen_tutar} TL`,
      color: ANALYSIS_COLORS.green,
      cardIcon: icons.money,
    },
    {
      title: `${item.grup_adi} Bekleyen`,
      value: `${item.beklenen_tutar} TL`,
      color: ANALYSIS_COLORS.yellow,
      cardIcon: icons.money,
    },
    {
      title: `${item.grup_adi} İptal`,
      value: `${item.iptal_tutar} TL`,
      color: ANALYSIS_COLORS.red,
      cardIcon: icons.money,
    },
  ];

  return (
    <div
      style={{
        display: "grid",
        gap: "16px",
        gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
        marginBottom: "24px",
      }}
    >
      {cards.map((card, index) => (
        <ReservationCard
          key={index}
          title={card.title}
          value={card.value.toString()}
          color={card.color}
          cardIcon={card.cardIcon}
        />
      ))}
    </div>
  );
};

export default ReservationCardRow;
