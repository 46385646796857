import { CustomPageDescriptions } from "Components/Custom/UI";
import CustomReservationMonitoringCard from "./CustomReservationMonitoringCard";
import ReservationMonitoringTable from "./ReservationMonitoringTable";
import Timer from "./Timer";
import useHandleCards from "./useHandleCards";
import { OPERATION_RESERVATION_MONITORING_PAGE_DESCRIPTION } from "utils/pageDescriptions";

interface OperationReservationMonitoringPageProps {
  isLoading: boolean;
}

const OperationReservationMonitoringPage = ({
  isLoading,
}: OperationReservationMonitoringPageProps) => {
  const { data, selectedTable, handleOnClick, countdown } = useHandleCards();

  const description = OPERATION_RESERVATION_MONITORING_PAGE_DESCRIPTION.map(
    (description, index) => (
      <div
        key={index}
        dangerouslySetInnerHTML={{ __html: description }}
        className="user-select-none"
      />
    )
  );

  return (
    <>
      <div className="d-flex justify-content-between gap-2">
        <CustomPageDescriptions descriptions={description} />
        <Timer countdown={countdown} />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          alignItems: "center",
          gap: "12px",
        }}
      >
        {data.map((card, index) => (
          <CustomReservationMonitoringCard
            key={index}
            title={card.title}
            info={card.info}
            link={card.link}
            tableCode={card.tableCode}
            onClick={handleOnClick}
            selectedTable={selectedTable}
          />
        ))}
      </div>
      <ReservationMonitoringTable
        selectedTable={selectedTable}
        isLoading={false}
      />
    </>
  );
};

export default OperationReservationMonitoringPage;
