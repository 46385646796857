import {
  CustomButton,
  CustomFormContainer,
  CustomFormFieldContainer,
} from "Components/Custom/UI";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import moment from "moment";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import {
  EditReservationData,
  EditReservationFormChoices,
} from "types/reservation";
import { Input } from "reactstrap";

interface Option {
  value: number;
  label: string;
}

interface AddNewRoomFormProps {
  reservation: EditReservationData;
  formChoices: EditReservationFormChoices;
  handleChangeInput: (key: string, value: any) => void;
  handleAddNewRoom: () => void;
}

const AddNewRoomForm = ({
  reservation,
  formChoices,
  handleChangeInput,
  handleAddNewRoom,
}: AddNewRoomFormProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <CustomFormContainer title="Eklenen Yeni Oda Bilgileri">
      <CustomFormFieldContainer
        label="Oda/Pansiyon Değiştir"
        divider
        orientation="vertical"
      >
        <Select
          options={[]}
          value={formChoices.oda_pansiyon_tipi.find(
            (option) => option.value === reservation.pansiyon_adi
          )}
          onChange={(selectedOption: Option) =>
            handleChangeInput("oda_pansiyon_tipi", selectedOption.value)
          }
        />
      </CustomFormFieldContainer>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "3rem",
        }}
      >
        <CustomFormFieldContainer
          label="Yetişkin Sayısı"
          divider
          orientation="vertical"
        >
          <Select
            options={formChoices.yetiskin_sayisi}
            value={formChoices.yetiskin_sayisi.find(
              (option) => option.value === reservation.yetiskin_sayisi
            )}
            onChange={(selectedOption: Option) =>
              handleChangeInput("yetiskin_sayisi", selectedOption.value)
            }
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="Çocuk Sayısı"
          divider
          orientation="vertical"
        >
          <Select
            options={formChoices.cocuk_sayisi}
            value={formChoices.cocuk_sayisi.find(
              (option) => option.value === reservation.cocuk_sayisi
            )}
            onChange={(selectedOption: Option) =>
              handleChangeInput("cocuk_sayisi", selectedOption.value)
            }
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="1. Çocuk Doğum Tarihi"
          divider
          orientation="vertical"
        >
          <Flatpickr
            className="form-control"
            value={reservation.giris_tarihi}
            onChange={(date) => {
              if (date) {
                const stringStartDate = moment(date).format("YYYY-MM-DD");

                handleChangeInput("giris_tarihi", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              minDate: moment().subtract(1, "days").toDate(),
              onDayCreate,
            }}
            placeholder="1. Çocuk Doğum Tarihi"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="2. Çocuk Doğum Tarihi"
          divider
          orientation="vertical"
        >
          <Flatpickr
            className="form-control"
            value={reservation.giris_tarihi}
            onChange={(date) => {
              if (date) {
                const stringStartDate = moment(date).format("YYYY-MM-DD");

                handleChangeInput("giris_tarihi", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              minDate: moment().subtract(1, "days").toDate(),
              onDayCreate,
            }}
            placeholder="2. Çocuk Doğum Tarihi"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="3. Çocuk Doğum Tarihi"
          divider
          orientation="vertical"
        >
          <Flatpickr
            className="form-control"
            value={reservation.giris_tarihi}
            onChange={(date) => {
              if (date) {
                const stringStartDate = moment(date).format("YYYY-MM-DD");

                handleChangeInput("giris_tarihi", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              minDate: moment().subtract(1, "days").toDate(),
              onDayCreate,
            }}
            placeholder="3. Çocuk Doğum Tarihi"
          />
        </CustomFormFieldContainer>
        <CustomFormFieldContainer
          label="4. Çocuk Doğum Tarihi"
          divider
          orientation="vertical"
        >
          <Flatpickr
            className="form-control"
            value={reservation.giris_tarihi}
            onChange={(date) => {
              if (date) {
                const stringStartDate = moment(date).format("YYYY-MM-DD");

                handleChangeInput("giris_tarihi", stringStartDate);
              }
            }}
            options={{
              dateFormat: "Y-m-d",
              locale: Turkish,
              minDate: moment().subtract(1, "days").toDate(),
              onDayCreate,
            }}
            placeholder="4. Çocuk Doğum Tarihi"
          />
        </CustomFormFieldContainer>
      </div>
      <CustomFormFieldContainer
        label="Misafirin rezervasyonla ilgili talepleri:"
        required
        divider
        orientation="vertical"
      >
        <Input
          type="text"
          value={reservation.musteri_ad_soyad}
          onChange={(e) => {
            handleChangeInput("", e.target.value);
          }}
        />
      </CustomFormFieldContainer>
      <CustomFormFieldContainer
        label="Rezervasyonla ilgili Acenta talepleri:"
        required
        divider
        orientation="vertical"
      >
        <Input
          type="text"
          value={reservation.musteri_ad_soyad}
          onChange={(e) => {
            handleChangeInput("", e.target.value);
          }}
        />
      </CustomFormFieldContainer>
      <div className="d-flex gap-3">
        <CustomButton variant="danger" onClick={() => {}}>
          Fiyat Hesapla
        </CustomButton>
      </div>
    </CustomFormContainer>
  );
};

export default AddNewRoomForm;
