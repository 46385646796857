import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import { GET_RESERVATION_CALL_HOTELS_AND_LOCATIONS } from "helpers/url_helper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { EditReservationInitialState, handleChangeInput } from "slices/reservation/editReservation/reducer";
import { setToastError } from "slices/toast/reducer";
import { EditReservationData, ReservationCallLocation } from "types/reservation";

const useHandleData = () => {
  const dispatch: Dispatch<any> = useDispatch();
  
  const { reservation, reservationOtherRooms, formChoices } = useSelector(
    (state: { EditReservation: EditReservationInitialState }) =>
      state.EditReservation
  );

  const [addNewRoom, setAddNewRoom] = useState(false);

  const [query, setQuery] = useState("");

  const loadOptions = async () => {
    if (query.length <= 1) {
      return [];
    }

    try {
      const response = await axios.post(
        GET_RESERVATION_CALL_HOTELS_AND_LOCATIONS,
        {
          term: query,
          platform: "crm",
        }
      );
      const res: ReservationCallLocation[] = (response as any).data || response;

      const options = res.reduce(
        (acc: any, item: ReservationCallLocation) => {
          if (item.category === "Otel") {
            acc[0].options.push({
              value: item.id,
              label: item.label,
              category: item.category,
            });
          } else {
            acc[1].options.push({
              value: item.id,
              label: item.label,
              category: item.category,
            });
          }
          return acc;
        },
        [
          {
            label: "Otel",
            options: [],
          },
          {
            label: "Lokasyon",
            options: [],
          },
        ]
      );

      return options;
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setToastError(message));
      });
      return [];
    }
  };

  const handleChangeQuery = (value: string) => {
    setQuery(value);
  };

  const handleFormChange = (
    field: keyof EditReservationData,
    value: string
  ) => {
    dispatch(handleChangeInput({ field, value }));
  };

  return {
    reservation,
    reservationOtherRooms,
    formChoices,
    addNewRoom,
    setAddNewRoom,
    handleFormChange,
    loadOptions,
    handleChangeQuery,
  };
};

export default useHandleData;
