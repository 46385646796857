import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { ListCostRevisePayload } from "types/reservationMonitoring";

interface DateInputProps {
  values: ListCostRevisePayload;
  handleFormChange: (key: string, value: string) => void;
}

const DateInput = ({ values, handleFormChange }: DateInputProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer label="Tarih">
        <Flatpickr
          className="form-control"
          value={values.date}
          onChange={(dates) => {
            const date = dates[0] ;
            if (date) {
              const stringDate = moment(date).format("YYYY-MM-DD");

              handleFormChange("date", stringDate);
            }
          }}
          options={{
            mode: "single",
            dateFormat: "Y-m-d",
            // showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default DateInput;
