import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";
import AnswerRequestModal from "./Modals/AnswerRequestModal";
import { useState } from "react";
import UpdateStatusModal from "./Modals/UpdateStatusModal";

interface ListOnRequestReservationsTableProps {
  isLoading: boolean;
}

const ListOnRequestReservationsTable = ({
  isLoading,
}: ListOnRequestReservationsTableProps) => {
  const [selectedReservationId, setSelectedReservationId] = useState(0);
  const [showAnswerRequestModal, setShowAnswerRequestModal] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);

  const {
    tableData,
    tableColumns,
    legend,
    reservations,
    handleAnswerRequest,
    handleUpdateStatus,
  } = useHandleTable(
    setSelectedReservationId,
    setShowAnswerRequestModal,
    setShowUpdateStatusModal
  );

  return (
    <>
      <CustomReactTable
        tableData={tableData}
        tableColumns={tableColumns}
        loading={isLoading}
        legend={legend}
      />
      {selectedReservationId !== 0 && (
        <AnswerRequestModal
          reservations={reservations}
          reservation_id={selectedReservationId}
          isOpen={showAnswerRequestModal}
          handleToggle={() =>
            setShowAnswerRequestModal((prevState) => !prevState)
          }
          answerRequest={handleAnswerRequest}
        />
      )}
      {selectedReservationId !== 0 && (
        <UpdateStatusModal
          reservations={reservations}
          reservation_id={selectedReservationId}
          isOpen={showUpdateStatusModal}
          handleToggle={() =>
            setShowUpdateStatusModal((prevState) => !prevState)
          }
          updateStatus={handleUpdateStatus}
        />
      )}
    </>
  );
};

export default ListOnRequestReservationsTable;
