import { useSelector } from "react-redux";
import { ListHotelsWithoutCommentInitialState } from "slices/comments/listHotelsWithoutComment/reducer";

const useHandleTable = () => {
  const { hotels } = useSelector(
    (state: { ListHotelsWithoutComment: ListHotelsWithoutCommentInitialState }) => state.ListHotelsWithoutComment
  );

  const tableData = hotels?.map((hotel) => {

    console.log('Comment data:', hotel);

    return {
      otel_adi: hotel.otel_adi,
    };
  });

  const tableColumns = [
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    // {
    //   header: "İşlemler",
    //   accessorKey: "yorum_id",
    //   enableColumnFilter: false,
    //   cell: (cell: any) => {
    //     const id = cell.getValue();
    //     return (
    //       <>
    //         <div className="d-flex justify-content-center gap-2">
    //           <CustomRowOperationButton
    //             variant="update"
    //             onClick={() => {
    //               onToggleUpdateCommentModal(id);
    //             }}
    //             title="Yorumu güncelle"
    //           />
    //           <CustomRowOperationButton
    //             variant="info"
    //             onClick={() => {
    //               onShowDetail(id);
    //             }}
    //             title="Rezervasyon detayı görüntüle"
    //           />
    //           <CustomRowOperationButton
    //             variant="check"
    //             onClick={() => {
    //               onApproveComment(id);
    //             }}
    //             title="Yorumu onayla"
    //           />
    //           <CustomRowOperationButton
    //             variant="delete"
    //             onClick={() => {
    //               onDeleteComment(id);
    //             }}
    //             title="Yorumu devre dışı bırak"
    //           />
    //         </div>
    //         <UpdateCommentModal
    //           yorum_id={id}
    //           isOpen={showUpdateCommentModal[id] || false}
    //           handleToggle={() => onToggleUpdateCommentModal(id)}
    //           updateComment={() =>
    //             onUpdateComment({
    //             })
    //           }
    //           deleteComment={() => {
    //             onDeleteComment(id);
    //           }}
    //         />
    //       </>
    //     );
    //   },
    // },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
