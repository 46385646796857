import { CustomButton, CustomModal } from "Components/Custom/UI";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ModalInfo from "./ModalInfo";
import {
  CalculatePriceForHotel,
  CalculatePriceFormValues,
  CreateOnRequestPayload,
} from "types/reservation";

interface OnRequestModalProps {
  // onRequestForm: CreateOnRequestPayload;
  // setOnRequestForm: Dispatch<SetStateAction<CreateOnRequestPayload>>;
  hotelInfo: CalculatePriceForHotel;
  calculationForm: CalculatePriceFormValues;
  formInfo: CalculatePriceFormValues;
  hotel_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  handleCreateRequest: (payload: CreateOnRequestPayload) => void;
}

const OnRequestModal = ({
  // onRequestForm,
  // setOnRequestForm,
  hotelInfo,
  calculationForm,
  formInfo,
  hotel_id,
  isOpen,
  handleToggle,
  handleCreateRequest,
}: OnRequestModalProps) => {
  const [onRequestForm, setOnRequestForm] = useState<CreateOnRequestPayload>({
    otel_id: hotel_id,
    otel_adi: hotelInfo.otel_adi ?? "",
    giris_tarihi: "",
    cikis_tarihi: "",
    yetiskin_sayisi: 0,
    cocuk_sayisi: 0,
    mesaj: "",
    cocuk_dogum_1: "",
    cocuk_dogum_2: "",
    cocuk_dogum_3: "",
    cocuk_dogum_4: "",
  });

  useEffect(() => {
    setOnRequestForm({
      otel_id: hotel_id,
      otel_adi: hotelInfo.otel_adi ?? "",
      giris_tarihi: formInfo.giris_tarihi ?? "",
      cikis_tarihi: formInfo.cikis_tarihi ?? "",
      yetiskin_sayisi: formInfo.yetiskin_sayisi ?? 0,
      cocuk_sayisi: formInfo.cocuk_sayisi ?? 0,
      mesaj: "",
      cocuk_dogum_1: calculationForm.cocuk_dogum_1 ?? "",
      cocuk_dogum_2: calculationForm.cocuk_dogum_2 ?? "",
      cocuk_dogum_3: calculationForm.cocuk_dogum_3 ?? "",
      cocuk_dogum_4: calculationForm.cocuk_dogum_4 ?? "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelInfo, formInfo, hotel_id]);

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="lg">
        <ModalHeader>SorSat Yap</ModalHeader>
        <ModalInfo hotelInfo={hotelInfo} formInfo={formInfo} />
        <ModalBody>
          <FormGroup row>
            <Label for="aciklama" sm={3} className="control-label">
              Açıklama
            </Label>
            <Col sm={9}>
              <Input
                id="aciklama"
                type="textarea"
                placeholder="Açıklama"
                rows={3}
                value={onRequestForm.mesaj}
                onChange={(e) =>
                  setOnRequestForm({ ...onRequestForm, mesaj: e.target.value })
                }
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="primary"
            onClick={() =>
              handleCreateRequest({
                ...onRequestForm,
              })
            }
            isLoading={false}
          >
            Kaydet
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default OnRequestModal;
