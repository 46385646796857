import ListCostRevisePage from "Components/Custom/ReservationMonitoringPages/ListCostRevisePage";
import useListCostRevise from "Components/Hooks/reservationMonitoring/useListCostRevise";
import PageContainer from "pages/PageContainer";

const ListCostRevise = () => {
  const { isLoading } = useListCostRevise();

  return (
    <PageContainer title="Maliyet Revize Listesi">
      <ListCostRevisePage isLoading={isLoading} />
    </PageContainer>
  );
};

export default ListCostRevise;
