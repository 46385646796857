import HotelsWithoutPriceTable from "./HotelsWithoutPriceTable";

interface HotelsWithoutPricePageProps {
    isLoading: boolean;
}

const HotelsWithoutPricePage = ({isLoading} : HotelsWithoutPricePageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <HotelsWithoutPriceTable isLoading={isLoading} />
    </div>
  );
};

export default HotelsWithoutPricePage;
