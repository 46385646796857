import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveGroupReservationsResponse,
  setIsLoading,
  setError,
  setUpdateStatusLoading,
  setUpdateStatusError,
  setMarkAsResponsibleLoading,
  setMarkAsResponsibleError,
  setChangeStatusLoading,
  setChangeStatusError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  APPROVE_GROUP_RESERVATION,
  CANCEL_GROUP_RESERVATION,
  GROUP_RESERVATIONS_MARK_AS_RESPONSIBLE,
  LIST_GROUP_RESERVATIONS,
  UPDATE_GROUP_RESERVATION_STATUS,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import {
  ChangeGroupReservationStatusPayload,
  MarkAsResponsibleGroupReservation,
  UpdateGroupReservationsPayload,
} from "types/reservationMonitoring";

type SuccessCallback = () => void;

export const listGroupReservations = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(LIST_GROUP_RESERVATIONS);

    dispatch(
      handleSaveGroupReservationsResponse(response.data || response)
    );
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateGroupReservation =
  (payload: UpdateGroupReservationsPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateStatusLoading(true));
      await axios.post(UPDATE_GROUP_RESERVATION_STATUS, payload);

      dispatch(setToastSuccess("Rezervasyon başarıyla güncellendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateStatusLoading(false));
    }
  };

export const markAsResponsible =
  (payload: MarkAsResponsibleGroupReservation, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setMarkAsResponsibleLoading(true));
      await axios.post(GROUP_RESERVATIONS_MARK_AS_RESPONSIBLE, payload);

      dispatch(setToastSuccess("Sorumlu başarıyla atandı."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMarkAsResponsibleError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMarkAsResponsibleLoading(false));
    }
  };

export const approveGroupReservation =
  (payload: ChangeGroupReservationStatusPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(APPROVE_GROUP_RESERVATION, payload);

      dispatch(setToastSuccess("Rezervasyon başarıyla onaylandı."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };

  export const cancelGroupReservation =
  (payload: ChangeGroupReservationStatusPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(CANCEL_GROUP_RESERVATION, payload);

      dispatch(setToastSuccess("Rezervasyon başarıyla iptal edildi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };
