import CreateNotificationButton from "./FormFields/CreateNotificationButton";
import MembersToSendNotificationList from "./FormFields/MembersToSendNotificationList";
import NotificationInput from "./FormFields/NotificationInput";
import NotificationTitleInput from "./FormFields/NotificationTitleInput";
import NotificationTypeSelector from "./FormFields/NotificationTypeSelector";
import useHandleForm from "./useHandleForm";
import { CustomFormContainer } from "Components/Custom/UI";

/*
 * MEMO: NotificationDateSelector commented out because the notification date will always be the
 *       notification creation date. If this field is added to the form again later, date fields
 *       need to be added to the endpoint.
 */

const CreateNewNotificationForm = () => {
  const {
    formChoices,
    values,
    formErrors,
    isLoading,
    validateForm,
    validateFormChange,
    handleSubmitted,
    requestPayload,
  } = useHandleForm();

  return (
    <CustomFormContainer title="Bildirim Oluştur">
      <NotificationTypeSelector
        values={values}
        formChoices={formChoices}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      {/* <NotificationDateSelector
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      /> */}
      <MembersToSendNotificationList
        values={values}
        formChoices={formChoices}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <NotificationTitleInput
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <NotificationInput
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
      />
      <div className="d-flex justify-content-end">
        <CreateNotificationButton
          values={values}
          requestPayload={requestPayload}
          validateForm={validateForm}
          handleSubmitted={handleSubmitted}
          isLoading={isLoading}
        />
      </div>
    </CustomFormContainer>
  );
};

export default CreateNewNotificationForm;
