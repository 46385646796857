import { RoomOption } from "types/reservation";
import AskToOperationButtons from "./AskToOperationButtons";
import RoomCard from "./RoomCard";
import useHandleRoomCards from "./useHandleRoomCards";

interface RoomCardsProps {
  toggleOnRequestModal: () => void;
  handleGroupReservationRequest: () => void;
  handleSelectRoomToCallLater: (
    oda_adi: string,
    pansiyon_adi: string,
    fiyat: string
  ) => void;
  selectedRoom: RoomOption;
}

const RoomCards = ({
  toggleOnRequestModal,
  handleGroupReservationRequest,
  handleSelectRoomToCallLater,
  selectedRoom,
}: RoomCardsProps) => {
  const { rooms } = useHandleRoomCards();

  return (
    <div className="d-flex flex-column gap-4">
      <AskToOperationButtons
        toggleOnRequestModal={toggleOnRequestModal}
        handleGroupReservationRequest={handleGroupReservationRequest}
      />
      {rooms.map((room) => (
        <RoomCard
          key={room.oda_id}
          {...room}
          handleSelectRoomToCallLater={handleSelectRoomToCallLater}
          selectedRoom={selectedRoom}
        />
      ))}
    </div>
  );
};

export default RoomCards;
