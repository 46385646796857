import ListCancellationRequestsPage from "Components/Custom/ReservationMonitoringPages/ListCancellationRequestsPage";
import useListCancellationRequests from "Components/Hooks/reservationMonitoring/useListCancellationRequests";
import PageContainer from "pages/PageContainer";

const ListCancellationRequests = () => {
  const { isLoading } = useListCancellationRequests();

  return (
    <PageContainer title="İptal Talebi Listesi">
      <ListCancellationRequestsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default ListCancellationRequests;
