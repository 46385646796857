import ListPriceRevisePage from "Components/Custom/ReservationMonitoringPages/ListPriceRevisePage";
import useListPriceRevise from "Components/Hooks/reservationMonitoring/useListPriceRevise";
import PageContainer from "pages/PageContainer";

const ListPriceRevise = () => {
  const { isLoading } = useListPriceRevise();

  return (
    <PageContainer title="Fiyat Revize Listesi">
      <ListPriceRevisePage isLoading={isLoading} />
    </PageContainer>
  );
};

export default ListPriceRevise;
