import { CustomFormContainer } from "Components/Custom/UI";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import { DocumentCenterData } from "types/documents";

interface AccountingDocumentsProps {
  data: DocumentCenterData;
}

const AccountingDocuments = ({ data }: AccountingDocumentsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <CustomFormContainer>
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer bg-secondary py-1 px-3 text-white rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="fs-18 fw-bold m-0 ">Muhasebe</p>
        <i className={`ri-arrow-${isOpen ? "up" : "down"}-s-line fs-24`}></i>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="d-flex flex-column gap-2">
        {data.links.map((linkText, index) => (
            <Link
              key={index}
              to={data.pdf_file_routes[index] || ""}
              target="_blank"
              className="text-decoration-none fw-semibold fs-18 px-4"
            >
              {linkText}
            </Link>
          ))}
        </div>
      </Collapse>
    </CustomFormContainer>
  );
};

export default AccountingDocuments;
