import { createSlice } from "@reduxjs/toolkit";
import { INetNonentryListData } from "types/reservationLists";

export interface INetNonentryListInitialState {
  inetNonentry: INetNonentryListData[];
  isLoading: boolean;
  error: string;
}

const initialState: INetNonentryListInitialState = {
  inetNonentry: [],
  isLoading: false,
  error: "",
};

const inetNonentryListSlice = createSlice({
  name: "inetNonentryList",
  initialState,
  reducers: {
    handleSaveINetNonentryListResponse(state, action) {
      state.inetNonentry = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveINetNonentryListResponse, setIsLoading, setError } =
  inetNonentryListSlice.actions;

export default inetNonentryListSlice.reducer;
