import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleReservationData,
  // handleFormChoices,
  // handleChangeInput,
  setIsLoading,
  setError,
} from "./reducer";
import { 
  setToastError, 
  // setToastSuccess 
} from "slices/toast/reducer";
import { GET_EDIT_RESERVATION_DATA } from "helpers/url_helper";
import { Dispatch } from "redux";

// type SuccessCallback = () => void;

export const getEditReservationData =
  (reservationId: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(
        `${GET_EDIT_RESERVATION_DATA}?rezervasyon_id=${reservationId}`
      );

      console.log("response :", response)

      dispatch(handleReservationData(response.data || response));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

// export const filterCostReviseData =
//   (payload: ListCostRevisePayload, successCallback: SuccessCallback) =>
//   async (dispatch: Dispatch) => {
//     try {
//       dispatch(setIsLoading(true));
//       const response = await axios.post(FILTER_COST_REVISE, payload);

//       dispatch(handleSaveCostReviseResponse(response.data || response));
//       dispatch(setToastSuccess("Maliyet revizeler başarıyla listelendi."));
//       successCallback();
//     } catch (error) {
//       handleErrors(error, (message: string) => {
//         dispatch(setError(message));
//         dispatch(setToastError(message));
//       });
//     } finally {
//       dispatch(setIsLoading(false));
//     }
//   };
