import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { TomorrowEntranceWithDepositInitialState } from "slices/reservationMonitoring/tomorrowEntranceWithDeposit/reducer";
import { listTomorrowEntranceWithDeposit } from "slices/reservationMonitoring/tomorrowEntranceWithDeposit/thunk";


const useTomorrowEntranceWithDeposit = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { TomorrowEntranceWithDeposit: TomorrowEntranceWithDepositInitialState }) => state.TomorrowEntranceWithDeposit
  );

  useEffect(() => {
    dispatch(listTomorrowEntranceWithDeposit());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useTomorrowEntranceWithDeposit;
