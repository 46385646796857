import { createSlice } from "@reduxjs/toolkit";
import {
  GroupReservationsData,
  GroupReservationsResponseData,
} from "types/reservationMonitoring";

export interface GroupReservationsInitialState {
  groupReservations: GroupReservationsData[];
  isLoading: boolean;
  error: string;
  updateStatusLoading: boolean;
  updateStatusError: string;
  markAsResponsibleLoading: boolean;
  markAsResponsibleError: string;
  changeStatusLoading: boolean;
  changeStatusError: string;
}

const initialState: GroupReservationsInitialState = {
  groupReservations: [],
  isLoading: false,
  error: "",
  updateStatusLoading: false,
  updateStatusError: "",
  markAsResponsibleLoading: false,
  markAsResponsibleError: "",
  changeStatusLoading: false,
  changeStatusError: "",
};

const groupReservationsSlice = createSlice({
  name: "groupReservations",
  initialState,
  reducers: {
    handleSaveGroupReservationsResponse(state, action) {
      const payload = action.payload as GroupReservationsResponseData[];
      const groupReservations: GroupReservationsData[] = payload.map(
        (item) => ({
          id: item.id,
          musteri_ad_soyad: item.musteri_ad_soyad,
          otel_adi: item.otel_adi,
          otel_id: item.otel_id,
          musteri_cep: item.musteri_cep,
          musteri_eposta: item.musteri_eposta, // !
          giris_tarihi: item.giris_tarihi,
          cikis_tarihi: item.cikis_tarihi,
          opsiyon_tarihi: item.opsiyon_tarihi,
          yetiskin_sayisi: item.yetiskin_sayisi,
          kayit_yapan_ad: item.kayit_yapan_ad,
          kayit_tarihi: item.kayit_tarihi,
          islemler: item.islemler,
          konfirme_edildi_mi: item.konfirme_edildi_mi,
          iptal_edildi_mi: item.iptal_edildi_mi,
          guncelleme_yapan_ad: item.guncelleme_yapan_ad,
          islem_durum: item.islem_durum,
          operasyon_uye_ad_soyad: item.operasyon_uye_ad_soyad,
          ozel_istekler: item.ozel_istekler,
        })
      );
      state.groupReservations = groupReservations;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateStatusLoading(state, action) {
      state.updateStatusLoading = action.payload;
    },
    setUpdateStatusError(state, action) {
      state.updateStatusError = action.payload;
    },
    setMarkAsResponsibleLoading(state, action) {
      state.markAsResponsibleLoading = action.payload;
    },
    setMarkAsResponsibleError(state, action) {
      state.markAsResponsibleError = action.payload;
    },
    setChangeStatusLoading(state, action) {
      state.changeStatusLoading = action.payload;
    },
    setChangeStatusError(state, action) {
      state.changeStatusError = action.payload;
    },
  },
});

export const {
  handleSaveGroupReservationsResponse,
  setIsLoading,
  setError,
  setUpdateStatusLoading,
  setUpdateStatusError,
  setMarkAsResponsibleLoading,
  setMarkAsResponsibleError,
  setChangeStatusLoading,
  setChangeStatusError,
} = groupReservationsSlice.actions;

export default groupReservationsSlice.reducer;
