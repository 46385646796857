import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { ListCommentsInitialState } from "slices/comments/listComments/reducer";
import ReservationInfoRow from "./ReservationInfoRow";

interface UpdateCommentModalProps {
  yorum_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  updateComment: (olumlu_yorum: string, olumsuz_yorum: string) => void;
  deleteComment: (yorum_id: number) => void;
}

const UpdateCommentModal = ({
  yorum_id,
  isOpen,
  handleToggle,
  updateComment,
  deleteComment,
}: UpdateCommentModalProps) => {
  const { comments, updateCommentLoading } = useSelector(
    (state: { ListComments: ListCommentsInitialState }) => state.ListComments
  );

  const comment = comments.find((comment) => comment.yorum_id === yorum_id)!;

  const [positiveComment, setPositiveComment] = useState(
    comment.yorum_olumlu ?? ""
  );
  const [negativeComment, setNegativeComment] = useState(
    comment.yorum_olumsuz ?? ""
  );

  useEffect(() => {
    setPositiveComment(comment.yorum_olumlu ?? "");
    setNegativeComment(comment.yorum_olumsuz ?? "");
  }, [comment]);

  const handleUpdate = () => {
    updateComment(positiveComment, negativeComment);
  };

  const handleDelete = () => {
    deleteComment(yorum_id);
  };

  const commentAdditionalData = {
    commenterInfo: [
      { label: "Otel Adı", value: comment.otel_adi ?? "" },
      { label: "Müşteri Adı", value: comment.musteri_adi ?? "" },
      {
        label: "Konaklama Tarihleri",
        value: `${comment.giris_tarihi} - ${comment.cikis_tarihi}`,
      },
      { label: "Kategori", value: comment.kategori },
    ],
    scores: [
      { label: "Personel İletişim", value: comment.cevap1 ?? "" },
      { label: "Temizlik", value: comment.cevap2 ?? "" },
      { label: "Konum & Çevre", value: comment.cevap3 ?? "" },
      { label: "Restorant & Yemek", value: comment.cevap4 ?? "" },
      { label: "Odalar", value: comment.cevap5 ?? "" },
      { label: "Fiyat / Kalite", value: comment.cevap6 ?? "" },
      { label: "Ortalama Puan", value: comment.cevap7 ?? "" },
    ],
  };

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="lg">
        <ModalHeader>Yorumu Güncelle</ModalHeader>
        <ReservationInfoRow values={commentAdditionalData} />
        <ModalBody>
          <Label>Olumlu Yorum</Label>
          <Input
            type="textarea"
            placeholder="Olumlu yorumu güncelle"
            value={positiveComment}
            onChange={(e) => setPositiveComment(e.target.value)}
          />
          <Label>Olumsuz Yorum</Label>
          <Input
            type="textarea"
            placeholder="Olumsuz yorumu güncelle"
            value={negativeComment}
            onChange={(e) => setNegativeComment(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="warning"
            onClick={handleToggle}
          >
            Vazgeç
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="danger"
            onClick={handleDelete}
          >
            Sil
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="success"
            onClick={handleUpdate}
            isLoading={updateCommentLoading}
          >
            Yorumu Güncelle
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default UpdateCommentModal;
