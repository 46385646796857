import { useCallback } from "react";

export const useNumberFormatter = () => {
  const formatNumber = useCallback((value: string | number): string => {
    const number = typeof value === "number" ? value : parseFloat(value);

    if (isNaN(number)) {
      throw new Error("Invalid number format");
    }

    const [integerPart, decimalPart] = number.toFixed(2).split(".");
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      "."
    );

    return `${formattedIntegerPart},${decimalPart}`;
  }, []);

  return { formatNumber };
};
