import TemporaryOnlineReservationsPage from "Components/Custom/ReservationMonitoringPages/TemporaryOnlineReservationsPage";
import useTemporaryOnlineReservations from "Components/Hooks/reservationMonitoring/useTemporaryOnlineReservations";
import PageContainer from "pages/PageContainer";

const TemporaryOnlineReservations = () => {
  const { isLoading } = useTemporaryOnlineReservations();

  return (
    <PageContainer title="Geçici Online Rezervasyonlar">
      <TemporaryOnlineReservationsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default TemporaryOnlineReservations;
