import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface IncomingTransfersTableProps {
  isLoading: boolean;
}

const IncomingTransfersTable = ({ isLoading }: IncomingTransfersTableProps) => {
  const { tableData, tableColumns} = useHandleTable();

  return (
    <CustomReactTable
      pagination={true}
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
    />
  );
};

export default IncomingTransfersTable;
