import { useSelector } from "react-redux";
import { TotalReservationDataInitialState } from "slices/reservationLists/totalReservationData/reducer";

const useHandleReservationData = () => {
  const { totalReservations } = useSelector(
    (state: { TotalReservationData: TotalReservationDataInitialState }) =>
      state.TotalReservationData
  );


  return {
    cards : totalReservations,
  };
};

export default useHandleReservationData;
