import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import { handleChangeInput } from "slices/reservation/reviewReservation/reducer";
import {
  ReservationReviewCollection,
  ReservationReviewCollectionFormErrors,
} from "types/reservation";

interface InstallmentDateProps {
  values: ReservationReviewCollection;
  formErrors: ReservationReviewCollectionFormErrors;
  validateFormChange: (values: ReservationReviewCollection) => void;
}

const InstallmentDate = ({
  values,
  formErrors,
  validateFormChange,
}: InstallmentDateProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { onDayCreate } = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer
        label="Tahsilat Tarihi "
        error={formErrors.cekim_tarihi}
        required
        divider
        orientation="vertical"
      >
        <Flatpickr
          className="form-control"
          value={values.cekim_tarihi}
          onChange={(date) => {
            if (date) {
              const stringDate = moment(date).format("DD-MM-YYYY");

              dispatch(
                handleChangeInput({
                  cekim_tarihi: stringDate,
                })
              );
              validateFormChange({
                ...values,
                cekim_tarihi: stringDate,
              });
            }
          }}
          options={{
            mode: "single",
            dateFormat: "d-m-Y",
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default InstallmentDate;
