import { useCallback, useEffect, useState } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import DnDCard from "./DnDCard";
import ImageContainer from "./ImageContainer";

interface CardItem {
  label: string;
  value: Object;
}

interface Card {
  id: number;
  item: CardItem;
  isPhoto: boolean;
}

interface CustomDnDOrderingProps {
  items: any[];
  labelKey?: string;
  photoKey?: string;
  orderKey: string;
  handleOrderItems: (items: any[]) => void;
  selectedItems?: string[];
  handleSelectItem?: (item: string) => void;
  handleDelete?: (items: string[]) => void;
  numbersOfFixedItems?: number;
}

const CustomDnDOrdering = ({
  items,
  labelKey,
  photoKey,
  orderKey,
  handleOrderItems,
  selectedItems = [],
  handleSelectItem = () => {},
  handleDelete = () => {},
  numbersOfFixedItems = -1,
}: CustomDnDOrderingProps) => {
  const [cards, setCards] = useState<Card[]>(
    items.map((item: any) => ({
      id: item[orderKey],
      item: {
        label: labelKey ? (
          item[labelKey]
        ) : photoKey ? (
          <ImageContainer
            src={item[photoKey]}
            alt={labelKey ? item[labelKey] : ""}
            selected={selectedItems.includes(item[photoKey])}
            handleSelectItem={handleSelectItem}
            handleDelete={handleDelete}
          />
        ) : (
          item
        ),
        value: item,
      },
      isPhoto: photoKey ? true : false,
    }))
  );

  useEffect(() => {
    // photoKey &&
      setCards(
        items.map((item: any) => ({
          id: item[orderKey],
          item: {
            label: labelKey ? (
              item[labelKey]
            ) : photoKey ? (
              <ImageContainer
                src={item[photoKey]}
                alt={labelKey ? item[labelKey] : ""}
                selected={selectedItems.includes(item[photoKey])}
                handleSelectItem={handleSelectItem}
                handleDelete={handleDelete}
              />
            ) : (
              item
            ),
            value: item,
          },
          isPhoto: photoKey ? true : false,
        }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, items]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dragIndex > numbersOfFixedItems &&
        setCards((prevCards: Card[]) =>
          prevCards.map((card: Card, index: number) => {
            if (index === dragIndex) {
              return prevCards[hoverIndex];
            }
            if (index === hoverIndex) {
              return prevCards[dragIndex];
            }
            return card;
          })
        );
    },
    [numbersOfFixedItems]
  );

  useEffect(() => {
    handleOrderItems(
      cards.map((card: Card, index: number) => {
        type Item = {
          [key: string]: any;
        };
        const item: Item = { ...card.item.value };

        item[orderKey] = index + 1;
        return item;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  const renderCard = useCallback(
    (card: Card, index: number) => {
      return (
        <DnDCard
          key={card.id}
          index={index}
          id={card.id}
          text={card.item.label}
          moveCard={moveCard}
          isPhoto={card.isPhoto}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
    <DndProvider backend={HTML5Backend}>
      <div
        className={photoKey ? "" : "gap-1"}
        style={
          photoKey
            ? {
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                gap: "1rem",
              }
            : {
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }
        }
      >
        {cards.map((card, i) => renderCard(card, i))}
      </div>
    </DndProvider>
    </>
  );
};

export default CustomDnDOrdering;