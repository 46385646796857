import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { NoteConfirmationReservationsInitialState } from "slices/reservationLists/noteconfirmationReservations/reducer";
import { filterReservations } from "slices/reservationLists/noteconfirmationReservations/thunk";
import { NoteConfirmationPayload } from "types/reservationLists";

const useHandleForm = () => {
  const { isLoading, filterNoteConfirmationReservations } = useSelector(
    (state: { NoteConfirmationReservations: NoteConfirmationReservationsInitialState }) =>
      state.NoteConfirmationReservations
  );

  const [filterForm, setFilterForm] = useState<NoteConfirmationPayload>({
    date: filterNoteConfirmationReservations.date,
    otel_adi: filterNoteConfirmationReservations.otel_adi,
  });

  const handleFormChange = (key: string, value: any) => {
    setFilterForm((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      date: filterForm.date,
      otel_adi: filterForm.otel_adi,
    };
    dispatch(filterReservations(params, () => {}));
  };

  const handleResetFilter = () => {
    setFilterForm({
        date: "",
        otel_adi: "",
    });

    const params = {
        date: "",
        otel_adi: "",
    };
    dispatch(filterReservations(params, () => {}));
  };

  return {
    isLoading,
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
