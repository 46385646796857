import AddNewRoomForm from "./AddNewRoomForm";
import ReservationInfoForm from "./ReservationInfoForm";
import ReservationRoomsInfoForm from "./ReservationRoomsInfoForm";
import useHandleData from "./useHandleData";
import { CustomLoadingPage } from "Components/Custom/UI";

interface EditReservationPageProps {
  isLoading: boolean;
}

const EditReservationPage = ({ isLoading }: EditReservationPageProps) => {
  const {
    reservation,
    reservationOtherRooms,
    formChoices,
    addNewRoom,
    setAddNewRoom,
  } = useHandleData();

  const handleAddNewRoom = () => {
    setAddNewRoom((prevState) => !prevState);
  };

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <ReservationInfoForm
          formValues={reservation}
          handleChangeInput={() => {}}
          roomConceptTypeOptions={[]}
          onCalculatePrice={function (): void {
            throw new Error("Function not implemented.");
          }}
          calculatedPrice={undefined}
          moneyPoint={0}
          specialDiscounts={[]}
        />
        <ReservationRoomsInfoForm
          reservation={reservation}
          reservationOtherRooms={reservationOtherRooms}
          formChoices={formChoices}
          handleAddNewRoom={handleAddNewRoom}
          handleChangeInput={() => {}}
          addNewRoom={addNewRoom}
        />
        {addNewRoom && (
          <AddNewRoomForm
            reservation={reservation}
            formChoices={formChoices}
            handleChangeInput={() => {}}
            handleAddNewRoom={handleAddNewRoom}
          />
        )}
        {/* <ReservationPaymentInfoForm reservation={reservation} /> */}
        {/* <ReservationInvoiceInfoForm reservation={reservation} /> */}
        {/* <ReservationActions reservation={reservation} /> */}
        {/* <ReservationAvailabilityInfoForm reservation={reservation} /> */}
      </div>
    </>
  );
};

export default EditReservationPage;
