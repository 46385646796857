import ListIncomingTransfersPage from "Components/Custom/ReservationListsPages/ListIncomingTransfersPage";
import useListIncomingTransfers from "Components/Hooks/reservationLists/useListIncomingTransfers";
import PageContainer from "pages/PageContainer";

const ListIncomingTransfers = () => {
  const { isLoading } = useListIncomingTransfers();

  return (
    <PageContainer title="Gelen Havaleleri Listele">
      <ListIncomingTransfersPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default ListIncomingTransfers;
