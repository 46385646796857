import { CustomButton } from "Components/Custom/UI";

interface FindIncomingTransfersButtonsProps {
    onResetFilter: () => void;
    onGetFilteredList: () => void;
    isLoading: boolean;
}

const FindIncomingTransfersButtons = ({
    onResetFilter,
    onGetFilteredList,
    isLoading,
}: FindIncomingTransfersButtonsProps) => {

  return (
    <>
      <div className="w-100 d-flex justify-content-end gap-2 mt-4">
        <CustomButton variant="warning" onClick={onResetFilter}>
          Formu Temizle
        </CustomButton>
        <CustomButton
          variant="success"
          onClick={onGetFilteredList}
          isLoading={isLoading}
        >
          Gelen Havaleleri Bul
        </CustomButton>
      </div>
    </>
  );
};

export default FindIncomingTransfersButtons;
