import { CustomRowOperationButton } from "Components/Custom/UI";
import { useNumberFormatter } from "Components/Hooks/useNumberFormatter";
import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginInitialState } from "slices/auth/login/reducer";
import { ListConfirmationApprovalInitialState } from "slices/reservationLists/confirmationApproval/reducer";
import { setToastError } from "slices/toast/reducer";
import { callCustomer } from "slices/zoiper/thunk";
import { CallCustomerPayload } from "types/reservationMonitoring";
import { TABLE_COLORS } from "utils/tableColors";

const useHandleTable = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const { reservations, listConfirmationApprovalResponse } = useSelector(
    (state: { ListConfirmationApproval: ListConfirmationApprovalInitialState }) =>
      state.ListConfirmationApproval
  );

  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );

  const { yetki } = user;

  const { formatNumber } = useNumberFormatter();

  const handleShowReservation = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-incele?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const handleUpdateReservation = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-duzenle?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const handleCall = (payload: CallCustomerPayload) => {
    try {
      dispatch(callCustomer(payload, () => {}));
    } catch (error) {
      setToastError(error);
    }
  };

  const dataKeyMatch: { [key: string]: string } = {
    otele_odeme_durum: "otele_odeme_durum",
    muhasebe_tahsilat_onay: "muhasebe_tahsilat_onay",
    ana_rez_kod: "ana_rez_kod",
    rezervasyon_kodu: "rezervasyon_kodu",
    kayit_tarihi: "kayit_tarihi",
    giris_cikis_tarihi: "giris_cikis_tarihi",
    musteri_ad_soyad: "musteri_ad_soyad",
    otel_adi: "otel_adi",
    otel_id: "otel_id",
    toplam_tutar: "toplam_tutar",
    grup: "grup",
    personel: "personel",
    musaitlik: "musaitlik",
    operator_firma: "operator_firma",
    operator_rez_kodu: "operator_rez_kodu",
    id: "id",
    oda_id: "oda_id",
    cekilen: "cekilen",
    komisyon_tutar: "komisyon_tutar",
    otel_tutar: "otel_tutar",
    durum: "durum",
    renk: "renk",
    tr_display: "tr_display",
    komisyon: "komisyon",
  };

  const hiddenColumns =
    listConfirmationApprovalResponse.gosterilmeyecek_alanlar[yetki] ?? [];

  const columnVisibility: { [key: string]: boolean } = {};

  hiddenColumns.forEach((column) => {
    columnVisibility[dataKeyMatch[column]] = false;
  });

  const rowColors = {
    success: TABLE_COLORS.green,
    danger: TABLE_COLORS.red,
    warning: TABLE_COLORS.yellow,
  };

  const tableData = reservations?.map((reservation, index) => ({

    rowColor: rowColors[reservation as keyof typeof rowColors],
    rowNumber: index + 1,
  }));

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "rowNumber",
      enableColumnFilter: false,
    },
    {
      header: "Rez. kodu",
      accessorKey: "rezervasyon_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Inet Üyelik",
      accessorKey: "grup",
      enableColumnFilter: false,
    },
    {
      header: "Inet Son Giriş",
      accessorKey: "kayit_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Analiz Durum",
      accessorKey: "musteri_ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Son 7 Gün Rez. Sayısı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Sat. Tarihi",
      accessorKey: "toplam_tutar",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Müşteri Adı",
      accessorKey: "cekilen",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "personel",
      enableColumnFilter: false,
    },
    {
      header: "Rez. Tutar",
      accessorKey: "musaitlik",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        const number = formatNumber(value);
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {number}
            </div>
          </>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        // const rowData: ListConfirmationApprovalData = cell.row.original;
        const id = cell.getValue();
        const dahili = user.dahili;
        const personel_no = `0${dahili}`;
        const otel_no = user.dahili;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="detail"
                onClick={() => handleShowReservation(id)}
                title="İncele"
              />
              <CustomRowOperationButton
                variant="update"
                onClick={() => handleUpdateReservation(id)}
                title="Düzenle"
              />
              <CustomRowOperationButton
                variant="update"
                onClick={() => {}}
                title="Durumu Güncelle"
              />
              <CustomRowOperationButton
                variant="check"
                onClick={() => {}}
                title="İlgileniyorum"
              />
              <CustomRowOperationButton
                variant="phone"
                onClick={() =>
                  handleCall({
                    dahili: dahili,
                    aranan_no: personel_no,
                    arayan_no: otel_no,
                  })
                }
                title="Oteli Ara"
              />
              <CustomRowOperationButton
                variant="warning"
                onClick={() => {}}
                title="Uyarı"
              />
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.green,
      label: "Fiyatımız Düşük",
    },
    {
      color: TABLE_COLORS.red,
      label: "Fiyatımız Yüksek",
    },
    {
      color: TABLE_COLORS.fuchsia,
      label: "??",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "?",
    },
  ];

  return { tableData, tableColumns, columnVisibility, legend };
};

export default useHandleTable;
