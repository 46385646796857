import { CustomRowOperationButton } from "Components/Custom/UI";
import CustomAuthorizedFile from "Components/Custom/UI/CustomAuthorizedFile/CustomAuthorizedFile";
import { useDispatch, useSelector } from "react-redux";
import { ListPriceReviseInitialState } from "slices/reservationMonitoring/listPriceRevise/reducer";
import { TABLE_COLORS } from "utils/tableColors";
import { Dispatch, SetStateAction } from "react";
import { setToastError } from "slices/toast/reducer";
import {
  UpdatePriveReviseOperationPayload,
  UpdatePriveReviseSalesPayload,
} from "types/reservationMonitoring";
import {
  listPriceRevise,
  markAsResponsible,
  updateOperation,
  updateSales,
} from "slices/reservationMonitoring/listPriceRevise/thunk";
import { ColumnColorRule } from "helpers/table_cell_coloring_helper";

const useHandleTable = (
  setSelectedReservationId: Dispatch<SetStateAction<number>>,
  setShowUpdateStatusOperationModal: Dispatch<SetStateAction<boolean>>,
  setShowUpdateStatusSalesModal: Dispatch<SetStateAction<boolean>>
) => {
  const { priceRevise } = useSelector(
    (state: { ListPriceRevise: ListPriceReviseInitialState }) =>
      state.ListPriceRevise
  );

  const dispatch: Dispatch<any> = useDispatch();

  const handleUpdateOperation = (
    payload: UpdatePriveReviseOperationPayload
  ) => {
    try {
      dispatch(
        updateOperation(payload, () => {
          setShowUpdateStatusOperationModal((prevState) => !prevState);
          setSelectedReservationId(0);
          dispatch(listPriceRevise());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleUpdateSales = (payload: UpdatePriveReviseSalesPayload) => {
    try {
      dispatch(
        updateSales(payload, () => {
          setShowUpdateStatusSalesModal((prevState) => !prevState);
          setSelectedReservationId(0);
          dispatch(listPriceRevise());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleMarkAsResponsible = (teklif_id: number) => {
    try {
      dispatch(
        markAsResponsible({ teklif_id: teklif_id }, () => {
          dispatch(listPriceRevise());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const tableData = priceRevise?.map((reservation) => {
    return {
      id: reservation.id,
      display_id: `FT-${reservation.id}`,
      arama_id: reservation.arama_id,
      musteri_id: reservation.musteri_id,
      musteri_isim: reservation.musteri_isim,
      musteri_tel: reservation.musteri_tel,
      otel_id: reservation.otel_id,
      otel_adi: reservation.otel_adi,
      giris_tarihi: reservation.giris_tarihi,
      cikis_tarihi: reservation.cikis_tarihi,
      yetiskin_sayisi: reservation.yetiskin_sayisi,
      cocuk_sayisi: reservation.cocuk_sayisi,
      cocuk_bilgileri: reservation.cocuk_bilgileri,
      operasyonun_aldigi_fiyat: reservation.operasyonun_aldigi_fiyat,
      room_upgrade: reservation.room_upgrade, // '0' -> 'Yok', '1' -> 'Var'
      child_free: reservation.child_free, // '0' -> 'Yok', '1' -> 'Var'
      verilen_fiyat: reservation.verilen_fiyat,
      oda_adi: reservation.oda_adi,
      pansiyon_adi: reservation.pansiyon_adi,
      teklif_aciklama: reservation.teklif_aciklama,
      operasyon_aciklama: reservation.operasyon_aciklama,
      satis_aciklama: reservation.satis_aciklama,
      operasyon_durum: reservation.operasyon_durum,
      satis_durum: reservation.satis_durum,
      operasyon_uye_id: reservation.operasyon_uye_id,
      satis_uye_id: reservation.satis_uye_id,
      created_at: reservation.created_at,
      operasyon_ad_soyad: reservation.operasyon_ad_soyad,
      operasyon_id: reservation.operasyon_id,
      satis_ad_soyad: reservation.satis_ad_soyad,
      ilgilenen: reservation.ilgilenen,
      op_bg: reservation.op_bg,
      sat_bg: reservation.sat_bg,
      fiyat_bg: reservation.fiyat_bg,
      cocuk_bilgileri_yeni: reservation.cocuk_bilgileri_yeni,
      durum_guncelle_operasyon: reservation.durum_guncelle_operasyon,
      durum_guncelle_satis: reservation.durum_guncelle_satis,
      ilgileniyorum_modal: reservation.ilgileniyorum_modal,
      sistem_fiyatı: `${reservation.verilen_fiyat}  ${reservation.oda_adi}  ${reservation.pansiyon_adi}`,
    };
  });

  const tableColumns = [
    {
      header: "ID",
      accessorKey: "display_id",
      enableColumnFilter: false,
    },
    {
      header: "Tarih",
      accessorKey: "created_at",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Op. Yetkili",
      accessorKey: "operasyon_ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Otel",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "100px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Sat. Yetkili",
      accessorKey: "satis_ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Giriş",
      accessorKey: "giris_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Çıkış",
      accessorKey: "cikis_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Yetişkin S.",
      accessorKey: "yetiskin_sayisi",
      enableColumnFilter: false,
    },
    {
      header: "Çocuk S.",
      accessorKey: "cocuk_sayisi",
      enableColumnFilter: false,
    },
    {
      header: "Çocuk Yaş",
      accessorKey: "cocuk_bilgileri",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        try {
          const cocukBilgileri = JSON.parse(value);
          const cocukYaslari = Object.values(cocukBilgileri).filter(
            (yas) => yas !== ""
          );

          const formattedValues = cocukYaslari
            .map((yas, index) => `${index + 1}) ${yas}`)
            .join(", ");
          return (
            <div className="text-wrap" style={{ width: "90px" }}>
              {formattedValues || ""}
            </div>
          );
        } catch (e) {
          return <div className="text-wrap" style={{ width: "90px" }}></div>;
        }
      },
    },
    {
      header: "Ch. Free.",
      accessorKey: "child_free",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "60px" }}>
              {value === 0 ? "Yok" : "Var"}
            </div>
          </>
        );
      },
    },
    {
      header: "Sis. Fiy",
      accessorKey: "verilen_fiyat",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.row.original;
        const fiyat = value.verilen_fiyat;
        const oda = value.oda_adi;
        const pansiyon = value.pansiyon_adi;
        return (
          <>
            <div className="text-wrap" style={{ width: "100px" }}>
              <div>{fiyat}</div>
              <div>({oda})</div>
              <div>({pansiyon})</div>
            </div>
          </>
        );
      },
    },
    {
      header: "Al. Fiyat",
      accessorKey: "operasyonun_aldigi_fiyat",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData = cell.row.original;
        return (
          <>
            <div
              className="text-wrap"
            >
              {rowData.operasyonun_aldigi_fiyat}
            </div>
          </>
        );
      },
    },
    {
      header: "İlgilenen",
      accessorKey: "ilgilenen",
      enableColumnFilter: false,
    },
    {
      header: "Operasyon Açıklama",
      accessorKey: "operasyon_aciklama",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData = cell.row.original;
        return (
          <>
            <div
              className="text-wrap"
              style={{ width: "150px" }}
            >
              {rowData.operasyon_aciklama}
            </div>
          </>
        );
      },
    },
    {
      header: "Satış Açıklama",
      accessorKey: "satis_aciklama",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData = cell.row.original;
        return (
          <>
            <div
              className="text-wrap"
              style={{ width: "150px" }}
            >
              {rowData.satis_aciklama}
            </div>
          </>
        );
      },
    },

    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData = cell.row.original;
        const reservationId = rowData.id;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomAuthorizedFile
                needAllAuthorities={false}
                userId={["6933", "6593"]}
                groupId={["7"]}
                yetki={[]}
              >
                {rowData.op_durum !== "1" && (
                  <CustomRowOperationButton
                    variant="update"
                    onClick={() => {
                      setSelectedReservationId(reservationId);
                      // console.log("reservationId : ", reservationId)
                      setShowUpdateStatusOperationModal(true);
                    }}
                    title="Durumu Güncelle (Operasyon)"
                  />
                )}
              </CustomAuthorizedFile>
              <CustomAuthorizedFile
                needAllAuthorities={false}
                userId={["38", "160", "122", "6933", "6593"]}
                groupId={["3", "4", "15", "9", "10", "16"]}
                yetki={[]}
              >
                {rowData.sat_durum !== "1" && (
                  <CustomRowOperationButton
                    variant="update"
                    onClick={() => {
                      setSelectedReservationId(reservationId);
                      setShowUpdateStatusSalesModal(true);
                    }}
                    title="Durumu Güncelle (Satış)"
                  />
                )}
              </CustomAuthorizedFile>
              <CustomAuthorizedFile
                needAllAuthorities={false}
                userId={["38", "160", "122", "6933", "6593"]}
                groupId={["7"]}
                yetki={[]}
              >
                {rowData.ilgilenen === null && (
                  <CustomRowOperationButton
                    variant="check"
                    onClick={() => handleMarkAsResponsible(reservationId)}
                    title="İlgileniyorum"
                  />
                )}
              </CustomAuthorizedFile>
            </div>
          </>
        );
      },
    },
  ];

  const colorConfig: ColumnColorRule[] = [
    {
      columnId: "operasyonun_aldigi_fiyat",
      getColor: (rowData: any) =>
        rowData.fiyat_bg === "warning" ? TABLE_COLORS.red : TABLE_COLORS.green,
    },
    {
      columnId: "operasyon_aciklama",
      getColor: (rowData: any) =>
        rowData.op_bg === "warning" ? TABLE_COLORS.red : TABLE_COLORS.green,
    },
    {
      columnId: "satis_aciklama",
      getColor: (rowData: any) =>
        rowData.sat_bg === "warning" ? TABLE_COLORS.red : TABLE_COLORS.green,
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.green,
      label: "Olumlu Sonuçlanmış",
    },
    {
      color: TABLE_COLORS.red,
      label: "Olumsuz Sonuçlanmış",
    },
  ];

  return {
    tableData,
    tableColumns,
    legend,
    colorConfig,
    reservations: priceRevise,
    handleUpdateOperation,
    handleUpdateSales,
  };
};

export default useHandleTable;
