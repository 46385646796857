import GroupReservationsTable from "./GroupReservationsTable";

interface GroupReservationsPageProps {
  isLoading: boolean;
}

const GroupReservationsPage = ({
  isLoading,
}: GroupReservationsPageProps) => {

  return (
    <div className="d-flex flex-column gap-4">
      <GroupReservationsTable isLoading={isLoading} />
    </div>
  );
};

export default GroupReservationsPage;
