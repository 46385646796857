import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveListCancellationRequestsResponse,
  setIsLoading,
  setError,
  setCancelLoading,
  setCancelError,
  setChangeStatusLoading,
  setChangeStatusError,
  setCallCustomerLoading,
  setCallCustomerError,
  setMarkAsResponsibleLoading,
  setMarkAsResponsibleError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  FILTER_CANCELLATION_REQUESTS,
  LIST_CANCELLATION_REQUESTS,
  CANCEL_CANCELLATION_REQUEST,
  UPDATE_CANCELLATION_REQUEST,
  FINALIZE_CANCELLATION_REQUEST,
  CALL_WITH_ZOIPER,
  CANCELLATION_REQUEST_MARK_AS_RESPONSIBLE
} from "helpers/url_helper";
import { Dispatch } from "redux";
import { CallCustomerPayload, CancelCancellationRequestPayload, FinalizeRequestPayload, ListCancellationRequestsPayload, MarkAsResponsibleCancellationPayload, UpdateCancellationRequestPayload } from "types/reservationMonitoring";

type SuccessCallback = () => void;

export const listListCancellationRequests = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(LIST_CANCELLATION_REQUESTS);

    dispatch(
        handleSaveListCancellationRequestsResponse(response.data || response)
    );
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const filterListCancellationRequestsData =
  (
    payload: ListCancellationRequestsPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(
        FILTER_CANCELLATION_REQUESTS,
        payload
      );

      dispatch(
        handleSaveListCancellationRequestsResponse(response.data || response)
      );
      dispatch(setToastSuccess("İptal talepleri başarıyla listelendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  export const cancelReservation =
  (payload: CancelCancellationRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setCancelLoading(true));
      await axios.post(CANCEL_CANCELLATION_REQUEST, payload);

      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setCancelError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setCancelLoading(false));
    }
  };

  export const updateStatus =
  (payload: UpdateCancellationRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(UPDATE_CANCELLATION_REQUEST, payload);

      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };

  export const finalizeRequest =
  (payload: FinalizeRequestPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(FINALIZE_CANCELLATION_REQUEST, payload);

      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };

  export const callCustomer =
    (
      payload: CallCustomerPayload,
      successCallback: SuccessCallback
    ) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch(setCallCustomerLoading(true));
        await axios.post(CALL_WITH_ZOIPER, payload);
  
        successCallback();
      } catch (error) {
        handleErrors(error, (message: string) => {
          dispatch(setCallCustomerError(message));
          dispatch(setToastError(message));
        });
      } finally {
        dispatch(setCallCustomerLoading(false));
      }
    };

    export const markAsResponsible =
      (payload: MarkAsResponsibleCancellationPayload, successCallback: SuccessCallback) =>
      async (dispatch: Dispatch) => {
        try {
          dispatch(setMarkAsResponsibleLoading(true));
          await axios.post(CANCELLATION_REQUEST_MARK_AS_RESPONSIBLE, payload);
    
          dispatch(setToastSuccess("Sorumlu başarıyla atandı."));
          successCallback();
        } catch (error) {
          handleErrors(error, (message: string) => {
            dispatch(setMarkAsResponsibleError(message));
            dispatch(setToastError(message));
          });
        } finally {
          dispatch(setMarkAsResponsibleLoading(false));
        }
      };