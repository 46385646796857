import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";
import UpdateStatusModal from "./Modals/UpdateStatusModal";
import ShowDetailModal from "./Modals/ShowDetailModal";
import { useState } from "react";

interface GroupReservationsTableProps {
  isLoading: boolean;
}

const GroupReservationsTable = ({ isLoading }: GroupReservationsTableProps) => {
  const [selectedReservationId, setSelectedReservationId] = useState(0);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const {
    tableData,
    tableColumns,
    legend,
    reservations,
    updateStatusLoading,
    changeStatusLoading,
    handleUpdateGroupReservation,
    handleApproveGroupReservation,
    handleCancelGroupReservation,
  } = useHandleTable(
    setSelectedReservationId,
    setShowUpdateStatusModal,
    setShowDetailModal
  );

  return (
    <>
      <CustomReactTable
        tableData={tableData}
        tableColumns={tableColumns}
        loading={isLoading}
        legend={legend}
      />
      {selectedReservationId !== 0 && (
        <UpdateStatusModal
          reservations={reservations}
          reservation_id={selectedReservationId}
          isOpen={showUpdateStatusModal}
          handleToggle={() =>
            setShowUpdateStatusModal((prevState) => !prevState)
          }
          updateGroupReservation={handleUpdateGroupReservation}
          isLoading={updateStatusLoading}
        />
      )}
      {selectedReservationId !== 0 && (
        <ShowDetailModal
          reservations={reservations}
          reservation_id={selectedReservationId}
          isOpen={showDetailModal}
          handleToggle={() => setShowDetailModal((prevState) => !prevState)}
          approveReservation={handleApproveGroupReservation}
          cancelReservation={handleCancelGroupReservation}
          isLoading={changeStatusLoading}
        />
      )}
    </>
  );
};

export default GroupReservationsTable;
