import { CustomButton, CustomModal } from "Components/Custom/UI";
import { ModalBody, ModalFooter } from "reactstrap";

interface ApprovePaymentModalProps {
  isOpen: boolean;
  handleToggle: () => void;
  onConfirm: () => void;
  onClose: () => void;
}

const ApprovePaymentModal = ({
  isOpen,
  handleToggle,
  onConfirm,
  onClose,
}: ApprovePaymentModalProps) => {
  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="md">
        <ModalBody>
          <div>Ödemeyi onaylıyor musunuz?</div>
        </ModalBody>
        <ModalFooter>
          <CustomButton classes="mt-2 me-2" variant="danger" onClick={onClose}>
            İptal
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="success"
            onClick={onConfirm}
          >
            Onayla
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default ApprovePaymentModal;
