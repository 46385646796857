import { CustomFormContainer } from "Components/Custom/UI";
import useHandleForm from "./useHandleForm";
import HotelNameInput from "./FormFields/HotelNameInput";
import CustomerNameInput from "./FormFields/CustomerNameInput";
import CustomerPhoneNumberInput from "./FormFields/CustomerPhoneNumberInput";
import CustomerEmailInput from "./FormFields/CustomerEmailInput";
import AdultNumberInput from "./FormFields/AdultNumberInput";
import AccommodationDates from "./FormFields/AccommodationDates";
import CustomerSpecialRequestsInput from "./FormFields/CustomerSpecialRequestsInput";
import SaveGroupReservationButton from "./FormFields/SaveGroupReservationButton";
import { CreateGroupReservationPayload } from "types/reservation";

const GroupReservationForm = () => {
  const {
    isLoading,
    values,
    formErrors,
    loadOptions,
    handleChangeQuery,
    handleEnterGroupResrvation,
    validateForm,
    validateFormChange,
    handleSubmitted,
    handleFormChange,
  } = useHandleForm();

  return (
    <CustomFormContainer title="Grup Rezervasyon Talebi Oluştur">
      <HotelNameInput
        values={values}
        formErrors={formErrors}
        validateFormChange={validateFormChange}
        loadOptions={loadOptions}
        handleChangeQuery={handleChangeQuery}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "1rem",
        }}
      >
        <CustomerNameInput
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <CustomerPhoneNumberInput
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <CustomerEmailInput
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <AccommodationDates
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <AdultNumberInput
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
        <CustomerSpecialRequestsInput
          values={values}
          formErrors={formErrors}
          validateFormChange={validateFormChange}
        />
      </div>
      <SaveGroupReservationButton
        values={values}
        validateForm={validateForm}
        handleSubmitted={handleSubmitted}
        onAddNewCollection={handleEnterGroupResrvation}
        isLoading={isLoading}
      />
    </CustomFormContainer>
  );
};

export default GroupReservationForm;
