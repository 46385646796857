import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { ReservationReview } from "types/reservation";

interface CancelReservationCollectionModalProps {
  reservation: ReservationReview;
  tahsilat_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  handleCreateRequest: (payload: {
    tahsilat_id: number;
    aciklama: string;
  }) => void;
}

const CancelReservationCollectionModal = ({
  reservation,
  tahsilat_id,
  isOpen,
  handleToggle,
  handleCreateRequest,
}: CancelReservationCollectionModalProps) => {
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    setAnswer("");
  }, [reservation]);

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="md">
        <ModalHeader>Tahsilatı İptal Et</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label for="aciklama" sm={3} className="control-label">
              Açıklama
            </Label>
            <Col sm={9}>
              <Input
                id="aciklama"
                type="textarea"
                placeholder="Açıklama"
                rows={3}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="danger"
            onClick={() =>
              handleCreateRequest({
                tahsilat_id: tahsilat_id,
                aciklama: answer,
              })
            }
            isLoading={false}
          >
            İptal Et
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default CancelReservationCollectionModal;
