import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CommissionPaymentInitialState } from "slices/financeAndAccounting/commissionPayment/reducer";
import { makeCommissionPayment } from "slices/financeAndAccounting/commissionPayment/thunk";
import { CreditCard, CreditCardInstallment } from "types/GlobalTypes";
import { StartPaymentPayload } from "Components/Custom/UI/CustomCreditCard/CustomCreditCard/types";

const useHandlePayment = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { makeCommissionPaymentLoading } = useSelector(
    (state: { CommissionPayment: CommissionPaymentInitialState }) =>
      state.CommissionPayment
  );

  const makePayment = (
    card: CreditCard,
    installment: CreditCardInstallment
  ) => {
    const payload: StartPaymentPayload = {
      isPostman: false, // Set to false in production
      type: "reservation", // ? ????
      reservationId: "1", // ? ????
      priceId: 1, // ? ????
      installmentId: installment.paymentId,
      card: {
        cardOwner: card.cardOwner,
        cardNumber: card.cardNumber,
        expiryMonth: card.expiryDate.split("/")[0],
        expiryYear: card.expiryDate.split("/")[1],
        cvc: card.cvc,
      },
    };

    console.log("payload : ", payload);
    // dispatch(makeCommissionPayment(payload));
  };

  return {
    makePayment,
    makeCommissionPaymentLoading,
  };
};

export default useHandlePayment;
