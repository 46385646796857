import { createSlice } from "@reduxjs/toolkit";
import {
  NoteConfirmationData,
  NoteConfirmationPayload,
  NoteConfirmationResponse,
} from "types/reservationLists";

export interface NoteConfirmationReservationsInitialState {
  noteConfirmationReservations: NoteConfirmationData[];
  filterNoteConfirmationReservations: NoteConfirmationPayload;
  isLoading: boolean;
  error: string;
}

const initialState: NoteConfirmationReservationsInitialState = {
  noteConfirmationReservations: [],
  filterNoteConfirmationReservations: {
    date: "",
    otel_adi: "",
  },
  isLoading: false,
  error: "",
};

const noteConfirmationReservationsSlice = createSlice({
  name: "noteConfirmationReservations",
  initialState,
  reducers: {
    handleSaveNoteConfirmationReservationsResponse(state, action) {
      const response = action.payload as NoteConfirmationResponse[];

      const noteConfirmations: NoteConfirmationData[] = response.map(
        (item) => ({
          rezervasyon_kodu: item.rezervasyon_kodu, // Rez. Kod
          yeni_field: "", // ! EKLENECEK Rez. Kod
          otel_yetkilisi: item.otel_yetkilisi, // Yetkili
          grup_adi: item.grup_adi, // Sat. Grup
          kayit_tarihi: item.kayit_tarihi, // Sat. Tarihi
          musteri_ad_soyad: item.musteri_ad_soyad, // Müşteri Ad
          yeni_field_2: "", // ! EKLENECEK Müşteri Ad
          otel_adi: item.otel_adi, // Otel Adı
          inet_otel_kodu: item.inet_otel_kodu, // Otel Adı
          giris_tarihi: item.giris_tarihi, // Giriş Tarihi
          cikis_tarihi: item.cikis_tarihi, // Çıkış Tarihi
          cekilecek_tutar: item.cekilecek_tutar, // Çekilen Tutar
          toplam_otel_maliyet_tutar_kur: item.toplam_otel_maliyet_tutar_kur, // Maliyet Tutar
          yeni_field_3: "", // ! EKLENECEK N. Konf
          iptal_nedeni: item.iptal_nedeni, // N. Konf
          otel_mail: "", // ! EKLENECEK
          renk: "", // ! EKLENECEK
        })
      );
      state.noteConfirmationReservations = noteConfirmations;
    },
    handleChangeFilterChoices(state, action) {
      state.filterNoteConfirmationReservations = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  handleSaveNoteConfirmationReservationsResponse,
  handleChangeFilterChoices,
  setIsLoading,
  setError,
} = noteConfirmationReservationsSlice.actions;

export default noteConfirmationReservationsSlice.reducer;
