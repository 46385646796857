import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import {
  EditReservationData,
  EditReservationFormChoices,
} from "types/reservation";
import RoomInfoForm from "./RoomInfoForm";

interface ReservationRoomsInfoFormProps {
  reservation: EditReservationData;
  reservationOtherRooms: EditReservationData[];
  formChoices: EditReservationFormChoices;
  addNewRoom: boolean;
  handleChangeInput: (key: string, value: any) => void;
  handleAddNewRoom: () => void;
}

const ReservationRoomsInfoForm = ({
  reservation,
  reservationOtherRooms,
  formChoices,
  addNewRoom,
  handleChangeInput,
  handleAddNewRoom,
}: ReservationRoomsInfoFormProps) => {
  let count = 0;

  return (
    <CustomFormContainer title="Oda Bilgileri">
      <div className="d-flex justify-content-end gap-3">
        <CustomButton variant="secondary" onClick={handleAddNewRoom}>
          {addNewRoom ? "Oda Eklemekten Vazgeç" : "Başka Oda Ekle"}
        </CustomButton>
        <CustomButton variant="danger" onClick={() => {}}>
          Tekrar Fiyat Hesapla
        </CustomButton>
      </div>
      <RoomInfoForm
        reservation={reservation}
        count={++count}
        formChoices={formChoices}
        handleChangeInput={handleChangeInput}
        handleAddNewRoom={handleAddNewRoom}
        addNewRoom={addNewRoom}
      />
      {reservationOtherRooms.map((reservationOtherRoom) => (
        <RoomInfoForm
          reservation={reservationOtherRoom}
          count={++count}
          key={reservationOtherRoom.rezervasyon_id}
          formChoices={formChoices}
          handleChangeInput={handleChangeInput}
          handleAddNewRoom={handleAddNewRoom}
          addNewRoom={addNewRoom}
        />
      ))}
    </CustomFormContainer>
  );
};

export default ReservationRoomsInfoForm;
