import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSavePriceReviseResponse,
  setIsLoading,
  setError,
  setMarkAsResponsibleLoading,
  setMarkAsResponsibleError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import { FILTER_PRICE_REVISE, LIST_PRICE_REVISE, PRICE_REVISE_MARK_AS_RESPONSIBLE, UPDATE_STATUS_OPERATION, UPDATE_STATUS_SALES } from "helpers/url_helper";
import { Dispatch } from "redux";
import { ListPriceRevisePayload, UpdatePriveReviseOperationPayload, UpdatePriveReviseSalesPayload } from "types/reservationMonitoring";

type SuccessCallback = () => void;

export const listPriceRevise = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(LIST_PRICE_REVISE);

    dispatch(
      handleSavePriceReviseResponse(response.data || response)
    );
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const filterPriceReviseData =
  (payload: ListPriceRevisePayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(FILTER_PRICE_REVISE, payload);

      dispatch(handleSavePriceReviseResponse(response.data || response));
      dispatch(setToastSuccess("Fiyat revizeler başarıyla listelendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  export const updateOperation =
  (
    payload: UpdatePriveReviseOperationPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(
        UPDATE_STATUS_OPERATION,
        payload
      );

      dispatch(setToastSuccess("Sorsat cevabı kaydedildi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  export const updateSales =
  (
    payload: UpdatePriveReviseSalesPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      await axios.post(
        UPDATE_STATUS_SALES,
        payload
      );

      dispatch(setToastSuccess("Sorsat durumu başarıyla güncellendi"));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  export const markAsResponsible =
  (payload: {teklif_id: number}, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setMarkAsResponsibleLoading(true));
     await axios.post(PRICE_REVISE_MARK_AS_RESPONSIBLE, payload);

      dispatch(setToastSuccess("Sorumlu başarıyla atandı."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMarkAsResponsibleError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMarkAsResponsibleLoading(false));
    }
  };