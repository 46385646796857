import { useEffect, useState } from "react";

import { getCardInfo } from "./getCardInfo";
import {
  CreditCardBrand,
  CreditCardInfo,
  CreditCardInstallment,
  GetCardInfoRequestPayload,
} from "./types";

const useHandleInstallments = (
  reservationId: string,
  priceId: number,
  getCardInfoUrl: string
) => {
  const [creditCardInfo, setCreditCardInfo] = useState<CreditCardInfo>({
    id: 0,
    bank_id: 0,
    bank: "",
    bankName: "",
    cardType: "",
    cardBrand: "",
    cardProgram: "",
    cvvRequired: false,
    installment: false,
    countryCode: "",
    cardLength: 0,
    cardBrandIcon: "",
    cardProgramIcon: "",
    installment_details: {
      0: { total_amount: 0, monthly_amount: 0 },
    },
  });

  const [installments, setInstallments] = useState<CreditCardInstallment[]>([]);
  const [cardBin, setCardBin] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const getInstallments = async (bin: string) => {
    const payload: GetCardInfoRequestPayload = {
      bin,
      reservationId,
      priceId,
    };
    try {
      setIsLoading(true);
      setError(false);
      setCardBin(bin);
      const cardInfo = await getCardInfo(payload, getCardInfoUrl);

      setCreditCardInfo(cardInfo);
      setInstallments(
        Object.keys(cardInfo.installment_details).map((key) => ({
          paymentId: Number(key),
          month: Number(key),
          amount: cardInfo.installment_details[key].monthly_amount,
          total: cardInfo.installment_details[key].total_amount,
        }))
      );
    } catch {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const resetInstallments = () => {
    setCreditCardInfo({
      id: 0,
      bank_id: 0,
      bank: "",
      bankName: "",
      cardType: "",
      cardBrand: "",
      cardProgram: "",
      cvvRequired: false,
      installment: false,
      countryCode: "",
      cardLength: 0,
      cardBrandIcon: "",
      cardProgramIcon: "",
      installment_details: {
        0: { total_amount: 0, monthly_amount: 0 },
      },
    });
    setInstallments([]);
  };

  const hasInstallments = creditCardInfo?.installment;

  const cardBrand: CreditCardBrand = {
    showBrand: creditCardInfo?.cardBrand ? true : false,
    cardBrand: creditCardInfo?.cardBrand || "",
    cardBrandLogo: creditCardInfo?.cardBrandIcon || "",
    cardProgram: creditCardInfo?.cardProgram || "",
    cardProgramLogo: creditCardInfo?.cardProgramIcon || "",
  };

  const invalidCardNumber = error ? "Kart bilgileri hatalı!" : "";

  useEffect(() => {
    if (cardBin.length > 5) {
      getInstallments(cardBin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceId]);

  return {
    getInstallments,
    resetInstallments,
    hasInstallments,
    creditCardInfo,
    installments,
    isLoading,
    error,
    cardBrand,
    invalidCardNumber,
  };
};

export default useHandleInstallments;
