import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ListPriceReviseInitialState } from "slices/reservationMonitoring/listPriceRevise/reducer";
import { listPriceRevise } from "slices/reservationMonitoring/listPriceRevise/thunk";


const useListPriceRevise = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ListPriceRevise: ListPriceReviseInitialState }) => state.ListPriceRevise
  );

  useEffect(() => {
    dispatch(listPriceRevise());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useListPriceRevise;
