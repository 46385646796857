import FindReservationPage from "Components/Custom/ReservationListsPages/FindReservationPage";
import useFindReservation from "Components/Hooks/reservationLists/useFindReservation";
import PageContainer from "pages/PageContainer";

const FindReservation = () => {
  const { isLoading } = useFindReservation();

  return (
    <PageContainer title="Rezervasyon Listele">
      <FindReservationPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default FindReservation;
