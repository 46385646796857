import { Dispatch, SetStateAction } from "react";
import ConceptQuotaTooltip from "./ConceptQuotaTooltip";
import { CalculatePriceForHotelConceptPrice, RoomOption } from "types/reservation";

interface ConceptInfoProps {
  priceType: string[];
  pansiyon_id: string;
  pansiyon_adi: string;
  oda_id: string;
  fiyatlar: {
    [key: string]: CalculatePriceForHotelConceptPrice;
  };
  kontenjan: {
    [key: string]: string;
  };
  gifts: string;
  kismi_odeme?: {
    kismi_odeme: 0 | 1;
    kismi_odeme_orani: number;
    kismi_odeme_tarihi: string;
    iptal_sigortasi_durum: "1" | "0";
  };
  noPrice?: boolean;
  setSelectedRoom?: Dispatch<SetStateAction<RoomOption>>;
}

interface GivenGifts {
  hediye_adi: string;
  hediye_aciklama: string;
  gecerli_kisi_sayisi: string;
}

const ConceptInfo = ({
  priceType,
  pansiyon_id,
  pansiyon_adi,
  oda_id,
  fiyatlar,
  kontenjan,
  gifts,
  kismi_odeme,
  noPrice,
  setSelectedRoom,
}: ConceptInfoProps) => {
  const paymentTypes = fiyatlar ? Object.keys(fiyatlar) : [];

  const normal =
    paymentTypes.includes("normal") && fiyatlar["normal"].fiyat_durum === 1;

  const iptal_edilemez =
    paymentTypes.includes("iptal_edilemez") &&
    fiyatlar["iptal_edilemez"].fiyat_durum === 1;

  const kapida =
    paymentTypes.includes("kapida") && fiyatlar["kapida"].fiyat_durum === 1;

  const kaporali =
    paymentTypes.includes("kaporali") && fiyatlar["kaporali"].fiyat_durum === 1;

  const kismi = kismi_odeme?.kismi_odeme === 1;

  const hasPrice = !!fiyatlar;

  const givenGifts: GivenGifts[] = gifts ? JSON.parse(gifts) : [];

  const getOrder = (type: string) => {
    return paymentTypes.indexOf(type);
  };

  return (
    <div className="d-flex flex-column justify-content-between">
      <div
        className="d-flex flex-column gap-2"
      >
        <div className="d-flex gap-2 align-items-end">
          <i className="ri-restaurant-fill"></i>
          <span className="fs-14 fw-medium">{pansiyon_adi}</span>
        </div>
        <div className="d-flex flex-column gap-1 mb-4">
          <div className="d-flex flex-column gap-2 justify-content-center">
            {priceType.includes("normal") && (normal || noPrice) && (
              <>
                <div
                  className="d-flex gap-2 align-items-end"
                  style={{ order: getOrder("normal") }}
                >
                  <i className="ri-bank-card-fill theme-text-primary"></i>
                  <span className="fs-14 fw-medium  theme-text-primary">
                    Ücretsiz İptal
                  </span>
                </div>
                {kismi && (
                  <div className="d-flex gap-2 align-items-end">
                    <i className="ri-verified-badge-fill theme-text-orange"></i>
                    <span className="fs-14 fw-medium theme-text-orange">
                      Ön Ödemeli Rezervasyon
                    </span>
                  </div>
                )}
              </>
            )}
            {priceType.includes("iptal_edilemez") &&
              (iptal_edilemez || noPrice) && (
                <div
                  className="d-flex gap-2 align-items-end"
                  style={{ order: getOrder("iptal_edilemez") }}
                >
                  <i className="ri-close-circle-fill theme-text-danger"></i>
                  <span className="fs-14 fw-medium theme-text-danger">
                    İptal Edilemez
                  </span>
                </div>
              )}
            {priceType.includes("kapida") && (kapida || noPrice) && (
              <div
                className="d-flex gap-2 align-items-end"
                style={{ order: getOrder("kapida") }}
              >
                <i className="ri-home-4-fill theme-text-info"></i>
                <span className="fs-14 fw-medium theme-text-info">
                  Otelde Ödeme
                </span>
              </div>
            )}
            {priceType.includes("kaporali") && (kaporali || noPrice) && (
              <div
                className="d-flex gap-2 align-items-end"
                style={{ order: getOrder("kaporali") }}
              >
                <i className="ri-verified-badge-fill theme-text-success"></i>
                <span className="fs-14 fw-medium theme-text-success">
                  Kaporalı Rezervasyon
                </span>
              </div>
            )}

            {givenGifts.map((gift, index) => (
              <div key={index} className="d-flex gap-2 align-items-end">
                <i className="ri-gift-fill theme-text-orange"></i>
                <span className="fs-14 fw-medium theme-text-orange">
                  {gift.hediye_adi}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {hasPrice && (
        <ConceptQuotaTooltip
          tooltipId={`tooltip-${pansiyon_id}-${oda_id}`
            .trim()
            .replaceAll(" ", "-")}
          quota={kontenjan}
        />
      )}
    </div>
  );
};

export default ConceptInfo;
