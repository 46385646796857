import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  ListCancellationRequestsData,
  CancelCancellationRequestPayload,
} from "types/reservationMonitoring";

interface CancellationModalProps {
  reservations: ListCancellationRequestsData[];
  reservation_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  updateStatus: (payload: CancelCancellationRequestPayload) => void;
}

const CancellationModal = ({
  reservations,
  reservation_id,
  isOpen,
  handleToggle,
  updateStatus,
}: CancellationModalProps) => {
  const reservation = reservations.find(
    (reservation) => reservation.rezervasyon_id === Number(reservation_id)
  )!;

  const [answer, setAnswer] = useState("");

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="md">
        <ModalHeader>
          {reservation.rezervasyon_kodu} İçin İptal Açıklaması Gir{" "}
        </ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label for="aciklama" sm={3} className="control-label">
              Açıklama
            </Label>
            <Col sm={9}>
              <Input
                id="aciklama"
                type="textarea"
                placeholder="Açıklama"
                rows={3}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="primary"
            onClick={() =>
              updateStatus({
                id: reservation.iptal_id,
                aciklama: answer,
              })
            }
            isLoading={false}
          >
            Güncelle
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default CancellationModal;
