import { Col, Label, Row } from "reactstrap";
import { ListCostReviseData } from "types/reservationMonitoring";

interface ModalInfoProps {
  reservation: ListCostReviseData;
}
const lineStyle: React.CSSProperties = {
  borderBottom: "1px solid #ccc",
  maxWidth: "460px",
  margin: "0 auto",
  paddingBottom: "4px",
};

const ModalInfo = ({ reservation }: ModalInfoProps) => {
  const formattedHotelName = reservation.otel_adi_aciklama
    ?.split(/<\/?br>/)
    .filter((line: string) => line.trim() !== "")
    .slice(0, 2) || [""];

  return (
    <>
      <Row className="mx-2 mt-4">
        <div className="d-flex gap-2">
          <Label>Otel Adı :</Label>
          {formattedHotelName.map((line: any, index: any) => 
           ( <p className= "d-flex gap-2" style={{ margin: 0}} key={index}>{line}</p>)
          )}
        </div>
        <div style={lineStyle}></div>
      </Row>

      <Row className="mx-2 mt-4 ">
        <Col>
          <div className="d-flex gap-2" style={{ marginRight: "2rem" }}>
            <Label>Satış Tarihi :</Label>
            <p style={{ margin: 0 }}>{reservation.kayit_tarihi ?? ""}</p>
          </div>
          <div style={lineStyle}></div>
        </Col>
      </Row>
      <Row className="mx-2 mt-4 ">
        <Col>
          <div className="d-flex gap-2">
            <Label>Çekilen Tutar :</Label>
            <p style={{ margin: 0 }}>{reservation.cekilen_tutar ?? ""}</p>
          </div>
        </Col>
        <div style={lineStyle}></div>
      </Row>
    </>
  );
};

export default ModalInfo;
