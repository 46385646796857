import React from 'react';

interface User {
  id: string;
  groupId: string;
  yetki: string;
}

interface CustomAuthorizedFileProps {
  userId: string[];
  groupId: string[];
  yetki: string[];
  children: React.ReactNode;
  needAllAuthorities?: boolean;
}

const CustomAuthorizedFile: React.FC<CustomAuthorizedFileProps> = ({ userId, groupId, yetki, children, needAllAuthorities=true }) => {

  const userInfo = localStorage.getItem("user");
  const user: User | null = userInfo ? JSON.parse(userInfo) : null;

  if (!user) {
    return <></>;
  }

   // Authorization logic
   const isAuthorized = needAllAuthorities
   ? (userId.length === 0 || userId.includes(user.id)) &&
     (groupId.length === 0 || groupId.includes(user.groupId)) &&
     (yetki.length === 0 || yetki.includes(user.yetki))
   : (userId.length === 0 || userId.includes(user.id)) ||
     (groupId.length === 0 || groupId.includes(user.groupId)) ||
     (yetki.length === 0 || yetki.includes(user.yetki));

  return <>{isAuthorized ? children : <></>}</>;
};

export default CustomAuthorizedFile;