import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { ListPriceRevisePayload } from "types/reservationMonitoring";

interface PriceIDInputProps {
  values: ListPriceRevisePayload;
  handleFormChange: (key: string, value: string) => void;
}

const PriceIDInput = ({
  values,
  handleFormChange,
}: PriceIDInputProps) => {

  return (
    <>
      <CustomFormFieldContainer label="Teklif ID :">
        <Input
          type="text"
          autoCorrect="on"
          value={values.teklif_id}
          onChange={(e) => {
            handleFormChange("teklif_id", e.target.value);
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default PriceIDInput;