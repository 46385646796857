import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveHotelsWithoutPriceResponse,
  setIsLoading,
  setError,
} from "./reducer";
import { setToastError } from "slices/toast/reducer";
import {
  LIST_HOTELS_WITHOUT_PRICE,
} from "helpers/url_helper";
import { Dispatch } from "redux";

export const listHotelsWithoutPrice = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(LIST_HOTELS_WITHOUT_PRICE);

    dispatch(
        handleSaveHotelsWithoutPriceResponse(response.data || response)
    );
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};