import ListOnRequestReservationsPage from "Components/Custom/ReservationMonitoringPages/ListOnRequestReservationsPage";
import useListOnRequestReservations from "Components/Hooks/reservationMonitoring/useListOnRequestReservations";
import PageContainer from "pages/PageContainer";

const ListOnRequestReservations = () => {
  const { isLoading } = useListOnRequestReservations();

  return (
    <PageContainer title="Sorsat Listele">
      <ListOnRequestReservationsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default ListOnRequestReservations;
