import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { handleChangeInput } from "slices/reservation/reviewReservation/reducer";
import {
  ReservationReviewCollection,
  ReservationReviewCollectionFormErrors,
} from "types/reservation";

interface CollectionDescriptionInputProps {
  values: ReservationReviewCollection;
  formErrors: ReservationReviewCollectionFormErrors;
  validateFormChange: (values: ReservationReviewCollection) => void;
}

const CollectionDescriptionInput = ({
  values,
  validateFormChange,
  formErrors,
}: CollectionDescriptionInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Tahsil Edilen Tutar "
        error={formErrors.cekilen_tutar}
        required
        orientation="vertical"
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.cekilen_tutar}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                cekilen_tutar: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              cekilen_tutar: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default CollectionDescriptionInput;
