import { CustomButton, CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch, useSelector } from "react-redux";
import { AdditionalPagesInitialState, handleSaveAdditionalPageToUpdate, handleSaveGalleryImage } from "slices/myWebSite/additionalPages/reducer";
import { AdditionalPage } from "types/myWebSite";
import { HotelInitialState } from "slices/hotel/reducer";
import { Dispatch } from "react";
import { deleteAdditionalPage, getAdditionalPages, getGalleryImages } from "slices/myWebSite/additionalPages/thunk";

const useHandleTable = () => {
  const { additionalPages } = useSelector(
    (state: { AdditionalPages: AdditionalPagesInitialState }) =>
      state.AdditionalPages
  );

  const { hotel } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const { otel_id } = hotel;

  const dispatch: Dispatch<any> = useDispatch();

  const handleEditClick = (id: number) => {
    const selectedRow = additionalPages.find((row) => row.id === id);

    if (selectedRow) {
      console.log("selected row : ", selectedRow)
      dispatch(handleSaveAdditionalPageToUpdate(selectedRow));
      window.scrollTo(0, 0);
    }
  };

  const handleDelete = (id: number) => {
    dispatch(
      deleteAdditionalPage({otel_id, id}, () => {
        dispatch(
          getAdditionalPages(otel_id)
        );
      })
    );
  };

  const handleShowGallery = (id: number) => {
    const selectedRow = additionalPages.find((row) => row.id === id);

    if (selectedRow) {
      dispatch(handleSaveGalleryImage({
        id: 0,
        sayfa_id: selectedRow.id,
        otel_id: selectedRow.otel_id,
        resim: "",
        add_time: "",
      }))

      dispatch(getGalleryImages(selectedRow.otel_id, selectedRow.id));
      window.scrollTo(0, 1600);
    }
  }

  const tableData: AdditionalPage[] = additionalPages?.map((page) => ({
    id: page.id,
    otel_id: page.otel_id,
    seflink: page.seflink,
    organizasyon_sayfasi_acik: page.organizasyon_sayfasi_acik,
    organizasyon_link_adi: page.organizasyon_link_adi,
    organizasyon_vitrin_resmi: page.organizasyon_vitrin_resmi,
    organizasyon_baslik: page.organizasyon_baslik,
    organizasyon_aciklama: page.organizasyon_aciklama,
    organizasyon_kart_baslik: page.organizasyon_kart_baslik,
    organizasyon_kart_aciklama: page.organizasyon_kart_aciklama,
    organizasyon_kart_resim: page.organizasyon_kart_resim,
    add_time: page.add_time,
    update_time: page.update_time,
  }));

  const tableColumns = [
    {
      header: "Sayfa Durum",
      accessorKey: "organizasyon_sayfasi_acik",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value: number = cell.getValue();
        return (
          <div className="d-flex gap-2">{value === 1 ? "Açık" : "kapalı"}</div>
        );
      },
    },
    {
      header: "Sleflink",
      accessorKey: "seflink",
      enableColumnFilter: false,
    },
    {
      header: "Link Adı",
      accessorKey: "organizasyon_link_adi",
      enableColumnFilter: false,
    },
    {
      header: "Başlık",
      accessorKey: "organizasyon_baslik",
      enableColumnFilter: false,
    },
    {
      header: "Galeri",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();
        return (
          <div className="d-flex justify-content-center gap-2">
            <CustomButton
              variant="info"
              onClick={handleShowGallery.bind(null, id)}
            >
              Galeriyi Düzenle
            </CustomButton>
          </div>
        );
      },
    },
    {
      header: "Ekleme Zamanı",
      accessorKey: "add_time",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const id = cell.getValue();

        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                title="Düzenle"
                variant="update"
                onClick={handleEditClick.bind(null, id)}
              />
              <CustomRowOperationButton
                title="Sil"
                variant="delete"
                onClick={() => handleDelete(id)}
              />
            </div>
          </>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
