import CalculatePriceForm from "../ReservationCallPage/CalculatePriceForm";
import SpecialDiscountsForm from "./SpecialDiscountsForm";
import { CalculatePriceFormValues, RoomOption } from "types/reservation";
import FacilityDetails from "./FacilityDetails";
import SalesReservationTrackingForm from "./SalesReservationTrackingForm";
import useHandlePage from "./useHandlePage";

interface SpecialDiscountsFormProps {
  formValues: CalculatePriceFormValues;
  handleChangeForm: (
    key: string,
    value:
      | number
      | string
      | {
          value: number;
          label: string;
          category: string;
        }
      | string[]
  ) => void;
}

const CalculatePriceForHotelPage = () => {
  const {
    calculatePriceForHotel,
    checkboxItems,
    selectedCallOption,
    selectedRoom,
    setSelectedCallOption,
    setSelectedRoom,
    handleInputChange,
    handleSaveFormValues,
    handleSelectRoomToCallLater,
  } = useHandlePage();

  return (
    <div className="d-flex flex-column gap-4">
      <CalculatePriceForm>
        {({ formValues, handleChangeForm }: SpecialDiscountsFormProps) => (
          <>
            <SpecialDiscountsForm
              formValues={formValues}
              handleChangeForm={handleChangeForm}
            />
            <FacilityDetails
              formValues={formValues}
              handleSelectRoomToCallLater={handleSelectRoomToCallLater}
              selectedRoom={selectedRoom}
            />
          </>
        )}
      </CalculatePriceForm>
      {calculatePriceForHotel.musteri ? (
        <SalesReservationTrackingForm
          calculatePriceForHotel={calculatePriceForHotel}
          checkboxItems={checkboxItems}
          selectedCallOption={selectedCallOption}
          handleInputChange={handleInputChange}
          handleSaveFormValues={handleSaveFormValues}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CalculatePriceForHotelPage;
