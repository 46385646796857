import ListCancellationRequestsTable from "../ListCancellationRequestsPage/ListCancellationRequestsTable";
import ListCostReviseTable from "../ListCostRevisePage/ListCostReviseTable";
import ListOnRequestReservationsTable from "../ListOnRequestReservationsPage/ListOnRequestReservationsTable";
import ListPriceReviseTable from "../ListPriceRevisePage/ListPriceReviseTable";
import { CustomFormContainer } from "Components/Custom/UI";
interface ReservationMonitoringTableProps {
  selectedTable: string;
  isLoading: boolean;
}

const ReservationMonitoringTable = ({
  selectedTable,
  isLoading,
}: ReservationMonitoringTableProps) => {

  switch (selectedTable) {
    case "sorsatListele":
      return (
        <div>
          <CustomFormContainer title="Sorsat Listesi">
            <ListOnRequestReservationsTable isLoading={isLoading} />
          </CustomFormContainer>
        </div>
      );
    case "fiyatRevizeListesi":
      return (
        <div>
          <CustomFormContainer title="Fiyat Revize Listesi">
            <ListPriceReviseTable isLoading={isLoading} />
          </CustomFormContainer>
        </div>
      );
    case "iptalTalebiListesi":
      return (
        <div>
          <CustomFormContainer title="İptal Talebi Listesi">
            <ListCancellationRequestsTable isLoading={isLoading} />
          </CustomFormContainer>
        </div>
      );
    case "maliyetRevizeListesi":
      return (
        <div>
          <CustomFormContainer title="Maliyet Revize">
            <ListCostReviseTable isLoading={isLoading} />
          </CustomFormContainer>
        </div>
      );
    case "konfirmeOnay":
      return (
        <div>
          <CustomFormContainer title="Konfirme Onay">
            Tablo gelecek
          </CustomFormContainer>
        </div>
      );
    default:
      return <></>;
  }
};

export default ReservationMonitoringTable;
