"use server";

import { GetCardInfoRequestPayload } from "./types";

export const getCardInfo = async (
  payload: GetCardInfoRequestPayload,
  url: string
) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const { data } = await response.json();

    const cardInfo = data[0];

    return cardInfo;
  } catch (error) {
    console.error("An error occurred while fetching data from the API", error);
  }
};
