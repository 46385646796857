import React from "react";
import { TeamData } from "types/userOperations";
import { PERITO_COLORS } from "utils/peritoColors";

interface TeamCardProps {
  teamData: TeamData;
}

const getColorFromStatusPrt = (status: string) => {
  switch (status) {
    case "Hazırda":
      return PERITO_COLORS.green;
    case "Kapalı":
      return PERITO_COLORS.gray;
    case "Çalıyor":
      return PERITO_COLORS.blue;
    case "Molada":
      return PERITO_COLORS.yellow;
    case "Görüşmede":
      return PERITO_COLORS.red;
    case "Meşgul":
      return PERITO_COLORS.red;
    case "Yok":
      return PERITO_COLORS.white;
    default:
      return PERITO_COLORS.gray;
  }
};

const getColorFromStatusCrm = (status: string) => {
  switch (status) {
    case "Aktif":
      return PERITO_COLORS.green;
    case "Meşgul":
      return PERITO_COLORS.yellow;
    case "Yemekte":
      return PERITO_COLORS.yellow;
    case "Dışarda":
      return PERITO_COLORS.yellow;
    case "Çevrimdışı":
      return PERITO_COLORS.gray;
    case "Görüşmede":
      return PERITO_COLORS.red;
    default:
      return PERITO_COLORS.black;
  }
};

// const spanStyle: React.CSSProperties = {
//   width: "60px",
//   display: "inline-block",
//   textAlign: "center",
// };

const TeamMemberCardRow = ({
  member_name,
  extension_number,
  prt_color,
  crm_color,
}: {
  member_name: string;
  extension_number: number;
  prt_color: string;
  crm_color: string;
}) => {
  return (
    <div className="d-flex align-items-center gap-4">
      <span
        className="rounded-circle"
        style={{ width: 12, height: 12, backgroundColor: prt_color }}
      ></span>
      <span
        className="fs-14"
        style={{ width: "40px", display: "inline-block", textAlign: "left" }}
      >
        {extension_number}
      </span>
      <span
        className="rounded-circle"
        style={{ width: 12, height: 12, backgroundColor: crm_color }}
      ></span>
      <span 
        className="fs-14" 
        style={{ width: "150px", display: "inline-block", textAlign: "left" }}
      >
        {member_name}
      </span>
    </div>
  );
};

const TeamCard = ({ teamData }: TeamCardProps) => {
  const { grup_adi, uyeler } = teamData;
  return (
    <div className="d-flex flex-column gap-4 py-3 px-4 bg-white rounded border">
      <span className="fs-18 fw-bold">{grup_adi}</span>
      <div
        style={{
          display: "grid",
          gap: "1rem",
        }}
      >
        <div className="d-flex align-items-center gap-4">
          <span className="fs-14" style={{ width: 12, display: "inline-block" }}>Prt.</span>
          <span className="fs-14" style={{ display: "inline-block", textAlign: "left" }}>Dahili</span>
          <span className="fs-14" style={{ width: 12, display: "inline-block" }}>Crm</span>
          <span className="fs-14" style={{ width: "150px", display: "inline-block", textAlign: "left" }}>Personel</span>
        </div>
        {uyeler.map((member, index) => (
          <TeamMemberCardRow
            key={index}
            member_name={member.ad_soyad}
            extension_number={member.dahili}
            prt_color={getColorFromStatusPrt(member.perito_durum)}
            crm_color={getColorFromStatusCrm(member.crm_durum)}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamCard;
