import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ListOnRequestReservationsInitialState } from "slices/reservationMonitoring/listOnRequestReservations/reducer";
import { listOnRequestReservations } from "slices/reservationMonitoring/listOnRequestReservations/thunk";


const useListOnRequestReservations = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ListOnRequestReservations: ListOnRequestReservationsInitialState }) => state.ListOnRequestReservations
  );

  useEffect(() => {
    dispatch(listOnRequestReservations());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useListOnRequestReservations;
