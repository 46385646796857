import { useSelector } from "react-redux";
import { ReviewReservationInitialState } from "slices/reservation/reviewReservation/reducer";

const useHandleData = () => {
  const { reservation, reservationOtherRooms, makeCommissionPaymentLoading } =
    useSelector(
      (state: { ReviewReservation: ReviewReservationInitialState }) =>
        state.ReviewReservation
    );

  return { reservation, reservationOtherRooms, makeCommissionPaymentLoading };
};

export default useHandleData;
