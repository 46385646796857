import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { TomorrowEntranceWithDepositInitialState } from "slices/reservationMonitoring/tomorrowEntranceWithDeposit/reducer";
import { filterTomorrowEntranceWithDepositData } from "slices/reservationMonitoring/tomorrowEntranceWithDeposit/thunk";
import { TomorrowEntranceWithDepositPayload } from "types/reservationMonitoring";

const useHandleForm = () => {
  const { isLoading, filterTomorrowEntranceWithDeposit } = useSelector(
    (state: { TomorrowEntranceWithDeposit: TomorrowEntranceWithDepositInitialState }) =>
      state.TomorrowEntranceWithDeposit
  );

  const [filterForm, setFilterForm] = useState<TomorrowEntranceWithDepositPayload>({
    date: filterTomorrowEntranceWithDeposit.date,
    rezervasyon_kodu: filterTomorrowEntranceWithDeposit.rezervasyon_kodu,
    musteri_adi: filterTomorrowEntranceWithDeposit.musteri_adi,
  });

  const handleFormChange = (key: string, value: any) => {
    setFilterForm((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const dispatch: Dispatch<any> = useDispatch();

  const handleGetFilteredList = () => {
    const params = {
      date: filterForm.date,
      rezervasyon_kodu: filterForm.rezervasyon_kodu,
      musteri_adi: filterForm.musteri_adi,
    };
    dispatch(filterTomorrowEntranceWithDepositData(params, () => {}));
  };

  const handleResetFilter = () => {
    setFilterForm({
      date: "",
      rezervasyon_kodu: "",
      musteri_adi: "",
    });

    const params = {
      date: "",
      rezervasyon_kodu: "",
      musteri_adi: "",
    };
    dispatch(filterTomorrowEntranceWithDepositData(params, () => {}));
  };

  return {
    isLoading,
    filterForm,
    handleFormChange,
    onGetFilteredList: handleGetFilteredList,
    onResetFilter: handleResetFilter,
  };
};

export default useHandleForm;
