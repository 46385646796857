import { createSlice } from "@reduxjs/toolkit";
import {
  TodayEntrancePayAtTheDoorResponseData,
  TodayEntrancePayAtTheDoorData,
  TodayEntrancePayAtTheDoorPayload,
} from "types/reservationMonitoring";

export interface TodayEntrancePayAtTheDoorInitialState {
  todayEntrancePayAtTheDoor: TodayEntrancePayAtTheDoorData[];
  filterTodayEntrancePayAtTheDoor: TodayEntrancePayAtTheDoorPayload;
  isLoading: boolean;
  error: string;
  changeStatusLoading: boolean;
  changeStatusError: string;
  callCustomerLoading: boolean;
  callCustomerError: string;
}

const initialState: TodayEntrancePayAtTheDoorInitialState = {
  todayEntrancePayAtTheDoor: [],
  filterTodayEntrancePayAtTheDoor: {
    date: "",
    rezervasyon_kodu: "",
    musteri_adi: "",
  },
  isLoading: false,
  error: "",
  changeStatusLoading: false,
  changeStatusError: "",
  callCustomerLoading: false,
  callCustomerError: "",
};

const todayEntrancePayAtTheDoorSlice = createSlice({
  name: "todayEntrancePayAtTheDoor",
  initialState,
  reducers: {
    handleSaveTodayEntrancePayAtTheDoorResponse(state, action) {
      const payload = action.payload as TodayEntrancePayAtTheDoorResponseData[];
      const todayEntrancePayAtTheDoor: TodayEntrancePayAtTheDoorData[] =
        payload.map((item) => ({
          id: item.id,
          rezervasyon_kodu: item.rezervasyon_kodu,
          otel_adi: item.otel_adi,
          musteri_ad_soyad: item.musteri_ad_soyad,
          musteri_cep: item.musteri_cep,
          musteri_tel: item.musteri_tel,
          toplam_tutar: item.toplam_tutar,
          kayit_tarihi: item.kayit_tarihi,
          iptal_edildi_mi: item.iptal_edildi_mi,
          iptal_nedeni: item.iptal_nedeni,
          kapida_odeme_giris: item.kapida_odeme_giris,
          satici_adi: item.satici_adi,
          otel_telefon: item.otel_telefon,
          grup_adi: item.grup_adi,
        }));
      state.todayEntrancePayAtTheDoor = todayEntrancePayAtTheDoor;
    },
    handleChangeFilterChoices(state, action) {
      state.filterTodayEntrancePayAtTheDoor = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setChangeStatusLoading(state, action) {
      state.changeStatusLoading = action.payload;
    },
    setChangeStatusError(state, action) {
      state.changeStatusError = action.payload;
    },
    setCallCustomerLoading(state, action) {
      state.callCustomerLoading = action.payload;
    },
    setCallCustomerError(state, action) {
      state.callCustomerError = action.payload;
    },
  },
});

export const {
  handleSaveTodayEntrancePayAtTheDoorResponse,
  handleChangeFilterChoices,
  setIsLoading,
  setError,
  setChangeStatusLoading,
  setChangeStatusError,
  setCallCustomerLoading,
  setCallCustomerError
} = todayEntrancePayAtTheDoorSlice.actions;

export default todayEntrancePayAtTheDoorSlice.reducer;
