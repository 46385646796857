import { useState } from "react";
import { Collapse, Input } from "reactstrap";
import {
  EditReservationData,
  EditReservationFormChoices,
} from "types/reservation";
import { CustomButton, CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

interface RoomOption {
  value: string;
  label: string;
  oda_id: number;
  pansiyon_id: number;
}
interface Option {
  value: number;
  label: string;
}

interface RoomInfoFormProps {
  count: number;
  reservation: EditReservationData;
  formChoices: EditReservationFormChoices;
  addNewRoom: boolean;
  handleChangeInput: (key: string, value: any) => void;
  handleAddNewRoom: () => void;
}

const RoomInfoForm = ({
  reservation,
  count,
  formChoices,
  addNewRoom,
  handleChangeInput,
  handleAddNewRoom,
}: RoomInfoFormProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <div className="d-flex flex-column gap-4 border rounded bg-white">
      <div
        className="d-flex align-items-center justify-content-between gap-2 p-3 rounded bg-dark bg-opacity-10"
        style={{ cursor: "pointer" }}
        onClick={toggle}
      >
        <div>
          <span className="fw-bold fs-18">{count}. Oda</span>
          <span>-</span>
          <span className="fw-medium ">({reservation.rezervasyon_id})</span>
        </div>
      </div>

      <Collapse className="p-3" isOpen={isOpen}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "3rem",
          }}
        >
          <CustomFormFieldContainer
            label="Oda/Pansiyon Tipi"
            divider
            orientation="vertical"
          >
            <div className="d-flex fs-14 fw-medium bg-light">
              <div className="border border-2 px-3 py-1">
                {reservation.oda_adi}
              </div>
              <div className="border border-2 px-3 py-1">
                {reservation.pansiyon_adi}
              </div>
            </div>
          </CustomFormFieldContainer>

          <CustomFormFieldContainer
            label="Oda/Pansiyon Değiştir"
            divider
            orientation="vertical"
          >
            <Select
              options={[]}
              value={formChoices.oda_pansiyon_tipi.find(
                (option) => option.value === reservation.pansiyon_adi
              )}
              onChange={(selectedOption: RoomOption) =>
                handleChangeInput("oda_pansiyon_tipi", selectedOption.value)
              }
            />
          </CustomFormFieldContainer>

          <CustomFormFieldContainer
            label="Yetişkin Sayısı"
            divider
            orientation="vertical"
          >
            <Select
              options={formChoices.yetiskin_sayisi}
              value={formChoices.yetiskin_sayisi.find(
                (option) => option.value === reservation.yetiskin_sayisi
              )}
              onChange={(selectedOption: Option) =>
                handleChangeInput("yetiskin_sayisi", selectedOption.value)
              }
            />
          </CustomFormFieldContainer>

          <CustomFormFieldContainer
            label="Çocuk Sayısı"
            divider
            orientation="vertical"
          >
            <Select
              options={formChoices.cocuk_sayisi}
              value={formChoices.cocuk_sayisi.find(
                (option) => option.value === reservation.cocuk_sayisi
              )}
              onChange={(selectedOption: Option) =>
                handleChangeInput("cocuk_sayisi", selectedOption.value)
              }
            />
          </CustomFormFieldContainer>

          <CustomFormFieldContainer
            label="1. Çocuk Doğum Tarihi"
            divider
            orientation="vertical"
          >
            <Flatpickr
              className="form-control"
              value={reservation.cikis_tarihi}
              onChange={(date) => {
                if (date) {
                  const stringStartDate = moment(date).format("YYYY-MM-DD");

                  handleChangeInput("giris_tarihi", stringStartDate);
                }
              }}
              options={{
                dateFormat: "Y-m-d",
                locale: Turkish,
                minDate: moment().subtract(1, "days").toDate(),
                onDayCreate,
              }}
              placeholder="Çocuk Doğum Tarihi"
            />
          </CustomFormFieldContainer>

          <CustomFormFieldContainer
            label="2. Çocuk Doğum Tarihi"
            divider
            orientation="vertical"
          >
            <Flatpickr
              className="form-control"
              value={reservation.cikis_tarihi}
              onChange={(date) => {
                if (date) {
                  const stringStartDate = moment(date).format("YYYY-MM-DD");

                  handleChangeInput("giris_tarihi", stringStartDate);
                }
              }}
              options={{
                dateFormat: "Y-m-d",
                locale: Turkish,
                minDate: moment().subtract(1, "days").toDate(),
                onDayCreate,
              }}
              placeholder="Çocuk Doğum Tarihi"
            />
          </CustomFormFieldContainer>

          <CustomFormFieldContainer
            label="3. Çocuk Doğum Tarihi"
            divider
            orientation="vertical"
          >
            <Flatpickr
              className="form-control"
              value={reservation.cikis_tarihi}
              onChange={(date) => {
                if (date) {
                  const stringStartDate = moment(date).format("YYYY-MM-DD");

                  handleChangeInput("giris_tarihi", stringStartDate);
                }
              }}
              options={{
                dateFormat: "Y-m-d",
                locale: Turkish,
                minDate: moment().subtract(1, "days").toDate(),
                onDayCreate,
              }}
              placeholder="Çocuk Doğum Tarihi"
            />
          </CustomFormFieldContainer>

          <CustomFormFieldContainer
            label="4. Çocuk Doğum Tarihi"
            divider
            orientation="vertical"
          >
            <Flatpickr
              className="form-control"
              value={reservation.cikis_tarihi}
              onChange={(date) => {
                if (date) {
                  const stringStartDate = moment(date).format("YYYY-MM-DD");

                  handleChangeInput("giris_tarihi", stringStartDate);
                }
              }}
              options={{
                dateFormat: "Y-m-d",
                locale: Turkish,
                minDate: moment().subtract(1, "days").toDate(),
                onDayCreate,
              }}
              placeholder="Çocuk Doğum Tarihi"
            />
          </CustomFormFieldContainer>

          <CustomFormFieldContainer
            label="4. Çocuk Doğum Tarihi"
            divider
            orientation="vertical"
          >
            <Input
              type="text"
              value={reservation.otel_adi}
              onChange={(e) => handleChangeInput("otel_adi", e.target.value)}
              disabled={true}
              style={{
                textTransform: "uppercase",
              }}
            />
          </CustomFormFieldContainer>
        </div>
      </Collapse>
    </div>
  );
};
export default RoomInfoForm;
