import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveTotalReservationDataResponse,
  setIsLoading,
  setError,
} from "./reducer";
import { setToastError } from "slices/toast/reducer";
import { GET_TOTAL_RESERVATION_DATA } from "helpers/url_helper";
import { Dispatch } from "redux";

export const getTotalReservationData = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(GET_TOTAL_RESERVATION_DATA);

    console.log("response : ", response)
    dispatch(handleSaveTotalReservationDataResponse(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};
