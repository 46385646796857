import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ListIncomingTranfersInitialState } from "slices/reservationLists/listIncomingTranfers/reducer";
import { listIncomingTransfers } from "slices/reservationLists/listIncomingTranfers/thunk";

const useListIncomingTransfers = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ListIncomingTranfers: ListIncomingTranfersInitialState }) =>
      state.ListIncomingTranfers
  );



  useEffect(() => {
    dispatch(listIncomingTransfers());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useListIncomingTransfers;
