import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Dispatch } from "redux";
import { ReviewReservationInitialState } from "slices/reservation/reviewReservation/reducer";
import {
  getCollectionFormOptions,
  getReservationReview,
} from "slices/reservation/reviewReservation/thunk";

const useReviewReservation = () => {
  const [searchParams] = useSearchParams();

  const dispatch: Dispatch<any> = useDispatch();

  const reservationId = searchParams.get("rezervasyon_id");

  const { isLoading } = useSelector(
    (state: { ReviewReservation: ReviewReservationInitialState }) =>
      state.ReviewReservation
  );

  useEffect(() => {
    if (reservationId) {
      dispatch(
        getReservationReview(reservationId, () => {
          dispatch(getCollectionFormOptions());
        })
      );
    }
  }, [dispatch, reservationId]);

  const isValid = reservationId ? true : false;

  return {
    isValid,
    isLoading,
  };
};

export default useReviewReservation;
