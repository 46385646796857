import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  ReservationReview,
  ReservationReviewCreditCard,
} from "types/reservation";

interface ChangeCreditCardInfoModalProps {
  reservation: ReservationReview;
  odeme_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  handleUpdateCardInfo: (payload: ReservationReviewCreditCard) => void;
}

const ChangeCreditCardInfoModal = ({
  reservation,
  odeme_id,
  isOpen,
  handleToggle,
  handleUpdateCardInfo,
}: ChangeCreditCardInfoModalProps) => {
  const creditCard = reservation.kk.find(
    (creditCard) => creditCard.odeme_id === odeme_id
  );

  const [formData, setFormData] = useState<ReservationReviewCreditCard>({
    odeme_id: odeme_id,
    kk_ad_soyad: "",
    kk_banka_adi: "",
    kk_taksit: "",
    kk_tutar: "",
    kk_vade_tutar: "",
    kk_banka_komisyon_tutar: "",
    kk_no: "",
    kk_tarih: "",
    kk_cvc: "",
    kk_mail_order: "",
    odeme_aciklama: "",
    inet_odeme_durum: "0",
    iptal_edildi_mi: "h",
  });

  useEffect(() => {
    if (creditCard) {
      setFormData({
        odeme_id: odeme_id,
        kk_ad_soyad: creditCard.kk_ad_soyad,
        kk_banka_adi: creditCard.kk_banka_adi,
        kk_taksit: creditCard.kk_taksit,
        kk_tutar: creditCard.kk_tutar,
        kk_vade_tutar: creditCard.kk_vade_tutar,
        kk_banka_komisyon_tutar: creditCard.kk_banka_komisyon_tutar,
        kk_no: creditCard.kk_no,
        kk_tarih: creditCard.kk_tarih,
        kk_cvc: creditCard.kk_cvc,
        kk_mail_order: creditCard.kk_mail_order,
        odeme_aciklama: creditCard.odeme_aciklama,
        inet_odeme_durum: creditCard.inet_odeme_durum,
        iptal_edildi_mi: creditCard.iptal_edildi_mi,
      });
    }
  }, [creditCard]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    handleUpdateCardInfo(formData);
  };

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="lg">
        <ModalHeader>Kredi Kartı Bilgilerini Güncelle</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label sm={4}>Ödeme Açıklama</Label>
            <Col sm={8}>
              <Input
                type="text"
                name="odeme_aciklama"
                value={formData.odeme_aciklama}
                onChange={handleChange}
                placeholder="Ödeme Açıklama"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Çekilecek Tutar</Label>
            <Col sm={8}>
              <Input
                type="number"
                name="kk_tutar"
                value={formData.kk_tutar}
                onChange={handleChange}
                placeholder="Tutar"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Banka Adı</Label>
            <Col sm={8}>
              <Input
                type="text"
                name="kk_banka_adi"
                value={formData.kk_banka_adi}
                onChange={handleChange}
                placeholder="Banka Adı"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Taksit Sayısı</Label>
            <Col sm={8}>
              <Input
                type="number"
                name="kk_taksit"
                value={formData.kk_taksit}
                onChange={handleChange}
                placeholder="Taksit"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Kart Sahibi</Label>
            <Col sm={8}>
              <Input
                type="text"
                name="kk_ad_soyad"
                value={formData.kk_ad_soyad}
                onChange={handleChange}
                placeholder="Kart Sahibi"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>Kart No</Label>
            <Col sm={8}>
              <Input
                type="text"
                name="kk_no"
                value={formData.kk_no}
                onChange={handleChange}
                placeholder="Kart Numarası"
              />
            </Col>
          </FormGroup>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label>Son Kullanma Tarihi</Label>
                <Input
                  type="text"
                  name="kk_tarih"
                  value={formData.kk_tarih}
                  onChange={handleChange}
                  placeholder="AA/YY"
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label>CVC</Label>
                <Input
                  type="text"
                  name="kk_cvc"
                  value={formData.kk_cvc}
                  onChange={handleChange}
                  placeholder="CVC"
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <CustomButton variant="danger" onClick={handleSubmit}>
            Kaydet
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default ChangeCreditCardInfoModal;
