import TotalReservationDataPage from "Components/Custom/ReservationListsPages/TotalReservationDataPage";
import useTotalReservationData from "Components/Hooks/reservationLists/useTotalReservationData";
import PageContainer from "pages/PageContainer";

const TotalReservationData = () => {
  const { isLoading } = useTotalReservationData();

  return (
    <PageContainer title="Toplam Rezervasyon Rakamları">
      <TotalReservationDataPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default TotalReservationData;
