import HotelsWithoutPriceLink from "./HotelsWithoutPriceLink";

const DocumentControlsPage = () => {
  return (
    <div className="d-flex flex-column gap-4">
      <HotelsWithoutPriceLink />
    </div>
  );
};

export default DocumentControlsPage;
