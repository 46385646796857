import { CustomLoadingPage } from "Components/Custom/UI";
import ReservationInfo from "./ReservationInfo";
import useHandleData from "./useHandleData";
import useHandleRequests from "./useHandleRequests";
import ReservationHeader from "./ReservationHeader";
import ReservationRoomsInfo from "./ReservationRoomsInfo";
import ReservationPaymentInfo from "./ReservationPaymentInfo";
import ReservationInvoiceInfo from "./ReservationInvoiceInfo";
import ReservationAvailabilityInfo from "./ReservationAvailabilityInfo";
import ReservationActions from "./ReservationActions";
import ReservationCollectionInfo from "./ReservationCollectionInfo";
import ChangeCreditCardInfoModal from "./Modals/ChangeCreditCardInfoModal";
import CancelReservationCollectionModal from "./Modals/CancelReservationCollectionModal";
import ApprovePaymentModal from "./Modals/ApprovePaymentModal";
import { makePayment } from "slices/reservation/reviewReservation/thunk";

interface ReviewReservationPageProps {
  isLoading: boolean;
}

const ReviewReservationPage = ({ isLoading }: ReviewReservationPageProps) => {
  const { reservation, reservationOtherRooms } = useHandleData();

  const {
    showCollectionCancellationModal,
    setShowCollectionCancellationModal,
    selectedCollectionId,
    setSelectedCollectionId,
    showUpdateCreditCardModal,
    setShowUpdateCreditCardModal,
    selectedPaymentId,
    setSelectedPaymentId,
    showApprovePaymentModal,
    setShowApprovePaymentModal,
    toggleCollectionCancellationModal,
    toggleUpdateCreditCardModal,
    toggleApprovePaymentModal,
    handleCreateRequest,
    handleUpdateCardInfo,
    selectedCreditCard,
    setSelectedCreditCard,
    handleApprovePayment,
  } = useHandleRequests();

  return (
    <>
      <CustomLoadingPage isLoading={isLoading} />
      <div className="d-flex flex-column gap-4">
        <ReservationHeader reservation={reservation} />
        <ReservationInfo reservation={reservation} />
        <ReservationRoomsInfo
          reservation={reservation}
          reservationOtherRooms={reservationOtherRooms}
        />
        <ReservationPaymentInfo
          reservation={reservation}
          setSelectedPaymentId={setSelectedPaymentId}
          setShowUpdateCreditCardModal={setShowUpdateCreditCardModal}
          setShowApprovePaymentModal={setShowApprovePaymentModal}
          setSelectedCreditCard={setSelectedCreditCard}
        />
        {showUpdateCreditCardModal && (
          <ChangeCreditCardInfoModal
            reservation={reservation}
            odeme_id={selectedPaymentId}
            isOpen={showUpdateCreditCardModal}
            handleToggle={toggleUpdateCreditCardModal}
            handleUpdateCardInfo={handleUpdateCardInfo}
          />
        )}
        {showApprovePaymentModal && (
          <ApprovePaymentModal
            isOpen={showApprovePaymentModal}
            handleToggle={toggleApprovePaymentModal}
            onConfirm={() => {
              handleApprovePayment(selectedCreditCard, reservation);
              setShowApprovePaymentModal(false);
            }}
            onClose={() => setShowApprovePaymentModal(false)}
          />
        )}
        <ReservationCollectionInfo
          reservation={reservation}
          setSelectedCollectionId={setSelectedCollectionId}
          setShowCollectionCancellationModal={
            setShowCollectionCancellationModal
          }
        />
        {showCollectionCancellationModal && (
          <CancelReservationCollectionModal
            reservation={reservation}
            tahsilat_id={selectedCollectionId}
            isOpen={showCollectionCancellationModal}
            handleToggle={toggleCollectionCancellationModal}
            handleCreateRequest={handleCreateRequest}
          />
        )}
        <ReservationInvoiceInfo reservation={reservation} />
        <ReservationActions reservation={reservation} />
        <ReservationAvailabilityInfo reservation={reservation} />
      </div>
    </>
  );
};

export default ReviewReservationPage;
