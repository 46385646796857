import { CustomButton, CustomModal } from "Components/Custom/UI";
import {
  Col,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  ChangeGroupReservationStatusPayload,
  GroupReservationsData,
} from "types/reservationMonitoring";

const lineStyle: React.CSSProperties = {
  borderBottom: "1px solid #eaeaea",
  maxWidth: "1400px",
  margin: "0 auto",
  paddingBottom: "4px",
};

interface ShowDetailModalProps {
  reservations: GroupReservationsData[];
  reservation_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  approveReservation: (payload: ChangeGroupReservationStatusPayload )=> void;
  cancelReservation: (payload: ChangeGroupReservationStatusPayload )=> void;
  isLoading: boolean;
}

const ShowDetailModal = ({
  reservations,
  reservation_id,
  isOpen,
  handleToggle,
  approveReservation,
  cancelReservation,
  isLoading,
}: ShowDetailModalProps) => {
  const reservation = reservations.find(
    (reservation) => reservation.id === reservation_id
  )!;

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="xl">
        <ModalHeader>Grup Rezervasyon İncele</ModalHeader>
        <ModalBody>
          <Row className="mx-2 mt-4">
            <Col>
              <div className="d-flex gap-2">
                <Label style={{ minWidth: "150px" }}>Otel Adı :</Label>
                <p style={{ margin: 0 }}>{reservation.otel_adi ? reservation.otel_adi : ""}</p>
              </div>
            </Col>
            <Col>
              <div className="d-flex gap-2">
                <Label style={{ minWidth: "150px" }}>Yetişkin Sayısı :</Label>
                <p style={{ margin: 0 }}>{reservation.yetiskin_sayisi ?? ""}</p>
              </div>
            </Col>
          </Row>
          <div style={lineStyle}></div>

          <Row className="d-flex mx-2 mt-4 ">
            <Col>
              <div className="d-flex gap-2" style={{ marginRight: "2rem" }}>
                <Label style={{ minWidth: "150px" }}>Müşteri Ad Soyad :</Label>
                <p style={{ margin: 0 }}>
                  {reservation.musteri_ad_soyad ?? ""}
                </p>
              </div>
            </Col>
            <Col>
              <div className="d-flex gap-2">
                <Label style={{ minWidth: "150px" }}>
                  Müşteri Cep Telefonu :
                </Label>
                <p style={{ margin: 0 }}>{reservation.musteri_cep ?? ""}</p>
              </div>
            </Col>
          </Row>
          <div style={lineStyle}></div>

          <Row className="d-flex mx-2 mt-4 ">
            <Col>
              <div className="d-flex gap-2" style={{ marginRight: "2rem" }}>
                <Label style={{ minWidth: "150px" }}>Müşteri Eposta :</Label>
                <p style={{ margin: 0 }}>{reservation.musteri_eposta ?? ""}</p>
              </div>
            </Col>
            <Col>
              <div className="d-flex gap-2">
                <Label style={{ minWidth: "150px" }}>Opsiyon Tarihi :</Label>
                <p style={{ margin: 0 }}>{reservation.opsiyon_tarihi ?? ""}</p>
              </div>
            </Col>
          </Row>
          <div style={lineStyle}></div>

          <Row className="mx-2 mt-4 d-flex">
            <Col>
              <div className="d-flex gap-2" style={{ marginRight: "2rem" }}>
                <Label style={{ minWidth: "150px" }}>Giriş Tarihi:</Label>
                <p style={{ margin: 0 }}>{reservation.giris_tarihi ?? ""}</p>
              </div>
            </Col>
            <Col>
              <div className="d-flex gap-2">
                <Label style={{ minWidth: "150px" }}>Çıkış Tarihi:</Label>
                <p style={{ margin: 0 }}>{reservation.cikis_tarihi ?? ""}</p>
              </div>
            </Col>
          </Row>
          <div style={lineStyle}></div>

          <Row className="mx-2 mt-4">
            <div className="d-flex gap-2">
              <Label style={{ minWidth: "150px" }}>Özel İstekler :</Label>
              <p style={{ margin: 0 }}>{reservation.ozel_istekler ?? ""}</p>
            </div>
          </Row>
          <div style={lineStyle}></div>
          <Row className="mx-2 mt-4">
            <div className="d-flex gap-2">
              <Label style={{ minWidth: "150px" }}>Durum Açıklaması :</Label>
              <p style={{ margin: 0 }}>{reservation.islemler ?? ""}</p>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="success"
            onClick={() =>
              approveReservation({
                rezervasyon_id: reservation_id,
              })
            }
            isLoading={isLoading}
          >
            Onayla
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="danger"
            onClick={() =>{
              console.log("rez id : ", reservation_id)
              cancelReservation({
                rezervasyon_id: reservation_id,
              })
              console.log("cancel button clicked!")}
            }
            isLoading={isLoading}
          >
            İptal Et
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default ShowDetailModal;
