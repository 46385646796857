import { CustomFormContainer } from "Components/Custom/UI";
import { ReservationReview } from "types/reservation";
import InfoRow from "./InfoRow";
import { Dispatch, SetStateAction, useState } from "react";
import CollectionForm from "./CollectionForm";
import CollectionRow from "./CollectionRow";

interface ReservationCollectionInfoProps {
  reservation: ReservationReview;
  setSelectedCollectionId: Dispatch<SetStateAction<number>>;
  setShowCollectionCancellationModal: Dispatch<SetStateAction<boolean>>;
}

const ReservationCollectionInfo = ({
  reservation,
  setSelectedCollectionId,
  setShowCollectionCancellationModal,
}: ReservationCollectionInfoProps) => {
  const [showCollectionForm, setShowCollectionForm] = useState(false);

  const showCollectionRow =
    reservation.tahsilat_bilgileri && reservation.tahsilat_bilgileri.length > 0;

  const handleShowCollectionForm = () => {
    setShowCollectionForm((prevState) => !prevState);
  };

  return (
    <CustomFormContainer title="Otel Tahsilat Bilgileri">
      <InfoRow
        reservation={reservation}
        handleShowCollectionForm={handleShowCollectionForm}
        showCollectionForm={showCollectionForm}
      />
      {showCollectionForm && <CollectionForm />}
      {showCollectionRow &&
        reservation.tahsilat_bilgileri!.map((tahsilat) => (
          <CollectionRow
            key={tahsilat.tahsilat_id}
            tahsilat={tahsilat}
            setSelectedCollectionId={setSelectedCollectionId}
            setShowCollectionCancellationModal={
              setShowCollectionCancellationModal
            }
          />
        ))}
    </CustomFormContainer>
  );
};

export default ReservationCollectionInfo;
