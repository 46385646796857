import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";
import HotelReducer from "./hotel/reducer";
import BasicPriceEntryReducer from "./pricesAndQuota/basicPriceEntry/reducer";
import BasicAvailabilityEntry from "./pricesAndQuota/basicAvailabilityEntry/reducer";
import BasicQuotaEntry from "./pricesAndQuota/basicQuotaEntry/reducer";
import Toast from "./toast/reducer";
import AdvancedEntryReducer from "./pricesAndQuota/advancedEntry/reducer";
import SpecialDiscounts from "./discountsAndActions/specialDiscounts/reducer";
import MobileDiscounts from "./discountsAndActions/mobileDiscounts/reducer";
import GeneralPriceSettings from "./pricesAndQuota/generalPriceSettings/reducer";
import BreakingDiscounts from "./discountsAndActions/breakingDiscounts/reducer";
import HourlyDiscounts from "./discountsAndActions/hourlyDiscounts/reducer";
import UncancellableRoomRates from "./discountsAndActions/uncancellableRoomRates/reducer";
import EarlyReservationDiscounts from "./discountsAndActions/earlyReservationDiscounts/reducer";
import StayLongPayLessActions from "./discountsAndActions/stayLongPayLessActions/reducer";
import UnderTheDeskDiscounts from "./discountsAndActions/underTheDeskDiscounts/reducer";
import HotelSearchBar from "./hotelSearcBar/reducer";
import GiftsAndPromotions from "./discountsAndActions/giftsAndPromotions/reducer";
import AllGiftsList from "./discountsAndActions/allGiftsList/reducer";
import DiscountCodes from "./discountsAndActions/discountCodes/reducer";
import NightRestrictions from "./discountsAndActions/nightRestrictions/reducer";
import AddLocationReducer from "./definitions/addLocation/reducer";
import ListLocationsReducer from "./definitions/listLocations/reducer";
import AddHotelFeatureReducer from "./definitions/addHotelFeature/reducer";
import AddEarlyReservationDefinitionReducer from "./definitions/addEarlyReservationDefinition/reducer";
import AddPlaceToVisitReducer from "./definitions/addPlaceToVisit/reducer";
import ListPlacesToVisitReducer from "./definitions/listPlacesToVisit/reducer";
import DefineCurrencyReducer from "./definitions/defineCurrency/reducer";
import AddNewThemeReducer from "./themes/addNewTheme/reducer";
import ListThemesReducer from "./themes/listThemes/reducer";
import AssignHotelToThemeReducer from "./themes/assignHotelToTheme/reducer";
import AssignRecommendedHotelsReducer from "./themes/assignRecommendedHotels/reducer";
import OrderThemeHotelsReducer from "./themes/orderThemeHotels/reducer";
import AddFacilityReducer from "./facility/addFacility/reducer";
import UpdateFacilityReducer from "./facility/updateFacility/reducer";
import RoomSalesAndPaymentMethods from "./pricesAndQuota/roomSalesAndPaymentMethods/reducer";
import ViewFeaturedImagesReducer from "./facility/viewFeaturedImages/reducer";
import FacilityImagesReducer from "./facility/facilityImages/reducer";
import FacilityRoomsReducer from "./facility/facilityRooms/reducer";
import FacilityConceptsReducer from "./facility/facilityConcepts/reducer";
import FacilityUsersReducer from "./facility/facilityUsers/reducer";
import FacilityManagersReducer from "./facility/facilityManagers/reducer";
import FacilitySpecialDaysReducer from "./facility/facilitySpecialDays/reducer";
import ListSpecialDaysReducer from "./facility/listSpecialDays/reducer";
import RoomConceptMatchReducer from "./facility/roomConceptMatch/reducer";
import FacilityCallsReducer from "./facility/facilityCalls/reducer";
import FacilityPartialPaymentDatesReducer from "./facility/facilityPartialPaymentDates/reducer";
import MoneyPointsReducer from "./reports/moneyPoints/reducer";
import CustomerMailsReducer from "./reports/customerMails/reducer";
import PhoneStatusReducer from "./reports/phoneStatus/reducer";
import DailyReservationsReducer from "./reports/dailyReservations/reducer";
import DailyCallsChartsReducer from "./reports/dailyCallsCharts/reducer";
import HourlyCallsChartReducer from "./reports/hourlyCallsChart/reducer";
import OurOwnHotelsReducer from "./reports/ourOwnHotels/reducer";
import WelcomeAndCalculationReducer from "./reports/welcomeAndCalculation/reducer";
import RoomPersonPriceRateReducer from "./pricesAndQuota/roomPersonPriceRates/reducer";
import RoomsLackPictureReducer from "./reports/roomsLackPicture/reducer";
import ForecastReportReducer from "./reports/forecastReport/reducer";
import AnsweredCallsReducer from "./reports/answeredCalls/reducer";
import OutboundCallsListReducer from "./reports/outboundCallsList/reducer";
import OutboundCallsListWithNumberReducer from "./reports/outboundCallsListWithNumber/reducer";
import DailyCallsPerPersonReducer from "./reports/dailyCallsPerPerson/reducer";
import EmployeeSalesAmountReducer from "./reports/employeeSalesAmount/reducer";
import RoomsLackDescriptionReducer from "./reports/roomsLackDescription/reducer";
import RiskyHotelsReducer from "./reports/riskyHotels/reducer";
import PhoneStatusListReducer from "./reports/phoneStatusList/reducer";
import DailyCallsForHotelsReducer from "./reports/dailyCallsForHotels/reducer";
import CallsFromGeneralReducer from "./reports/callsFromGeneral/reducer";
import MonthlySalesReducer from "./reports/monthlySales/reducer";
import AdsGroupsReducer from "./adsAndAnalyze/adsGroups/reducer";
import AdsReportReducer from "./adsAndAnalyze/adsReport/reducer";
import HotelSalesReducer from "./reports/hotelSales/reducer";
import HotelInvoicesReducer from "./financeAndAccounting/hotelInvoices/reducer";
import ContractRulesReducer from "./financeAndAccounting/contractRules/reducer";
import ChildInformationReducer from "./pricesAndQuota/childInformation/reducer";
import HotelContractsReducer from "./financeAndAccounting/hotelContracts/reducer";
import ArchivedContractsReducer from "./financeAndAccounting/archivedContracts/reducer";
import IssuedInvoicesReducer from "./financeAndAccounting/issuedInvoices/reducer";
import MailOrderInfoReducer from "./financeAndAccounting/mailOrderInfo/reducer";
import CommissionPaymentReducer from "./financeAndAccounting/commissionPayment/reducer";
import ChildSpecialAgeInformationReducer from "./pricesAndQuota/childSpecialAgeInformation/reducer";
import CompetitorAnalysisReducer from "./adsAndAnalyze/competitorAnalysis/reducer";
import CompetitorAnalysisInfoReducer from "./adsAndAnalyze/competitorAnalysisInfo/reducer";
import PersonnelRequestReducer from "./personnelRequest/reducer";
import ListCommentsReducer from "./comments/listComments/reducer";
import AddCommentReducer from "./comments/addComment/reducer";
import ListHotelsWithoutCommentReducer from "./comments/listHotelsWithoutComment/reducer";
import CreateWebSiteReducer from "./myWebSite/createWebSite/reducer";
import AddFooterLinkReducer from "./myWebSite/addFooterLinks/reducer";
import ListWebSitesReducer from "./myWebSite/listWebSites/reducer";
import AddCertificateReducer from "./myWebSite/addCertificate/reducer";
import AdditionalPagesReducer from "./myWebSite/additionalPages/reducer";
import CreateWidgetReducer from "./myWebSite/createWidget/reducer";
import ReservationsReducer from "./reports/reservations/reducer";
import CategorizeFacilityReducer from "./facilityCategorization/categorizeFaclity/reducer";
import NotificationsReducer from "./userOperations/notifications/reducer";
import CreateNotificationReducer from "./userOperations/createNotification/reducer";
import AddUserReducer from "./userOperations/addUser/reducer";
import ListUsersReducer from "./userOperations/listUsers/reducer";
import UpdateUserReducer from "./userOperations/updateUser/reducer";
import UserAuthoritiesReducer from "./userOperations/authorities/reducer";
import PersonnelHotelAssignmentsReducer from "./userOperations/personnelHotelAssignments/reducer";
import ListLogsReducer from "./userOperations/listLogs/reducer";
import AddGroupReducer from "./userOperations/addGroup/reducer";
import ListGroupsReducer from "./userOperations/listGroups/reducer";
import UpdateGroupReducer from "./userOperations/updateGroup/reducer";
import PeritoExtensionNumbersReducer from "./userOperations/peritoExtensionNumbers/reducer";
import ReservationChangeApproveCancelReducer from "./userOperations/reservationChangeApproveCancel/reducer";
import TransferReservationsReducer from "./userOperations/transferReservations/reducer";
import UserIPsReducer from "./userOperations/userIPs/reducer";
import InformationTechnologiesReducer from "./userOperations/informationTechnologies/reducer";
import ReservationCallReducer from "./reservation/reservationCall/reducer";
import ReviewReservationReducer from "./reservation/reviewReservation/reducer";
import EditReservationReducer from "./reservation/editReservation/reducer";
import DashboardReducer from "./dashboard/reducer";
import CrmDashboardReducer from "./dashboard/crm/reducer";
import FindReservationsReducer from "./reservationLists/findReservation/reducer";
import ListReservationsChannelReducer from "./reservationLists/listReservationsChannel/reducer";
import ListIncomingTranfersReducer from "./reservationLists/listIncomingTranfers/reducer";
import INetNonentryListReducer from "./reservationLists/inetNonentryList/reducer";
import TotalReservationDataReducer from "./reservationLists/totalReservationData/reducer";
import ListConfirmationApprovalReducer from "./reservationLists/confirmationApproval/reducer";
import NoteConfirmationReservationsReducer from "./reservationLists/noteconfirmationReservations/reducer";
import ListOnRequestReservationsReducer from "./reservationMonitoring/listOnRequestReservations/reducer";
import ListPriceReviseReducer from "./reservationMonitoring/listPriceRevise/reducer";
import ListCostReviseReducer from "./reservationMonitoring/listCostRevise/reducer";
import TemporaryOnlineReservationsReducer from "./reservationMonitoring/temporaryOnlineReservations/reducer";
import CallUsFormsReducer from "./reservationMonitoring/callUsForms/reducer";
import GroupReservationsReducer from "./reservationMonitoring/groupReservations/reducer";
import TodayEntrancePayAtTheDoorReducer from "./reservationMonitoring/todayEntrancePayAtTheDoor/reducer";
import TomorrowEntranceWithDepositReducer from "./reservationMonitoring/tomorrowEntranceWithDeposit/reducer";
import ListCancellationRequestsReducer from "./reservationMonitoring/listCancellationRequests/reducer";
import InetRoomsWithoutImagesReducer from "./reservationMonitoring/inetRoomsWithoutImages/reducer";
import InetRoomsWithoutDescriptionReducer from "./reservationMonitoring/inetRoomsWithoutDescription/reducer";
import HotelsWithoutPriceReducer from "./documents/hotelsWithoutPrice/reducer";
import ZoiperCallReducer from "./zoiper/reducer";
import EnterGroupReservationReducer from "./reservation/enterGroupReservation/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Hotel: HotelReducer,
  BasicPriceEntry: BasicPriceEntryReducer,
  BasicAvailabilityEntry: BasicAvailabilityEntry,
  BasicQuotaEntry: BasicQuotaEntry,
  Toast: Toast,
  AdvancedEntry: AdvancedEntryReducer,
  GeneralPriceSettings: GeneralPriceSettings,
  SpecialDiscounts: SpecialDiscounts,
  MobileDiscounts: MobileDiscounts,
  BreakingDiscounts: BreakingDiscounts,
  HourlyDiscounts: HourlyDiscounts,
  UncancellableRoomRates: UncancellableRoomRates,
  EarlyReservationDiscounts: EarlyReservationDiscounts,
  StayLongPayLessActions: StayLongPayLessActions,
  UnderTheDeskDiscounts: UnderTheDeskDiscounts,
  HotelSearchBar: HotelSearchBar,
  GiftsAndPromotions: GiftsAndPromotions,
  AllGiftsList: AllGiftsList,
  DiscountCodes: DiscountCodes,
  NightRestrictions: NightRestrictions,
  AddLocation: AddLocationReducer,
  ListLocations: ListLocationsReducer,
  AddHotelFeature: AddHotelFeatureReducer,
  AddEarlyReservationDefinition: AddEarlyReservationDefinitionReducer,
  AddPlaceToVisit: AddPlaceToVisitReducer,
  ListPlacesToVisit: ListPlacesToVisitReducer,
  DefineCurrency: DefineCurrencyReducer,
  AddNewTheme: AddNewThemeReducer,
  ListThemes: ListThemesReducer,
  AssignHotelToTheme: AssignHotelToThemeReducer,
  AssignRecommendedHotels: AssignRecommendedHotelsReducer,
  OrderThemeHotels: OrderThemeHotelsReducer,
  AddFacility: AddFacilityReducer,
  RoomSalesAndPaymentMethods: RoomSalesAndPaymentMethods,
  UpdateFacility: UpdateFacilityReducer,
  ViewFeaturedImages: ViewFeaturedImagesReducer,
  FacilityImages: FacilityImagesReducer,
  FacilityRooms: FacilityRoomsReducer,
  FacilityConcepts: FacilityConceptsReducer,
  FacilityUsers: FacilityUsersReducer,
  FacilityManagers: FacilityManagersReducer,
  FacilitySpecialDays: FacilitySpecialDaysReducer,
  ListSpecialDays: ListSpecialDaysReducer,
  RoomConceptMatch: RoomConceptMatchReducer,
  FacilityCalls: FacilityCallsReducer,
  FacilityPartialPaymentDates: FacilityPartialPaymentDatesReducer,
  MoneyPoints: MoneyPointsReducer,
  CustomerMails: CustomerMailsReducer,
  PhoneStatus: PhoneStatusReducer,
  DailyReservations: DailyReservationsReducer,
  DailyCallsCharts: DailyCallsChartsReducer,
  HourlyCallsChart: HourlyCallsChartReducer,
  OurOwnHotels: OurOwnHotelsReducer,
  WelcomeAndCalculation: WelcomeAndCalculationReducer,
  RoomPersonPriceRates: RoomPersonPriceRateReducer,
  RoomsLackPicture: RoomsLackPictureReducer,
  ForecastReport: ForecastReportReducer,
  AnsweredCalls: AnsweredCallsReducer,
  OutboundCallsList: OutboundCallsListReducer,
  OutboundCallsListWithNumber: OutboundCallsListWithNumberReducer,
  DailyCallsPerPerson: DailyCallsPerPersonReducer,
  EmployeeSalesAmount: EmployeeSalesAmountReducer,
  RoomsLackDescription: RoomsLackDescriptionReducer,
  RiskyHotels: RiskyHotelsReducer,
  PhoneStatusList: PhoneStatusListReducer,
  DailyCallsForHotels: DailyCallsForHotelsReducer,
  CallsFromGeneral: CallsFromGeneralReducer,
  MonthlySales: MonthlySalesReducer,
  AdsGroups: AdsGroupsReducer,
  AdsReport: AdsReportReducer,
  HotelSales: HotelSalesReducer,
  HotelInvoices: HotelInvoicesReducer,
  ContractRules: ContractRulesReducer,
  ChildInformations: ChildInformationReducer,
  HotelContracts: HotelContractsReducer,
  ArchivedContracts: ArchivedContractsReducer,
  IssuedInvoices: IssuedInvoicesReducer,
  MailOrderInfo: MailOrderInfoReducer,
  CommissionPayment: CommissionPaymentReducer,
  ChildSpecialAgeInformations: ChildSpecialAgeInformationReducer,
  CompetitorAnalysis: CompetitorAnalysisReducer,
  CompetitorAnalysisInfo: CompetitorAnalysisInfoReducer,
  PersonnelRequest: PersonnelRequestReducer,
  ListComments: ListCommentsReducer,
  AddComment: AddCommentReducer,
  ListHotelsWithoutComment: ListHotelsWithoutCommentReducer,
  CreateWebSite: CreateWebSiteReducer,
  AddFooterLink: AddFooterLinkReducer,
  ListWebSites: ListWebSitesReducer,
  AddCertificate: AddCertificateReducer,
  AdditionalPages: AdditionalPagesReducer,
  CreateWidget: CreateWidgetReducer,
  Reservations: ReservationsReducer,
  CategorizeFacility: CategorizeFacilityReducer,
  Notifications: NotificationsReducer,
  CreateNotification: CreateNotificationReducer,
  AddUser: AddUserReducer,
  ListUsers: ListUsersReducer,
  UpdateUser: UpdateUserReducer,
  UserAuthorities: UserAuthoritiesReducer,
  PersonnelHotelAssignments: PersonnelHotelAssignmentsReducer,
  ListLogs: ListLogsReducer,
  AddGroup: AddGroupReducer,
  ListGroups: ListGroupsReducer,
  UpdateGroup: UpdateGroupReducer,
  PeritoExtensionNumbers: PeritoExtensionNumbersReducer,
  ReservationChangeApproveCancel: ReservationChangeApproveCancelReducer,
  TransferReservations: TransferReservationsReducer,
  UserIPs: UserIPsReducer,
  InformationTechnologies: InformationTechnologiesReducer,
  ReservationCall: ReservationCallReducer,
  ReviewReservation: ReviewReservationReducer,
  EditReservation: EditReservationReducer,
  Dashboard: DashboardReducer,
  CrmDashboard: CrmDashboardReducer,
  FindReservations: FindReservationsReducer,
  ListReservationsChannel: ListReservationsChannelReducer,
  ListIncomingTranfers: ListIncomingTranfersReducer,
  INetNonentryList: INetNonentryListReducer,
  TotalReservationData: TotalReservationDataReducer,
  ListConfirmationApproval: ListConfirmationApprovalReducer,
  NoteConfirmationReservations: NoteConfirmationReservationsReducer,
  ListOnRequestReservations: ListOnRequestReservationsReducer,
  ListPriceRevise: ListPriceReviseReducer,
  ListCostRevise: ListCostReviseReducer,
  TemporaryOnlineReservations: TemporaryOnlineReservationsReducer,
  CallUsForms: CallUsFormsReducer,
  GroupReservations: GroupReservationsReducer,
  TodayEntrancePayAtTheDoor: TodayEntrancePayAtTheDoorReducer,
  TomorrowEntranceWithDeposit: TomorrowEntranceWithDepositReducer,
  ListCancellationRequests: ListCancellationRequestsReducer,
  InetRoomsWithoutImages: InetRoomsWithoutImagesReducer,
  InetRoomsWithoutDescription: InetRoomsWithoutDescriptionReducer,
  HotelsWithoutPrice: HotelsWithoutPriceReducer,
  ZoiperCall: ZoiperCallReducer,
  EnterGroupReservation: EnterGroupReservationReducer,
});

export default rootReducer;
