import {
  CustomButton,
  CustomFormContainer,
  CustomModal,
} from "Components/Custom/UI";
import { ReservationReview } from "types/reservation";
import useHandleActions from "./useHandleActions";
import { useState } from "react";

interface ReservationActionsProps {
  reservation: ReservationReview;
}

const ReservationActions = ({ reservation }: ReservationActionsProps) => {
  const {
    onCancelReservation,
    onApproveReservation,
    onSendConfirmation,
    onSendVoucher,
    onSendInvoice,
    onGetConfirmationFile,
    onGetVoucherFile,
    onGetInvoiceFile,
  } = useHandleActions(reservation);

  const {
    konfirme_edildi_mi,
    iptal_edildi_mi,
    inet_no_show_durum,
    kismi_odeme_durum,
    muhasebe_bilgileri,
    musaitlik,
    ucretli_cocuk_durum,
    rez_tipi,
    farkli_acenteden_satis_durum,
  } = reservation;

  const customerInvoiceNumber = muhasebe_bilgileri
    ? muhasebe_bilgileri.misafir_fatura_no
    : "";

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const showSpecificApproveMessage =
    ((ucretli_cocuk_durum && rez_tipi !== "kaporalisatis") ||
      farkli_acenteden_satis_durum === "1") &&
    rez_tipi !== "kapidaodeme";

  const isApproved =
    musaitlik === "Evet" &&
    (konfirme_edildi_mi === "h" || konfirme_edildi_mi === "k") &&
    iptal_edildi_mi === "h" &&
    inet_no_show_durum === "0";

  const showVoucherButton =
    iptal_edildi_mi === "h" &&
    inet_no_show_durum === "0" &&
    (konfirme_edildi_mi === "e" ||
      (konfirme_edildi_mi === "k" &&
        kismi_odeme_durum === "1" &&
        muhasebe_bilgileri?.toplam_cekilen_tutar <=
          muhasebe_bilgileri?.toplam_tahsilat_tutar));

  const showConfirmationButton =
    konfirme_edildi_mi === "e" ||
    (konfirme_edildi_mi === "k" &&
      kismi_odeme_durum === "1" &&
      muhasebe_bilgileri?.toplam_cekilen_tutar <=
        muhasebe_bilgileri?.toplam_tahsilat_tutar);

  const showInvoiceButton =
    iptal_edildi_mi === "h" &&
    konfirme_edildi_mi === "e" &&
    !!muhasebe_bilgileri?.misafir_fatura_no;

  const showCancelReservationButton =
    iptal_edildi_mi !== "e" && inet_no_show_durum === "0";

  return (
    <>
      <CustomFormContainer title="İşlemler">
        {customerInvoiceNumber ? (
          <span className="theme-text-danger fw-bold">
            Fatura No: {customerInvoiceNumber}
          </span>
        ) : (
          <></>
        )}
        {showSpecificApproveMessage && (
          <span className="theme-text-danger fw-bold">
            Bu Rezervasyonun Onaylama İşlemini Operasyon Yapacaktır.
          </span>
        )}
        <div className="d-flex align-items-center gap-2">
          {!showSpecificApproveMessage && !isApproved && (
            <>
              <CustomButton variant="success" onClick={onApproveReservation}>
                Onayla
              </CustomButton>
            </>
          )}

          {showVoucherButton && (
            <CustomButton variant="primary" onClick={onSendVoucher}>
              Voucher Gönder
            </CustomButton>
          )}
          {showConfirmationButton && (
            <CustomButton variant="primary" onClick={onSendConfirmation}>
              Konfirme Gönder
            </CustomButton>
          )}
          {showInvoiceButton && (
            <CustomButton variant="info" onClick={onSendInvoice}>
              Fatura Gönder
            </CustomButton>
          )}
          {showVoucherButton && (
            <CustomButton variant="primary" onClick={onGetVoucherFile}>
              Voucher İndir
            </CustomButton>
          )}
          {showConfirmationButton && (
            <CustomButton variant="primary" onClick={onGetConfirmationFile}>
              Konfirme İndir
            </CustomButton>
          )}
          {showInvoiceButton && (
            <CustomButton variant="info" onClick={onGetInvoiceFile}>
              Fatura İndir
            </CustomButton>
          )}
          {showCancelReservationButton && (
            <CustomButton variant="danger" onClick={handleToggle}>
              Satıştan İptal Et
            </CustomButton>
          )}
        </div>
      </CustomFormContainer>
      <CustomModal
        isOpen={isOpen}
        handleToggle={handleToggle}
        title="Rezervasyonu İptal Et"
        message="Rezervasyonu iptal etmek istediğinize emin misiniz?"
        approveButtonLabel="İptal Et"
        approveCallback={() => {
          onCancelReservation();
          handleToggle();
        }}
        cancelButtonLabel="Vazgeç"
        cancelCallback={handleToggle}
      />
    </>
  );
};

export default ReservationActions;
