import { createSlice } from "@reduxjs/toolkit";
import { ListOnRequestReservationsData, ListOnRequestReservationsPayload } from "types/reservationMonitoring";


export interface ListOnRequestReservationsInitialState {
  onRequestReservations: ListOnRequestReservationsData[];
  filterOnRequestReservations: ListOnRequestReservationsPayload;
  isLoading: boolean;
  error: string;
  markAsResponsibleLoading: boolean;
  markAsResponsibleError: string;
}

const initialState: ListOnRequestReservationsInitialState = {
  onRequestReservations: [],
  filterOnRequestReservations: {
    start_date: "",
    end_date: "",
    sorsat_id: "",
  },
  isLoading: false,
  error: "",
  markAsResponsibleLoading: false,
  markAsResponsibleError: "",
};

const onRequestReservationsSlice = createSlice({
  name: "onRequestReservations",
  initialState,
  reducers: {
    handleSaveOnRequestReservationsResponse(state, action) {
      state.onRequestReservations = action.payload;
    },
    handleChangeFilterChoices(state, action) {
      state.filterOnRequestReservations = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setMarkAsResponsibleLoading(state, action) {
      state.markAsResponsibleLoading = action.payload;
    },
    setMarkAsResponsibleError(state, action) {
      state.markAsResponsibleError = action.payload;
    },
  },
});

export const {
  handleSaveOnRequestReservationsResponse,
  handleChangeFilterChoices,
  setIsLoading,
  setError,
  setMarkAsResponsibleLoading,
  setMarkAsResponsibleError,
} = onRequestReservationsSlice.actions;

export default onRequestReservationsSlice.reducer;