const message = {
  title: "Görüşme Esnasında Zorunlu 5 Önemli Kural!",
  description: [
    'Karşılamamız :  "Rezervasyon / Otel Adı / Otelfiyat Ahmet Ben Nasıl Yardımcı Olabilirim ?',
    "Görüşme Başında Misafirden İsim Alınması ve Görüşme Süresince İsim ile Hitap Edilmesi",
    'Fiyatın Sunumu : "Liste Fiyatı 2000TL, Bugüne özel Rezervasyonlarda %25 İndirim kampanyamız var. İndirimli Rezervasyon Tutarınız 1500TL, Ödemeniz Nasıl Olacaktı?"',
    "Fiyat Verilen Her Misafirin Gün İçerisinde En az 1 Kere Aranması Zorunlu!",
    'Tesis Hakkında Genel Bilgi verilmesi : "Daha Önce Tesisimizde Konaklamış mıydınız?" "Sizi Misafir Etmekten Memnuniyet Duyarız." "Otelimiz Hakkında Size Kısa Bir Bilgi Vermemi İster Misiniz?"',
  ],
};

const GreetingMessage = () => {
  return (
    <ul className="list-group">
      <li className="list-group-item list-group-item-primary fw-semibold">
        {message.title}
      </li>
      {message.description.map((desc, index) => (
        <li key={index} className="list-group-item fs-14">
          <span className="theme-text-danger fw-bold me-2">*</span>
          {desc}
        </li>
      ))}
    </ul>
  );
};

export default GreetingMessage;
