import FilterForm from "./FilterForm";
import ListCancellationRequestsTable from "./ListCancellationRequestsTable";


interface ListCancellationRequestsPageProps {
  isLoading: boolean;
}

const ListCancellationRequestsPage = ({
  isLoading,
}: ListCancellationRequestsPageProps) => {

  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <ListCancellationRequestsTable isLoading={isLoading} />
    </div>
  );
};

export default ListCancellationRequestsPage;
