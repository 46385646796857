import OperationReservationMonitoringPage from "Components/Custom/ReservationMonitoringPages/OperationReservationMonitoringPage";
import PageContainer from "pages/PageContainer";

const OperationReservationMonitoring = () => {
//   const { isLoading } = useOperationReservationMonitoring();

  return (
    <PageContainer title="Operasyon Rezervasyon Takibi">
      <OperationReservationMonitoringPage isLoading={false} />
    </PageContainer>
  );
};

export default OperationReservationMonitoring;
