//AUTHENTICATION
export const LOGIN_USER = "/auth/login";
export const LOGOUT_USER = "/auth/logout";
export const FORGOT_PASSWORD = "/auth/forgot-password";
export const RESET_PASSWORD = "/auth/forgot-password-code";
export const RESET_PASSWORD_PROFILE = "/auth/reset-password";
export const UPDATE_PROFILE = "/profile_edit";

//HOTEL
export const GET_HOTELS = "/hotels";
export const GET_HOTEL = "/hotel";
export const GET_HOTEL_SUMMARY = "/get_hotel_summary";

//PRICES&QUOTA
export const GET_PRICES_QUOTA_DATA = "/otel-oda-pansiyon";
export const GET_BASIC_PRICE_ENTRY_PAGE_DATA = "/otel-oda-pansiyon";
export const POST_BASIC_PRICE_ENTRIES = "/basit_takvim_giris";
export const POST_BASIC_AVAILABILITY_ENTRIES = "/update-availability";
export const POST_BASIC_QUOTA_ENTRIES = "/kontenjan_girisi";
export const POST_PERIODS = "/periyot_kaydet";
export const GET_PERIODS = "/periyot_listele";

export const GET_ROOM_SALES_AND_PAYMENT_METHODS =
  "/oda-satis-odeme-yontemleri-listele";
export const POST_ROOM_SALES_AND_PAYMENT_METHODS =
  "/oda-satis-odeme-yontemleri-guncelle";

export const GET_GENERAL_PRICE_SETTINGS = "/fiyat-ayar-listesi";
export const POST_GENERAL_PRICE_SETTINGS = "/genel-fiyat-kaydet";

export const GET_ROOM_PERSON_PRICE_RATES = "/oda-kisi-fiyat-oranlari-listele";
export const POST_ROOM_PERSON_PRICE_RATES = "/oda-kisi-fiyat-oranlari-guncelle";

export const GET_CHILD_INFORMATION = "/cocuk-bilgileri-listele";
export const POST_CHILD_INFORMATION = "/cocuk-bilgileri-kaydet";

export const GET_CHILD_SPECIAL_AGE_INFORMATION = "/cocuk-ozel-yas-listele";
export const UPDATE_CHILD_SPECIAL_AGE_INFORMATION = "/cocuk-ozel-yas-duzenle";
export const POST_CHILD_SPECIAL_AGE_INFORMATION = "/cocuk-ozel-yas-kaydet";

//! DISCOUNTS&ACTIONS
//SPECIAL DISCOUNTS
export const GET_SPECIAL_DISCOUNTS = "/ozel-indirim/get_inet_ozel_indirim";
export const ADD_SPECIAL_OFFER = "/ozel-indirim/add_ozel_indirim_tanimlari";
export const GET_SPECIAL_OFFER = "/ozel-indirim/ozel_indirim_tanimlari";
export const SAVE_SPECIAL_DISCOUNT = "/ozel-indirim/ozel_indirim_kaydet";
export const UPDATE_SPECIAL_DISCOUNT = "/ozel-indirim/ozel_indirim_duzenle";
export const DELETE_SPECIAL_DISCOUNT = "/ozel-indirim/ozel_indirim_sil";

//MOBILE DISCOUNTS
export const GET_MOBILE_DISCOUNTS = "/ozel-indirim/get_mobile_indirim";
export const ADD_MOBILE_DISCOUNT = "/ozel-indirim/mobil_indirim_kaydet";
export const UPDATE_MOBILE_DISCOUNT = "/ozel-indirim/mobil_indirim_duzenle";
export const DELETE_MOBILE_DISCOUNT = "/ozel-indirim/mobil_indirim_sil";

//BREAKING DISCOUNTS
export const GET_BREAKING_DISCOUNTS = "/ozel-indirim/get_sondakika_indirim";
export const ADD_BREAKING_DISCOUNT = "/ozel-indirim/sondakika_indirim_kaydet";

//HOURLY DISCOUNTS
export const GET_HOURLY_DISCOUNTS = "/ozel-indirim/get_saatlik_indirim";
export const ADD_HOURLY_DISCOUNT = "/ozel-indirim/saatlik_indirim_kaydet";
export const UPDATE_HOURLY_DISCOUNT = "/ozel-indirim/saatlik_indirim_duzenle";
export const DELETE_HOURLY_DISCOUNT = "/ozel-indirim/saatlik_indirim_sil";

//UNCANCELLABLE ROOM RATES
export const GET_UNCANCELLABLE_ROOM_RATES =
  "/ozel-indirim/get_iptal_edilemez_indirim";
export const UPDATE_UNCANCELLABLE_ROOM_RATE =
  "/ozel-indirim/iptal_edilemez_indirimi_guncelle";

//EARLY RESERVATION DISCOUNTS
export const GET_EARLY_RESERVATION_DISCOUNTS =
  "/ozel-indirim/get_erken_rezervasyon_indirim";
export const ADD_EARLY_RESERVATION_DISCOUNT =
  "/ozel-indirim/erken_rezervasyon_indirim_kaydet";
export const UPDATE_EARLY_RESERVATION_DISCOUNT =
  "/ozel-indirim/erken_rezervasyon_indirim_duzenle";
export const DELETE_EARLY_RESERVATION_DISCOUNT =
  "/ozel-indirim/erken_rezervasyon_indirim_sil";

//STAY LONG PAY LESS ACTIONS
export const GET_STAY_LONG_PAY_LESS_ACTIONS =
  "/ozel-indirim/get_kal_ode_indirim";
export const ADD_STAY_LONG_PAY_LESS_ACTIONS =
  "/ozel-indirim/kal_ode_indirim_kaydet";
export const UPDATE_STAY_LONG_PAY_LESS_ACTIONS =
  "/ozel-indirim/kal_ode_indirim_duzenle";
export const DELETE_STAY_LONG_PAY_LESS_ACTIONS =
  "/ozel-indirim/kal_ode_indirim_sil";

//UNDER THE DESK DISCOUNTS
export const GET_UNDER_THE_DESK_DISCOUNTS =
  "/ozel-indirim/get_deskalti_indirim";
export const ADD_UNDER_THE_DESK_DISCOUNT =
  "/ozel-indirim/deskalti_indirim_kaydet";
export const UPDATE_UNDER_THE_DESK_DISCOUNT =
  "/ozel-indirim/deskalti_indirim_duzenle";
export const DELETE_UNDER_THE_DESK_DISCOUNT =
  "/ozel-indirim/deskalti_indirim_sil";

//GIFTS AND PROMOTIONS
export const GET_GIFTS_AND_PROMOTIONS_LIST =
  "/ozel-indirim/get_hediyeler_listesi";
export const GET_GIFTS_AND_PROMOTIONS = "/ozel-indirim/get_hediye";
export const ADD_GIFTS_AND_PROMOTIONS = "/ozel-indirim/hediye_kaydet";
export const UPDATE_GIFTS_AND_PROMOTIONS = "/ozel-indirim/hediye_duzenle";
export const DELETE_GIFTS_AND_PROMOTIONS = "/ozel-indirim/hediye_sil";

//ALL GIFTS LIST
export const GET_ALL_GIFTS_LIST = "/ozel-indirim/tum_otellerin_hediyeleri";

//DISCOUNT CODES
export const GET_DISCOUNT_CODES = "/ozel-indirim/get_indirim_kuponu";
export const ADD_DISCOUNT_CODE = "/ozel-indirim/indirim_kuponu_kaydet";
export const UPDATE_DISCOUNT_CODE = "/ozel-indirim/indirim_kuponu_duzenle";
export const DELETE_DISCOUNT_CODE = "/ozel-indirim/indirim_kuponu_sil";

//NIGHT RESTRICTIONS
export const GET_NIGHT_RESTRICTIONS = "/ozel-indirim/get_gece_kisitlamalari";
export const ADD_NIGHT_RESTRICTION = "/ozel-indirim/gece_kisitlamalari_kaydet";
export const UPDATE_NIGHT_RESTRICTION =
  "/ozel-indirim/gece_kisitlamalari_duzenle";
export const DELETE_NIGHT_RESTRICTION = "/ozel-indirim/gece_kisitlamalari_sil";

//! DEFINITIONS
//LOCATIONS
export const GET_LOCATIONS = "/tanimlamalar/lokasyon_listesi";
export const ADD_LOCATION = "/tanimlamalar/lokasyon_ekle";
export const DELETE_LOCATION = "/tanimlamalar/lokasyon_sil";

//ADD HOTEL FEATURE
export const ADD_HOTEL_FEATURE = "/tanimlamalar/tesis_ozellik_ekle";

//ADD EARLY RESERVATION DEFINITION
export const GET_EARLY_RESERVATION_DEFINITION =
  "/tanimlamalar/erken_rezervasyon_tarihi";
export const UPDATE_EARLY_RESERVATION_DEFINITION =
  "/tanimlamalar/erken_rezervasyon_tarihi_guncelle";

//PLACE TO VISIT
export const GET_PLACES_TO_VISIT = "/tanimlamalar/gezilecek_yer_listesi";
export const ADD_PLACE_TO_VISIT = "/tanimlamalar/gezilecek_yer_ekle";
export const UPDATE_PLACE_TO_VISIT = "/tanimlamalar/gezilecek_yer_guncelle";

// DEFINE CURRENCY
export const GET_CURRENCY = "/tanimlamalar/kur_listele";
export const UPDATE_CURRENCY = "/tanimlamalar/kur_guncelle";

//!THEMES
//ADD NEW THEME
export const GET_THEME_REGION_OPTIONS = "/temalar/tema_bolge_list_options";
export const GET_THEME_TYPES_OPTIONS = "/temalar/tema_turleri";
export const ADD_NEW_THEME = "/temalar/tema_ekle";

//THEME LIST
export const GET_THEMES = "/temalar/tema_list";
export const GET_THEME = "/temalar/tema_details";
export const UPDATE_THEME = "/temalar/tema_guncelle";

//ASSIGN HOTEL TO THEME
export const GET_HOTELS_FOR_THEME = "/temalar/get_oteller";
export const ADD_HOTEL_TO_THEME = "/temalar/tema_otel_ata";

// ASSIGN RECOMMENDED HOTELS
export const GET_RECOMMENDED_HOTELS = "/temalar/tema_tavsiye_edilen_oteller";
export const ADD_RECOMMENDED_HOTEL =
  "/temalar/tema_tavsiye_edilen_otel_guncelle";

//ORDER THEME HOTELS
export const GET_ORDER_THEME_HOTELS = "/temalar/tema_otel_sira_getir";
export const ADD_ORDER_THEME_HOTELS = "/temalar/tema_otel_siralama_guncelle";

//! FACILITY
//ADD FACILITY
export const GET_FACILITY_FORM_CHOICES = "/tesis/tesis-kaydet-listele";
export const ADD_FACILITY = "/tesis/tesis-kaydet";

//UPDATE FACILITY
export const GET_FACILITY = "/tesis/tesis-duzenle-listele";
export const UPDATE_FACILITY = "/tesis/tesis-duzenle";

//VIEW FEATURED IMAGES
export const GET_FEATURED_IMAGES = "/tesis/tesis-vitrin-gorselleri";

//FACILITY IMAGES
export const GET_FACILITY_IMAGES = "/tesis/otel-resimlerini-getir/";
export const ADD_FACILITY_IMAGES = "/tesis/inet-resim";
export const UPDATE_FACILITY_IMAGES_ORDER = "/tesis/update-sira";
export const DELETE_FACILITY_IMAGES = "/tesis/delete-resim";

//FACILITY ROOMS
export const GET_FACILITY_ROOM = "/tesis/get-hotel-room-details";
export const UPDATE_FACILITY_ROOM = "/tesis/oda-ozellik-guncelle";
export const UPDATE_FACILITY_ROOM_FEATURES = "/tesis/oda-ozellik-guncelle";
export const ADD_FACILITY_ROOM_IMAGES = "/tesis/oda-ozellik-guncelle";
export const DELETE_FACILITY_ROOM_IMAGES = "/tesis/oda-resim-silme";
export const GET_FACILITY_ROOMS_NAMES = "/tesis/otel-oda-isimleri-listele";

//FACILITY CONCEPTS
export const GET_FACILITY_CONCEPTS = "/tesis/otel-pansiyon-listele/";
export const ADD_FACILITY_CONCEPT = "/tesis/otel-pansiyon-ekle";
export const UPDATE_FACILITY_CONCEPT = "/tesis/otel-pansiyon-duzenle";

//FACILITY USERS
export const GET_FACILITY_USER = "/tesis/tesis-kullanici-listele/";
export const FACILITY_USER = "/tesis/tesis-kullanici-kaydet";

//FACILITY MANAGERS
export const GET_FACILITY_MANAGERS = "/tesis/otel-yetkili-listele/";
export const ADD_FACILITY_MANAGER = "/tesis/otel-yetki-kaydet";
export const UPDATE_FACILITY_MANAGER = "/tesis/otel-yetki-kaydet";
export const DELETE_FACILITY_MANAGER = "/tesis/tesis-yetkili-sil";

//FACILITY SPECIAL DAYS
export const GET_FACILITY_SPECIAL_DAYS = "/tesis/tesis-ozel-gunler-listele";
export const ADD_FACILITY_SPECIAL_DAY = "/tesis/tesis-ozel-gunler-kaydet";
export const UPDATE_FACILITY_SPECIAL_DAY = "/tesis/tesis-ozel-gunler-kaydet";
export const DELETE_FACILITY_SPECIAL_DAY = "/tesis/tesis-ozel-gunler-kaydet";

//ROOM CONCEPT MATCH
export const GET_ROOM_CONCEPT_MATCH_LIST =
  "/tesis/pansiyon-oda-eslestir-listele/";
export const ROOM_CONCEPT_MATCH = "/tesis/tedarikci-eslestir";

//FACILITY CALLS
export const GET_FACILITY_CALLS = "/tesis/tesis-aramalari-listele";
export const UPDATE_FACILITY_CALL = "/tesis/tesis-aramalari-guncelle";

// FACILITY PARTIAL PAYMENT DATES
export const GET_FACILITY_PARTIAL_PAYMENT_DATES =
  "/tesis/kismi-odemeli-oteller";

//MONEY POINTS REPORTS
export const GET_MONEY_POINTS = "/raporlar/tesis_parapuan_listesi";
export const ADD_MONEY_POINT = "/raporlar/tesis_parapuan_ekle";

//CUSTOMER MAILS REPORTS
export const GET_ALL_CUSTOMER_MAILS = "/raporlar/rezervasyon_mail_listele";
export const GET_FILTERED_CUSTOMER_MAILS = "/raporlar/rezervasyon_mail_listele";

//PHONE STATUS REPORTS
export const GET_ALL_PHONE_STATUS = "/raporlar/dahili_durum_genel";
export const GET_FILTERED_PHONE_STATUS = "/raporlar/dahili_durum_genel";

//DAILY RESERVATIONS REPORTS
export const GET_DAILY_RESERVATIONS = "/raporlar/gunluk_satis_raporu";

//DAILY CALLS CHARTS REPORTS
export const GET_DAILY_CALLS_CHARTS = "/raporlar/gunluk_cagri_grafik";

//HOURLY CALLS CHART REPORTS
export const GET_HOURLY_CALLS_CHART = "/raporlar/saatlik_cagri_grafik";

//OUR OWN HOTELS REPORTS
export const GET_OUR_OWN_HOTELS = "/raporlar/fiyati_olan_bizeait_oteller";

//WELCOME AND CALCULATION REPORTS
export const GET_WELCOME_AND_CALCULATION_LIST =
  "/raporlar/otel_cagri_fiyat_prefix";
export const GET_WELCOME_AND_CALCULATION = "/raporlar/otel_cagri_fiyat_detay";
export const UPDATE_WELCOME_AND_CALCULATION =
  "/raporlar/otel_cagri_fiyat_detay_guncelle";

//ROOMS LACK PICTURE REPORTS
export const GET_ALL_ROOMS_LACK_PICTURE = "/raporlar/inet_resmi_olmayanlar";
export const GET_FILTERED_ROOMS_LACK_PICTURE =
  "/raporlar/inet_resmi_olmayanlar_filtrele";

//FORCAST REPORTS
export const GET_FORECAST_REPORT = "/raporlar/forecast_raporu";

//ANSWERED CALLS REPORTS
export const GET_ANSWERED_CALLS = "/raporlar/cevaplanan_cagri_hareketleri";

//OUTBOUND CALLS LIST REPORTS
export const GET_OUTBOUND_CALLS = "/raporlar/dis_arama_sayilari";

//OUTBOUND CALLS LIST WITH NUMBER REPORTS
export const GET_OUTBOUND_CALLS_WITH_NUMBER =
  "/raporlar/dis_arama_sayilari_personel";

//DAILY CALLS PER PERSON REPORTS
export const GET_DAILY_CALLS_PER_PERSON =
  "/raporlar/kisilere_gelen_gunluk_cagri";

//EMPLOYEE SALES AMOUNT REPORTS
export const GET_EMPLOYEE_SALES_AMOUNT = "/raporlar/personel_satislari_sorgula";

//ROOMS LACK DESCRIPTION REPORTS
export const GET_ROOMS_LACK_DESCRIPTION =
  "/raporlar/inet_oda_aciklama_olmayanlar";

//RISKY HOTELS REPORTS
export const GET_RISKY_HOTELS = "/raporlar/inet_riskli_oteller";

//PHONE STATUS LIST REPORTS
export const GET_PHONE_STATUS_LIST = "/raporlar/dahili_durum_listele";

//DAILY CALLS FOR HOTELS REPORTS
export const GET_DAILY_CALLS_FOR_HOTELS =
  "/raporlar/otellere_gelen_gunluk_cagri";

//CALLS FROM GENERAL REPORTS
export const GET_CALLS_FROM_GENERAL = "/raporlar/genel_gelen_aramalar";

//MONTHLY SALES REPORTS
export const GET_MONTHLY_SALES = "/raporlar/personel_aylik_satis_listele";
export const GET_MONTHLY_SALE_FILTERS =
  "/raporlar/personel_aylik_satis_listele";

//HOTEL SALES REPORTS
export const GET_HOTEL_SALES = "/raporlar/otel_satis_rakamlari_listele";
export const GET_HOTEL_SALE_FILTERS = "/raporlar/otel_satis_rakamlari_filters";
export const GET_HOTEL_SALE_FILTER_DISTRICTS =
  "/raporlar/otel_satis_rakamlari_ilce";

//RESERVATIONS
export const GET_RESERVATION_FORM_CHOICES = "/raporlar/rezervasyon_listele";
export const GET_RESERVATIONS = "/raporlar/rezervasyon_listele";

//! ADS&ANALYZE
//ADS GROUPS
export const GET_ADS_AND_HOTELS = "/analiz/oteller-analiz-r";
export const GET_MATCHED_HOTELS = "/analiz/eslestirilen-oteller";
export const GET_UNMATCHED_HOTELS = "/analiz/eslestirilmemis-oteller-listele";
export const GET_UNMATCHED_ADS_GROUPS = "/analiz/eslestirilmeyen-r-gruplari";
export const ADD_AD_AND_HOTEL_MATCH = "/analiz/otel-r-eslestir";
export const DELETE_MATCHED_HOTEL = "/analiz/eslestirilen-oteller-sil";

//ADS REPORT
export const GET_ADS_REPORT = "/analiz/r-raporlari-listele";
export const GET_ADS_REPORT_SUMMARY = "/analiz/rapor-ozeti-listele";
export const GET_ADS_REPORT_MANAGERS = "/analiz/yetkili_listele";

//COMPETITOR ANALYSIS
export const GET_COMPETITOR_ANALYSIS = "/analiz/rakip-analizi-listele";
export const GET_COMPETITOR_ANALYSIS_FACILITY_AVAILABILITIES =
  "/analiz/tesis-musaitlilik-listele/";
export const UPDATE_COMPETITOR_ANALYSIS_HOTEL_DESCRIPTION =
  "/analiz/analiz-data-guncelle";
export const UPDATE_COMPETITOR_ANALYSIS_ADS_STATUS = "/analiz/r-durum-guncelle";
export const UPDATE_COMPETITOR_ANALYSIS_HOTEL_INFO = "/analiz/analiz-guncelle/";
export const UPDATE_EB_COMPETITOR_ANALYSIS_HOTEL_INFO =
  "/analiz/analiz-eb-guncelle/";

//COMPETITOR ANALYSIS INFO
export const GET_COMPETITOR_ANALYSIS_INFO =
  "/analiz/rakip-analiz-bilgileri-listele";
export const UPDATE_COMPETITOR_ANALYSIS_INFO =
  "/analiz/rakip-analiz-bilgileri-guncelle";

//! FINANCE & ACCOUNTING
//HOTEL INVOICES
export const GET_HOTEL_INVOICES = "/finans/otele-kesilen-faturalar";

//CONTRACT RULES
export const GET_CONTRACT_PROCEDURES = "/finans/kontrat-islemleri-listele";
export const GET_CONTRACT_INVOICE = "/finans/kontrat-fatura-listele";
export const GET_HOTEL_CONTRACT_PROCEDURES =
  "/finans/kontrat-islemleri-listele";
export const GET_HOTEL_CONTRACT_RULES = "/finans/kontrat-kurallari-listele/";
export const ADD_HOTEL_CONTRACT_RULE = "/finans/otel-kurallari-ekle";
export const DELETE_HOTEL_CONTRACT_RULE = "/finans/otel-kurali-pasif";

//HOTEL CONTRACTS
export const GET_HOTEL_CONTRACTS = "/finans/tesis-kontrat-listele";
export const ADD_HOTEL_CONTRACT = "/finans/tesis-kontrat-ekle";
export const UPDATE_HOTEL_CONTRACT = "/finans/tesis-kontrat-duzenle";
export const DELETE_HOTEL_CONTRACT = "/finans/tesis-kontrat-duzenle";
export const APPROVE_HOTEL_CONTRACT = "/finans/tesis-kontrat-duzenle";

//ARCHIVED CONTRACTS
export const GET_ARCHIVED_CONTRACTS = "/finans/tesis-kontrat-arsiv-listele";

//ISSUED INVOICES
export const GET_ISSUED_INVOICES = "/finans/kesilen-faturalar-listele";

//MAIL ORDER INFO
export const GET_MAIL_ORDERS = "/finans/mail-order-bilgisi";
export const ADD_MAIL_ORDER = "/finans/mail-order-duzenle";
export const UPDATE_MAIL_ORDER = "/finans/mail-order-duzenle";
export const DELETE_MAIL_ORDER = "/finans/mail-order-duzenle";

//COMMISSION PAYMENT
export const GET_COMMISSION_PAYMENT = "/finans/komisyon-odeme/";
export const GET_COMMISSION_PAYMENT_CARD_INFO = "/finans/kart-bin";
export const MAKE_COMMISSION_PAYMENT = "/finans/odeme-test-istek";
export const GO_TO_3D_SECURE = "/odeme-test-response/";

//PERSONNEL REQUESTS
export const GET_PERSONNEL_REQUEST = "/personel_talepleri/talep-listele";
export const POST_PERSONNEL_REQUEST = "/personel_talepleri/talep-cevapla";

//COMMENTS
export const GET_COMMENTS = "yorumlar/yorum-listele";
export const UPDATE_COMMENT = "yorumlar/yorum-duzenle";
export const DELETE_COMMENT = "yorumlar/yorum-sil";
export const ADD_COMMENTS = "yorumlar/yorum-ekle";
export const APPROVE_COMMENT = "yorumlar/yorum-onayla";
export const GET_ADD_COMMENT_QUESTIONS = "yorumlar/yorum-sorulari-listele";
export const GET_HOTELS_WITHOUT_COMMENT = "yorumlar/yorumsuz-oteller";

//! MY WEB SITE
//CREATE WEB SITE
export const GET_WEB_SITE = "/website/site_olustur";
export const CREATE_WEB_SITE = "/website/site_olustur"; //* ?otel_id=456

///FOOTER LINKS
export const GET_FOOTER_LINKS = "/website/footer_linkleri"; //* ?otel_id=456
export const DELETE_FOOTER_LINK = "/website/footer_link_sil";
export const ADD_FOOTER_LINK = "/website/footer_link_ekle"; //* ?otel_id=456

///CERTIFICATES
export const GET_CERTIFICATES = "/website/site_sertifikalari"; //* ?otel_id=456
export const ADD_CERTIFICATE = "/website/site_sertifika_ekle";
export const DELETE_CERTIFICATE = "/website/site_sertifika_sil";

//ADDITIONAL PAGES
export const GET_ADDITIONAL_PAGES = "/website/site_ek_sayfalari"; //* ?otel_id=456
export const GET_ADDITIONAL_PAGE_TO_UPDATE = "/website/site_ek_sayfa_duzenle"; //* ?otel_id=456&sayfa_id=9
export const UPDATE_ADDITIONAL_PAGE = "/website/site_ek_sayfa_duzenle";
export const ADD_ADDITIONAL_PAGE = "/website/site_ek_sayfa_ekle";
export const DELETE_ADDITIONAL_PAGE = "/website/site_ek_sayfa_sil";
export const GET_GALLERY_IMAGES = "/website/site_ek_sayfa_resimleri"; //* ?otel_id=456&sayfa_id=9
export const ADD_GALLERY_IMAGE = "/website/site_ek_sayfa_resim_ekle";
export const DELETE_GALLERY_IMAGE = "/website/site_ek_sayfa_resim_sil";

//LIST WEB SITES
export const GET_WEB_SITE_LIST = "/website/website_list";
export const ADD_WEB_SITE = "/website/add_website";
export const GET_HOTELS_WITHOUT_WEB_SITE = "/website/add_website";

//CREATE WIDGET
export const GET_WIDGET_DATA = "/website/widget_olustur"; //* ?otel_id=456
export const CREATE_WIDGET = "/website/widget_olustur"; //* ?otel_id=456

// ! FACILITY CATEGORIZATION

// CATEGORIZE FACILITIES
export const GET_CATEGORIZE_FACILITY_FORM_CHOICES = "/tesis/tesise-sinif-ata";
export const GET_CITY_SELECTION_RESPONSE = "/tesis/sehir-secimi"; //* ?sehir_id=481
export const CATEGORIZE_FACILITY = "/tesis/otele-sinif-ekle";
export const GET_CATEGORY_SELECTION_RESPONSE = "/tesis/sinif-otelleri-getir"; //* ?sinif=sehir
export const CHANGE_FACILITY_CATEGORY = "/tesis/otel-sinif-degistir";
export const LIST_FACILITIES = "/tesis/tesis-sinif-listele";

//!USER OPERATIONS
//NOTIFICATIONS
export const GET_NOTIFICATIONS = "/kullanici/bildirimler";
export const READ_NOTIFICATION = "/kullanici/bildirim-okundu";

//CREATE NOTIFICATION
export const GET_CREATE_NOTIFICATION_FORM_CHOICES =
  "/kullanici/yeni-bildirim-ekle";
export const CREATE_NOTIFICATION = "/kullanici/yeni-bildirim-ekle";

//ADD USER
export const ADD_USER = "kullanici/kullanici-ekle";

//LIST USERS
export const GET_USERS = "kullanici/kullanici-duzenle-listele";
export const CHANGE_USER_STATUS = "kullanici/kullanici-statu";

//UPDATE USER
export const UPDATE_USER = "kullanici/kullanici-duzenle";
export const GET_USER = "kullanici/kullanici-duzenle";

// USER AUTHORITIES
export const ADD_AUTHORITY_TO_USER = "kullanici/kullanici-yetkileri-guncelle";
export const GET_AUTHORITIES_AND_USERS = "kullanici/kullanici-yetki-listele";
export const ADD_AUTHORITY = "kullanici/yetkiler-ekle";

// PERSONNEL HOTEL ASSIGNMENT
export const GET_USERS_CITIES_HOTELS = "kullanici/atanmamis-oteller-listele";
export const ASSIGN_HOTELS_TO_USER = "kullanici/otelleri-personel-ata";
export const GET_USER_HOTELS = "kullanici/personel-bilgileri-sorgula";
export const UPDATE_HOTEL_ASSIGNEES =
  "kullanici/personel-otel-gorevleri-duzenle";
export const GET_ASSIGNED_HOTELS = "kullanici/atanmis-oteller-listele";

// LIST LOGS
export const GET_LOGS = "kullanici/log-listele";
export const FILTER_LOGS = "/kullanici/log-listele";

//ADD GROUP
export const GET_ADD_GROUP_FORM_CHOICES = "kullanici/grup-ekle";
export const ADD_GROUP = "kullanici/grup-ekle";

//LIST GROUPS
export const GET_GROUPS = "kullanici/grup-listele";
export const CHANGE_GROUP_STATUS = "kullanici/grup-durum-degistir";

//UPDATE GROUPS
export const UPDATE_GROUP = "kullanici/grup-duzenle";
export const GET_GROUP = "kullanici/grup-duzenle"; //* ?grup_id=4
export const GET_GROUP_FORM_CHOICES = "/kullanici/grup-ekle";

//PERITO ENTENSION NUMBERS
export const GET_PERITO_EXTENSION_NUMBERS = "kullanici/perito-dahili-durum";

//RESERVATION CHANGE APPROVE CANCEL
export const GET_FORM_CHOICES = "kullanici/rez-onay-iptal-degistir";
export const CHANGE_APPROVE_CANCEL = "kullanici/rez-onay-iptal-degistir";
export const GET_RESERVATION_AMOUNT = "kullanici/rez-tutar-sifirla"; //* ?rez_kodu=1064028
export const RESET_RESERVATION_AMOUNT = "kullanici/rez-tutar-sifirla";

//TRANSFER RESERVATION
export const GET_PERSONNEL_FORM_CHOICES = "kullanici/rezervasyon-aktar";
export const TRANSFER_RESERVATION = "kullanici/rezervasyon-aktar";

//USER IPS
export const GET_USER_IPS = "kullanici/dis-ipler";

//IT
export const GET_RESERVATION_TABLE = "/kullanici/bilgi-islem-rezervasyon"; //* ?rez_kod=8Q2GRH
export const UPDATE_PRICE_PROVIDER = "/kullanici/bilgi-islem-rezervasyon";
export const GET_RESERVATION_PAYMENT = "/kullanici/bilgi-islem-odeme"; //* ?rez_kod=8Q2GRH
export const UPDATE_COLLECTION = "/kullanici/bilgi-islem-odeme";
export const GET_RESERVATION_COLLECTION = "/kullanici/bilgi-islem-tahsilat"; //* ?rez_kod=8Q2GRH
export const UPDATE_PAYMENT_STATUS = "/kullanici/bilgi-islem-tahsilat";

//! RESERVATION
//RESERVATION CALL
export const GET_RESERVATION_CALL = "/rezervasyon/fiyat-hesapla/santral";
export const UPDATE_RESERVATION_CALL = "/rezervasyon/fiyat-hesapla/santral";
export const GET_RESERVATION_CALL_HOTELS_AND_LOCATIONS =
  "/system/inet-autocomplete";
export const RESERVATION_CALL_CALCULATE_PRICE_FOR_HOTEL =
  "/rezervasyon/fiyat-hesapla/oteller";
export const RESERVATION_CALL_CALCULATE_PRICE_FOR_LOCATION =
  "/rezervasyon/fiyat-hesapla/lokasyon_otelleri_listele";
export const GET_RESERVATION_CALL_CHILDREN_INFO = "/system/cocuk-bilgisi";
export const GET_RESERVATION_CALL_ROOM_CONCEPT_INFO =
  "/rezervasyon/fiyat-hesapla/OtelOdaPansiyonBilgisi";
export const GET_RESERVATION_CALL_REQUEST_ROOM =
  "/rezervasyon/fiyat-hesapla/kontenjan_sorgula";
export const GET_RESERVATION_GET_EMPLOYEES =
  "/rezervasyon/fiyat-hesapla/UyelerListesi";
export const GET_RESERVATION_GET_BANK_INSTALLMENTS =
  "/rezervasyon/fiyat-hesapla/BankaTaksitListele";
export const GET_RESERVATION_CHECK_CARD = "/finans/kart-bin"; //TODO: Change this to the correct endpoint
export const SAVE_RESERVATION =
  "/rezervasyon/fiyat-hesapla/rezervasyon_olustur";
  export const SAVE_ON_REQUEST =
  "/rezervasyon/fiyat-hesapla/sorsat-yap";

//REVIEW RESERVATION
export const GET_REVIEW_RESERVATION =
  "/rezervasyon/fiyat-hesapla/rezervasyon_incele";
export const REVIEW_CANCEL_RESERVATION = "/rezervasyon/iptal/IptalIstegiTalebi";
export const SEND_CONFIRMATION = "/rezervasyon/incele/konfirme_gonder";
export const SEND_VOUCHER = "/rezervasyon/incele/voucher_gonder";
export const GET_CONFIRMATION_FILE = "/rezervasyon/incele/konfirme_indir";
export const GET_VOUCHER_FILE = "/rezervasyon/incele/voucher_indir";
export const GET_COLLECTION_FORM_OPTIONS = "";
export const ADD_COLLECTION = "";

//EDIT RESERVATION
export const GET_EDIT_RESERVATION_DATA = "/rezervasyon/rezervasyon_duzenle"; // * ?rezervasyon_id=1179969

// ENTER GROUP RESERVATION
export const ENTER_GROUP_RESERVATION = "/rezervasyon/grup_rezervasyon_ekle";

// ! DASHBOARD
export const GET_DASHBOARD_DATA = "/dashboard/show"; //* /16370
export const GET_CRM_DASHBOARD_DATA = "/dashboard/crm";

//! DOCUMENTS
// HOTELS WITHOUT PRICE
export const LIST_HOTELS_WITHOUT_PRICE = "/kontroller/fiyati-olmayan-oteller";

// ! RESERVATION LISTS

//LIST/FIND RESERVATION
export const FIND_RESERVATION = "/rezervasyon/rezervasyon_listele";
export const LIST_RESERVATIONS = "/rezervasyon/rezervasyon_listele";
export const GET_AVAILABILITY_DATA = "/rezervasyon/musaitlik"; //* ?rezervasyon_id=107242
export const UPDATE_AVAILABILITY_DATA = "/rezervasyon/musaitlik";

//LIST RESERVATIONS CHANNEL
export const FILTER_RESERVATION_CHANNEL =
  "/rezervasyon/rezervasyon_listele_kanal";
export const LIST_RESERVATIONS_CHANNEL =
  "/rezervasyon/rezervasyon_listele_kanal";

//LIST INCOMING TRANSFERS
export const FILTER_INCOMING_TRANSFERS = "/rezervasyon/gelen_havale_listele";
export const LIST_INCOMING_TRANSFERS = "/rezervasyon/gelen_havale_listele";

//LIST INET NONENTRY RESERVATIONS
export const LIST_INET_NONENTRY_RESERVATIONS =
  "/rezervasyon/inet_giris_yapamayan_rezervasyonlar";

//TOTAL RESERVATION DATA
export const GET_TOTAL_RESERVATION_DATA =
  "/rezervasyon/rezervasyon_toplam_listele";

//CONFIRMATION APPROVAL
export const FILTER_CONFIRMATION_APPROVAL_DATA =
  "/rezervasyon/supheli_rezervasyon_listele";
export const LIST_CONFIRMATION_APPROVAL_DATA =
  "/rezervasyon/supheli_rezervasyon_listele";

//NOTE CONFIRMATION RESERVATIONS
export const FILTER_NOTE_CONFIRMATION_RESERVATIONS =
  "/rezervasyon/not_konfirme_listele";
export const LIST_NOTE_CONFIRMATION_RESERVATIONS =
  "/rezervasyon/not_konfirme_listele";

// ! RESERVATION MONITORING

// LIST ON REQUEST RESERVATIONS
export const FILTER_ON_REQUEST_RESERVATIONS = "/rezervasyon/sor_sat_listele";
export const LIST_ON_REQUEST_RESERVATIONS = "/rezervasyon/sor_sat_listele";
export const ANSWER_REQUEST = "/rezervasyon/sor_sat_cevapla";
export const UPDATE_ON_REQUEST_STATUS = "/rezervasyon/sor_sat_durum_guncelle";
export const ON_REQUEST_MARK_AS_RESPONSIBLE =
  "/rezervasyon/sor_sat_durum_guncelle";

// LIST PRICE REVISE
export const FILTER_PRICE_REVISE = "/rezervasyon/fiyat_revize_listesi";
export const LIST_PRICE_REVISE = "/rezervasyon/fiyat_revize_listesi";
export const UPDATE_STATUS_OPERATION =
  "/rezervasyon/fiyat_teklif_guncelle_operasyon";
export const UPDATE_STATUS_SALES = "/rezervasyon/fiyat_teklif_guncelle_satis";
export const PRICE_REVISE_MARK_AS_RESPONSIBLE =
  "rezervasyon/fiyat_revize_ilgileniyorum";

// LIST COST REVISE
export const FILTER_COST_REVISE = "/rezervasyon/maliyet_revize_listesi";
export const LIST_COST_REVISE = "/rezervasyon/maliyet_revize_listesi";
export const CHANGE_COST_REVISE_STATUS_POSITIVE =
  "/rezervasyon/maliyet_revize_olumlu_tamamla";
export const CHANGE_COST_REVISE_STATUS_NEGATIVE =
  "/rezervasyon/maliyet_revize_olumsuz_tamamla";
export const UPDATE_COST = "/rezervasyon/maliyet_revize_cevap_guncelle";
export const COST_REVISE_MARK_AS_RESPONSIBLE =
  "/rezervasyon/maliyet_revize_ilgileniyorum";

// TEMPORARY ONLINE RESERVATIONS
export const LIST_TEMPORARY_ONLINE_RESERVATIONS =
  "/rezervasyon/gecici-online-rezervasyon";
export const UPDATE_TEMP_REQUEST_DESCRIPTION =
  "/rezervasyon/gecici_rezervasyon_durum_guncelle";
export const GET_TEMP_REQUEST_DESCRIPTION =
  "/rezervasyon/gecici_rezervasyon_durum_guncelle";
export const HANDLE_CALL_TEMPORARY_ONLINE = "";

// CALL US FORMS
export const LIST_CALL_US_FORMS = "";

// GROUP RESERVATIONS
export const LIST_GROUP_RESERVATIONS = "/rezervasyon/grup_rezervasyonlar";
export const GROUP_RESERVATIONS_MARK_AS_RESPONSIBLE =
  "/rezervasyon/grup_rezervasyon_ilgileniyorum";
export const APPROVE_GROUP_RESERVATION = "/rezervasyon/grup_rezervasyon_onayla";
export const CANCEL_GROUP_RESERVATION =
  "/rezervasyon/grup_rezervasyon_iptal_et";
export const UPDATE_GROUP_RESERVATION_STATUS =
  "/rezervasyon/grup_rezervasyon_duzenle";

// TODAY ENTRANCE PAY AT THE DOOR
export const FILTER_TODAY_ENTRANCE_PAY_AT_THE_DOOR =
  "/rezervasyon/bugun_girisli_kapida_odemeler";
export const LIST_TODAY_ENTRANCE_PAY_AT_THE_DOOR =
  "/rezervasyon/bugun_girisli_kapida_odemeler";
export const CHANGE_PAY_AT_THE_DOOR_STATUS_POSITIVE =
  "/rezervasyon/bugun_girisli_kapida_gelecek";
export const CHANGE_PAY_AT_THE_DOOR_STATUS_NOT_REACHED =
  "/rezervasyon/bugun_girisli_kapida_ulasilamadi";
export const CANCEL_PAY_AT_THE_DOOR_RESERVATION =
  "/rezervasyon/bugun_girisli_kapida_gelmeyecek";
export const HANDLE_CALL_PAY_AT_THE_DOOR = "";

// TOMORROW ENTRANCE WITH DEPOSIT
export const FILTER_TOMORROW_ENTRANCE_WITH_DEPOSIT =
  "/rezervasyon/yarin_girisli_kaporali_rezervasyonlar";
export const LIST_TOMORROW_ENTRANCE_WITH_DEPOSIT =
  "/rezervasyon/yarin_girisli_kaporali_rezervasyonlar";
export const CHANGE_PAY_WITH_DEPOSIT_STATUS_POSITIVE =
  "/rezervasyon/yarin_girisli_kaporali_gelecek";
export const CHANGE_PAY_WITH_DEPOSIT_STATUS_NOT_REACHED =
  "/rezervasyon/yarin_girisli_kaporali_ulasilamadi";
export const CANCEL_WITH_DEPOSIT_RESERVATION =
  "/rezervasyon/yarin_girisli_kaporali_iptal";
export const HANDLE_CALL_WITH_DEPOSIT = "";

// LIST CANCELLTAION REQUESTS
export const FILTER_CANCELLATION_REQUESTS =
  "/rezervasyon/inet_iptal_talebi_listele";
export const LIST_CANCELLATION_REQUESTS =
  "/rezervasyon/inet_iptal_talebi_listele";
export const CANCEL_CANCELLATION_REQUEST = "/rezervasyon/iptal_talebi_duzenle";
export const UPDATE_CANCELLATION_REQUEST =
  "/rezervasyon/iptal_talebi_cevap_guncelle";
export const FINALIZE_CANCELLATION_REQUEST =
  "/rezervasyon/iptal_talebi_cevap_guncelle";
export const HANDLE_CALL_CANCELLATION_REQUEST = "";
export const CANCELLATION_REQUEST_MARK_AS_RESPONSIBLE =
  "/rezervasyon/iptal_talebi_ilgileniyorum";

// INET ROOMS WITHOUT IMAGES
export const LIST_INET_ROOMS_WITHOUT_IMAGES = "";

// INET ROOMS WITHOUT DESCRIPTION
export const LIST_INET_ROOMS_WITHOUT_DESCRIPTION = "";

// ! ZOIPER
export const CALL_WITH_ZOIPER = "/click-to-call/start-call";
