import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { TotalReservationDataInitialState } from "slices/reservationLists/totalReservationData/reducer";
import { getTotalReservationData } from "slices/reservationLists/totalReservationData/thunk";

const useTotalReservationData = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { TotalReservationData: TotalReservationDataInitialState }) =>
      state.TotalReservationData
  );



  useEffect(() => {
    dispatch(getTotalReservationData());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useTotalReservationData;
