interface ReservationCardProps {
  title: string;
  value: string;
  color: string;
  cardIcon: string;
}
const ReservationCard = ({
  title,
  value,
  color,
  cardIcon,
}: ReservationCardProps) => {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center rounded px-6 py-3 gap-4"
        style={{ backgroundColor: color, width: "240px", padding: '3px 32px' }}
      >
        <div className={cardIcon} style={{fontSize: '30px', color: 'white'}}></div>
        <div className="d-flex flex-column align-items-start gap-2 rounded text-white">
          <span className="fw-semibold fs-18">{value} TL</span>
          <span className="fs-14">{title}</span>
        </div>
      </div>
    </>
  );
};

export default ReservationCard;
