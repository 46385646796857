import { createSlice } from "@reduxjs/toolkit";
import { Pagination } from "types/GlobalTypes";
import {
  AvailabilityDataResponse,
  ListReservationsChannelData,
  ListReservationsChannelPayload,
  ListReservationsChannelResponse,
} from "types/reservationLists";

export interface ListReservationsChannelInitialState {
  listReservationsResponse: ListReservationsChannelResponse;
  reservations: ListReservationsChannelData[];
  filterReservations: ListReservationsChannelPayload;
  pagination: Pagination;
  availabilityResponse: AvailabilityDataResponse;
  isLoading: boolean;
  error: string;
  updateAvailabilityLoading: boolean;
  updateAvailabilityError: string;
}

const initialState: ListReservationsChannelInitialState = {
  listReservationsResponse: {
    rezler: [],
    iptal_tutar: 0,
    bekleyen_tutar: 0,
    tamamlanan_tutar: 0,
    toplam_rez: 0,
    gosterilmeyecek_alanlar: {},
  },
  reservations: [],
  filterReservations: {
    start_date: "",
    rezervasyon_kodu: "",
    musteri_adi: "",
    per_page: 0,
    page: 0,
    search: "",
  },
  pagination: {
    current_page: 0,
    first_page_url: "",
    from: 0,
    last_page: 0,
    last_page_url: "",
    links: [],
    next_page_url: null,
    path: "",
    per_page: 10,
    prev_page_url: null,
    to: 0,
    total: 0,
  },
  availabilityResponse: {
    musaitlik_list: [],
    operator_firma_list: [],
    operator_rez_kodu: "",
    musaitlik_aciklama: "",
  },
  isLoading: false,
  error: "",
  updateAvailabilityLoading: false,
  updateAvailabilityError: "",
};

const listReservationsChannelSlice = createSlice({
  name: "listReservationsChannel",
  initialState,
  reducers: {
    handleSaveListReservationsResponse(state, action) {
      state.listReservationsResponse = action.payload;
      const { data, ...rest } = action.payload.rezler;
      state.reservations = data;
      state.pagination = { ...rest };
    },
    handleChangeReservationFilterChoices(state, action) {
      state.filterReservations = action.payload;
    },
    handleSaveAvailabilityResponse(state, action) {
      const data = action.payload;
      const flattenedMusaitlik = Array.isArray(data.musaitlik_list)
        ? data.musaitlik_list.flat(1)
        : [];
      const flattenedOperatorFirma = Array.isArray(data.operator_firma_list)
        ? data.operator_firma_list.flat(1)
        : [];
      state.availabilityResponse = {
        ...data,
        musaitlik_list: flattenedMusaitlik,
        operator_firma_list: flattenedOperatorFirma,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateAvailabilityLoading(state, action) {
      state.updateAvailabilityLoading = action.payload;
    },
    setUpdateAvailabilityError(state, action) {
      state.updateAvailabilityError = action.payload;
    },
  },
});

export const {
  handleSaveListReservationsResponse,
  handleChangeReservationFilterChoices,
  handleSaveAvailabilityResponse,
  setIsLoading,
  setError,
  setUpdateAvailabilityLoading,
  setUpdateAvailabilityError,
} = listReservationsChannelSlice.actions;

export default listReservationsChannelSlice.reducer;
