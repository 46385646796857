import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";

interface HotelsWithoutPriceTableProps {
  isLoading: boolean;
}

const HotelsWithoutPriceTable = ({ isLoading }: HotelsWithoutPriceTableProps) => {
  const { tableData, tableColumns, legend} = useHandleTable();

  return (
    <CustomReactTable
      tableData={tableData}
      tableColumns={tableColumns}
      loading={isLoading}
      legend={legend}
    />
  );
};

export default HotelsWithoutPriceTable;