import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  ChangeStatusPayload,
  ListCostReviseData,
  UpdateCostRevisePayload,
} from "types/reservationMonitoring";
import ModalInfo from "./ModalInfo";

const lineStyle: React.CSSProperties = {
  borderBottom: "1px solid #ccc",
  maxWidth: "460px",
  margin: "16px auto",
};

interface UpdateStatusModalProps {
  reservations: ListCostReviseData[];
  reservation_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  updateCostRevise: (payload: UpdateCostRevisePayload) => void;
  changeStatusPositive: (payload: ChangeStatusPayload) => void;
  changeStatusNegative: (payload: ChangeStatusPayload) => void;
}

const UpdateStatusModal = ({
  reservations,
  reservation_id,
  isOpen,
  handleToggle,
  updateCostRevise,
  changeStatusPositive,
  changeStatusNegative,
}: UpdateStatusModalProps) => {
  const reservation = reservations.find(
    (reservation) => reservation.rezervasyon_id === Number(reservation_id)
  )!;

  const [answer, setAnswer] = useState(reservation.operasyon_not ?? "");
  const [price, setPrice] = useState(reservation.operasyon_fiyat ?? "");

  useEffect(() => {
    setAnswer(reservation.operasyon_not ?? "");
    setPrice(reservation.operasyon_fiyat ?? "");
  }, [reservation]);


  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="md">
        <ModalHeader>Durum Güncelle</ModalHeader>
        <ModalInfo reservation={reservation} />
        <ModalBody>
          <FormGroup row className="d-flex align-items-center">
            <Label sm={3}>Kar</Label>
            <Col sm={9}>
              <Input
                type="text"
                placeholder="Fiyat"
                value={price}
                onChange={(e) => setPrice(Number(e.target.value))}
              />
            </Col>
          </FormGroup>
          <div style={lineStyle}></div>
          <FormGroup row>
            <Label for="aciklama" sm={3} className="control-label">
              Açıklama
            </Label>
            <Col sm={9}>
              <Input
                id="aciklama"
                type="textarea"
                placeholder="Açıklama"
                rows={3}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="primary"
            onClick={() =>
              updateCostRevise({
                rezervasyon_id: reservation_id,
                aciklama: answer,
              })
            }
            isLoading={false}
          >
            Güncelle
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="success"
            onClick={() =>
              changeStatusPositive({
                rezervasyon_id: reservation_id,
                aciklama: answer,
                kar: price.toString(),
              })
            }
          >
            Olumlu
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="danger"
            onClick={() =>
              changeStatusNegative({
                rezervasyon_id: reservation_id,
                aciklama: answer,
                kar: price.toString(),
              })
            }
          >
            Olumsuz
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default UpdateStatusModal;
