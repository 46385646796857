import FilterReservationsForm from "./FilterReservationsForm";
import ReservationsTable from "./ReservationsTable";


interface NoteConfirmationReservationsPageProps {
  isLoading: boolean;
}

const NoteConfirmationReservationsPage = ({ isLoading }: NoteConfirmationReservationsPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterReservationsForm />
      <ReservationsTable isLoading={isLoading} />
    </div>
  );
};

export default NoteConfirmationReservationsPage;
