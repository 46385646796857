interface CustomPageDescriptionsProps {
  descriptions: string[] | JSX.Element[];
  variant?: "info" | "warning" | "danger" | "success";
}

const CustomPageDescriptions = ({
  descriptions,
  variant = "info",
}: CustomPageDescriptionsProps) => {
  const iconClasses = {
    info: "ri-information-line",
    warning: "ri-error-warning-line",
    danger: "ri-error-warning-line",
    success: "ri-check-line",
  };

  const bgColorClasses = {
    info: "theme-bg-primary",
    warning: "theme-bg-warning",
    danger: "theme-bg-danger",
    success: "theme-bg-success",
  };

  return (
    <div className="border rounded overflow-hidden mb-3 d-flex">
      <div
        className={`d-none d-md-flex justify-content-center align-items-center ${bgColorClasses[variant]}`}
        style={{ width: "80px" }}
      >
        <i className={`${iconClasses[variant]} text-white fw-medium fs-36`}></i>
      </div>
      <div className="flex-grow-1 d-flex justify-content-end gap-5 theme-bg-light p-2 ps-3">
        <div className="d-flex flex-grow-1 flex-column gap-1">
          {descriptions.map((row, index) => (
            <p key={index} className="fw-normal m-0 fs-14">
              {row}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomPageDescriptions;
