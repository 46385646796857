import HotelsWithoutPricePage from "Components/Custom/DocumentsPages/HotelsWithoutPricePage";
import useHotelsWithoutPrice from "Components/Hooks/documents/useHotelsWithoutPrice";
import PageContainer from "pages/PageContainer";


const HotelsWithoutPrice = () => {
  const { isLoading } = useHotelsWithoutPrice();

  return (
    <PageContainer title={"Fiyatı Olmayan Oteller"}>
      <HotelsWithoutPricePage isLoading={isLoading} />
    </PageContainer>
  );
};

export default HotelsWithoutPrice;
