import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { ListCostReviseInitialState } from "slices/reservationMonitoring/listCostRevise/reducer";
import { listCostRevise } from "slices/reservationMonitoring/listCostRevise/thunk";


const useListCostRevise = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { ListCostRevise: ListCostReviseInitialState }) => state.ListCostRevise
  );

  useEffect(() => {
    dispatch(listCostRevise());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useListCostRevise;
