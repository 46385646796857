import { CustomFormContainer } from "Components/Custom/UI";
import ReservationCard from "./ReservationCard";
import useHandleCards from "./useHandleCards";

const DataCards = () => {
  const { cards } = useHandleCards();

  return (
    <>
      <CustomFormContainer title="Günün Özeti">
        <div className="d-flex flex-wrap gap-4">
          {cards.map((card, index) => (
            <ReservationCard
              key={index}
              title={card.title}
              value={card.value}
              color={card.color}
              cardIcon={card.Icon}
            />
          ))}
        </div>
      </CustomFormContainer>
    </>
  );
};

export default DataCards;