import FilterReservationsForm from "./FilterReservationsForm";
import ReservationData from "./ReservationData";
import ReservationsTable from "./ReservationsTable";



interface ListReservationsChannelPageProps {
  isLoading: boolean;
}

const ListReservationsChannelPage = ({ isLoading }: ListReservationsChannelPageProps) => {
  return (
    <div className="d-flex flex-column gap-4">
      <FilterReservationsForm />
      <ReservationsTable isLoading={isLoading} />
      <ReservationData/>
    </div>
  );
};

export default ListReservationsChannelPage;
