import ListReservationsChannelPage from "Components/Custom/ReservationListsPages/ListReservationsChannel";
import useListReservationsChannel from "Components/Hooks/reservationLists/useListReservationsChannel";
import PageContainer from "pages/PageContainer";

const ListReservationsChannel = () => {
  const { isLoading } = useListReservationsChannel();

  return (
    <PageContainer title="Rezervasyon Listele Kanal">
      <ListReservationsChannelPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default ListReservationsChannel;
