import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch, useSelector } from "react-redux";
import { TemporaryOnlineReservationsInitialState } from "slices/reservationMonitoring/temporaryOnlineReservations/reducer";
import { TABLE_COLORS } from "utils/tableColors";
import {
  CallCustomerPayload,
  TemporaryOnlineReservationsData,
  UpdateTempOnlineReservationPayload,
} from "types/reservationMonitoring";
import {
  callCustomer,
  listTemporaryOnlineReservations,
  updateTempDescription,
} from "slices/reservationMonitoring/temporaryOnlineReservations/thunk";
import { setToastError } from "slices/toast/reducer";
import { Dispatch, SetStateAction } from "react";
import { LoginInitialState } from "slices/auth/login/reducer";

const useHandleTable = (
  setSelectedReservationId: Dispatch<SetStateAction<number>>,
  setShowUpdateStatusModal: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { temporaryOnlineReservations } = useSelector(
    (state: {
      TemporaryOnlineReservations: TemporaryOnlineReservationsInitialState;
    }) => state.TemporaryOnlineReservations
  );

  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );

  const handleUpdateTempDescription = (
    payload: UpdateTempOnlineReservationPayload
  ) => {
    try {
      dispatch(
        updateTempDescription(payload, () => {
          setShowUpdateStatusModal((prevState) => !prevState);
          setSelectedReservationId(0);
          dispatch(listTemporaryOnlineReservations());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleCall = (payload: CallCustomerPayload) => {
    try {
      dispatch(callCustomer(payload, () => {}));
    } catch (error) {
      setToastError(error);
    }
  };

  const tableData = temporaryOnlineReservations?.map((reservation) => {
    let rowColor = "";

    if (reservation.bg_color === "danger") {
      rowColor = TABLE_COLORS.red;
    } else if (reservation.bg_color === "warning") {
      rowColor = TABLE_COLORS.green;
    } else {
      rowColor = TABLE_COLORS.green;
    }

    return {
      otel_adi: reservation.otel_adi,
      otel_telefon: reservation.otel_telefon,
      id: reservation.id,
      rezervasyon_kodu: reservation.rezervasyon_kodu,
      yetiskin_ad_1: reservation.yetiskin_ad_1,
      yetiskin_ad_2: reservation.yetiskin_ad_2,
      sabit_tel: reservation.sabit_tel,
      cep: reservation.cep,
      otel_id: reservation.otel_id,
      data: reservation.data,
      giris_tarihi: reservation.giris_tarihi,
      cikis_tarihi: reservation.cikis_tarihi,
      toplam_tutar: reservation.toplam_tutar,
      islem_tarihi: reservation.islem_tarihi,
      islem_durumu: reservation.islem_durumu,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "Tarih",
      accessorKey: "islem_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "100px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Giriş Tarihi",
      accessorKey: "giris_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Çıkış Tarihi",
      accessorKey: "cikis_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Toplam Tutar",
      accessorKey: "toplam_tutar",
      enableColumnFilter: false,
    },
    {
      header: "Cep Telefonu",
      accessorKey: "cep",
      enableColumnFilter: false,
    },
    {
      header: "1. Yetişkin",
      accessorKey: "yetiskin_ad_1",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "100px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "2. Yetişkin",
      accessorKey: "yetiskin_ad_2",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "100px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    // {
    //   header: "Sabit Tel",
    //   accessorKey: "sabit_tel",
    //   enableColumnFilter: false,
    // },
    {
      header: "Durum",
      accessorKey: "islem_durumu",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();

        const formattedLines = value
          ?.split(")")
          .filter((line: string) => line.trim() !== "") || [""];

        return (
          <>
            <div className="text-wrap" style={{ width: "200px" }}>
              {formattedLines.map((line: any, index: any) => (
                <div key={index}>
                  {line !== "" ? <i className="ri-arrow-right-line" /> : ""}{" "}
                  {line}
                </div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData: TemporaryOnlineReservationsData = cell.row.original;
        const rezervasyon_id = rowData.id;
        const dahili = user.dahili;
        const musteri_no = rowData.cep;
        const otel_no = rowData.otel_telefon;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="update"
                onClick={() => {
                  setSelectedReservationId(rezervasyon_id);
                  setShowUpdateStatusModal((prevState) => !prevState);
                }}
                title="Durumu Güncelle"
              />
              <CustomRowOperationButton
                variant="phone"
                onClick={() =>
                  handleCall({
                    dahili: dahili,
                    aranan_no: musteri_no,
                    arayan_no: otel_no,
                  })
                }
                title="Müşteriyi Ara"
              />
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.green,
      label: "İlgileniliyor",
    },
    {
      color: TABLE_COLORS.red,
      label: "İlgilenilmiyor",
    },
  ];

  return {
    tableData,
    tableColumns,
    legend,
    reservations: temporaryOnlineReservations,
    handleUpdateTempDescription,
  };
};

export default useHandleTable;
