import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  TemporaryOnlineReservationsData,
  UpdateTempOnlineReservationPayload,
} from "types/reservationMonitoring";

interface UpdateStatusModalProps {
  reservations: TemporaryOnlineReservationsData[];
  reservation_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  updateStatus: (payload: UpdateTempOnlineReservationPayload) => void;
}

const UpdateStatusModal = ({
  reservations,
  reservation_id,
  isOpen,
  handleToggle,
  updateStatus,
}: UpdateStatusModalProps) => {
  const reservation = reservations.find(
    (reservation) => reservation.id === reservation_id
  )!;

  const [answer, setAnswer] = useState("");

  useEffect(() => {
    setAnswer("");
  }, [reservation]);

  const handleUpdate = () => {
    updateStatus({
      rezervasyon_id: reservation_id,
      aciklama: answer,
    });
  };

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="md">
        <ModalHeader>Geçici Rezervasyon Durum Güncelle</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label for="aciklama" sm={3} className="control-label">
              Açıklama
            </Label>
            <Col sm={9}>
              <Input
                id="aciklama"
                type="textarea"
                placeholder="Açıklama"
                rows={3}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="primary"
            onClick={handleUpdate}
            isLoading={false}
          >
            Güncelle
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default UpdateStatusModal;
