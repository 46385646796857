import moment from "moment";
import { useSelector } from "react-redux";
import { ListPriceReviseInitialState } from "slices/reservationMonitoring/listPriceRevise/reducer";
import { ANALYSIS_COLORS } from "utils/analysisColors";

const useHandleReservationData = () => {
  const { cardData } = useSelector(
    (state: { ListPriceRevise: ListPriceReviseInitialState }) =>
      state.ListPriceRevise
  );

  const today = moment().format('YYYY-MM-DD');
  
  const cards = [
    {
      title: `${today} Tarihinde Alınan Toplam Fiyat ve Toplam İndirim Bilgisi`,
      value: [
        "CRM için alt limit: 10.000 TL",
        "CC  için alt limit: 30.000 TL",
      ],
      color: ANALYSIS_COLORS.blue,
    },
    {
      title: "Fiyat Revize İstenen Toplam Tutar",
      value: cardData.toplam_maliyet,
      color: ANALYSIS_COLORS.red,
    },
    {
      title: "Toplam Alınan İndirim",
      value: cardData.toplam_alinan_indirim,
      color: ANALYSIS_COLORS.green,
    },
  ];

  return {
    cards,
  };
};

export default useHandleReservationData;
