import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch, useSelector } from "react-redux";
import { AdditionalPagesInitialState } from "slices/myWebSite/additionalPages/reducer";
import { GalleryImage } from "types/myWebSite";
import { HotelInitialState } from "slices/hotel/reducer";
import { Dispatch } from "react";
import { deleteGalleryImage, getGalleryImages } from "slices/myWebSite/additionalPages/thunk";

const useHandleTable = () => {
    const { galleryImages } = useSelector(
        (state: { AdditionalPages: AdditionalPagesInitialState }) =>
            state.AdditionalPages
    );

    const { hotel } = useSelector(
        (state: { Hotel: HotelInitialState }) => state.Hotel
    );

    const { otel_id } = hotel;

    const dispatch: Dispatch<any> = useDispatch();

    const handleDelete = (sayfa_id: number, resim_id: number) => {
        dispatch(
            deleteGalleryImage({ otel_id, sayfa_id, resim_id }, () => {
                dispatch(
                    getGalleryImages(otel_id, sayfa_id)
                );
            })
        );
    };

    const tableData: GalleryImage[] = galleryImages?.map((image) => ({
        id: image.id,
        sayfa_id: image.sayfa_id,
        otel_id: image.otel_id,
        resim: image.resim,
        add_time: image.add_time,
    }));

    const tableColumns = [
        {
            header: "Resim",
            accessorKey: "resim",
            enableColumnFilter: false,
            cell: (cell: any) => {
                const rowData = cell.row.original;
                const { resim } = rowData;
                return (
                    <img src={resim} style={{ height: '200px' }} alt="resim"/>
                );
            },
        },
        {
            header: "Ekleme Zamanı",
            accessorKey: "add_time",
            enableColumnFilter: false,
        },
        {
            header: "İşlemler",
            accessorKey: "id",
            enableColumnFilter: false,
            cell: (cell: any) => {
                const rowData = cell.row.original;
                const { sayfa_id, id: resim_id } = rowData;
                return (
                    <>
                        <div className="d-flex justify-content-center gap-2">
                            <CustomRowOperationButton
                                title="Sil"
                                variant="delete"
                                onClick={() => handleDelete(sayfa_id, resim_id)}
                            />
                        </div>
                    </>
                );
            },
        },
    ];

    return { tableData, tableColumns };
};

export default useHandleTable;
