import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";
import UpdateStatusOperationModal from "./Modals/UpdateStatusOperationModal";
import UpdateStatusSalesModal from "./Modals/UpdateStatusSalesModal";
import { useState } from "react";

interface ListPriceReviseTableProps {
  isLoading: boolean;
}

const ListPriceReviseTable = ({ isLoading }: ListPriceReviseTableProps) => {
  const [selectedReservationId, setSelectedReservationId] = useState(0);
  const [showUpdateStatusOperationModal, setShowUpdateStatusOperationModal] =
    useState(false);
  const [showUpdateStatusSalesModal, setShowUpdateStatusSalesModal] =
    useState(false);

  const {
    tableData,
    tableColumns,
    legend,
    colorConfig,
    reservations,
    handleUpdateOperation,
    handleUpdateSales
  } = useHandleTable(
    setSelectedReservationId,
    setShowUpdateStatusOperationModal,
    setShowUpdateStatusSalesModal
  );

  return (
    <>
      <CustomReactTable
        tableData={tableData}
        tableColumns={tableColumns}
        loading={isLoading}
        legend={legend}
        colorConfig={colorConfig}
      />
      {selectedReservationId !== 0 && (
        <UpdateStatusOperationModal
          reservations={reservations}
          reservation_id={selectedReservationId}
          isOpen={showUpdateStatusOperationModal}
          handleToggle={() =>
            setShowUpdateStatusOperationModal((prevState) => !prevState)
          }
          updateStatus={handleUpdateOperation}
        />
      )}
      {selectedReservationId !== 0 && (
        <UpdateStatusSalesModal
          reservations={reservations}
          reservation_id={selectedReservationId}
          isOpen={showUpdateStatusSalesModal}
          handleToggle={() => setShowUpdateStatusSalesModal((prevState) => !prevState)}
          updateStatus={handleUpdateSales}
        />
      )}
    </>
  );
};

export default ListPriceReviseTable;
