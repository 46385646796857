import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { HotelsWithoutPriceInitialState } from "slices/documents/hotelsWithoutPrice/reducer";
import { listHotelsWithoutPrice } from "slices/documents/hotelsWithoutPrice/thunk";

const useHotelsWithoutPrice = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { HotelsWithoutPrice: HotelsWithoutPriceInitialState }) => state.HotelsWithoutPrice
  );

  useEffect(() => {
    dispatch(listHotelsWithoutPrice());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useHotelsWithoutPrice;