// import { CustomFormFieldContainer } from "Components/Custom/UI";
// import { Dispatch } from "react";
// import { useDispatch } from "react-redux";
// import { Input } from "reactstrap";
// import { handleChangeInput } from "slices/userOperations/addUser/reducer";
import { UpdateUserFormErrors, UpdateUserPayload } from "types/userOperations";

interface UserInetCodeProps {
  values: UpdateUserPayload;
  formErrors: UpdateUserFormErrors;
  validateFormChange: (values: UpdateUserPayload) => void;
}

const UserInetCode = ({
  values,
  formErrors,
  validateFormChange,
}: UserInetCodeProps) => {
  // const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      {/* <CustomFormFieldContainer
        label="Operatör Kodu"
        error={formErrors.inet_kodu}
        divider
        required
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.inet_kodu}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                inet_kodu: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              inet_kodu: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer> */}

    </>
  );
};

export default UserInetCode;
