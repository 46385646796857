import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";
import {
  savePeriods,
  getPeriods,
} from "slices/pricesAndQuota/advancedEntry/thunk";
import { Column, Period, PriceType } from "types/PricesQuotas";

interface RequestPayload {
  otel_id: number;
  oda_id: number;
  pansiyon_id: number;
  geceleme_gunu: number;
  cocuk_ozel_yas_id: number;
  veriler: Period[];
}

interface InputData {
  [bookingType: string]: { [key: string]: any };
  priceTypes: string[];
}

function convertBookingData(
  data: InputData,
  priceTypes: string[],
  currency: string
): { veriler: Period[] } {
  const result: Period[] = [];

  for (const [bookingType, dates] of Object.entries(data)) {
    const bookings: Period = {
      odeme_tipi: bookingType,
      sutunlar: [],
    };

    const dateRecords: { [date: string]: Column } = {};

    for (const [key, value] of Object.entries(dates)) {
      const [year, month, day, suffix] = key.split("-");
      const date = `${year}-${month}-${day}`;

      if (!dateRecords[date]) {
        dateRecords[date] = {
          tarih: date,
          musaitlik: dates[`${year}-${month}-${day}-musaitlik`],
          "giris-cikislar": {
            cta: parseInt(dates[`${year}-${month}-${day}-cta`]).toString(),
            ctd: parseInt(dates[`${year}-${month}-${day}-ctd`]).toString(),
          },
          rezervasyon: dates[`${year}-${month}-${day}-rezervasyon`],
          kontenjan: dates[`${year}-${month}-${day}-kontenjan`],
          fiyatlar: [],
        };
      }

      if (
        suffix &&
        suffix !== "cta" &&
        suffix !== "ctd" &&
        priceTypes.includes(suffix) &&
        (suffix.startsWith("y") ||
          suffix.startsWith("c") ||
          suffix.includes("+") ||
          suffix.includes("T.") ||
          suffix.includes("kb"))
      ) {
        dateRecords[date].fiyatlar.push({
          fiyat_tipi: suffix,
          fiyat: value,
          currency,
        });
      }
    }

    bookings.sutunlar = Object.values(dateRecords);
    result.push(bookings);
  }

  return { veriler: result };
}

const useHandleSavePeriods = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectedAdvancedEntryState = (state: any) => state.AdvancedEntry;
  const selectAdvancedEntryProperties = createSelector(
    selectedAdvancedEntryState,
    (state) => ({
      selectedRoom: state.selectedRoom,
      selectedConcept: state.selectedConcept,
      selectedDates: state.selectedDates,
      selectedDays: state.selectedDays,
      isAllDaysSelected: state.isAllDaysSelected,
      nights: state.nights,
      childSpeacialAgeId: state.childSpeacialAgeId,
      periods: state.periods,
      isLoading: state.isLoading,
      error: state.error,
      columnsInputValues: state.columnsInputValues,
    })
  );

  const selectedHotelState = (state: any) => state.Hotel;
  const selectHotelProperties = createSelector(selectedHotelState, (state) => ({
    hotel: state.hotel,
  }));

  const {
    selectedRoom,
    selectedConcept,
    selectedDates,
    selectedDays,
    isAllDaysSelected,
    nights,
    childSpeacialAgeId,
    columnsInputValues,
    periods,
  } = useSelector(selectAdvancedEntryProperties);

  const { hotel } = useSelector(selectHotelProperties);

  const handleSavePeriods = () => {
    const priceTypes: string[] = periods.fiyat_tipleri.map(
      (priceType: PriceType) => priceType.fiyat_tipi
    );

    const currency = hotel.currency;

    const requestPayload: RequestPayload = {
      otel_id: hotel.otel_id,
      oda_id: selectedRoom,
      pansiyon_id: selectedConcept,
      geceleme_gunu: nights,
      cocuk_ozel_yas_id: childSpeacialAgeId,
      veriler: convertBookingData(columnsInputValues, priceTypes, currency)
        .veriler,
    };

    const getRequestedPayload = {
      otel_id: hotel.otel_id,
      oda_id: selectedRoom,
      pansiyon_id: selectedConcept,
      tarihler: `${selectedDates.startDate} - ${selectedDates.endDate}`,
      tum_gunler: isAllDaysSelected ? 1 : 0,
      gunler: selectedDays,
      geceleme_gunu: nights,
      cocuk_ozel_yas_id: childSpeacialAgeId,
    };

    dispatch(
      savePeriods(requestPayload, () => {
        dispatch(getPeriods(getRequestedPayload));
      })
    );
  };
  return { handleSavePeriods };
};

export default useHandleSavePeriods;
