import { CustomButton } from "Components/Custom/UI";
import { CreateGroupReservationPayload } from "types/reservation";

interface SaveGroupReservationButtonProps {
  values: CreateGroupReservationPayload;
  validateForm: (values: CreateGroupReservationPayload) => { isValid: boolean };
  handleSubmitted: (isSubmitted: boolean) => void;
  onAddNewCollection: (formValues: CreateGroupReservationPayload) => void;
  isLoading: boolean;
}

const SaveGroupReservationButton = ({
  values,
  validateForm,
  handleSubmitted,
  onAddNewCollection,
  isLoading,
}: SaveGroupReservationButtonProps) => {
  const onClick = () => {
    const { isValid } = validateForm(values);
    handleSubmitted(true);

    if (isValid) {
      onAddNewCollection(values);
    }
  };
  return (
    <div className="d-flex justify-content-end gap-4 mt-4">
      <CustomButton variant="success" onClick={onClick} isLoading={isLoading}>
        Talebi Kaydet
      </CustomButton>
    </div>
  );
};

export default SaveGroupReservationButton;
