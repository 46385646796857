import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveListIncomingTransfersResponse,
  setIsLoading,
  setError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  FILTER_INCOMING_TRANSFERS,
  LIST_INCOMING_TRANSFERS,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import { ListIncomingTranfersPayload } from "types/reservationLists";

type SuccessCallback = () => void;

export const listIncomingTransfers = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(LIST_INCOMING_TRANSFERS);

    dispatch(
      handleSaveListIncomingTransfersResponse(response.data || response)
    );
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const filterIncomingTransfersData =
  (payload: ListIncomingTranfersPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(FILTER_INCOMING_TRANSFERS, payload);

      dispatch(
        handleSaveListIncomingTransfersResponse(response.data || response)
      );
      dispatch(setToastSuccess("Gelen havaleler başarıyla listelendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
