import {
  UpdateReservationCallRequestPayload,
  CalculatePriceForHotelRequestParams,
  CalculatePriceForLocationRequestPayload,
  ReservationCall,
  EnterReservationRequesRoomPayload,
  CreateOnRequestPayload,
} from "types/reservation";
import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveReservationCall,
  handleSaveCalculatePriceForHotel,
  handleSaveCalculatePriceForLocation,
  handleSaveReservationCallChildrenInfo,
  setIsLoading,
  setError,
  setUpdateCallLoading,
  setUpdateCallError,
  setCalculatePriceLoading,
  setCalculatePriceError,
  setChildrenInfoLoading,
  setChildrenInfoError,
  setSaveReservationLoading,
  setSaveReservationError,
  setCheckCardLoading,
  setCheckCardError,
  setSaveOnRequestLoading,
  setSaveOnRequestError,
} from "./reducer";

import { setToastSuccess, setToastError } from "slices/toast/reducer";

import {
  GET_RESERVATION_CALL,
  UPDATE_RESERVATION_CALL,
  RESERVATION_CALL_CALCULATE_PRICE_FOR_HOTEL,
  RESERVATION_CALL_CALCULATE_PRICE_FOR_LOCATION,
  GET_RESERVATION_CALL_CHILDREN_INFO,
  GET_RESERVATION_CALL_ROOM_CONCEPT_INFO,
  GET_RESERVATION_CALL_REQUEST_ROOM,
  GET_RESERVATION_GET_EMPLOYEES,
  GET_RESERVATION_GET_BANK_INSTALLMENTS,
  SAVE_RESERVATION,
  GET_RESERVATION_CHECK_CARD,
  SAVE_ON_REQUEST,
} from "helpers/url_helper";

import { Dispatch } from "redux";
import { CreditCardBin } from "types/GlobalTypes";

interface GetReservationCallParams {
  aranan: string;
  arayan: string;
  dahili: string;
}

interface SaveReservationResponse {
  mesaj_kodu: number;
  mesaj: string;
  rezervasyon_kodu: number;
  rezervasyon_id: number;
}

type SuccessCallback = (otel_adi?: string) => void;
type SuccessRedirectCallback = () => void;
type SuccessCallbackWithResponse = (res: any) => void;
type SuccessCallbackForSaveReservation = (reservation_id: number) => void;
type SuccessCheckCardCallback = (card: CreditCardBin) => void;

export const getReservationCall =
  (params: GetReservationCallParams, successCallback?: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.get(GET_RESERVATION_CALL, {
        params,
      });
      const res: ReservationCall = response.data || response;
      const otel_adi =
        res.otel_sorgu.length === 1 ? res.otel_sorgu[0].otel_adi : "";

      otel_adi && successCallback && successCallback(otel_adi);
      dispatch(handleSaveReservationCall(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateReservationCall =
  (
    payload: UpdateReservationCallRequestPayload,
    successCallback: SuccessCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateCallLoading(true));

      await axios.post(UPDATE_RESERVATION_CALL, payload);

      successCallback();
      dispatch(setToastSuccess("Müşteri bilgileri güncellendi."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateCallError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateCallLoading(false));
    }
  };

export const calculatePriceForHotel =
  (
    params: CalculatePriceForHotelRequestParams,
    successCallback: SuccessRedirectCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setCalculatePriceLoading(true));
      const response = await axios.get(
        RESERVATION_CALL_CALCULATE_PRICE_FOR_HOTEL,
        { params }
      );

      const res = response.data || response;

      successCallback();
      dispatch(handleSaveCalculatePriceForHotel(res));
      dispatch(setToastSuccess("Fiyat hesaplandı."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setCalculatePriceError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setCalculatePriceLoading(false));
    }
  };
export const calculatePriceAgain =
  (
    params: CalculatePriceForHotelRequestParams,
    successCallback: SuccessCallbackWithResponse
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setCalculatePriceLoading(true));
      const response = await axios.get(
        RESERVATION_CALL_CALCULATE_PRICE_FOR_HOTEL,
        { params }
      );

      const res = response.data || response;

      successCallback(res);
      dispatch(setToastSuccess("Fiyat tekrar hesaplandı."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setCalculatePriceError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setCalculatePriceLoading(false));
    }
  };

export const calculatePriceForLocation =
  (
    payload: CalculatePriceForLocationRequestPayload,
    successCallback: SuccessRedirectCallback
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setCalculatePriceLoading(true));
      const response = await axios.post(
        RESERVATION_CALL_CALCULATE_PRICE_FOR_LOCATION,
        payload
      );

      const res = response.data || response;

      dispatch(handleSaveCalculatePriceForLocation(res));
      successCallback();
      dispatch(setToastSuccess("Fiyat hesaplandı."));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setCalculatePriceError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setCalculatePriceLoading(false));
    }
  };

export const getReservationCallChildrenInfo =
  (otel_adi: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(setChildrenInfoLoading(true));
      const response = await axios.post(GET_RESERVATION_CALL_CHILDREN_INFO, {
        otel_adi,
      });

      const res = response.data || response;
      dispatch(handleSaveReservationCallChildrenInfo(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChildrenInfoError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChildrenInfoLoading(false));
    }
  };

export const getRoomConceptInfo =
  (otel_id: number, successCallback: SuccessCallbackWithResponse) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChildrenInfoLoading(true));
      const response = await axios.post(
        GET_RESERVATION_CALL_ROOM_CONCEPT_INFO,
        {
          otel_id,
        }
      );

      const res = response.data || response;
      successCallback(res);
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChildrenInfoError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChildrenInfoLoading(false));
    }
  };

export const requestRoom =
  (
    payload: EnterReservationRequesRoomPayload,
    successCallback: SuccessCallbackWithResponse
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChildrenInfoLoading(true));
      const response = await axios.post(
        GET_RESERVATION_CALL_REQUEST_ROOM,
        payload
      );

      const res = response.data || response;
      successCallback(res);
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChildrenInfoError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChildrenInfoLoading(false));
    }
  };

export const getEmployees =
  (successCallback: SuccessCallbackWithResponse) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChildrenInfoLoading(true));
      const response = await axios.post(GET_RESERVATION_GET_EMPLOYEES);

      const res = response.data || response;

      successCallback(res);
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChildrenInfoError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChildrenInfoLoading(false));
    }
  };

export const getBankInstallments =
  (successCallback: SuccessCallbackWithResponse) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChildrenInfoLoading(true));
      const response = await axios.post(GET_RESERVATION_GET_BANK_INSTALLMENTS);

      const res = response.data || response;

      successCallback(res);
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChildrenInfoError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChildrenInfoLoading(false));
    }
  };

export const saveReservation = (
  payload: any,
  successCallback: SuccessCallbackForSaveReservation
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setSaveReservationLoading(true));
      const response = await axios.post(SAVE_RESERVATION, payload);

      const res: SaveReservationResponse = response.data || response;

      successCallback(res.rezervasyon_id);
      dispatch(setToastSuccess(res.mesaj));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setSaveReservationError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setSaveReservationLoading(false));
    }
  };
};

export const checkCard = (
  bin: string,
  successCllback: SuccessCheckCardCallback
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setCheckCardLoading(true));
      const response = await axios.post(GET_RESERVATION_CHECK_CARD, { bin });

      const res: CreditCardBin[] = response.data || response;

      successCllback(res[0]);
      dispatch(setCheckCardError(""));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setCheckCardError(message));
      });
      successCllback({
        id: 0,
        bank_id: 0,
        bank: "",
        bankName: "",
        cardBrand: "",
        cardBrandIcon: "",
        cardLength: 0,
        cardProgram: "",
        cardProgramIcon: "",
        cardType: "",
        countryCode: "",
        cvvRequired: true,
      });
    } finally {
      dispatch(setCheckCardLoading(false));
    }
  };
};

export const saveOnRequest = (
  payload: CreateOnRequestPayload,
  successCallback: SuccessRedirectCallback
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setSaveOnRequestLoading(true));
      await axios.post(SAVE_ON_REQUEST, payload);

      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setSaveOnRequestError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setSaveOnRequestLoading(false));
    }
  };
};
