import { useSelector } from "react-redux";
import useValidateForm from "./useValidateForm";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { ReservationReviewCollection } from "types/reservation";
import {
  handleResetCollectionForm,
  ReviewReservationInitialState,
} from "slices/reservation/reviewReservation/reducer";
import { useSearchParams } from "react-router-dom";
import {
  addCollection,
  getReservationReview,
  getCollectionFormOptions,
} from "slices/reservation/reviewReservation/thunk";

interface Option {
  value: string;
  label: string;
}

const useHandleForm = () => {
  const [searchParams] = useSearchParams();

  const dispatch: Dispatch<any> = useDispatch();

  const reservationId = searchParams.get("rezervasyon_id");

  const {
    collection,
    collectionFormOptions,
    addCollectionLoading,
    collectionFormErrors,
  } = useSelector(
    (state: { ReviewReservation: ReviewReservationInitialState }) =>
      state.ReviewReservation
  );

  const handleAddNewCollection = (formValues: ReservationReviewCollection) => {
    const payload = new FormData();

    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        payload.append(key, String(value));
      }
    });

    console.log("payload : ", formValues);

    // dispatch(
    //   addCollection(payload, () => {
    //     dispatch(handleResetCollectionForm());
    //     dispatch(getReservationReview(reservationId!, () => {}));
    //     dispatch(getCollectionFormOptions());
    //   })
    // );
  };

  const { validateForm, validateFormChange, handleSubmitted } =
    useValidateForm();

  return {
    values: collection,
    collectionFormOptions,
    addCollectionLoading,
    collectionFormErrors,
    handleAddNewCollection,
    validateForm,
    validateFormChange,
    handleSubmitted,
  };
};

export default useHandleForm;
