import { CustomButton } from "Components/Custom/UI";
import { useNumberFormatter } from "Components/Hooks/useNumberFormatter";
import { ReservationReview } from "types/reservation";

interface SingleInfoProps {
  label: string;
  value: string;
}

const SingleInfo = ({ label, value }: SingleInfoProps) => {
  return (
    <>
      <div className="d-flex align-items-center fs-16 gap-2">
        <div className="fw-semibold">{label}</div>
        <div>{value} TL</div>
      </div>
    </>
  );
};

interface InfoRowProps {
  reservation: ReservationReview;
  showCollectionForm: boolean;
  handleShowCollectionForm: () => void;
}

const InfoRow = ({
  reservation,
  showCollectionForm,
  handleShowCollectionForm,
}: InfoRowProps) => {
  const { formatNumber } = useNumberFormatter();
  const { kk, havale, nakit } = reservation;

  const toNumber = (value: string) => parseFloat(value) || 0;

  const creditCardTotal = kk
    ? kk.reduce((sum, item) => sum + toNumber(item.kk_tutar), 0)
    : 0;

  const transferTotal = havale
    ? havale.reduce((sum, item) => sum + toNumber(item.havale_tutar), 0)
    : 0;

  const cashTotal = nakit
    ? nakit.reduce((sum, item) => sum + toNumber(item.nakit_tutar), 0)
    : 0;

  const totalAmount = formatNumber(creditCardTotal + transferTotal + cashTotal);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div
        className="gap-3 border rounded bg-white p-3"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "3rem",
        }}
      >
        <SingleInfo label={"ONAYLI TAHSİLAT:"} value={"0.00"} />
        <SingleInfo label={"VADE TUTARI:"} value={"0.00"} />
        <SingleInfo label={"ÇEKİLECEK TUTAR:"} value={totalAmount} />
        <SingleInfo label={"MİN. KISMİ TUTAR:"} value={"0.00"} />
        <SingleInfo label={"TAHSİLAT:"} value={"0.00"} />
        <SingleInfo label={"KALAN:"} value={"0.00"} />
      </div>
      <CustomButton
        variant={showCollectionForm ? "danger" : "primary"}
        onClick={handleShowCollectionForm}
      >
        {showCollectionForm ? "Tahsilat Girmekten Vazgeç" : "Tahsilat Gir"}
      </CustomButton>
    </div>
  );
};
export default InfoRow;
