import { CustomPageDescriptions } from "Components/Custom/UI";
import { TEMPORARY_ONLINE_RESERVATIONS } from "utils/pageDescriptions";
import TemporaryOnlineReservationsTable from "./TemporaryOnlineReservationsTable";

interface TemporaryOnlineReservationsPageProps {
  isLoading: boolean;
}

const TemporaryOnlineReservationsPage = ({
  isLoading,
}: TemporaryOnlineReservationsPageProps) => {
  const description = TEMPORARY_ONLINE_RESERVATIONS.map(
    (description, index) => (
      <div
        key={index}
        dangerouslySetInnerHTML={{ __html: description }}
        className="user-select-none"
      />
    )
  );
  return (
    <div className="d-flex flex-column gap-4">
      <CustomPageDescriptions descriptions={description} />
      <TemporaryOnlineReservationsTable isLoading={isLoading} />
    </div>
  );
};

export default TemporaryOnlineReservationsPage;
