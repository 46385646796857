import { CustomRowOperationButton } from "Components/Custom/UI";
import CustomAuthorizedFile from "Components/Custom/UI/CustomAuthorizedFile/CustomAuthorizedFile";
import { useDispatch, useSelector } from "react-redux";
import { ListOnRequestReservationsInitialState } from "slices/reservationMonitoring/listOnRequestReservations/reducer";
import { TABLE_COLORS } from "utils/tableColors";
import { Dispatch, SetStateAction } from "react";
import { AnswerOnRequestReservationPayload, UpdateStatusOnRequestReservationPayload } from "types/reservationMonitoring";
import { answerRequest, listOnRequestReservations, updateStatus, markAsResponsible } from "slices/reservationMonitoring/listOnRequestReservations/thunk";
import { setToastError } from "slices/toast/reducer";

/*
 * user with id 38 = Metin Özyön
 * user with id 160 = Muhammed Bodur
 * user with id 122 = Eda Yetkin
 * user with id 6933 = İlsu Sunal
 * user with id 6593 = Erol Mahmutoğlu
 */

const useHandleTable = (
  setSelectedReservationId: Dispatch<SetStateAction<number>>,
  setShowAnswerRequestModal: Dispatch<SetStateAction<boolean>>,
  setShowUpdateStatusModal: Dispatch<SetStateAction<boolean>>
) => {
  const { onRequestReservations } = useSelector(
    (state: {
      ListOnRequestReservations: ListOnRequestReservationsInitialState;
    }) => state.ListOnRequestReservations
  );

  const dispatch: Dispatch<any> = useDispatch();

  const handleAnswerRequest = (payload: AnswerOnRequestReservationPayload) => {
    try {
      dispatch(
        answerRequest(payload, () => {
          setShowAnswerRequestModal((prevState) => !prevState);
          setSelectedReservationId(0);
          dispatch(listOnRequestReservations());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleUpdateStatus = (
    payload: UpdateStatusOnRequestReservationPayload
  ) => {
    try {
      dispatch(
        updateStatus(payload, () => {
          setShowUpdateStatusModal((prevState) => !prevState);
          setSelectedReservationId(0);
          dispatch(listOnRequestReservations());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleMarkAsResponsible = (sorsat_id: number) => {
    try {
      dispatch(
        markAsResponsible({ sorsat_id: sorsat_id }, () => {
          dispatch(listOnRequestReservations());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const tableData = onRequestReservations?.map((reservation) => {
    let rowColor = "";

    if (reservation.durum === "1") {
      rowColor = TABLE_COLORS.yellow;
    } else if (reservation.durum === "2") {
      rowColor = TABLE_COLORS.green;
    } else if (reservation.durum === "3") {
      rowColor = TABLE_COLORS.red;
    } else {
      rowColor = TABLE_COLORS.gray;
    }

    return {
      id: reservation.id,
      istek_zamani: reservation.istek_zamani,
      operasyon_uye_ad_soyad: reservation.ilgilenen_personel,
      otel_info: `${reservation.otel_adi} (${reservation.sehir})`,
      otel_adi: reservation.otel_adi,
      sehir: reservation.sehir,
      istek_yapan_personel: reservation.istek_yapan_personel,
      giris_tarihi: reservation.giris_tarihi,
      cikis_tarihi: reservation.cikis_tarihi,
      yetiskin_sayisi: reservation.yetiskin_sayisi,
      cocuk_sayisi: reservation.cocuk_sayisi,
      cocuk_bilgileri: reservation.cocuk_bilgileri,
      istek_mesaj: reservation.istek_mesaj,
      ilgilenen_personel: reservation.ilgilenen_personel,
      cevap_mesaj: reservation.cevap_mesaj,
      durum: reservation.durum,
      istek_yapan_personel_id: reservation.istek_yapan_uye_id,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "ID",
      accessorKey: "id",
      enableColumnFilter: false,
    },
    {
      header: "Tarih",
      accessorKey: "istek_zamani",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "100px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Yetkili",
      accessorKey: "operasyon_uye_ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Otel",
      accessorKey: "otel_info",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "150px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Personel",
      accessorKey: "istek_yapan_personel",
      enableColumnFilter: false,
    },
    {
      header: "Giriş",
      accessorKey: "giris_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Çıkış",
      accessorKey: "cikis_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Y. S.",
      accessorKey: "yetiskin_sayisi",
      enableColumnFilter: false,
    },
    {
      header: "Ç. S.",
      accessorKey: "cocuk_sayisi",
      enableColumnFilter: false,
    },
    {
      header: "Ç. Yaş",
      accessorKey: "cocuk_bilgileri",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        try {
          const cocukBilgileri = JSON.parse(value);
          const cocukYaslari = Object.values(cocukBilgileri).filter(
            (yas) => yas !== ""
          );

          const formattedValues = cocukYaslari
            .map((yas, index) => `${index + 1}) ${yas}`)
            .join(", ");
          return (
            <div className="text-wrap" style={{ width: "90px" }}>
              {formattedValues || ""}
            </div>
          );
        } catch (e) {
          return <div className="text-wrap" style={{ width: "90px" }}></div>;
        }
      },
    },
    {
      header: "İstek Mesaj",
      accessorKey: "istek_mesaj",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "200px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "İlgilenen",
      accessorKey: "ilgilenen_personel",
      enableColumnFilter: false,
    },
    {
      header: "Cevap Mesaj",
      accessorKey: "cevap_mesaj",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();

        const formattedLines = value
          ?.split(/<\/?br>/)
          .filter((line: string) => line.trim() !== "") || [""];

        return (
          <>
            <div className="text-wrap" style={{ width: "200px" }}>
              {formattedLines.map((line: any, index: any) => (
                <div key={index}>
                  <i className="ri-arrow-right-line" /> {line}
                </div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData = cell.row.original;
        const personnelWhoCreatedRequest: string =
          rowData.istek_yapan_personel_id;
        const reservationId = rowData.id;

        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              {/* Alttaki buton sadece durum <= 1 ise, isteği yapan personel tarafından görülecek */}
              <CustomAuthorizedFile
                needAllAuthorities={false}
                userId={[personnelWhoCreatedRequest, "6933", "6593"]}
                groupId={[]}
                yetki={[]}
              >
                {rowData.durum <= 1 && (
                  <CustomRowOperationButton
                    variant="addPlus"
                    onClick={() => {
                      setSelectedReservationId(reservationId);
                      setShowAnswerRequestModal(true);
                    }}
                    title="Cevapla"
                  />
                )}
              </CustomAuthorizedFile>

              {/* Alttaki buton sadece durum <= 1 ise, operasyon grubu ve [38, 160, 122, 6933] idli personel tarafından görülecek */}
              <CustomAuthorizedFile
                needAllAuthorities={false}
                userId={["38", "160", "122", "6933", "6593"]}
                groupId={["7"]}
                yetki={[]}
              >
                {rowData.durum <= 1 && (
                  <CustomRowOperationButton
                    variant="update"
                    onClick={() => {
                      setSelectedReservationId(reservationId);
                      setShowUpdateStatusModal(true);
                    }}
                    title="Durumu Güncelle"
                  />
                )}
              </CustomAuthorizedFile>

              {/* Alttaki buton sadece durum 0 ise, operasyon grubu ve [38, 160, 122, 6933] idli personel tarafından görülecek */}
              <CustomAuthorizedFile
                needAllAuthorities={false}
                userId={["38", "160", "122", "6933", "6593"]}
                groupId={["7"]}
                yetki={[]}
              >
                {rowData.durum === "0" && (
                  <CustomRowOperationButton
                    variant="check"
                    onClick={() => handleMarkAsResponsible(reservationId)}
                    title="İlgileniyorum"
                  />
                )}
              </CustomAuthorizedFile>
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.gray,
      label: "Henüz İlgilenilmiyor",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "İlgileniliyor",
    },
    {
      color: TABLE_COLORS.green,
      label: "Olumlu Sonuçlanmış",
    },
    {
      color: TABLE_COLORS.red,
      label: "Olumsuz Sonuçlanmış",
    },
  ];

  return {
    tableData,
    tableColumns,
    legend,
    reservations: onRequestReservations,
    handleAnswerRequest, 
    handleUpdateStatus
  };
};

export default useHandleTable;
