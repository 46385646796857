import axios from "axios";
import { handleErrors } from "helpers/axios_helpers";
import {
  handleSaveCostReviseResponse,
  setIsLoading,
  setError,
  setUpdateRequestLoading,
  setUpdateRequestError,
  setChangeStatusLoading,
  setChangeStatusError,
  setMarkAsResponsibleLoading,
  setMarkAsResponsibleError,
} from "./reducer";
import { setToastError, setToastSuccess } from "slices/toast/reducer";
import {
  CHANGE_COST_REVISE_STATUS_NEGATIVE,
  CHANGE_COST_REVISE_STATUS_POSITIVE,
  COST_REVISE_MARK_AS_RESPONSIBLE,
  FILTER_COST_REVISE,
  LIST_COST_REVISE,
  UPDATE_COST,
} from "helpers/url_helper";
import { Dispatch } from "redux";
import {
  ChangeStatusPayload,
  ListCostRevisePayload,
  MarkAsResponsiblePayload,
  UpdateCostRevisePayload,
} from "types/reservationMonitoring";

type SuccessCallback = () => void;

export const listCostRevise = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(LIST_COST_REVISE);

    dispatch(handleSaveCostReviseResponse(response.data || response));
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(setError(message));
      dispatch(setToastError(message));
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const filterCostReviseData =
  (payload: ListCostRevisePayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const response = await axios.post(FILTER_COST_REVISE, payload);

      dispatch(handleSaveCostReviseResponse(response.data || response));
      dispatch(setToastSuccess("Maliyet revizeler başarıyla listelendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const changeStatusPositive =
  (payload: ChangeStatusPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(CHANGE_COST_REVISE_STATUS_POSITIVE, payload);

      dispatch(setToastSuccess("Durum başarıyla güncellendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };

export const changeStatusNegative =
  (payload: ChangeStatusPayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setChangeStatusLoading(true));
      await axios.post(CHANGE_COST_REVISE_STATUS_NEGATIVE, payload);

      dispatch(setToastSuccess("Durum başarıyla güncellendi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setChangeStatusError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setChangeStatusLoading(false));
    }
  };

export const updateCostRevise =
  (payload: UpdateCostRevisePayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateRequestLoading(true));
      await axios.post(UPDATE_COST, payload);

      dispatch(setToastSuccess("Başarıyla revize edildi."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setUpdateRequestError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setUpdateRequestLoading(false));
    }
  };

export const markAsResponsible =
  (payload: MarkAsResponsiblePayload, successCallback: SuccessCallback) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setMarkAsResponsibleLoading(true));
      await axios.post(COST_REVISE_MARK_AS_RESPONSIBLE, payload);

      dispatch(setToastSuccess("Sorumlu başarıyla atandı."));
      successCallback();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMarkAsResponsibleError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMarkAsResponsibleLoading(false));
    }
  };
