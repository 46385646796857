import { CustomRowOperationButton } from "Components/Custom/UI";
import { useNumberFormatter } from "Components/Hooks/useNumberFormatter";
import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginInitialState } from "slices/auth/login/reducer";
import { INetNonentryListInitialState } from "slices/reservationLists/inetNonentryList/reducer";
import { setToastError } from "slices/toast/reducer";
import { callCustomer } from "slices/zoiper/thunk";
import { INetNonentryListData } from "types/reservationLists";
import { CallCustomerPayload } from "types/reservationMonitoring";

const useHandleTable = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const { inetNonentry } = useSelector(
    (state: { INetNonentryList: INetNonentryListInitialState }) =>
      state.INetNonentryList
  );

  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );

  const { formatNumber } = useNumberFormatter();

  const handleShowReservation = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-incele?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const handleNotifyTheHotel = () => {};

  const handleCall = (payload: CallCustomerPayload) => {
    try {
      dispatch(callCustomer(payload, () => {}));
    } catch (error) {
      setToastError(error);
    }
  };

  const tableData = inetNonentry?.map((reservation, index) => ({
    rezervasyon_id: reservation.rezervasyon_id,
    rezervasyon_kodu: reservation.rezervasyon_kodu,
    ana_rez_kod: reservation.ana_rez_kod, // ?
    satici_id: reservation.satici_id,
    otel_adi: reservation.otel_adi,
    otel_id: reservation.otel_id,
    musteri_ad_soyad: reservation.musteri_ad_soyad,
    toplam_tutar: reservation.toplam_tutar,
    konfirme_edildi_mi: reservation.konfirme_edildi_mi,
    iptal_edildi_mi: reservation.iptal_edildi_mi,
    satis_tarihi: reservation.satis_tarihi,
    musaitlik: reservation.musaitlik,
    musaitlik_alan: reservation.musaitlik_alan,
    operator_firma: reservation.operator_firma,
    operator_rez_kodu: reservation.operator_rez_kodu,
    inet_misafir_giris_durum: reservation.inet_misafir_giris_durum,
    satici_adi: reservation.satici_adi,
    grup_adi: reservation.grup_adi,
    otel_telefon: reservation.otel_telefon,
    musteri_cep: reservation.musteri_cep,
    list_bg_color_class: reservation.list_bg_color_class,
    konfirme: reservation.konfirme,
    cekilen_tutar: reservation.cekilen_tutar,
    rowNumber: index + 1,
  }));

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "rowNumber",
      enableColumnFilter: false,
    },
    {
      header: "Rez. Kodu",
      accessorKey: "rezervasyon_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Grup",
      accessorKey: "grup_adi",
      enableColumnFilter: false,
    },
    {
      header: "Sat. Tarihi",
      accessorKey: "satis_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Müşteri",
      accessorKey: "musteri_ad_soyad",
      enableColumnFilter: false,
    },
    {
      header: "Otel",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Rez. Tutarı",
      accessorKey: "toplam_tutar",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        const number = formatNumber(value);
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {number}
            </div>
          </>
        );
      },
    },
    {
      header: "Çekilen Tutar",
      accessorKey: "cekilen_tutar",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        const number = formatNumber(value);
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {number}
            </div>
          </>
        );
      },
    },
    {
      header: "Personel",
      accessorKey: "satici_adi",
      enableColumnFilter: false,
    },
    {
      header: "Müsaitlik",
      accessorKey: "musaitlik",
      enableColumnFilter: false,
    },
    {
      header: "Onay",
      accessorKey: "konfirme",
      enableColumnFilter: false,
    },
    {
      header: "Operatör",
      accessorKey: "operator_firma",
      enableColumnFilter: false,
    },
    {
      header: "İşlemler",
      accessorKey: "rezervasyon_id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData: INetNonentryListData = cell.row.original;
        const id = rowData.rezervasyon_id;
        const dahili = user.dahili;
        const musteri_no = `0${rowData.musteri_cep}`;
        const otel_no = rowData.otel_telefon;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="detail"
                onClick={() => handleShowReservation(id)}
                title="İncele"
              />
              <CustomRowOperationButton
                variant="check"
                onClick={() => handleNotifyTheHotel()}
                title="Otele Giriş Yaptı Bildirimi Yolla"
              />
              <CustomRowOperationButton
                variant="phone"
                onClick={() =>
                  handleCall({
                    dahili: dahili,
                    aranan_no: musteri_no,
                    arayan_no: otel_no,
                  })
                }
                title="Müşteriyi Ara"
              />
            </div>
          </>
        );
      },
    },
  ];

  return { tableData, tableColumns };
};

export default useHandleTable;
