// import Cookies from "js-cookie";
import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardInitialState,
} from "slices/dashboard/reducer";
import { getDashboardData } from "slices/dashboard/thunk";
import { HotelInitialState } from "slices/hotel/reducer";

// ! Kullanıcı  otel ise ve birden fazla oteli varsa ne yapılacak?

// interface User {
//   id: string;
//   groupId: string;
//   yetki: string;
// }

const useDashboard = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { hotel, error, loading } = useSelector(
    (state: { Hotel: HotelInitialState }) => state.Hotel
  );

  const otel_id = hotel.otel_id;
  const hotel_name = hotel.otel_adi;

  const { isLoading } = useSelector(
    (state: { Dashboard: DashboardInitialState }) => state.Dashboard
  );

  // const idFromCookies = Cookies.get("hotel") ?? "";
  // const userInfo = localStorage.getItem("user");
  // const user: User | null = userInfo ? JSON.parse(userInfo) : null;

  useEffect(() => {
    // if(user?.yetki === "otel"){
    //   dispatch(getDashboardData(Number(idFromCookies), () => {
    //   }));
    // }
    if (otel_id) {
      dispatch(getDashboardData(otel_id, () => {
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otel_id]);

  const isValidHotel = true;

  return {
    loading,
    error,
    isValidHotel,
    isLoading,
    otel_id,
    hotel_name,
  };
};

export default useDashboard;
