import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";
import UpdateStatusModal from "./Modals/UpdateStatusModal";
import { useState } from "react";

interface ListCostReviseTableProps {
  isLoading: boolean;
}

const ListCostReviseTable = ({ isLoading }: ListCostReviseTableProps) => {
  const [selectedReservationId, setSelectedReservationId] = useState(0);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);

  const { tableData, tableColumns, legend, reservations, 
    handleChangeStatusPositive,
    handleChangeStatusNegative,
    handleUpdateCostRevise, } = useHandleTable(
    setSelectedReservationId,
    setShowUpdateStatusModal
  );

  return (
    <>
      <CustomReactTable
        tableData={tableData}
        tableColumns={tableColumns}
        loading={isLoading}
        legend={legend}
      />
      {selectedReservationId !== 0 && (
        <UpdateStatusModal
          reservations={reservations}
          reservation_id={selectedReservationId}
          isOpen={showUpdateStatusModal}
          handleToggle={() =>
            setShowUpdateStatusModal((prevState) => !prevState)
          }
          updateCostRevise={handleUpdateCostRevise}
          changeStatusPositive={handleChangeStatusPositive}
          changeStatusNegative={handleChangeStatusNegative}
        />
      )}
    </>
  );
};

export default ListCostReviseTable;
