import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { handleChangeInput } from "slices/reservation/enterGroupReservation/reducer";
import {
  CreateGroupReservationPayload,
  CreateGroupReservationFormErrors,
} from "types/reservation";

interface CustomerPhoneNumberInputProps {
  values: CreateGroupReservationPayload;
  formErrors: CreateGroupReservationFormErrors;
  validateFormChange: (values: CreateGroupReservationPayload) => void;
}

const CustomerPhoneNumberInput = ({
  values,
  validateFormChange,
  formErrors,
}: CustomerPhoneNumberInputProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <>
      <CustomFormFieldContainer
        label="Müşteri Cep Telefonu "
        error={formErrors.musteri_cep}
        divider
        required
        orientation="vertical"
      >
        <Input
          type="text"
          autoCorrect="on"
          value={values.musteri_cep}
          onChange={(e) => {
            dispatch(
              handleChangeInput({
                musteri_cep: e.target.value,
              })
            );
            validateFormChange({
              ...values,
              musteri_cep: e.target.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default CustomerPhoneNumberInput;
