import { CustomButton, CustomFormContainer } from "Components/Custom/UI";
import { Dispatch, SetStateAction } from "react";
import {
  ReservationReview,
  ReservationReviewCreditCard,
} from "types/reservation";
import ListItem from "../ListItem";
import PaymentItem from "./PaymentItem";

interface ReservationPaymentInfoProps {
  reservation: ReservationReview;
  setSelectedPaymentId: Dispatch<SetStateAction<number>>;
  setShowUpdateCreditCardModal: Dispatch<SetStateAction<boolean>>;
  setShowApprovePaymentModal: Dispatch<SetStateAction<boolean>>;
  setSelectedCreditCard: Dispatch<
    SetStateAction<ReservationReviewCreditCard | null>
  >;
}

const ReservationPaymentInfo = ({
  reservation,
  setSelectedPaymentId,
  setShowUpdateCreditCardModal,
  setShowApprovePaymentModal,
  setSelectedCreditCard,
}: ReservationPaymentInfoProps) => {
  const { kk, havale, nakit } = reservation;

  let paymentNumber = 1;

  return (
    <CustomFormContainer title="Ödeme Bilgileri">
      {kk ? (
        kk.map((creditCard, index) => (
          <PaymentItem
            key={index}
            title={`${paymentNumber++}. Ödeme (Kredi Kartı)`}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <ListItem
                title="Ödeme ID"
                value={creditCard.odeme_id}
                topBorder
              />
              <ListItem
                title="Ödeme Açıklama"
                value={creditCard.odeme_aciklama}
                topBorder
              />
              <ListItem title="Çekilecek Tutar" value={creditCard.kk_tutar} />
              <ListItem title="Mail Order" value={creditCard.kk_mail_order} />
              <ListItem title="Banka Adı" value={creditCard.kk_banka_adi} />
              <ListItem title="Taksit Sayısı" value={creditCard.kk_taksit} />
              <ListItem title="Kart Sahibi" value={creditCard.kk_ad_soyad} />
              <ListItem title="Kart No" value={creditCard.kk_no} />
              <ListItem
                title="Son Kullanma Tarihi"
                value={creditCard.kk_tarih}
              />
              <ListItem title="CVC" value={creditCard.kk_cvc} />
              <div></div>
              <div className="d-flex gap-2 mt-3 justify-content-end">
                <CustomButton
                  variant="danger"
                  onClick={() => {
                    setSelectedPaymentId(creditCard.odeme_id);
                    setShowUpdateCreditCardModal(true);
                  }}
                >
                  Bilgiler Hatalı
                </CustomButton>
                <CustomButton
                  variant="success"
                  onClick={() => {
                    setSelectedPaymentId(creditCard.odeme_id);
                    setSelectedCreditCard(creditCard);
                    setShowApprovePaymentModal(true);
                  }}
                >
                  Ödemeyi Çek
                </CustomButton>
              </div>
            </div>
          </PaymentItem>
        ))
      ) : (
        <></>
      )}
      {havale ? (
        havale.map((transfer, index) => (
          <PaymentItem
            key={index}
            title={`${paymentNumber++}. Ödeme ${
              transfer.tip === "odeme" ? "(Havale)" : "(Kapora Farkı)"
            }`}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <ListItem
                title="Banka"
                value={transfer.havale_banka_adi}
                topBorder
              />
              <ListItem title="Tutar" value={transfer.havale_tutar} topBorder />
            </div>
          </PaymentItem>
        ))
      ) : (
        <></>
      )}
      {nakit ? (
        nakit.map((cash, index) => (
          <PaymentItem
            key={index}
            title={`${paymentNumber++}. Ödeme ${
              Number(cash.nakit_tutar ?? 0) < 0 ? "(İndirim)" : "(Nakit)"
            }`}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <ListItem title="Tutar" value={cash.nakit_tutar} topBorder />
              <ListItem
                title="Açıklama"
                value={cash.nakit_aciklama}
                topBorder
              />
            </div>
          </PaymentItem>
        ))
      ) : (
        <></>
      )}
    </CustomFormContainer>
  );
};

export default ReservationPaymentInfo;
