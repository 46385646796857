import axios from "axios";

import { handleErrors } from "helpers/axios_helpers";

import {
  handleSaveCommissionPayment,
  setIsLoading,
  setError,
  handleSaveCommissionPaymentCardInfo,
  setCardInfoLoading,
  setCardInfoError,
  setMakeCommissionPaymentLoading,
  setMakeCommissionPaymentError,
} from "./reducer";

import { setToastError } from "slices/toast/reducer";

import {
  GET_COMMISSION_PAYMENT,
  GET_COMMISSION_PAYMENT_CARD_INFO,
  MAKE_COMMISSION_PAYMENT,
  GO_TO_3D_SECURE,
} from "helpers/url_helper";

import { Dispatch } from "redux";
import { MakeCommissionPaymentRequestPayload } from "types/financeAndAccounting";
import Cookies from "js-cookie";
import { StartPaymentPayload } from "Components/Custom/UI/CustomCreditCard/CustomCreditCard/types";

interface GetCardInfoRequestPayload {
  bin: number;
  otel_id: number;
}

export const getCommissionPayment =
  (otel_id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setError(""));
      const response = await axios.get(GET_COMMISSION_PAYMENT + otel_id);

      const res = response.data || response;

      dispatch(handleSaveCommissionPayment(res));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getCardInfo =
  (payload: GetCardInfoRequestPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setCardInfoLoading(true));
      dispatch(setCardInfoError(""));
      const response = await axios.post(
        GET_COMMISSION_PAYMENT_CARD_INFO,
        payload
      );

      const res = response.data || response;

      dispatch(handleSaveCommissionPaymentCardInfo(res[0]));
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setCardInfoError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setCardInfoLoading(false));
    }
  };

export const makeCommissionPayment =
  (payload: StartPaymentPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch(setMakeCommissionPaymentLoading(true));
      dispatch(setMakeCommissionPaymentError(""));
      const response = await fetch("/payment/start-payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
    } catch (error) {
      handleErrors(error, (message: string) => {
        dispatch(setMakeCommissionPaymentError(message));
        dispatch(setToastError(message));
      });
    } finally {
      dispatch(setMakeCommissionPaymentLoading(false));
    }
  };
