import GroupReservationsPage from "Components/Custom/ReservationMonitoringPages/GroupReservationsPage";
import useGroupReservations from "Components/Hooks/reservationMonitoring/useGroupReservations";
import PageContainer from "pages/PageContainer";

const GroupReservations = () => {
  const { isLoading } = useGroupReservations();

  return (
    <PageContainer title="Grup Rezervasyonlar">
      <GroupReservationsPage isLoading={isLoading} />
    </PageContainer>
  );
};

export default GroupReservations;
