import { createSlice } from "@reduxjs/toolkit";
import { CallUsFormsData } from "types/reservationMonitoring";

export interface CallUsFormsInitialState {
  callUsForms: CallUsFormsData[];
  isLoading: boolean;
  error: string;
}

const initialState: CallUsFormsInitialState = {
  callUsForms: [],
  isLoading: false,
  error: "",
};

const callUsFormsSlice = createSlice({
  name: "callUsForms",
  initialState,
  reducers: {
    handleSaveCallUsFormsResponse(state, action) {
      state.callUsForms = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { handleSaveCallUsFormsResponse, setIsLoading, setError } =
  callUsFormsSlice.actions;

export default callUsFormsSlice.reducer;
