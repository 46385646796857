import { CustomButton, CustomModal } from "Components/Custom/UI";
import { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  AvailabilityDataResponse,
  AvailabilityPayload,
} from "types/reservationLists";

const lineStyle: React.CSSProperties = {
  borderBottom: "1px solid #ccc",
  maxWidth: "460px",
  margin: "16px auto",
};

interface UpdateAvailabilityModalProps {
  availabilityResponse: AvailabilityDataResponse;
  reservation_id: number;
  isOpen: boolean;
  handleToggle: () => void;
  updateAvailabilityData: (payload: AvailabilityPayload) => void;
}

const UpdateAvailabilityModal = ({
  availabilityResponse,
  reservation_id,
  isOpen,
  handleToggle,
  updateAvailabilityData,
}: UpdateAvailabilityModalProps) => {
  const [formData, setFormData] = useState<AvailabilityPayload>({
    rezervasyon_id: reservation_id.toString(),
    durum: "",
    operator: "",
    operator_rez_kodu: availabilityResponse.operator_rez_kodu ?? "",
    musaitlik_aciklama: availabilityResponse.musaitlik_aciklama ?? "",
  });

  console.log(
    "availabilityResponse.musaitlik_list:",
    availabilityResponse.musaitlik_list
  );

  useEffect(() => {
    setFormData({
      rezervasyon_id: reservation_id.toString(),
      durum:
        availabilityResponse.musaitlik_list?.find(
          (item) => item.checked === "checked"
        )?.value || "",
      operator:
        availabilityResponse.operator_firma_list?.find(
          (item) => item.checked === "checked"
        )?.value || "",
      operator_rez_kodu: availabilityResponse.operator_rez_kodu ?? "",
      musaitlik_aciklama: availabilityResponse.musaitlik_aciklama ?? "",
    });
  }, [reservation_id, availabilityResponse]);

  return (
    <>
      <CustomModal isOpen={isOpen} handleToggle={handleToggle} size="md">
        <ModalHeader>Müsaitlik Bilgileri</ModalHeader>
        <ModalBody>
          <FormGroup row className="d-flex align-items-center">
            <Label sm={3}>Durum</Label>
            <Col sm={9}>
              {availabilityResponse?.musaitlik_list?.map((radioItem, idx) => {
                const radioId = `musaitlik_option_${idx}`;
                return (
                  <FormGroup key={idx} check inline>
                    <Input
                      type="radio"
                      name={radioItem.name}
                      id={radioId}
                      value={radioItem.value}
                      checked={formData.durum === radioItem.value}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          durum: e.target.value,
                        }))
                      }
                    />
                    <Label check for={radioId} className="ms-1">
                      {radioItem.text}
                    </Label>
                  </FormGroup>
                );
              })}
            </Col>
          </FormGroup>
          <div style={lineStyle}></div>
          <FormGroup row className="d-flex align-items-center">
            <Label sm={3}>Operatör</Label>
            <Col sm={9}>
              {availabilityResponse?.operator_firma_list?.map(
                (radioItem, idx) => (
                  <div key={idx} className="form-check form-check-inline">
                    <Input
                      type="radio"
                      name={radioItem.name}
                      value={radioItem.value}
                      checked={formData.operator === radioItem.value}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          operator: e.target.value,
                        }))
                      }
                    />
                    <Label className="form-check-label ms-1">
                      {radioItem.text}
                    </Label>
                  </div>
                )
              )}
            </Col>
          </FormGroup>
          <div style={lineStyle}></div>
          <FormGroup row className="d-flex align-items-center">
            <Label sm={3}>Operator Rezervasyon Kodu</Label>
            <Col sm={9}>
              <Input
                type="text"
                value={formData.operator_rez_kodu}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    operator_rez_kodu: e.target.value,
                  })
                }
              />
            </Col>
          </FormGroup>
          <div style={lineStyle}></div>
          <FormGroup row className="d-flex align-items-center">
            <Label sm={3}>Açıklama</Label>
            <Col sm={9}>
              <Input
                type="textarea"
                value={formData.musaitlik_aciklama}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    musaitlik_aciklama: e.target.value,
                  })
                }
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            classes="mt-2 me-2"
            variant="danger"
            onClick={() => handleToggle}
            isLoading={false}
          >
            Vazgeç
          </CustomButton>
          <CustomButton
            classes="mt-2 me-2"
            variant="primary"
            onClick={() => updateAvailabilityData(formData)}
          >
            Müsaitlik Güncelle
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default UpdateAvailabilityModal;
