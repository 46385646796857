import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import {
  CalculatePriceForHotel,
  CalculatePriceForLocation,
  CalculatePriceFormValues,
  ReservationCall,
  ReservationCallChildrenInfo,
} from "types/reservation";

export interface ReservationCallInitialState {
  reservationCall: ReservationCall;
  calculatePriceForHotel: CalculatePriceForHotel;
  calculatePriceForLocation: CalculatePriceForLocation[];
  childrenInfo: ReservationCallChildrenInfo;
  calculationForm: CalculatePriceFormValues;
  isLoading: boolean;
  error: "";
  updateCallLoading: boolean;
  updateCallError: "";
  calculatePriceLoading: boolean;
  calculatePriceError: "";
  childrenInfoLoading: boolean;
  childrenInfoError: "";
  saveReservationLoading: boolean;
  saveReservationError: "";
  checkCardLoading: boolean;
  checkCardError: "";
  saveOnRequestLoading: boolean;
  saveOnRequestError: string;
}

const initialState: ReservationCallInitialState = {
  reservationCall: {
    arama_id: 0,
    musteri_id: 0,
    otel_telefonlari: [],
    gercek_telefon: "",
    aranan_otel_telefon: "",
    musteri_tel: "",
    musteri_tel_2: "",
    musteri_ad_soyad: "",
    musteri_eposta: "",
    musteri_tc: "",
    musteri_notu: "",
    geri_arama_id: 0,
    geri_arama_tipi: "",
    arama_aciklama: [],
    sonuc_musteri_gecmisi: [],
    otel_sorgu: [],
    uyeler: [],
  },
  calculatePriceForHotel: {
    arama_sorgu: {
      sorgu_id: 0,
      status: false,
    },
    musteri: {
      musteri_id: 0,
      musteri_isim: "",
      musteri_tel: "",
      musteri_tel_2: "",
      musteri_eposta: "",
      musteri_notu: "",
      ilk_kayit_tarihi: "",
      kayit_yapan: "",
      guncelleme_yapan: "",
      guncelleme_tarih: "",
      guncelleme_log: "",
    },
    musaitlik: [],
    vitrin: [],
    gallery: [],
    zincir_oteller: {},
    banka_taksit: [],
    otel_adi: "",
    otel_adres: "",
    otel_turu: "Otel",
    lokasyon_id: "",
    genel_aciklama: "",
    konaklama_aciklama: "",
    yiyecek_aciklama: "",
    aktivite_aciklama: "",
    aciklamali_adres: "",
    konum_aciklama: "",
    uyari_aciklama: "",
    google_map_x: "",
    google_map_y: "",
    otel_pansiyon_tipi: "",
    termal: "",
    havuz: "",
    toplanti: "",
    balayi: "",
    internet: "",
    otel_insaa: "",
    otel_yenileme: "",
    otel_oda_sayisi: "",
    denize_mesafe: "",
    one_cikan_ozellik: "",
    ucretsiz_cocuk: "",
    kibris: "h",
    tesis_ozellikleri: {
      genel: [],
      cocuk: [],
      oda: [],
      yiyecek: [],
      aktivite: [],
      havuz: [],
      termal: [],
      toplanti: [],
      balayi: [],
      internet: [],
    },
    telefon: "",
    banka_kampanya_banka_adi: "",
    banka_kampanya_indirim_orani: "",
    banka_kampanya_taksit_sayisi: "",
    banka_kampanya_aktiflik: "",
    crm_aciklama: "",
    A2_id: 0,
    desk_alti: "",
    desk_alti_oran: "",
    kapida_odeme: "hayir",
    komisyon_orani: 0,
    otelin_mail_adresi: {
      id: 0,
      otel_id: 0,
      yetkili_adi: "",
      unvan: "",
      dogum_tarihi: "",
      telefon: "",
      mail: "",
      birim: "",
      tarih: "",
    },
    otelin_gercek_telefonu: {
      id: 0,
      otel_id: 0,
      yetkili_adi: "",
      unvan: "",
      dogum_tarihi: "",
      telefon: "",
      mail: "",
      birim: "",
      tarih: "",
    },
    kaporali_satis: "hayir",
    kaporali_satis_orani: "",
    balayi_indirimi: "hayir",
    balayi_indirimi_oran: "",
    balayi_indirimi_baslangic: "",
    balayi_indirimi_bitis: "",
    bugun: "",
    crm_aciklama_oper_checkboxlar: "",
    indirim_orani: 0,
    gece_sayisi: 0,
    otel_odalar: [],
    otel_mevkii: [],
    tedarikci: {
      fiyat_hesaplama: "",
      tedarikci: "",
      inet_durum: "1",
    },
    cari: {
      odeme_tipi: "",
      odeme_tipi_gunu: 0,
    },
    parapuan_toplam_tutar: "",
    iptal_sigortasi_durum: "0",
    iptal_sigortasi_gun: "0",
    fiyatlar: {
      "0": {
        otel_id: "",
        otel_adi: "",
        otel_adres: "",
        otel_adres_aciklama: "",
        micro_logo: "",
        calisma_sekli: "",
        toplam_gece: 0,
        giris_tarihi: "",
        cikis_tarihi: "",
        karsilama_kodu: "",
        fiyat_hesaplama: "",
        otelin_mail_adresi: "",

        komisyon_orani: "",
        ozel_komisyon_orani: "",
        komisyon_kumulatif_durum: "0",

        kickback_orani: "",
        kickback_kumulatif_durum: "0",

        kapida_odeme_durum: "hayir",
        kapida_komisyon_orani: "",
        kapida_indirim_orani: "",
        kapida_odeme_para_birimi_goster: "0",

        kaporali_satis_durum: "hayir",
        kaporali_satis_orani: "",
        kaporali_indirim_orani: "",

        kismi_odeme: 0,
        kismi_odeme_orani: 0,
        kismi_odeme_tarihi: "",

        indirim_orani: "",
        ozel_indirim_orani: "",

        satis_para_birimi: "",
        para_birimi: "",
        kur: "",
        kur_sabitleme: 1,
        kur_degeri: 1,

        gostermelik_indirim_orani: "",
        fiyat_saglayici: "",
        toplam_para_puan: "",
        indirim_kuponu_kullanilabilir: 0,

        transfer_bilgileri: {
          durum: 0,
          transfer_ucreti: 0,
        },
        iptal_sigortasi_durum: "0",

        request: {
          otel_id: [0],
          oda_id: 0,
          pansiyon_id: 0,
          odeme_tipi: 0,
          giris_tarihi: "",
          cikis_tarihi: "",
          toplam_gece: 0,
          yetiskin_sayisi: "1",
          cocuk_sayisi: 0,
          cocuk_hesaplama_tipi: "dogum_tarihi",
          cocuk_1: "",
          cocuk_2: "",
          cocuk_3: "",
          cocuk_4: "",
          ozel_indirimler: [],
          platform: "crm",
          cihaz: 1,
          analiz: 0,
          indirim_kuponu_kodu: null,
          otel_bilgileri: null,
          parapuan_bilgisi: {
            uye_id: 0,
            harcanan_parapuan: 0,
          },
          transfer_ucreti: 0,
          iptal_sigortasi: 0,
          acente_kodu: null,
          cocuk_yaslari: [],
        },

        otelz_id: 0,
        acente_kodu: "",
        acente_komisyon_orani: "",
        tek_bay_kabul: 0,

        odalar: [],
        en_dusuk_fiyat: {
          oda_id: "",
          oda_adi: "",
          pansiyon_id: "",
          pansiyon_adi: "",
          odeme_tipi: "",
          resim1: "", //need preURL
          normal_toplam: 0,
          normal_maliyet: 0,
          normal_indirimli: 0,
          normal_toplam_kur: 0,
          normal_maliyet_kur: 0,
          normal_indirimli_kur: 0,
          normal_brut_maliyet: 0,
          normal_brut_maliyet_kur: 0,
          normal_otelfiyat: 0,
          normal_otelfiyat_kur: 0,
          normal_gostermelik: 0,
          fiyat_durum: 0,
          fiyat_durum_mesaj: "",
          ucretli_cocuk_durum: 0,
          minimum_gece_durum: 0,
          stop_durum: 0,
          stop_durum_mesaj: "",
          kontenjan_durum: 0,
          periyot_durum: 0,
          periyot_durum_mesaj: "",
        },
      },
    },
    etkinlik: [],
    ilk_son_fiyat: {
      "0": {
        otel_id: "",
        otel_adi: "",
        tesis_sinif: "",
        para_birimi: "",
        odalar: {
          "0": {
            oda_id: "",
            oda_adi: "",
            pansiyonlar: {
              "0": {
                pansiyon_id: "",
                pansiyon_adi: "",
                ilk_fiyat: {
                  odeme_tipi: "",
                  tarih: "",
                  fiyat: 0,
                  fiyat_json: "",
                },
                son_fiyat: {
                  odeme_tipi: "",
                  tarih: "",
                  fiyat: 0,
                  fiyat_json: "",
                },
              },
            },
          },
        },
      },
    },
    ozel_indirim: [],
  },
  calculatePriceForLocation: [],
  childrenInfo: {
    ucretsiz_bilgi: {},
    oda_bilgileri: [],
  },
  calculationForm: {
    secilen: {
      value: 0,
      label: "",
      category: "",
    },
    giris_tarihi: moment().format("D MMMM Y"),
    cikis_tarihi: moment().add(1, "day").format("D MMMM Y"),
    yetiskin_sayisi: 1,
    cocuk_sayisi: 0,
    cocuk_dogum_1: "",
    cocuk_dogum_2: "",
    cocuk_dogum_3: "",
    cocuk_dogum_4: "",
    parapuan_secim: 0,
    ozel_indirimler: [],
  },
  isLoading: false,
  error: "",
  updateCallLoading: false,
  updateCallError: "",
  calculatePriceLoading: false,
  calculatePriceError: "",
  childrenInfoLoading: false,
  childrenInfoError: "",
  saveReservationLoading: false,
  saveReservationError: "",
  checkCardLoading: false,
  checkCardError: "",
  saveOnRequestLoading: false,
  saveOnRequestError: ""
};

const reservationCallSlice = createSlice({
  name: "reservationCall",
  initialState,
  reducers: {
    handleSaveReservationCall(state, action) {
      state.reservationCall = action.payload;
    },
    handleSaveCalculatePriceForHotel(state, action) {
      state.calculatePriceForHotel = action.payload;
    },
    handleSaveCalculatePriceForLocation(state, action) {
      const payload: CalculatePriceForLocation[] = Object.values(
        action.payload
      );
      state.calculatePriceForLocation = payload;
    },
    handleSaveReservationCallChildrenInfo(state, action) {
      state.childrenInfo = action.payload;
    },
    resetReservationCallChildrenInfo(state) {
      state.childrenInfo = {
        ucretsiz_bilgi: {},
        oda_bilgileri: [],
      };
    },
    handleCalculationFormChange(state, action) {
      state.calculationForm = {
        ...state.calculationForm,
        ...action.payload,
      };
    },
    resetCalculationForm(state) {
      state.calculationForm = {
        secilen: {
          value: 0,
          label: "",
          category: "",
        },
        giris_tarihi: moment().format("D MMMM Y"),
        cikis_tarihi: moment().add(1, "day").format("D MMMM Y"),
        yetiskin_sayisi: 1,
        cocuk_sayisi: 0,
        cocuk_dogum_1: "",
        cocuk_dogum_2: "",
        cocuk_dogum_3: "",
        cocuk_dogum_4: "",
        parapuan_secim: 0,
        ozel_indirimler: [],
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateCallLoading(state, action) {
      state.updateCallLoading = action.payload;
    },
    setUpdateCallError(state, action) {
      state.updateCallError = action.payload;
    },
    setCalculatePriceLoading(state, action) {
      state.calculatePriceLoading = action.payload;
    },
    setCalculatePriceError(state, action) {
      state.calculatePriceError = action.payload;
    },
    setChildrenInfoLoading(state, action) {
      state.childrenInfoLoading = action.payload;
    },
    setChildrenInfoError(state, action) {
      state.childrenInfoError = action.payload;
    },
    setSaveReservationLoading(state, action) {
      state.saveReservationLoading = action.payload;
    },
    setSaveReservationError(state, action) {
      state.saveReservationError = action.payload;
    },

    setCheckCardLoading(state, action) {
      state.checkCardLoading = action.payload;
    },
    setCheckCardError(state, action) {
      state.checkCardError = action.payload;
    },

    setSaveOnRequestLoading(state, action) {
      state.saveOnRequestLoading = action.payload;
    },
    setSaveOnRequestError(state, action) {
      state.saveOnRequestError = action.payload;
    },
  },
});

export const {
  handleSaveReservationCall,
  handleSaveCalculatePriceForHotel,
  handleSaveCalculatePriceForLocation,
  handleSaveReservationCallChildrenInfo,
  resetReservationCallChildrenInfo,
  handleCalculationFormChange,
  resetCalculationForm,
  setIsLoading,
  setError,
  setUpdateCallLoading,
  setUpdateCallError,
  setCalculatePriceLoading,
  setCalculatePriceError,
  setChildrenInfoLoading,
  setChildrenInfoError,
  setSaveReservationLoading,
  setSaveReservationError,
  setCheckCardLoading,
  setCheckCardError,
  setSaveOnRequestLoading,
  setSaveOnRequestError,
} = reservationCallSlice.actions;

export default reservationCallSlice.reducer;
