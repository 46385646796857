import moment from "moment";
import { ANALYSIS_COLORS } from "utils/analysisColors";

const useHandleReservationData = () => {
  const today = moment().format('YYYY-MM-DD');

  const cards = [
    {
      title: `${today} Tarihinde Alınan Toplam Fiyat ve Toplam İndirim Bilgisi`,
      value: [
        "CRM için alt limit: 10.000 TL",
        "CC  için alt limit: 30.000 TL",
        "Ücretli çocuk alt limit: 10.000 TL"
      ],
      color: ANALYSIS_COLORS.blue,
    },
    {
      title: "Maliyet Revize İstenen Toplam Tutar",
      value: "",
      color: ANALYSIS_COLORS.red,
    },
    {
      title: "Toplam Kar",
      value: "",
      color: ANALYSIS_COLORS.green,
    },
  ];

  return {
    cards,
  };
};

export default useHandleReservationData;