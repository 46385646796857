import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { TemporaryOnlineReservationsInitialState } from "slices/reservationMonitoring/temporaryOnlineReservations/reducer";
import { listTemporaryOnlineReservations } from "slices/reservationMonitoring/temporaryOnlineReservations/thunk";


const useTemporaryOnlineReservations = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading } = useSelector(
    (state: { TemporaryOnlineReservations: TemporaryOnlineReservationsInitialState }) => state.TemporaryOnlineReservations
  );

  useEffect(() => {
    dispatch(listTemporaryOnlineReservations());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export default useTemporaryOnlineReservations;
