import { CustomCreditCard } from "Components/Custom/UI/CustomCreditCard/CustomCreditCard";
import useHandlePayment from "./useHandlePayment";
import { CreditCard, CreditCardInstallment } from "types/GlobalTypes";

const PaymentForm = () => {
  const { makePayment, makeCommissionPaymentLoading } = useHandlePayment();

  return (
    <>
      <CustomCreditCard
        formId="commission-payment-form"
        makePayment={(card: CreditCard, installment: CreditCardInstallment) => {
          // console.log("card :", card);
          // console.log("installment :", installment);
          makePayment(card, installment);
        }}
        makePaymentLoading={makeCommissionPaymentLoading}
        reservationId="277840485"
        priceId={1} // 1 hard coded
        getCardInfoUrl="https://laraveld.oteld.com/api/v1/otelfiyat/payment/bin-request"
      />
    </>
  );
};

export default PaymentForm;
