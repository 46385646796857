import { CustomFormFieldContainer } from "Components/Custom/UI";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { Dispatch } from "react";
import { handleChangeInput } from "slices/reservation/reviewReservation/reducer";
import {
  ReservationReviewCollection,
  ReservationReviewCollectionFormChoices,
  ReservationReviewCollectionFormErrors,
} from "types/reservation";

interface InstallmentSelectorProps {
  values: ReservationReviewCollection;
  formChoices: ReservationReviewCollectionFormChoices;
  formErrors: ReservationReviewCollectionFormErrors;
  validateFormChange: (values: ReservationReviewCollection) => void;
}

interface Option {
  value: string;
  label: string;
}

const InstallmentSelector = ({
  values,
  formChoices,
  formErrors,
  validateFormChange,
}: InstallmentSelectorProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const installmentsOptions: Option[] = formChoices.taksit.map((item) => ({
    value: item.value,
    label: item.label,
  }));

  const selectedOption = installmentsOptions.find(
    (option) => option.value === values.cekim_taksit
  );

  return (
    <>
      <CustomFormFieldContainer
        label="Taksit "
        error={formErrors.cekim_taksit}
        divider
        required
        orientation="vertical"
      >
        <Select
          options={installmentsOptions}
          value={selectedOption || null}
          onChange={(selected: Option) => {
            dispatch(
              handleChangeInput({
                cekim_taksit: selected.value,
              })
            );

            validateFormChange({
              ...values,
              cekim_taksit: selected.value,
            });
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default InstallmentSelector;
