import { createSlice } from "@reduxjs/toolkit";
import {
  DailyCardData,
  ListCostReviseData,
  ListCostRevisePayload,
  ListCostReviseResponse,
} from "types/reservationMonitoring";

export interface ListCostReviseInitialState {
  costRevise: ListCostReviseData[];
  filterCostRevise: ListCostRevisePayload;
  dataCards: DailyCardData;
  isLoading: boolean;
  error: string;
  updateRequestLoading: boolean;
  updateRequestError: string;
  changeStatusLoading:  boolean;
  changeStatusError: string;
  markAsResponsibleLoading: boolean;
  markAsResponsibleError: string;
}

const initialState: ListCostReviseInitialState = {
  costRevise: [],
  filterCostRevise: {
    date: "",
    rezervasyon_kodu: "",
  },
  dataCards: {
    toplam_gerceklesen_tutar: 0,
    toplam_beklenen_tutar: 0,
    toplam_iptal_tutar: 0,
    toplam_otelfiyat_satisi: 0,
    toplam_otelfiyat_oda_sayisi: 0,
    toplam_otelfiyat_online_oda_sayisi: 0,
    toplam_cagri_merkezi_satisi: 0,
    toplam_cagri_merkezi_oda_sayisi: 0,
    toplam_cagri_merkezi_online_oda_sayisi: 0
  },
  isLoading: false,
  error: "",
  updateRequestLoading: false,
  updateRequestError: "",
  changeStatusLoading: false,
  changeStatusError: "",
  markAsResponsibleLoading: false,
  markAsResponsibleError: "",
};

const costReviseSlice = createSlice({
  name: "costRevise",
  initialState,
  reducers: {
    handleSaveCostReviseResponse(state, action) {
      const {rezervasyonlar, gunun_ozeti} = action.payload as ListCostReviseResponse;

      const costRevise : ListCostReviseData[] = rezervasyonlar.map((item) => ({
        kbr_durum: item.kbr_durum,
        kbr_id: item.kbr_id,
        operasyon_fiyat: item.operasyon_fiyat,
        operasyon_not: item.operasyon_not,
        rezervasyon_id: item.rezervasyon_id,
        id: item.id,
        rezervasyon_kodu: item.rezervasyon_kodu,
        ana_rez_kod: item.ana_rez_kod,
        satici_id: item.satici_id,
        musteri_ad_soyad: item.musteri_ad_soyad,
        toplam_tutar: item.toplam_tutar,
        kayit_tarihi: item.kayit_tarihi,
        iptal_edildi_mi: item.iptal_edildi_mi,
        musaitlik: item.musaitlik,
        rez_tipi: item.rez_tipi,
        satis_operator: item.satis_operator,
        iptal_tutar: item.iptal_tutar,
        supheli_aciklama: item.supheli_aciklama,
        satici_adi: item.satici_adi,
        supheli_cevaplayan_ad_soyad: item.supheli_cevaplayan_ad_soyad,
        beklemede_ilgilenen_personel: item.beklemede_ilgilenen_personel,
        grup_adi: item.grup_adi,
        cekilen_tutar: item.cekilen_tutar,
        konfirme_durum: item.konfirme_durum,
        iptal_durum: item.iptal_durum,
        otel_adi_aciklama: item.otel_adi_aciklama,
        otel_danismani: item.otel_danismani,
        ucretli_cocuk_sayisi: item.ucretli_cocuk_sayisi,
        ucretli_cocuk_aciklama: item.ucretli_cocuk_aciklama,
        operator_aciklama: item.operator_aciklama,
        tamamla_button: item.tamamla_button,
        incele_button: item.incele_button,
        duzenle_button: item.duzenle_button,
        ilgileniyorum_button: item.ilgileniyorum_button,
      }))
      state.costRevise = costRevise;
      state.dataCards = gunun_ozeti;
    },
    handleChangeFilterChoices(state, action) {
      state.filterCostRevise = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateRequestLoading(state, action) {
      state.updateRequestLoading = action.payload;
    },
    setUpdateRequestError(state, action) {
      state.updateRequestError = action.payload;
    },
    setChangeStatusLoading(state, action) {
      state.changeStatusLoading = action.payload;
    },
    setChangeStatusError(state, action) {
      state.changeStatusError = action.payload;
    },
    setMarkAsResponsibleLoading(state, action) {
      state.markAsResponsibleLoading = action.payload;
    },
    setMarkAsResponsibleError(state, action) {
      state.markAsResponsibleError = action.payload;
    },
  },
});

export const {
  handleSaveCostReviseResponse,
  handleChangeFilterChoices,
  setIsLoading,
  setError,
  setUpdateRequestLoading,
  setUpdateRequestError,
  setChangeStatusLoading,
  setChangeStatusError,
  setMarkAsResponsibleLoading,
  setMarkAsResponsibleError,
} = costReviseSlice.actions;

export default costReviseSlice.reducer;
