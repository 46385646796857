import { CustomButton } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { CalculatePriceForHotel, CallLaterPayload } from "types/reservation";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";

interface SalesReservationTrackingFormProps {
  calculatePriceForHotel: CalculatePriceForHotel;
  checkboxItems: { value: string; label: string }[];
  selectedCallOption: string;
  handleInputChange: (field: keyof CallLaterPayload, value: any) => void;
  handleSaveFormValues: () => void;
}

const SalesReservationTrackingForm = ({
  calculatePriceForHotel,
  checkboxItems,
  selectedCallOption,
  handleInputChange,
  handleSaveFormValues,
}: SalesReservationTrackingFormProps) => {
  const { onDayCreate } = useSpecialDaysForFlatpickr();

  const handleMultipleInputChange = (
    fields: Partial<Record<keyof CallLaterPayload, string>>
  ) => {
    Object.entries(fields).forEach(([key, value]) =>
      handleInputChange(key as keyof CallLaterPayload, value)
    );
  };

  return (
    <div
      className="bg-white d-flex gap-3 justify-content-around align-items-center"
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 1000,
        padding: "24px 24px",
        paddingLeft: "280px",
        boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.5)",
        // backgroundColor: "#DADCDE",
      }}
    >
      <div className="d-flex flex-column gap-1">
        <div className="fw-semibold fs-20">
          {calculatePriceForHotel?.musteri?.musteri_isim ?? ""}
        </div>
        <div className="fs-14">{calculatePriceForHotel?.otel_adi ?? ""}</div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "2px",
        }}
      >
        {checkboxItems.map((item, index) => (
          <div key={index} className="d-flex align-items-center">
            <input
              type="checkbox"
              name="reservationAction"
              id={`checkbox-${index}`}
              value={item.value}
              checked={selectedCallOption === item.value}
              onChange={() => handleInputChange("islem", item.value)}
            />
            <label
              htmlFor={`checkbox-${index}`}
              style={{ margin: "0 6px" }}
              className="fw-normal fs-14"
            >
              {item.label}
            </label>
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-end gap-4 align-items-center">
        {selectedCallOption === "sonra-ara" ? (
          <div className="d-flex flex-column gap-3 align-items-center">
            <Flatpickr
              className="form-control"
              style={{
                border: "1px solid #3B414D",
              }}
              onChange={(date) => {
                const selectedDate = date[0];
                if (selectedDate) {
                  const year = selectedDate.getFullYear();
                  const month = String(selectedDate.getMonth() + 1).padStart(
                    2,
                    "0"
                  );
                  const day = String(selectedDate.getDate()).padStart(2, "0");

                  const formattedDate = `${year}-${month}-${day}`; // "2025-01-20"

                  handleInputChange("arama_tarihi", formattedDate);
                }
              }}
              options={{
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: Turkish,
                onDayCreate,
              }}
              placeholder="Tarih Seçin"
            />
            <Flatpickr
              className="form-control"
              style={{
                border: "1px solid #3B414D",
              }}
              onChange={(date) => {
                const selectedTime = date[0];
                if (selectedTime) {
                  const hours = String(selectedTime.getHours()).padStart(
                    2,
                    "0"
                  );
                  const minutes = String(selectedTime.getMinutes()).padStart(
                    2,
                    "0"
                  );

                  const formattedTime = `${hours}:${minutes}`; // "14:00"

                  console.log("Selected Time:", formattedTime);

                  handleInputChange("arama_saati", formattedTime);
                }
              }}
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                locale: Turkish,
              }}
              placeholder="Saat Seçin"
            />
          </div>
        ) : (
          <Input
            style={{
              border: "1px solid #3B414D",
            }}
            type="textarea"
            placeholder="Açıklama giriniz..."
            onChange={(e) => handleInputChange("aciklama", e.target.value)}
          />
        )}
        <CustomButton
          variant="primary"
          onClick={handleSaveFormValues}
          isLoading={false}
        >
          Kaydet
        </CustomButton>
      </div>
    </div>
  );
};

export default SalesReservationTrackingForm;
