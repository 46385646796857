import { useSelector } from "react-redux";
import { ReservationsInitialState } from "slices/reports/reservations/reducer";
import { ANALYSIS_COLORS } from "utils/analysisColors";

const useHandleReservationData = () => {
  const { reservationsResponse } = useSelector(
    (state: { Reservations: ReservationsInitialState }) => state.Reservations
  );

  const icons = {
    "money" : "ri-wallet-3-line",
    "room" : "ri-hotel-bed-line",
    "hotel" : "ri-hotel-line",
  }

  const cards = [
    {
      title: "Toplam Rezervasyon",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.hotel
    },
    {
      title: "Tamamlanan Tutar",
      value: reservationsResponse.tamamlanan_tutar,
      color: ANALYSIS_COLORS.green,
      Icon: icons.money
    },
    {
      title: "Bekleyen Tutar",
      value: reservationsResponse.bekleyen_tutar,
      color: ANALYSIS_COLORS.yellow,
      Icon: icons.money
    },
    {
      title: "İptal Tutar",
      value: reservationsResponse.iptal_tutar,
      color: ANALYSIS_COLORS.red,
      Icon: icons.money
    },
    {
      title: "Toplam OtelFiyat Oda Sayısı",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.room
    },
    {
      title: "Toplam OtelFiyat Online Oda Sayısı",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.room
    },
    {
      title: "Toplam OtelFiyat Satışı",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.money
    },
    {
      title: "Toplam Çağrı Merkezi Oda Sayısı",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.room
    },
    {
      title: "Toplam Çağrı Merkezi Online Oda Sayısı",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.room
    },
    {
      title: "Toplam Çağrı Merkezi Satışı",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.money
    },
    {
      title: "Toplam HotelCenter Oda Sayısı",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.money
    },
    {
      title: "Toplam HotelCenter Satışı",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.money
    },
    {
      title: "Toplam Obilet Oda Sayısı",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.room
    },
    {
      title: "Toplam Obilet Satışı",
      value: reservationsResponse.toplam_rez,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.money
    },
  ];

  return {
    cards,
  };
};

export default useHandleReservationData;
