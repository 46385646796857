import { CustomFormFieldContainer } from "Components/Custom/UI";
import { ReservationFilterPayload } from "types/reports";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

interface DateInputProps {
  values: ReservationFilterPayload;
  handleFormChange: (key: string, value: string) => void;
  fieldKeyStart: keyof ReservationFilterPayload;
  fieldKeyEnd: keyof ReservationFilterPayload;
}

const DateInput = ({
  values,
  handleFormChange,
  fieldKeyStart,
  fieldKeyEnd,
}: DateInputProps) => {
  const {onDayCreate} = useSpecialDaysForFlatpickr();

  const valueStart = values[fieldKeyStart] as string;
  const valueEnd = values[fieldKeyEnd] as string;

  return (
    <>
      <CustomFormFieldContainer label="Tarih">
        <Flatpickr
          className="form-control"
          value={[valueStart, valueEnd]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;

            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");

              handleFormChange(fieldKeyStart, stringStartDate);
              handleFormChange(fieldKeyEnd, stringEndDate);
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default DateInput;
