import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { COLORS } from "utils/cardColors";

interface CustomReservationMonitoringCardProps {
  title: string;
  info: number;
  link: string;
  selectedTable: string;
  tableCode: string;
  onClick: (tableCode: string) => void;
}

const CustomReservationMonitoringCard = ({
  title,
  info,
  link,
  selectedTable,
  tableCode,
  onClick,
}: CustomReservationMonitoringCardProps) => {
  return (
    <>
      <Card
        style={{
          backgroundColor: `${
            selectedTable === tableCode ? COLORS.orange : COLORS.purple
          }`,
          height: "80px",
          border: "none"
        }}
      >
        <CardBody
          className="gap-1 py-2 px-3"
          style={{
            color: COLORS.white,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{
              background: "none",
              border: "none",
              textAlign: "start",
              padding: "0",
              color:"white"
            }}
            onClick={() => onClick(tableCode)}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                margin: "0",
              }}
            >
              {title}
            </p>
          </button>
          <div className="d-flex justify-content-between align-items-center">
            <button
              style={{
                background: "none",
                border: "none",
                textAlign: "start",
                padding: "0",
              }}
              onClick={() => onClick(tableCode)}
            >
              <p style={{ color: COLORS.white, margin: "0", fontSize: "18px" }}>
                <strong>{info} </strong>yeni
              </p>
            </button>
            <Link
              to={`${link}`}
              style={{
                textDecoration: "none",
                color: COLORS.white,
                display: "flex",
                alignItems: "center",
                gap: "4px",
                fontSize: "14px",
              }}
            >
              <p style={{textDecoration: "underline", margin: "0"}}>Sayfaya Git</p> <i className="ri-arrow-right-line" />
            </Link>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default CustomReservationMonitoringCard;
