import { ReservationReview } from "types/reservation";
import StatusMessages from "./StatusMessages";
import DismissableAlerts from "./DismissableAlerts";

interface ReservationHeaderProps {
  reservation: ReservationReview;
}

const ReservationHeader = ({ reservation }: ReservationHeaderProps) => {
  const showStatusMessages = reservation.muhasebe_bilgileri ? true : false; // TODO : Hangi durumlarda muhasebe bilgileri false dönüyor?

  const { eski_rezervasyon } = reservation;

  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <p className="fs-20 text-uppercase fw-medium">
          Rezervasyon İnceleme Ekranı
        </p>
        {eski_rezervasyon === "1" && (
          <p className="fs-20 text-uppercase theme-text-danger fw-bold">
            ( Eski Rezervasyon )
          </p>
        )}
      </div>
      {showStatusMessages && <StatusMessages {...reservation} />}
      <DismissableAlerts reservation={reservation} />
    </>
  );
};

export default ReservationHeader;
