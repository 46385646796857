import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { FindReservationPayload } from "types/reservationLists";

interface ReservationCodeInputProps {
  values: FindReservationPayload;
  handleFormChange: (key: string, value: string) => void;
}

const ReservationCodeInput = ({
  values,
  handleFormChange,
}: ReservationCodeInputProps) => {


  return (
    <>
      <CustomFormFieldContainer label="Rezervasyon Kodu :">
        <Input
          type="text"
          autoCorrect="on"
          value={values.rezervasyon_kodu}
          onChange={(e) => {
            handleFormChange("rezervasyon_kodu", e.target.value);
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default ReservationCodeInput;
