import { CustomFormFieldContainer } from "Components/Custom/UI";
import moment from "moment";
import { useSpecialDaysForFlatpickr } from "Components/Hooks";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { ListCancellationRequestsPayload } from "types/reservationMonitoring";

interface DateInputProps {
  values: ListCancellationRequestsPayload;
  handleFormChange: (key: string, value: string) => void;
}

const DateInput = ({
  values,
  handleFormChange,
}: DateInputProps) => {
  const {onDayCreate} = useSpecialDaysForFlatpickr();

  return (
    <>
      <CustomFormFieldContainer label="Tarih">
        <Flatpickr
          className="form-control"
          value={[values.start_date, values.end_date]}
          onChange={(dates) => {
            const [startDate, endDate] = dates;

            if (startDate && endDate) {
              const stringStartDate = moment(startDate).format("YYYY-MM-DD");
              const stringEndDate = moment(endDate).format("YYYY-MM-DD");

              handleFormChange("start_date", stringStartDate);
              handleFormChange("end_date", stringEndDate);
            }
          }}
          options={{
            mode: "range",
            dateFormat: "Y-m-d",
            showMonths: 2,
            locale: Turkish,
            onDayCreate,
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default DateInput;