import { CustomRowOperationButton } from "Components/Custom/UI";
import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginInitialState } from "slices/auth/login/reducer";
import { ListCancellationRequestsInitialState } from "slices/reservationMonitoring/listCancellationRequests/reducer";
import {
  markAsResponsible,
  callCustomer,
  cancelReservation,
  finalizeRequest,
  listListCancellationRequests,
  updateStatus,
} from "slices/reservationMonitoring/listCancellationRequests/thunk";
import { setToastError } from "slices/toast/reducer";
import {
  CallCustomerPayload,
  CancelCancellationRequestPayload,
  FinalizeRequestPayload,
  ListCancellationRequestsData,
  MarkAsResponsibleCancellationPayload,
  UpdateCancellationRequestPayload,
} from "types/reservationMonitoring";
import { TABLE_COLORS } from "utils/tableColors";

const useHandleTable = (
  setSelectedReservationId: Dispatch<SetStateAction<number>>,
  setShowCancellationModal: Dispatch<SetStateAction<boolean>>,
  setShowUpdateStatusModal: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const navigate = useNavigate();

  const { listCancellationRequests } = useSelector(
    (state: {
      ListCancellationRequests: ListCancellationRequestsInitialState;
    }) => state.ListCancellationRequests
  );

  const { user } = useSelector(
    (state: { Login: LoginInitialState }) => state.Login
  );
  
  const handleShowReservation = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-incele?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const handleUpdateReservation = (rezervasyon_id: number) => {
    if (rezervasyon_id !== null) {
      navigate(`/rezervasyon-duzenle?rezervasyon_id=${rezervasyon_id}`);
    }
  };

  const handleCall = (payload: CallCustomerPayload) => {
    try {
      dispatch(callCustomer(payload, () => {}));
    } catch (error) {
      setToastError(error);
    }
  };

  const handleUpdateCancellationRequest = (
    payload: CancelCancellationRequestPayload
  ) => {
    try {
      dispatch(
        cancelReservation(payload, () => {
          setShowCancellationModal((prevState) => !prevState);
          dispatch(listListCancellationRequests());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleUpdateStatus = (payload: UpdateCancellationRequestPayload) => {
    try {
      dispatch(
        updateStatus(payload, () => {
          setShowUpdateStatusModal((prevState) => !prevState);
          dispatch(listListCancellationRequests());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleFinalizeRequest = (payload: FinalizeRequestPayload) => {
    try {
      dispatch(
        finalizeRequest(payload, () => {
          setShowUpdateStatusModal((prevState) => !prevState);
          dispatch(listListCancellationRequests());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleMarkAsResponsible = (
    payload: MarkAsResponsibleCancellationPayload
  ) => {
    try {
      console.log("payload : ", payload);
      dispatch(
        markAsResponsible(payload, () => {
          dispatch(listListCancellationRequests());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const tableData = listCancellationRequests?.map((item) => {
    let rowColor = "";

    if (item.list_bg_color_class === "warning") {
      rowColor = TABLE_COLORS.yellow;
    } else if (item.list_bg_color_class === "success") {
      rowColor = TABLE_COLORS.green;
    } else {
      rowColor = TABLE_COLORS.green;
    }

    return {
      id: item.id,
      kayit_tarihi: item.kayit_tarihi,
      rezervasyon_id: item.rezervasyon_id,
      rezervasyon_kodu: item.rezervasyon_kodu,
      otel_adi: item.otel_adi,
      giris_tarihi: item.giris_tarihi,
      cikis_tarihi: item.cikis_tarihi,
      istek_yapan_personel: item.istek_yapan_personel,
      istek_mesaj: item.istek_mesaj,
      ilgilenen_personel: item.ilgilenen_personel,
      cevap_mesaj: item.cevap_mesaj,
      musteri_cep: item.musteri_cep,
      musteri_tel: item.musteri_tel,
      iptal_edildi_mi: item.iptal_edildi_mi,
      inet_no_show_durum: item.inet_no_show_durum,
      kaporali_rezervasyon_giris: item.kaporali_rezervasyon_giris,
      musteri_ad_soyad: item.musteri_ad_soyad,
      toplam_tutar: item.toplam_tutar,
      iptal_nedeni: item.iptal_nedeni,
      durum: item.durum,
      otel_telefon: item.otel_telefon,
      list_bg_color_class: item.list_bg_color_class,
      iptal_id: item.iptal_id,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "Tarih",
      accessorKey: "kayit_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Rez. Kod",
      accessorKey: "rezervasyon_kodu",
      enableColumnFilter: false,
    },
    {
      header: "Otel Ad",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "100px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Giriş Tarihi",
      accessorKey: "giris_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Çıkış Tarihi",
      accessorKey: "cikis_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Personel",
      accessorKey: "istek_yapan_personel",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "İstek Mesaj",
      accessorKey: "istek_mesaj",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "150px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "İlgilenen",
      accessorKey: "ilgilenen_personel",
      enableColumnFilter: false,
    },
    {
      header: "Cevap Mesaj",
      accessorKey: "cevap_mesaj",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();

        const formattedLines = value
          ?.replace(/<\/?br\s*\/?>/g, "") 
          .trim() 
          .split(".")
          .map((line: string) => line.trim())
          .filter((line: string) => line !== "") || [""];

        return (
          <>
            <div className="text-wrap" style={{ width: "150px" }}>
              {formattedLines.map((line: any, index: any) => (
                <div key={index}>
                  <i className="ri-arrow-right-line" /> {line}
                </div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData: ListCancellationRequestsData = cell.row.original;
        const id = rowData.rezervasyon_id;
        const dahili = user.dahili;
        const musteri_no = `0${rowData.musteri_tel}`;
        const otel_no = rowData.otel_telefon;
        const cancellationId = rowData.iptal_id;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="update"
                onClick={() => handleUpdateReservation(id)}
                title="Rez. Düzenle"
              />
              <CustomRowOperationButton
                variant="detail"
                onClick={() => handleShowReservation(id)}
                title="Rez. İncele"
              />
              <CustomRowOperationButton
                variant="phone"
                onClick={() =>
                  handleCall({
                    dahili: dahili,
                    aranan_no: musteri_no,
                    arayan_no: otel_no,
                  })
                }
                title="Müşteriyi Ara"
              />
              <CustomRowOperationButton
                variant="update"
                onClick={() => {
                  setSelectedReservationId(id);
                  setShowCancellationModal((prevState) => !prevState);
                }}
                title="İptal Talebi Düzenle"
              />
              <CustomRowOperationButton
                variant="update"
                onClick={() => {
                  setSelectedReservationId(id);
                  setShowUpdateStatusModal((prevState) => !prevState);
                }}
                title="Durumu Güncelle"
              />
              <CustomRowOperationButton
                variant="check"
                onClick={() =>
                  handleMarkAsResponsible({
                    id: cancellationId,
                  })
                }
                title="İlgileniyorum"
              />
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.yellow,
      label: "Henüz İlgilenilmiyor",
    },
    {
      color: TABLE_COLORS.orange,
      label: "İlgileniliyor",
    },
    {
      color: TABLE_COLORS.green,
      label: "Sonuçlandı",
    },
  ];

  return {
    tableData,
    tableColumns,
    legend,
    reservations: listCancellationRequests,
    handleUpdateCancellationRequest,
    handleUpdateStatus,
    handleFinalizeRequest,
  };
};

export default useHandleTable;
