import axios from "axios";
import Cookies from "js-cookie";
import { Dispatch } from "redux";

import { LOGIN_USER, LOGOUT_USER } from "helpers/url_helper";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
} from "./reducer";
import { handleErrors } from "helpers/axios_helpers";
import { resetHotel } from "slices/hotel/reducer";
import { User } from "types/authentication";

interface UserForm {
  username: string;
  password: string;
}

interface RequestData {
  kullanici_adi: string;
  password: string;
}

type LoginUser = (
  user: UserForm,
  navigate: any
) => (dispatch: Dispatch) => Promise<void>;

type LogoutUser = () => (dispatch: Dispatch) => Promise<void>;

type ResetLoginFlag = () => (dispatch: Dispatch) => Promise<void>;

export const loginUser: LoginUser = (user, navigate) => async (dispatch) => {
  const requestData: RequestData = {
    kullanici_adi: user.username,
    password: user.password,
  };

  interface Response {
    message: string;
    status: number;
    success: boolean;
    token: string;
    user: User;
    yetkiler: string[];
  }

  try {
    dispatch(reset_login_flag());

    const response: Response = await axios.post(LOGIN_USER, requestData);

    const { 
      token, 
      user, 
      // yetkiler 
    } = response;

    let authoritiesAccordingToAuthStr: string[] = [];
    if (user.yetki === "root") {
      authoritiesAccordingToAuthStr = [];
    } else if (user.yetki === "admin") {
      authoritiesAccordingToAuthStr = [
        "/tesis-resimleri",
        "/rakip-analizi-bilgileri",
        "/kesilen-faturalar",
        "/site-olustur",
        "/kullanici-ekle",
        "/kullanicilari-listele",
        "/personel-otel-gorevleri",
        "/yetkiler",
        "/grup-ekle",
        "/gruplari-listele",
        "/perito-dahili-durum",
        "/rezervasyon-onay-iptal-degisikligi",
        "/rezervasyon-aktar",
        "/dis-ipler",
      ];
    } else if (user.yetki === "user") {
      authoritiesAccordingToAuthStr = [
        "/admin-dashboard",
        "/takvim",
        "/genel-fiyat-ayarlari",
        "/oda-satis-odeme-yontemleri",
        "/oda-kisi-fiyat-oranlari",
        "/cocuk-bilgileri",
        "/cocuk-ozel-yas-bilgileri",
        "/ozel-indirimler",
        "/mobil-indirimler",
        "/son-dakika-indirimleri",
        "/saatlik-indirimler",
        "/iptal-edilemez-oda-oranlari",
        "/erken-rezervasyon-indirimleri",
        "/cok-kal-az-ode-aksiyonlari",
        "/desk-alti-indirimler",
        "/hediyeler-ve-promosyonlar",
        "/tum-otellere-ait-hediyeler",
        "/indirim-kuponlari",
        "/gece-kisitlamalari",
        "/indirimler",
        "/lokasyon-ekle",
        "/lokasyon-listele",
        "/tesis-ozelligi-ekle",
        "/erken-rezervasyon-tarihi-tanimlama",
        "/gezilecek-yer-ekle",
        "/gezilecek-yer-listele",
        "/kur-tanimlama",
        "/tema-ekle",
        "/tema-listele",
        "/tema-otel-ata",
        "/tema-tavsiye-edilen-otel-atama",
        "/tema-otel-siralama",
        "/tesis-ekle",
        "/tesis-guncelle",
        "/tesis-vitrin-gorselleri",
        "/tesis-resimleri",
        "/tesis-odalari",
        "/tesis-pansiyonlari",
        "/tesis-kullanicilari",
        "/tesis-yetkilileri",
        "/tesis-ozel-gunleri",
        "/ozel-gun-listele",
        "/oda-pansiyon-eslestir",
        "/tesis-aramalari",
        "/tesis-kismi-odeme-tarihleri",
        "/musteri-mailleri",
        "/dahili-durum",
        "/tesis-parapuan-listesi",
        "/gunluk-satis-raporu",
        "/gunluk-cagri-grafigi",
        "/saatlik-cagri-grafigi",
        "/karsilama-ve-hesaplama",
        "/oda-resmi-olmayanlar",
        "/forecast-raporu",
        "/cevaplanan-cagri-hareketleri",
        "/dis-arama-sayilari",
        "/dis-arama-listeleme",
        "/kisilere-gelen-gunluk-cagri",
        "/personel-satislari-listele",
        "/oda-aciklamasi-olmayanlar",
        "/riskli-oteller",
        "/dahili-durum-listesi",
        "/otellere-gelen-gunluk-cagri",
        "/genelden-gelen-cagrilar",
        "/personel-aylik-satis-listesi",
        "/otel-satislari",
        "/arsivlenmis-kontratlar",
        "/rezervasyonlar",
        "/tesisler-reklam-gruplari",
        "/reklam-raporu",
        "/rakip-analizi",
        "/rakip-analizi-bilgileri",
        "/tesis-siniflandir",
        "/yorumlari-listele",
        "/bildirim-olustur",
        "/kullanici-ekle",
        "/kullanicilari-listele",
        "/personel-otel-gorevleri",
        "/yetkiler",
        "/loglari-listele",
        "/grup-ekle",
        "/gruplari-listele",
        "/perito-dahili-durum",
        "/rezervasyon-onay-iptal-degisikligi",
        "/rezervasyon-aktar",
        "/dis-ipler",
        "/bilgi-islem",
      ];
    } else if (user.yetki === "otel") {
      authoritiesAccordingToAuthStr = [
        "/dosya-merkezi",
        "/kontroller",
        "/crm-dashboard",
        "/basit-musaitlik-girisi",
        "/cocuk-ozel-yas-bilgileri",
        "/desk-alti-indirimler",
        "/tum-otellere-ait-hediyeler",
        "/indirim-kuponlari",
        "/gece-kisitlamalari",
        "/tanimlamalar",
        "/temalar",
        "/tesis-ekle",
        "/tesis-guncelle",
        "/tesis-resimleri",
        "/tesis-kullanicilari",
        "/tesis-ozel-gunleri",
        "/ozel-gun-listele",
        "/oda-pansiyon-eslestir",
        "/tesis-aramalari",
        "/tesis-parapuan-listesi",
        "/musteri-mailleri",
        "/dahili-durum",
        "/gunluk-cagri-grafigi",
        "/saatlik-cagri-grafigi",
        "/karsilama-ve-hesaplama",
        "/oda-resmi-olmayanlar",
        "/cevaplanan-cagri-hareketleri",
        "/dis-arama-sayilari",
        "/kisilere-gelen-gunluk-cagri",
        "/personel-satislari-listele",
        "/oda-aciklamasi-olmayanlar",
        "/riskli-oteller",
        "/dahili-durum-listesi",
        "/otellere-gelen-gunluk-cagri",
        "/genelden-gelen-cagrilar",
        "/personel-aylik-satis-listesi",
        "/otel-satislari",
        "/tesisler-reklam-gruplari",
        "/reklam-raporu",
        "/rakip-analizi",
        "/rakip-analizi-bilgileri",
        "/kontrat-kurallari",
        "/otel-kontrat-islemleri",
        "/arsivlenmis-kontratlar",
        "/kesilen-faturalar",
        "/mail-order-bilgileri",
        "/site-olustur",
        "/web-site-listesi",
        "/widget-olustur",
        "/tesis-siniflandir",
        "/yorumlari-listele",
        "/yorum-ekle",
        "/yorumsuz-oteller",
        "/bildirim-olustur",
        "/kullanici-ekle",
        "/kullanicilari-listele",
        "/personel-otel-gorevleri",
        "/yetkiler",
        "/loglari-listele",
        "/grup-ekle",
        "/gruplari-listele",
        "/perito-dahili-durum",
        "/rezervasyon-onay-iptal-degisikligi",
        "/rezervasyon-aktar",
        "/dis-ipler",
        "/bilgi-islem",
        "/rezervasyon-cagri",
        "/otel-fiyat-hesaplama",
        "/lokasyon-fiyat-hesaplama",
        "/rezervasyon-girisi",
        "/rezervasyon-incele",
        "/fiyat-hesapla",
        "/rezervasyon-duzenle",
        "/rezervasyon-listele",
        "/rezervasyon-listele-kanal",
        "/gelen-havale-listele",
        "/inet-giris-yapamayan-rezervasyonlar",
        "/toplam-rezervasyon-rakamlari",
        "/konfirme-onay",
        "/not-konfirmeleri-listele",
        "/operasyon-rez-takibi",
        "/sorsat-listele",
        "/fiyat-revize-listesi",
        "/maliyet-revize-listesi",
        "/gecici-online-rezervasyonlar",
        "/grup-rezervasyonlar",
        "/bugun-girisli-kapida-odemeli-rezervasyonlar",
        "/yarin-girisli-kaporali-rezervasyonlar",
        "/iptal-talebi-listesi",
        "/lokasyon-ekle",
        "/lokasyon-listele",
        "/tesis-ozelligi-ekle",
        "/erken-rezervasyon-tarihi-tanimlama",
        "/gezilecek-yer-ekle",
        "/gezilecek-yer-listele",
        "/kur-tanimlama",
        "/tema-ekle",
        "/tema-listele",
        "/tema-otel-ata",
        "/tema-tavsiye-edilen-otel-atama",
        "/tema-otel-siralama",
      ];
    }

    const yetkiler_encoded = btoa(
      JSON.stringify(authoritiesAccordingToAuthStr)
    );

    // const yetkiler_encoded = btoa(JSON.stringify(yetkiler));

    if (token && user) {
      Cookies.set("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("auth", yetkiler_encoded);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      dispatch(loginSuccess(user));

      if (user.yetki === "otel" && user.tanimli_oteller !== null) {
        const hotelId = user.tanimli_oteller[0];
        console.log("hotelId");
        navigate(`/?otel_id=${hotelId}`);
      }
      navigate("/");
    }
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(apiError(message));
    });
  }
};

export const logoutUser: LogoutUser = () => async (dispatch) => {
  try {
    await axios.post(LOGOUT_USER, {});

    dispatch(logoutUserSuccess(true));
    dispatch(resetHotel());
    Cookies.remove("token");
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove("hotel");
    window.location.reload();
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(apiError(message));
    });
  }
};

export const resetLoginFlag: ResetLoginFlag = () => async (dispatch) => {
  try {
    dispatch(reset_login_flag());
  } catch (error) {
    handleErrors(error, (message: string) => {
      dispatch(apiError(message));
    });
  }
};
