import { createSlice } from "@reduxjs/toolkit";
import {
  TemporaryOnlineReservationsData,
  TemporaryOnlineReservationsResponseData,
} from "types/reservationMonitoring";

export interface TemporaryOnlineReservationsInitialState {
  temporaryOnlineReservations: TemporaryOnlineReservationsData[];
  isLoading: boolean;
  error: string;
  updateStatusLoading: boolean;
  updateStatusError: string;
  callCustomerLoading: boolean;
  callCustomerError: string;
}

const initialState: TemporaryOnlineReservationsInitialState = {
  temporaryOnlineReservations: [],
  isLoading: false,
  error: "",
  updateStatusLoading: false,
  updateStatusError: "",
  callCustomerLoading: false,
  callCustomerError: "",
};

const temporaryOnlineReservationsSlice = createSlice({
  name: "temporaryOnlineReservations",
  initialState,
  reducers: {
    handleSaveTemporaryOnlineReservationsResponse(state, action) {
      const payload =
        action.payload as TemporaryOnlineReservationsResponseData[];

      const temporaryOnlineReservations: TemporaryOnlineReservationsData[] =
        payload.map((item) => ({
          otel_adi: item.otel_adi,
          otel_telefon: item.otel_telefon,
          id: item.id,
          rezervasyon_kodu: item.rezervasyon_kodu,
          yetiskin_ad_1: item.yetiskin_ad_1,
          yetiskin_ad_2: item.yetiskin_ad_2,
          sabit_tel: item.sabit_tel,
          cep: item.cep,
          otel_id: item.otel_id,
          data: item.data,
          giris_tarihi: item.giris_tarihi,
          cikis_tarihi: item.cikis_tarihi,
          toplam_tutar: item.toplam_tutar,
          islem_tarihi: item.islem_tarihi,
          islem_durumu: item.islem_durumu,
          bg_color: item.bg_color,
        }));

      state.temporaryOnlineReservations = temporaryOnlineReservations;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUpdateStatusLoading(state, action) {
      state.updateStatusLoading = action.payload;
    },
    setUpdateStatusError(state, action) {
      state.updateStatusError = action.payload;
    },
    setCallCustomerLoading(state, action) {
      state.callCustomerLoading = action.payload;
    },
    setCallCustomerError(state, action) {
      state.callCustomerError = action.payload;
    },
  },
});

export const {
  handleSaveTemporaryOnlineReservationsResponse,
  setIsLoading,
  setError,
  setUpdateStatusLoading,
  setUpdateStatusError,
  setCallCustomerLoading,
  setCallCustomerError
} = temporaryOnlineReservationsSlice.actions;

export default temporaryOnlineReservationsSlice.reducer;
