import { translate } from "../helpers";
import { CreditCardInstallment, TranslatedContent } from "../types";

import styles from "./InstallmentsList.module.scss";

interface InstallmentsListProps {
  hasInstallments: boolean;
  installments: CreditCardInstallment[];
  selectedInstallment: CreditCardInstallment;
  onSelectedInstallment: (installment: CreditCardInstallment) => void;
  common: TranslatedContent[];
}

const InstallmentsList = ({
  hasInstallments,
  installments,
  selectedInstallment,
  onSelectedInstallment,
  common,
}: InstallmentsListProps) => {
  return (
    <>
      {hasInstallments && (
        <div className={styles.container}>
          <p className="">{translate("Taksit Seçenekleri", common)}</p>
          {installments.map((installment) => (
            <label
              key={installment.month}
              className={`${styles.installment} ${
                selectedInstallment.month === installment.month
                  ? styles.selected
                  : ""
              }`}
              htmlFor={"installment" + installment.month.toString()}
            >
              <div className={styles.installment__inner}>
                <div>
                  <input
                    id={"installment" + installment.month.toString()}
                    className="radio radio-secondary radio-sm"
                    type="radio"
                    name="installment"
                    value={installment.month}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onSelectedInstallment(installment);
                      }
                    }}
                    checked={selectedInstallment.month === installment.month}
                  />
                  {installment.month === 1 ? (
                    <p className={styles.installment_text}>
                      {translate("Tek Çekim", common)}
                    </p>
                  ) : (
                    <p className={styles.installment_text}>
                      {" "}
                      {installment.month} {translate("Taksit", common)} x{" "}
                      {installment.amount}₺
                    </p>
                  )}
                </div>

                <p className={styles.installment_sum}>{installment.total}₺</p>
              </div>
            </label>
          ))}
        </div>
      )}
    </>
  );
};

export default InstallmentsList;
