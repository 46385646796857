import useHandleDetails from "./useHandleDetails";
import DetailsHeader from "./DetailsHeader";
import ContentContainer from "../ContentContainer";
import useHandleTabs from "./useHandleTabs";
import parse from "html-react-parser";
import OnRequestModal from "./Modals/OnRequestModal";
import { useState } from "react";
import { ReservationCallInitialState } from "slices/reservation/reservationCall/reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  CalculatePriceFormValues,
  CreateOnRequestPayload,
  RoomOption,
} from "types/reservation";
import { useNavigate } from "react-router-dom";
import { saveOnRequest } from "slices/reservation/reservationCall/thunk";
import { Dispatch } from "redux";

interface FacilityDetailsProps {
  formValues: CalculatePriceFormValues;
  handleSelectRoomToCallLater: (
    oda_adi: string,
    pansiyon_adi: string,
    fiyat: string
  ) => void;
  selectedRoom: RoomOption;
}

const FacilityDetails = ({
  formValues,
  handleSelectRoomToCallLater,
  selectedRoom,
}: FacilityDetailsProps) => {
  const {
    title,
    subtitle,
    featuredImages,
    crm_aciklama,
    crmCheckboxes,
    calculationForm,
    showOnRequestModal,
    toggleOnRequestModal,
    handleGroupReservationRequest,
    handleCreateRequest,
  } = useHandleDetails();

  const { tabButtons, tabContents } = useHandleTabs(
    toggleOnRequestModal,
    handleGroupReservationRequest,
    handleSelectRoomToCallLater,
    selectedRoom
  );

  const { calculatePriceForHotel } = useSelector(
    (state: { ReservationCall: ReservationCallInitialState }) =>
      state.ReservationCall
  );

  return (
    <div className="d-flex flex-column gap-2 border rounded pb-3 overflow-hidden bg-white">
      <DetailsHeader
        title={title}
        subtitle={subtitle}
        featuredImages={featuredImages}
      />
      {crm_aciklama ? (
        <div className="p-3 bg-danger bg-opacity-25">{parse(crm_aciklama)}</div>
      ) : (
        <></>
      )}
      {crmCheckboxes.length > 0 ? (
        <ul className="d-flex flex-column gap-2 p-3 theme-bg-success">
          {crmCheckboxes.map((checkbox, index) => (
            <li
              key={index}
              className="w-100 d-flex justify-content-center align-items-center gap-2"
            >
              <i className="ri-check-double-fill fs-20"></i>
              <span className="fw-medium text-center">{checkbox}</span>
            </li>
          ))}
        </ul>
      ) : (
        <></>
      )}
      <ContentContainer tabButtons={tabButtons} tabContents={tabContents} />
      {showOnRequestModal && (
        <OnRequestModal
          hotelInfo={calculatePriceForHotel}
          hotel_id={calculatePriceForHotel.arama_sorgu.sorgu_id}
          formInfo={formValues}
          isOpen={showOnRequestModal}
          handleToggle={toggleOnRequestModal}
          handleCreateRequest={handleCreateRequest}
          calculationForm={calculationForm}
        />
      )}
    </div>
  );
};

export default FacilityDetails;
