import { Label, Row } from "reactstrap";
import { ListOnRequestReservationsData } from "types/reservationMonitoring";

interface Option {
  label: string;
  value: string;
}

interface ReservationInfoFields {
  reservationData: Option[];
}

interface ModalInfoProps {
  reservation: ListOnRequestReservationsData;
}

const ModalInfo = ({ reservation }: ModalInfoProps) => {
  const data: ReservationInfoFields = {
    reservationData: [
      { label: "Otel Adı", value: reservation.otel_adi? reservation.otel_adi :  " " },
      { label: "Giriş Tarihi", value: reservation.giris_tarihi ? reservation.giris_tarihi :  " " },
      {
        label: "Çıkış Tarihi",
        value: reservation.cikis_tarihi ? reservation.cikis_tarihi : "",
      },
    ],
  };

  return (
    <>
      <Row className="gap-3 mx-2 my-4">
        {data.reservationData.map((info) => (
          <div key={info.label} className="d-flex gap-2">
            <Label>{info.label} : </Label>
            <p style={{ margin: "0" }}>{info.value}</p>
          </div>
        ))}
      </Row>
    </>
  );
};

export default ModalInfo;
