import { CustomRowOperationButton } from "Components/Custom/UI";
import { useDispatch, useSelector } from "react-redux";
import { GroupReservationsInitialState } from "slices/reservationMonitoring/groupReservations/reducer";
import {
  ChangeGroupReservationStatusPayload,
  GroupReservationsData,
  MarkAsResponsibleGroupReservation,
  UpdateGroupReservationsPayload,
} from "types/reservationMonitoring";
import { TABLE_COLORS } from "utils/tableColors";
import { Dispatch, SetStateAction } from "react";
import {
  approveGroupReservation,
  cancelGroupReservation,
  listGroupReservations,
  markAsResponsible,
  updateGroupReservation,
} from "slices/reservationMonitoring/groupReservations/thunk";
import { setToastError } from "slices/toast/reducer";

const useHandleTable = (
  setSelectedReservationId: Dispatch<SetStateAction<number>>,
  setShowUpdateStatusModal: Dispatch<SetStateAction<boolean>>,
  setShowDetailModal: Dispatch<SetStateAction<boolean>>
) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { groupReservations, updateStatusLoading, changeStatusLoading } =
    useSelector(
      (state: { GroupReservations: GroupReservationsInitialState }) =>
        state.GroupReservations
    );

  const handleUpdateGroupReservation = (
    payload: UpdateGroupReservationsPayload
  ) => {
    try {
      dispatch(
        updateGroupReservation(payload, () => {
          setShowUpdateStatusModal((prevState) => !prevState);
          dispatch(listGroupReservations());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleApproveGroupReservation = (
    payload: ChangeGroupReservationStatusPayload
  ) => {
    try {
      dispatch(
        approveGroupReservation(payload, () => {
          setShowDetailModal((prevState) => !prevState);
          dispatch(listGroupReservations());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleCancelGroupReservation = (
    payload: ChangeGroupReservationStatusPayload
  ) => {
    try {
      dispatch(
        cancelGroupReservation(payload, () => {
          setShowDetailModal((prevState) => !prevState);
          dispatch(listGroupReservations());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const handleMarkAsResponsible = (
    payload: MarkAsResponsibleGroupReservation
  ) => {
    try {
      dispatch(
        markAsResponsible(payload, () => {
          dispatch(listGroupReservations());
        })
      );
    } catch (error) {
      setToastError(error);
    }
  };

  const tableData = groupReservations?.map((reservation) => {
    let rowColor = "";

    if (reservation.konfirme_edildi_mi === "e" && reservation.iptal_edildi_mi === "h") {
      rowColor = TABLE_COLORS.green;
    } else if (reservation.iptal_edildi_mi === "e" && reservation.konfirme_edildi_mi === "h") {
      rowColor = TABLE_COLORS.red;
    } else if (reservation.islem_durum === "1") {
      rowColor = TABLE_COLORS.orange;
    } else {
      rowColor = TABLE_COLORS.yellow;
    }

    return {
      id: reservation.id,
      musteri_ad_soyad: reservation.musteri_ad_soyad,
      otel_adi: reservation.otel_adi,
      otel_id: reservation.otel_id,
      musteri_cep: reservation.musteri_cep,
      giris_tarihi: reservation.giris_tarihi,
      cikis_tarihi: reservation.cikis_tarihi,
      yetiskin_sayisi: reservation.yetiskin_sayisi,
      kayit_yapan_ad: reservation.kayit_yapan_ad,
      kayit_tarihi: reservation.kayit_tarihi,
      islemler: reservation.islemler,
      konfirme_edildi_mi: reservation.konfirme_edildi_mi,
      iptal_edildi_mi: reservation.iptal_edildi_mi,
      guncelleme_yapan_ad: reservation.guncelleme_yapan_ad,
      islem_durum: reservation.islem_durum,
      operasyon_uye_ad_soyad: reservation.operasyon_uye_ad_soyad,
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "rowNumber",
      enableColumnFilter: false,
      cell: ({ row }: { row: { index: number } }) => <>{row.index + 1}</>,
    },
    {
      header: "Yetkili",
      accessorKey: "operasyon_uye_ad_soyad",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "60px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Müşteri",
      accessorKey: "musteri_ad_soyad",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "60px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "60px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Müşteri Cep",
      accessorKey: "musteri_cep",
      enableColumnFilter: false,
    },
    {
      header: "Giriş Tarihi",
      accessorKey: "giris_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Çıkış Tarihi",
      accessorKey: "cikis_tarihi",
      enableColumnFilter: false,
    },
    {
      header: "Konuk Sayısı",
      accessorKey: "yetiskin_sayisi",
      enableColumnFilter: false,
    },
    {
      header: "Kayıt Yapan",
      accessorKey: "kayit_yapan_ad",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "60px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Kayıt",
      accessorKey: "kayit_tarihi",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "80px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "İlgilenen",
      accessorKey: "guncelleme_yapan_ad",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();
        return (
          <>
            <div className="text-wrap" style={{ width: "60px" }}>
              {value}
            </div>
          </>
        );
      },
    },
    {
      header: "Durum",
      accessorKey: "islemler",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const value = cell.getValue();

        const formattedLines = value
          ?.split("(")
          .filter((line: string) => line.trim() !== "") || [""];

        return (
          <>
            <div className="text-wrap" style={{ width: "200px" }}>
              {formattedLines.map((line: any, index: any) => (
                <div key={index}>
                  {line !== "" ? <i className="ri-arrow-right-line" /> : ""} (
                  {line}
                </div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      header: "İşlemler",
      accessorKey: "grup_id",
      enableColumnFilter: false,
      cell: (cell: any) => {
        const rowData: GroupReservationsData = cell.row.original;
        const id = rowData.id;
        return (
          <>
            <div className="d-flex justify-content-center gap-2">
              <CustomRowOperationButton
                variant="update"
                onClick={() => {
                  setSelectedReservationId(id);
                  setShowUpdateStatusModal((prevState) => !prevState);
                }}
                title="Durumu Güncelle"
              />
              <CustomRowOperationButton
                variant="detail"
                onClick={() => {
                  setSelectedReservationId(id);
                  setShowDetailModal((prevState) => !prevState);
                }}
                title="İncele"
              />
              <CustomRowOperationButton
                variant="check"
                onClick={() =>
                  handleMarkAsResponsible({
                    rezervasyon_id: id,
                  })
                }
                title="İlgileniyorum"
              />
            </div>
          </>
        );
      },
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.green,
      label: "Onaylandı",
    },
    {
      color: TABLE_COLORS.red,
      label: "İptal",
    },
    {
      color: TABLE_COLORS.orange,
      label: "İlgileniyor",
    },
    {
      color: TABLE_COLORS.yellow,
      label: "ilgilenilmiyor",
    },
  ];

  return {
    tableData,
    tableColumns,
    legend,
    reservations: groupReservations,
    updateStatusLoading,
    changeStatusLoading,
    handleUpdateGroupReservation,
    handleApproveGroupReservation,
    handleCancelGroupReservation,
  };
};

export default useHandleTable;
