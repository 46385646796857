import { CustomFormFieldContainer } from "Components/Custom/UI";
import { Input } from "reactstrap";
import { NoteConfirmationPayload } from "types/reservationLists";

interface HotelNameInputProps {
  values: NoteConfirmationPayload;
  handleFormChange: (key: string, value: string) => void;
}

const HotelNameInput = ({
  values,
  handleFormChange,
}: HotelNameInputProps) => {


  return (
    <>
      <CustomFormFieldContainer label="Otel Adı :">
        <Input
          type="text"
          autoCorrect="on"
          value={values.otel_adi}
          onChange={(e) => {
            handleFormChange("otel_adi", e.target.value);
          }}
        />
      </CustomFormFieldContainer>
    </>
  );
};

export default HotelNameInput;
