interface ReservationCardProps {
  title: string;
  value: string;
  color: string;
  cardIcon: string;
}
const ReservationCard = ({
  title,
  value,
  color,
  cardIcon,
}: ReservationCardProps) => {
  return (
    <div
      className="d-flex align-items-center justify-content-start gap-4 rounded"
      style={{
        backgroundColor: color,
        padding: "12px 24px",
      }}
    >
      <div
        className={cardIcon}
        style={{ fontSize: "50px", color: "white", flexShrink: 0 }}
      ></div>
      <div className="d-flex flex-column align-items-start gap-1 text-white">
        <span className="fw-semibold fs-24">{value}</span>
        <span className="fs-16">{title}</span>
      </div>
    </div>
  );
};

export default ReservationCard;
