import FilterForm from "./FilterForm";
import TodayEntrancePayAtTheDoorTable from "./TodayEntrancePayAtTheDoorTable";

interface TodayEntrancePayAtTheDoorPageProps {
  isLoading: boolean;
}

const TodayEntrancePayAtTheDoorPage = ({
  isLoading,
}: TodayEntrancePayAtTheDoorPageProps) => {

  return (
    <div className="d-flex flex-column gap-4">
      <FilterForm />
      <TodayEntrancePayAtTheDoorTable isLoading={isLoading} />
    </div>
  );
};

export default TodayEntrancePayAtTheDoorPage;
