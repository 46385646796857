import pdfMake from "pdfmake/build/pdfmake";
import { PageOrientation, PageSize } from "pdfmake/interfaces";
import CustomButton from "../CustomButton";

// Set the fonts for pdfMake
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface CustomPdfExportProps {
  objectToPrint: any[];
  fileName: string;
}

const CustomPdfExport = ({ objectToPrint, fileName }: CustomPdfExportProps) => {
  const pageOrientation: PageOrientation = "landscape";
  const pageSize: PageSize = "A4";

  // Helper function to generate table body from array of objects
  const generateTableBody = (data: any[]): any[][] => {
    if (data.length === 0) return [];

    const headers = Object.keys(data[0]).map((key) => ({
      text: key.charAt(0).toUpperCase() + key.slice(1),
      bold: true,
      fillColor: "#2C3E50",
      color: "#FFFFFF",
      alignment: "center",
    }));

    const body = [headers];

    data.forEach((item: Object) => {
      const row: any = Object.values(item).map((value) => ({
        text: value !== null ? value.toString() : "",
        alignment: "center",
      }));
      body.push(row);
    });

    return body;
  };

  const generatePdf = () => {
    const tableBody = generateTableBody(objectToPrint);

    const docDefinition = {
      pageOrientation,
      pageSize,
      pageMargins: [20, 20, 20, 20], // Adjust margins to fit more content
      content: [
        {
          text: fileName,
          style: "header",
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          table: {
            headerRows: 1,
            widths: tableBody[0].map(() => "*"),
            body: tableBody,
            alignment: "center",
          },
          layout: {
            hLineWidth: (i: number, node: any) =>
              i === 0 || i === node.table.body.length ? 1 : 0.5,
            vLineWidth: (i: number, node: any) =>
              i === 0 || i === node.table.widths.length ? 1 : 0.5,
            hLineColor: (i: number, node: any) =>
              i === 0 || i === node.table.body.length ? "black" : "gray",
            vLineColor: (i: number, node: any) =>
              i === 0 || i === node.table.widths.length ? "black" : "gray",
            paddingLeft: (i: number) => 3,
            paddingRight: (i: number) => 3,
            paddingTop: (i: number) => 3,
            paddingBottom: (i: number) => 3,
          },
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
      },
      defaultStyle: {
        fontSize: 7, // Reduce font size to fit more content
      },
    };

    pdfMake.createPdf(docDefinition as any).download(`${fileName}.pdf`);
  };

  return (
    <div>
      <CustomButton disabled={(objectToPrint??[]).length === 0} onClick={generatePdf}>
        İndir (.pdf)
      </CustomButton>
    </div>
  );
};

export default CustomPdfExport;
