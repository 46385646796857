import ReservationCard from "./ReservationCard";
import useHandleReservationData from "./useHandleReservationData";

const InfoCards = () => {
  const { cards } = useHandleReservationData();

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between">
        {cards.map((card, index) => (
          <ReservationCard
            key={index}
            title={card.title}
            value={card.value}
            color={card.color}
          />
        ))}
      </div>
    </>
  );
};

export default InfoCards;
