import { useSelector } from "react-redux";
import { HotelsWithoutPriceInitialState } from "slices/documents/hotelsWithoutPrice/reducer";
import { TABLE_COLORS } from "utils/tableColors";

const useHandleTable = () => {
  const { hotelsWithoutPrice } = useSelector(
    (state: { HotelsWithoutPrice: HotelsWithoutPriceInitialState }) => state.HotelsWithoutPrice
  );

  const tableData= hotelsWithoutPrice?.map((webSite) => {
    let rowColor = "";
    // if (hotelsWithoutPrice.otel_aktiflik === "0") {
    //   rowColor = TABLE_COLORS.red;
    // } else {
    //   rowColor = TABLE_COLORS.green;
    // }

    return {
      rowColor: rowColor,
    };
  });

  const tableColumns = [
    {
      header: "Sıra",
      accessorKey: "otel_id",
      enableColumnFilter: false,
    },
    {
      header: "Otel Id",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "Otel Adı",
      accessorKey: "otel_adi",
      enableColumnFilter: false,
    },
    {
      header: "İl / İlçe",
      accessorKey: "id",
      enableColumnFilter: false,
    },
    {
      header: "Vitrin Fiyatı",
      accessorKey: "domain_link",
      enableColumnFilter: false,
    },
    {
      header: "Fiyat Listesi",
      accessorKey: "aktiflik_text",
      enableColumnFilter: false,
    },
    {
      header: "Tedarikçi",
      accessorKey: "aktiflik_text",
      enableColumnFilter: false,
    },
    {
      header: "Otel Aktiflik",
      accessorKey: "otel_aktiflik",
      enableColumnFilter: false,
    },
  ];

  const legend = [
    {
      color: TABLE_COLORS.red,
      label: "Pasif",
    },
    {
      color: TABLE_COLORS.green,
      label: "Aktif",
    },
  ];

  return { tableData, tableColumns, legend };
};

export default useHandleTable;
