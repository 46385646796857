import { useSelector } from "react-redux";
import { ANALYSIS_COLORS } from "utils/analysisColors";
import { ListCostReviseInitialState } from "slices/reservationMonitoring/listCostRevise/reducer";

const useHandleCards = () => {
  const { costRevise, dataCards } = useSelector(
    (state: { ListCostRevise: ListCostReviseInitialState }) =>
      state.ListCostRevise
  );

  const icons = {
    money: "ri-wallet-3-line",
    room: "ri-hotel-bed-line",
    hotel: "ri-hotel-line",
  };

  const cards = [
    {
      title: "Toplam Rezervasyon",
      value: `${costRevise?.length}`,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.hotel,
    },
    {
      title: "Tamamlanan Tutar",
      value: `${dataCards?.toplam_gerceklesen_tutar} TL`,
      color: ANALYSIS_COLORS.green,
      Icon: icons.money,
    },
    {
      title: "Bekleyen Tutar",
      value: `${dataCards?.toplam_beklenen_tutar} TL`,
      color: ANALYSIS_COLORS.yellow,
      Icon: icons.money,
    },
    {
      title: "İptal Tutar",
      value: `${dataCards?.toplam_iptal_tutar} TL`,
      color: ANALYSIS_COLORS.red,
      Icon: icons.money,
    },
    {
      title: "Toplam OtelFiyat Oda Sayısı",
      value: `${dataCards?.toplam_otelfiyat_oda_sayisi}`,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.room,
    },
    {
      title: "Toplam OtelFiyat Online Oda Sayısı",
      value: `${dataCards?.toplam_otelfiyat_online_oda_sayisi}`,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.room,
    },
    {
      title: "Toplam OtelFiyat Satışı",
      value: `${dataCards?.toplam_otelfiyat_satisi} TL`,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.money,
    },
    {
      title: "Toplam Çağrı Merkezi Oda Sayısı",
      value: `${dataCards?.toplam_cagri_merkezi_oda_sayisi}`,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.room,
    },
    {
      title: "Toplam Çağrı Merkezi Online Oda Sayısı",
      value: `${dataCards?.toplam_cagri_merkezi_online_oda_sayisi}`,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.room,
    },
    {
      title: "Toplam Çağrı Merkezi Satışı",
      value: `${dataCards?.toplam_cagri_merkezi_satisi} TL`,
      color: ANALYSIS_COLORS.blue,
      Icon: icons.money,
    },
  ];

  return {
    cards,
  };
};

export default useHandleCards;
