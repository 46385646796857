import { CustomReactTable } from "Components/Custom/UI";
import useHandleTable from "./useHandleTable";
import useHandlePagination from "./useHandlePagination";
import { useState } from "react";
import UpdateAvailabilityModal from "./Modals/UpdateAvailabilityModal";

interface ReservationsTableProps {
  isLoading: boolean;
}

const ReservationsTable = ({ isLoading }: ReservationsTableProps) => {
  const [selectedReservationId, setSelectedReservationId] = useState(0);
  const [showUpdateAvailabilityModal, setShowUpdateAvailabilityModal] =
    useState(false);

  const {
    tableData,
    tableColumns,
    columnVisibility,
    legend,
    availabilityResponse,
    handleUpdateAvailabilityData,
  } = useHandleTable(setSelectedReservationId, setShowUpdateAvailabilityModal);

  const { handleChange, paginationBottomProps } = useHandlePagination();

  return (
    <>
      <CustomReactTable
        pagination={true}
        tableData={tableData}
        tableColumns={tableColumns}
        loading={isLoading}
        onSearch={(searchValue) => {
          handleChange("search", searchValue);
        }}
        onPageSizeChange={(pageSize) => {
          handleChange("per_page", pageSize);
        }}
        paginationBottomProps={paginationBottomProps}
        legend={legend}
        columnVisibility={columnVisibility}
      />
      {selectedReservationId !== 0 && (
        <UpdateAvailabilityModal
          availabilityResponse={availabilityResponse}
          reservation_id={selectedReservationId}
          isOpen={showUpdateAvailabilityModal}
          handleToggle={() =>
            setShowUpdateAvailabilityModal((prevState) => !prevState)
          }
          updateAvailabilityData={handleUpdateAvailabilityData}
        />
      )}
    </>
  );
};

export default ReservationsTable;
